import {Component, Input, OnInit} from '@angular/core';
import { BoardService } from '../../utils/services/board.service';
import {Column} from '../../utils/models/column.model';
import {ScrumBoadService} from '../../utils/services/scrum-boad.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SprintService} from '../../../../services/sprint/sprint.service';
import {Sprint} from '../../../../models/Sprint';
@Component({
  selector: 'ngx-board-header',
  templateUrl: './board-header.component.html',
  styleUrls: ['./board-header.component.scss'],
})
export class BoardHeaderComponent implements OnInit {
  sprint: Sprint = new Sprint();
  newColumn: Column;
  selectedSprintId: string;
  constructor(
    public boardService: BoardService,
    private boardApi: ScrumBoadService,
    private router: Router,
    private route: ActivatedRoute,
    private sprintService: SprintService ,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selectedSprintId = params['id'];
    });
    this.sprintService.findProjectById(this.selectedSprintId).subscribe({
      next: (result) => {
        this.sprint = result;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  addColumn(event: string) {
    if (event) {
      // this.boardService.addColumn(event);
      this.newColumn = new Column();
      this.newColumn.title = event;
      this.newColumn.list = [];
      this.newColumn.sprintId = this.selectedSprintId;
      this.boardApi.addColumn(this.newColumn).subscribe((res: Column) => {
        console.log(res);
        this.boardService.board$.next([...this.boardService.board, this.newColumn]);
      });
    }
  }

  navigateToScrumProcess() {
    this.router.navigate([`/projectManagement/scrum-process`] , { queryParams: { projectId: this.sprint.project }});
  }

}
