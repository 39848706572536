import { Component } from '@angular/core';

@Component({
  selector: 'ngx-pdf-agenda',
  templateUrl: './pdf-agenda.component.html',
  styleUrls: ['./pdf-agenda.component.scss']
})
export class PdfAgendaComponent {

}
