import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from './../../../../environments/environment';
import {DataDescription} from '../models/DataDescription';
import {DataCleaningOptions} from '../models/DataCleaningOptions'


@Injectable({
  providedIn: 'root'
})
export class CrispService {
  psUrl = environment.psUrl;
  private dataInfoSubject = new BehaviorSubject<any>(null);
  private dataDescriptionSubject = new BehaviorSubject<any>(null);

  dataInfo$ = this.dataInfoSubject.asObservable();
  dataDescription$ = this.dataDescriptionSubject.asObservable();


  constructor(private http: HttpClient) { }
  createBusinessUnderstanding(formData: any): Observable<any> {
    return this.http.post(`${this.psUrl}understanding`, formData);
  }
 uploadCSV(file: File, projectId: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', projectId);

    return this.http.post(`${this.psUrl}datadescription/processCSV`, formData);
  }
  uploadDocument(projectId: string, fileData: string, type: string): Observable<void> {
    const formData = new FormData();
    formData.append('file', this.base64ToBlob(fileData));
    formData.append('type', type);

    return this.http.post<void>(`${this.psUrl}understanding/${projectId}/uploadDocument`, formData);
  }

  downloadDocument(projectId: string, type: string): Observable<Blob> {
    let params = new HttpParams();
    params = params.append('type', type);

    return this.http.get(`${this.psUrl}understanding/${projectId}/downloadDocument`, {
      params: params,
      responseType: 'blob'
    });
  }

  private base64ToBlob(base64: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf' });
  }

  getDataDescriptions(projectId: string): Observable<DataDescription[]> {
    return this.http.get<DataDescription[]>(`${this.psUrl}datadescription/project/${projectId}`);
  }
  createOrUpdate(businessUnderstanding: any, projectId: string): Observable<any> {
    return this.http.post<any>(`${this.psUrl}understanding/${projectId}`, businessUnderstanding);
  }
  getBusinessUnderstandingByProjectId(projectId: string): Observable<any> {
    return this.http.get<any>(`${this.psUrl}understanding/project/${projectId}`);
  }
  deleteDataDescriptions(projectId: string): Observable<void> {
    return this.http.delete<void>(`${this.psUrl}datadescription/delete-by-project/${projectId}`);
  }
  uploadFile(file: File,projectId: String): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.psUrl}file/${projectId}/upload`, formData);
  }

  cleanData(projectId: String): Observable<any> {
    return this.http.get(`${this.psUrl}file/${projectId}/clean-data`);
  }

  saveSelection(projectId: String,selectedColumns: string[], selectedRows: any[]): Observable<any> {
    const selection = { selectedColumns, selectedRows };
    return this.http.post(`${this.psUrl}file/${projectId}/save-selection`, selection);
  }
  deleteFile(id: string): Observable<any> {
    return this.http.delete(`${this.psUrl}file/delete-file${id}`);
  }
  setDataInfo(dataInfo: any) {
    this.dataInfoSubject.next(dataInfo);
  }

  setDataDescription(dataDescription: any) {
    this.dataDescriptionSubject.next(dataDescription);
  }
  fetchDataInfo(projectId: string, fileId: string): Observable<Map<string, number>> {
    return this.http.get<Map<string, number>>(`${this.psUrl}datadescription/info/${projectId}/${fileId}`);
  }

  fetchDataDescription(projectId: string, fileId: string): Observable<Map<string, any>> {
    return this.http.get<Map<string, any>>(`${this.psUrl}datadescription/description/${projectId}/${fileId}`);
  }
  getFiles(projectId: string): Observable<any> {
    return this.http.get(`${this.psUrl}file/${projectId}/listfiles`);
  }

  getFile(fileId: string): Observable<any> {
    return this.http.get(`${this.psUrl}file/${fileId}`);
  }

  processFile(fileId: string): Observable<any> {
    return this.http.post(`${this.psUrl}datadescription/process/${fileId}`, {});
  }
  cleanDataAdvanced(projectId: string, options: DataCleaningOptions): Observable<any> {
    return this.http.post<any>(`${this.psUrl}file/clean-data-advanced/${projectId}`, options);
  }
}
