import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { DataCleaningOptions } from 'app/modules/project-management/models/DataCleaningOptions';
import { CrispService } from 'app/modules/project-management/services/crisp.service';

@Component({
  selector: 'ngx-select-clean',
  templateUrl: './select-clean.component.html',
  styleUrls: ['./select-clean.component.scss']
})
export class SelectCleanComponent {
  uploadClicked: boolean = false;
  cleanClicked: boolean = false;
  selectedFile: File;
  projectId: string;
  cleanedData: any;
  columns: string[] = [];
  rows: any[] = [];
  selectedColumns: string[] = [];
  selectedRows: any[] = [];
  allColumnsSelected: boolean = false;
  allRowsSelected: boolean = false;
  options: DataCleaningOptions = {
    removeDuplicates: false,
    imputeMissingValues: false,
    normalizeData: false,
    removeOutliers: false,
    oneHotEncode: false
  };

  constructor(private fileUploadService: CrispService, private route: ActivatedRoute, private toastrService: NbToastrService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
    });
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    // Read file to get columns and rows (this is a simplified version)
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csv = e.target.result;
      const lines = csv.split('\n');
      this.columns = lines[0].split(',');
      this.rows = lines.slice(1).map(line => line.split(','));
    };
    reader.readAsText(this.selectedFile);
  }

  onUpload() {
    this.fileUploadService.uploadFile(this.selectedFile, this.projectId).subscribe(
      response => {
        this.uploadClicked = true;
        this.toastrService.success('File uploaded successfully!', 'Success');
      },
      error => {
        this.toastrService.danger('File upload failed.', 'Error');
      }
    );
  }
  onCleanData() {
    this.fileUploadService.cleanData(this.projectId).subscribe(
      response => {
        this.cleanClicked = true;
        this.toastrService.success('Data cleaned successfully!', 'Success');
      },
      error => {
        this.toastrService.danger('Data cleaning failed.', 'Error');
      }
    );
  }
  onCleanDataAdvanced() {
    this.fileUploadService.cleanDataAdvanced(this.projectId, this.options).subscribe(
      data => {
        this.cleanedData = data;
        this.toastrService.success('Advanced data cleaning completed successfully!', 'Success');
      },
      error => {
        this.toastrService.danger('Advanced data cleaning failed.', 'Error');
      }
    );
  }

  onSelectColumn(event, column: string) {
    if (event.target.checked) {
      this.selectedColumns.push(column);
    } else {
      this.selectedColumns = this.selectedColumns.filter(col => col !== column);
    }
    this.allColumnsSelected = this.selectedColumns.length === this.columns.length;
  }

  onSelectRow(event, row: any) {
    if (event.target.checked) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows = this.selectedRows.filter(r => r !== row);
    }
    this.allRowsSelected = this.selectedRows.length === this.rows.length;
  }

  onSaveSelection() {
    this.fileUploadService.saveSelection(this.projectId, this.selectedColumns, this.selectedRows).subscribe(
      response => {
        this.toastrService.success('Selection saved successfully!', 'Success');
      },
      error => {
        this.toastrService.danger('Failed to save selection.', 'Error');
      }
    );
  }
  selectAllColumns(event) {
    this.allColumnsSelected = event.target.checked;
    if (this.allColumnsSelected) {
      this.selectedColumns = [...this.columns];
    } else {
      this.selectedColumns = [];
    }
  }

  selectAllRows(event) {
    this.allRowsSelected = event.target.checked;
    if (this.allRowsSelected) {
      this.selectedRows = [...this.rows];
    } else {
      this.selectedRows = [];
    }
  }
}
