import { Component, OnInit } from '@angular/core';
import {CalenderService} from '../../../services/calender/calender.service';
import {Meeting} from '../../../models/meeting';
import {Auth} from '../../../../auth/model/Auth';
import {MeetingType} from '../../../models/enum/MeetingType';
import {AuthService} from '../../../../auth/service/auth.service';
import {TokenStorageService} from '../../../../auth/service/token/token.service';
import {Employee} from '../../../models/Employee';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';
import { Project } from 'app/modules/project-management/models/Project';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'ngx-create-multiple-meet',
  templateUrl: './create-multiple-meet.component.html',
  styleUrls: ['./create-multiple-meet.component.scss'],
})
export class CreateMultipleMeetComponent implements OnInit {
  positions = NbGlobalPhysicalPosition;

  meet: Meeting = new Meeting();
  users: Auth[] = [];
  selectedType: MeetingType;
  typeMeetings: MeetingType[] = [
    MeetingType.SprintPlanning,
    MeetingType.DailyScrum,
    MeetingType.SprintReview,
    MeetingType.SprintRetrospective,
    MeetingType.BacklogRefinement,
];
  meetTypes = [
    {name : 'Planning', value : MeetingType.SprintPlanning},
    {name : 'Daily', value : MeetingType.DailyScrum},
    {name : 'Review', value : MeetingType.SprintReview},
    {name : 'Retrospective', value : MeetingType.SprintRetrospective},
    {name : 'Refinement', value : MeetingType.BacklogRefinement},
  ];
  emp: Employee = new Employee();
  usersLoaded = false;
  userConnected = JSON.parse(localStorage.getItem('auth-user'));
  public connectedUser: Auth = new Auth();
  projectId: string;
  project: Project;
  projectMembers: string[] = [];
  protected readonly Object = Object;
  private routeSubscription: Subscription;
  repeatDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  meetDurations = [];
  constructor(private projectService: ProjectService, private calendarService: CalenderService, private authService: AuthService,
              private tokenStorageService: TokenStorageService, private route: ActivatedRoute,
              private toastrService: NbToastrService) {
                for (let number = 1; number <= 24; number += 0.5) {
                  this.meetDurations.push(number);
                }
  }

  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        this.projectId = params['projectId'];
        this.project = new Project();
        this.projectService.findProjectById(this.projectId).subscribe({
          next: (value: Project) => {
            this.project = value;
            console.log(value);

            this.projectMembers = [...this.project?.memberList];
            console.log(this.projectMembers);

          },
          error: (err) => {
            console.log(err);
          },
        });
      });
  }

  createMeeting(): void {
    // Set the idProject in your 'this.meet' object, assuming it has a property 'project'
    this.meet.idProject = this.projectId;
    const uniqueString = uuidv4();
    this.meet.recursiveKey = uniqueString;
    // Rest of your existing code for adding the meeting

    this.calendarService.addMultipleMeetings(this.meet).subscribe(
      (response: any) => {
        window.location.reload();
      },
      (error: any) => {
        console.error('Error creating meeting', error);
      },
    );
  }

  // ngOnDestroy() {
  //   // Don't forget to unsubscribe to avoid memory leaks
  //   this.routeSubscription.unsubscribe();
  // }


}
