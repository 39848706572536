import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalenderService } from 'app/modules/project-management/services/calender/calender.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MeetingMinutes } from 'app/modules/project-management/models/MeetingMinutes';
import { ActionItem } from 'app/modules/project-management/models/ActionItem';
import { AgendaItem } from 'app/modules/project-management/models/AgendaItem';


@Component({
  selector: 'ngx-assign-action-items',
  templateUrl: './assign-action-items.component.html',
  styleUrls: ['./assign-action-items.component.scss']
})
export class AssignActionItemsComponent implements OnInit {
  statusColor: string = 'success';
  flippedState: boolean = false;

  @Input() meetingMinutes: MeetingMinutes = {
    id: '',
    agendaId: '',
    date: '',
    time: '',
    location: '',
    agendaName: '',
    agendaDescription: '',
    description: '',
    finalNotes: '',
    participants: [], 
    agendaItems: [], 
    actionItems: []
  };
  unassignedActionItems: ActionItem[] = [];
  id: string;
  agendaItemIds: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private agendaService: CalenderService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id') || '';
      this.fetchMeetingMinutes(this.id);
    });
  }

  fetchMeetingMinutes(id: string): void {
    this.agendaService.getMeetingMinutesById(id).subscribe(
      (data: MeetingMinutes) => {
        this.meetingMinutes = data || {
          id: '',
          agendaId: '',
          date: '',
          time: '',
          location: '',
          agendaName: '',
          agendaDescription: '',
          description: '',
          finalNotes: '',
          participants: [], 
          agendaItems: [], 
          actionItems: []
        };
        console.log('Data fetched:', this.meetingMinutes);

        this.unassignedActionItems = this.extractUnassignedActionItems(data.agendaItems, data.actionItems);
        this.agendaItemIds = this.meetingMinutes.agendaItems.map((_, index) => 'agendaItemList-' + index);
        console.log('Unassigned Action Items:', this.unassignedActionItems);

        // Manually trigger change detection
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error fetching meeting minutes:', error);
      }
    );
  }

  getConnectedDropListIds(): string[] {
    return ['unassigned', ...this.agendaItemIds];
  }

  extractUnassignedActionItems(agendaItems: AgendaItem[], allActionItems: ActionItem[]): ActionItem[] {
    console.log('Extracting Unassigned Action Items');
    const assignedActionItems = agendaItems.reduce((acc, item) => acc.concat(item.actionItems), []);
    return allActionItems.filter(actionItem =>
      !assignedActionItems.some(item => item?.id === actionItem.id)
    );
  }

  drop(event: CdkDragDrop<ActionItem[]>, agendaItem?: AgendaItem): void {
    console.log("Drop event triggered:", event);
    console.log("Previous container:", event.previousContainer);
    console.log("Current container:", event.container);

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      console.log("Item moved within the same container");
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      console.log("Item transferred between containers");

      if (agendaItem) {
        agendaItem.actionItems = event.container.data;
        // Update the unassignedActionItems list dynamically
        this.unassignedActionItems = this.extractUnassignedActionItems(this.meetingMinutes.agendaItems, this.meetingMinutes.actionItems);
        console.log('Updated Unassigned Action Items:', this.unassignedActionItems);
        console.log('Updated Agenda Items:', this.meetingMinutes.agendaItems);
      }
    }

    // Manually trigger change detection
    this.cdr.detectChanges();
    this.saveAssignments()
  }
  saveAssignments(): void {
    this.agendaService.updateMeetingMinutes(this.meetingMinutes.id, this.meetingMinutes).subscribe(
      response => {
        console.log('Assignments saved:', response);
      },
      error => {
        console.error('Error saving assignments:', error);
      }
    );
  }
  saveAssignments2(): void {
    // Group action items by agenda item ID
    const actionItemsByAgendaItemId = this.meetingMinutes.actionItems.reduce((acc, actionItem) => {
      const agendaItem = this.meetingMinutes.agendaItems.find(item => item.id === actionItem.agendaItemId);
      if (agendaItem) {
        if (!acc[agendaItem.id]) {
          acc[agendaItem.id] = [];
        }
        acc[agendaItem.id].push(actionItem);
      }
      return acc;
    }, {});
  
    // Iterate over each agenda item and assign the corresponding action items
    this.meetingMinutes.agendaItems.forEach(agendaItem => {
      const actionItems = actionItemsByAgendaItemId[agendaItem.id] || [];
      this.agendaService.assignActionItemsToAgendaItem(
        this.meetingMinutes.id,
        agendaItem.id,
        actionItems
      ).subscribe(
        response => {
          console.log('Assignments saved:', response);
        },
        error => {
          console.error('Error saving assignments:', error);
        }
      );
    });
  }
 
  
}
