<nb-card>
  <nb-card-header>Create Meeting Minutes</nb-card-header>
  <nb-card-body>
    <form [formGroup]="meetingMinutesForm" (ngSubmit)="onSubmit()">
      <div class="container text-center">
        <div class="row">
          <div class="col">
            <div class="form-group text-left">
              <label for="date">Date</label>
              <input nbInput fullWidth id="date" formControlName="date" type="date" disabled readonly />
            </div>
          </div>
          <div class="col">
            <div class="form-group text-left">
              <label for="time">Time</label>
              <input nbInput fullWidth id="time" formControlName="time" type="time" disabled readonly />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group text-left">
              <label for="location">Location</label>
              <input nbInput fullWidth id="location" formControlName="location" disabled readonly />
            </div>
          </div>
          <div class="col">
            <div class="form-group text-left">
              <label for="agendaName">Agenda Name</label>
              <input nbInput fullWidth id="agendaName" formControlName="agendaName" disabled readonly />
            </div>
          </div>
        </div>
        <div class="form-group text-left">
          <label for="agendaDescription">Agenda Description</label>
          <textarea nbInput fullWidth id="agendaDescription" formControlName="agendaDescription" disabled readonly></textarea>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-8">
            <div class="form-group">
              <label for="participantName">Add Participant</label>
              <input nbInput fullWidth id="participantName" #participantNameInput />
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <button nbButton status="info" type="button" (click)="addParticipant(participantNameInput.value)">Add Participant</button>
          </div>
        </div>
        
            <div class="form-group">
              <label for="participants">Added Participants : </label>
              <div class ="container-fluid">
                <div class="row">
                  <div class="col">
              <ul id="participants" class="list-unstyled participants-grid">
                <li class="participant-item" *ngFor="let participant of participants.controls; let i = index">
                  <div>{{ participant.value }}</div>
                  <nb-button nbButton status="danger" type="button" (click)="participants.removeAt(i)">Remove</nb-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="form-group">
        <label for="actionItemTitle">Add Action Item</label>
        <div class ="container-fluid">
        <div class ="row">
          <div class = "col">
        <input nbInput fullWidth id="actionItemTitle" #actionItemTitle placeholder="Title" /></div>
        <div class ="col-6">
        <textarea nbInput fullWidth id="actionItemDescription" #actionItemDescription placeholder="Action to Assign"></textarea>
      </div>
        </div>
        <div class ="row">
          <div class = "col">
      
            <ngx-customselect [control]="actionItemResponsiblePerson" [options]="participantNames"></ngx-customselect>

      </div>
      <div class = "col" >
        <input nbInput fullWidth id="actionItemDeadline" #actionItemDeadline type="date" placeholder="Deadline" />
      </div>
      <div class = "col">
        <button nbButton status="info" type="button" (click)="addActionItem(actionItemTitle.value, actionItemDescription.value, actionItemResponsiblePerson.value, actionItemDeadline.value)">Add Action Item</button>
      </div>
    </div>
    </div>
    </div>
      <div class="form-group">
        <label for="actionItems">Added Action Items : </label>
        <div class ="container">
          <div class ="row">
            <div class = "col">
        <ul id="actionItems" class="list-unstyled participants-grid">
          <li  class="action-item" *ngFor="let item of actionItems.controls; let i = index">
            

            <div>{{ item.get('title').value }} - {{ item.get('description').value }}</div>
            <div>Responsible: {{ item.get('responsiblePerson').value }}</div>
            <div>Deadline: {{ item.get('deadline').value }}</div>
            <nb-button nbButton status="danger" type="button" (click)="actionItems.removeAt(i)">Remove</nb-button>
  
          </li>
        </ul>
      </div>
      </div>
    </div>
      </div>
      <div class ="row">
        <div class = "col">
      <div class="form-group">
        <label for="finalNotes">Final Notes</label>
        <textarea nbInput fullWidth id="finalNotes" formControlName="finalNotes"></textarea>
      </div></div>
      <div class="col">
      <div class="form-group">
        <label for="description">Description</label>
        <textarea nbInput fullWidth id="description" formControlName="description"></textarea>
      </div></div></div>
      <button nbButton status="primary" type="submit">Submit</button>
    </form>
  </nb-card-body>
</nb-card>