import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Meeting} from '../../models/meeting';
import {Agenda} from '../../models/Agenda'
import {AgendaItem} from '../../models/AgendaItem'
import {MeetingMinutes} from '../../models/MeetingMinutes'
import {ActionItem} from '../../models/ActionItem'
import { Sprint, SprintReview } from '../../components/scrum-board/utils/models/column.model';



@Injectable({
  providedIn: 'root',
})
export class CalenderService {
  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private httpClient: HttpClient) { }
  addSprintReview(sprintId: string, review: SprintReview): Observable<Sprint> {
    return this.httpClient.put<Sprint>(`${this.psUrl}sprint/${sprintId}/reviews`, review);
  }

  updateSprintReview(sprintId: string, reviewId: string, review: SprintReview): Observable<Sprint> {
    return this.httpClient.put<Sprint>(`${this.psUrl}sprint/${sprintId}/reviews/${reviewId}`, review);
  }

  removeSprintReview(sprintId: string, reviewId: string): Observable<Sprint> {
    return this.httpClient.delete<Sprint>(`${this.psUrl}sprint/${sprintId}/reviews/${reviewId}`);
  }
  findProjectById(id: string): Observable<Sprint> {
    return this.httpClient.get<Sprint>(`${this.psUrl}sprint/findSprintById/${id}`);
  }
  getSprintById(sprintId: string): Observable<Sprint> {
    return this.httpClient.get<Sprint>(`${this.psUrl}sprint/findSprintById/${sprintId}`);
  }

  updateSprint(sprintId: string, sprint: Sprint): Observable<Sprint> {
    return this.httpClient.put<Sprint>(`${this.psUrl}sprint/${sprintId}`, sprint);
  }
  createMeetingMinutes(meetingMinutes: MeetingMinutes): Observable<MeetingMinutes> {
    return this.httpClient.post<MeetingMinutes>(this.psUrl + 'meeting-minutes', meetingMinutes);
  }
  createMeetingMinutesFromAgenda(agendaId: string ,meetingMinutes: MeetingMinutes,): Observable<MeetingMinutes> {
    return this.httpClient.post<MeetingMinutes>(`${this.psUrl}meeting-minutes/fromagenda/${agendaId}`, meetingMinutes);
  }

  getMeetingMinutesById(id: string): Observable<MeetingMinutes> {
    return this.httpClient.get<MeetingMinutes>(this.psUrl + 'meeting-minutes/' + id);
  }

  getAllMeetingMinutes(): Observable<MeetingMinutes[]> {
    return this.httpClient.get<MeetingMinutes[]>(this.psUrl + 'meeting-minutes');
  }

  updateMeetingMinutes(id: string, meetingMinutes: MeetingMinutes): Observable<MeetingMinutes> {
    return this.httpClient.put<MeetingMinutes>(this.psUrl + 'meeting-minutes/' + id, meetingMinutes);
  }

  deleteMeetingMinutes(id: string): Observable<void> {
    return this.httpClient.delete<void>(this.psUrl + 'meeting-minutes/' + id);
  }
  getAllMeetingMinutess(archived: boolean): Observable<MeetingMinutes[]> {
    let params = new HttpParams().set('archived', archived.toString());
    return this.httpClient.get<MeetingMinutes[]>(`${this.psUrl}meeting-minutes/get`, { params });
  }

  archiveMeetingMinutes(id: string): Observable<void> {
    return this.httpClient.put<void>(`${this.psUrl}meeting-minutes/archive/${id}`, {});
  }

  assignActionItemsToAgendaItem(meetingMinutesId: string, agendaItemId: string, actionItemDTOs: any[]): Observable<any> {
    return this.httpClient.post(`${this.psUrl}meeting-minutes/${meetingMinutesId}/agenda-items/${agendaItemId}/action-items`, actionItemDTOs);
  }
  getAgendas(): Observable<Agenda[]> {
    return this.httpClient.get<Agenda[]>(`${this.psUrl}agenda`);
  }
  getAgendaById(id:string): Observable<any> {
    return this.httpClient.get<any>(`${this.psUrl}agenda/${id}`);
  }
  

  createAgenda(agenda: Agenda): Observable<Agenda> {
    return this.httpClient.post<Agenda>(`${this.psUrl}agenda`, agenda);
  }

  getAgendaItems(agendaId: string): Observable<AgendaItem[]> {
    return this.httpClient.get<AgendaItem[]>(`${this.psUrl}agenda/${agendaId}/items`);
  }

  createAgendaItem(agendaId: string, agendaItem: AgendaItem): Observable<AgendaItem> {
    return this.httpClient.post<AgendaItem>(`${this.psUrl}agenda/${agendaId}/items`, agendaItem);
  }
  getAllMeet(): Observable<Meeting[]> {
    return this.httpClient.get<[Meeting]>(`${(this.psUrl)}Meeting/allMeeting`);

  }
  // selectedEmployees: Employee[] = [];

  addMeeting(m: Meeting ) {
    // const participantEmails = this.selectedEmployees.map(employee => employee.employeeEmail);

    return this.httpClient.post<Meeting>(`${this.psUrl}Meeting/add`, m, this.httpOptions);
  }
  addMultipleMeetings(meet: Meeting ): Observable<Meeting[]> {
    return this.httpClient.post<Meeting[]>(`${this.psUrl}Meeting/add-multiple`, meet, this.httpOptions);
  }

  updateMeeting(meet: Meeting): Observable<Meeting> {
    return this.httpClient.put<Meeting>(`${this.psUrl}Meeting/update-Meeting`, meet , this.httpOptions);
  }

  deleteMeeting(id: String): Observable<void> {
    return this.httpClient.delete<void>(`${this.psUrl}Meeting/delete/${id}`, this.httpOptions);
  }


  getMeetingById(id: String ): Observable<Meeting> {
    return this.httpClient.get<Meeting>(`${this.psUrl}Meeting/Meeting_by_id/${id}`);
  }

  getMeetingByIdProject(id: String) {
    return this.httpClient.get<Meeting[]>(`${this.psUrl}Meeting/all/${id}`, this.httpOptions);
  }
  updateRecursiveMeetings(meet: Meeting): Observable<Meeting[]> {
    return this.httpClient.put<Meeting[]>(`${this.psUrl}Meeting/update-recursive`, meet , this.httpOptions);
  }
  deleteRecursiveMeetings(key: String): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.psUrl}Meeting/delete-recursive/${key}`, this.httpOptions);
  }

}
