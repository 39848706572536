import {sprintStatus} from './enum/sprintStatus';
import {Task} from '../components/scrum-board/utils/models/task';

export class Sprint {
  id: string;
  title: string;
  startDate: Date | null;
  endDate: Date | null;
  status: sprintStatus;
  description: string;
  restrospectives: string;
  tasks: Task[];
  project: string;
  collapsed: boolean;
  
}



