import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Sprint, SprintReview } from '../../scrum-board/utils/models/column.model';
import { CalenderService } from 'app/modules/project-management/services/calender/calender.service';
import { TokenStorageService } from 'app/modules/auth/service/token/token.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-create-review',
  templateUrl: './create-review.component.html',
  styleUrls: ['./create-review.component.scss']
})
export class CreateReviewComponent implements OnInit {
  showAddForm: boolean = false;
  currentUser: any;
  sprintReviewForm: FormGroup;
  @Input() selectedSprint;

  
  constructor(
    private toastrService : NbToastrService,
    private fb: FormBuilder,
    private sprintService: CalenderService,
    private tokenStorageService: TokenStorageService,
  ) { }
  
  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    console.log(this.currentUser);
    this.initializeForm();
  }
  
  initializeForm(): void {
    this.sprintReviewForm = this.fb.group({
      id: [''], 
      process: ['', Validators.required],
      stepsToExecute: ['', Validators.required],
      expectedResults: ['', Validators.required],
      technicalConstraints: ['', Validators.required],
      pass: [false],
      fail: [false],
      comments: ['']
    });
  }
  
  addReview(): void {
    if (this.sprintReviewForm.valid) {
      const newReview: SprintReview = this.sprintReviewForm.value;
      this.sprintService.addSprintReview(this.selectedSprint.id, newReview).subscribe(response => {
        if (response) {
          this.selectedSprint = response;
          this.sprintReviewForm.reset();
          this.showAddForm = false;
          this.toastrService.success('Review added successfully', 'Success');

        }},
        error => {
          console.error('Error adding review:', error);
          this.toastrService.danger('Failed to add Review', 'Error');
  
        
      });
    }
  }
  
  editReview(review: SprintReview): void {
    this.sprintReviewForm.patchValue(review);
    this.showAddForm = true;
  }
  
  updateReview(): void {
    if (this.sprintReviewForm.valid) {
      const updatedReview: SprintReview = this.sprintReviewForm.value;
      this.sprintService.updateSprintReview(this.selectedSprint.id, updatedReview.id, updatedReview).subscribe(response => {
        this.selectedSprint = response;
        this.toastrService.success('Review updated successfully', 'Success');

        this.sprintReviewForm.reset();
      },
      
      error => {
        console.error('Error updating review:', error);
        this.toastrService.danger('Failed to update Review', 'Error');

      });
    }
    this.toggleForm();
  }
  
  deleteReview(reviewId: string): void {
    console.log(this.selectedSprint.id);
    console.log(reviewId);
    this.sprintService.removeSprintReview(this.selectedSprint.id, reviewId).subscribe(
      response => {
        this.selectedSprint = response;
        this.toastrService.success('Review deleted successfully', 'Success');

      },
      error => {
        console.error('Error deleting review:', error);
        this.toastrService.danger('Failed to delete Review', 'Error');

      }
    );
  }
  addForm(){
    this.initializeForm();
    this.toggleForm();
  }
  toggleForm(): void {
    this.showAddForm = !this.showAddForm;
  }
 
}
