import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Card, Column, Comment } from '../models/column.model';
import {Checklist, Item, Priority, Task, Type} from '../models/task';
@Injectable({
  providedIn: 'root',
})
export class ScrumBoadService {
  url = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {
  }
  // Columns
  getColumns(): Observable<Column[]> {
    return this.http.get<Column[]>(`${this.url}column/find-all`);
  }
  getColumnsBySprint(sprintId: String): Observable<Column[]> {
  return this.http.get<Column[]>(`${this.url}column/find-column-by-sprint/${sprintId}`);
  }
  getColumnsByleanMethod(leanMethod: String, projectId: String): Observable<Column[]> {
    return this.http.get<Column[]>(`${this.url}column/find-column-by-lean-method/${leanMethod}/${projectId}`);
    }
  getColumnById(columnId: String): Observable<Column> {
    return this.http.get<Column>(`${this.url}column/find-columns/${columnId}`);
  }
  updateColumn(column: Column): Observable<Column> {
    return this.http.put<Column>(
      `${this.url}column/updateColumn`,
      column,
      this.httpOptions,
    );
  }
  addColumn(column: Column): Observable<Column> {
    return this.http.post<Column>(
      `${this.url}column/add-column`,
      column,
      this.httpOptions,
    );
  }
  deleteColumn(columnId: String): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}column/delete/${columnId}`);
  }

  // Tasks
  addStory(task: Task): Observable<Task> {
    return this.http.post<Task>(
      `${this.url}task/addStory`,
      task,
      this.httpOptions,
    );
  }
  addTaskBoard(task: Task, columnId: String): Observable<Task> {
    return this.http.post<Task>(
      `${this.url}task/add-task-board/${columnId}`,
      task,
      this.httpOptions,
    );
  }
  updateTask(task: Task): Observable<Task> {
    return this.http.put<Task>(
      `${this.url}task/updateTask`,
      task,
      this.httpOptions,
    );
  }
  findAllTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.url}task/find-all`);
  }
  findTaskById(taskId: string): Observable<Task> {
    return this.http.get<Task>(`${this.url}task/find-task/${taskId}`);
  }
  findTaskBySprintId(sprintId: String): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.url}task/find-task-by-sprint/${sprintId}`);
  }
  findTaskBySprintIdAndTypeStory(sprintId: String): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.url}task/find-task-by-sprint-story/${sprintId}}`);
  }
  deleteTask(taskId: String): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}task/delete/${taskId}`);
  }

  // Comment
  addComment(comment: Comment, taskId: String): Observable<Comment> {
    return this.http.post<Comment>(
      `${this.url}comment/add-comment/${taskId}`,
      comment,
      this.httpOptions,
    );
  }
  findAllCommentsByTask(taskId: String): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.url}comment/findAllByTask/${taskId}`);
  }
  deleteComment(commentId: String): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}comment/delete/${commentId}`);
  }
  // Sprints
  getAllSprintsByProject(projectId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}sprint/all/${projectId}`);
  }
  // Checklists
  getAllChecklistsByTask(taskId: string): Observable<Checklist[]> {
    return this.http.get<Checklist[]>(`${this.url}checklist/findAllByTask/${taskId}`);
  }
  addChecklist(checklist: Checklist, taskId: String): Observable<Checklist> {
    return this.http.post<Checklist>(
      `${this.url}checklist/add-checklist/${taskId}`,
      checklist,
      this.httpOptions,
    );
  }
  updateChecklist(checklist: Checklist): Observable<Checklist> {
    return this.http.put<Checklist>(
      `${this.url}checklist/updateChecklist`,
      checklist,
      this.httpOptions,
    );
  }
  deleteChecklist(checklistId: String): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}checklist/delete/${checklistId}`);
  }
  // Items
  getAllItemsByChecklist(checklistId: string): Observable<Item[]> {
    return this.http.get<Item[]>(`${this.url}item/findAllByTask/${checklistId}`);
  }
  addItem(item: Item, checklistId: String): Observable<Item> {
    return this.http.post<Item>(
      `${this.url}item/add-item/${checklistId}`,
      item,
      this.httpOptions,
    );
  }
  deleteItem(itemId: String): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}item/delete/${itemId}`);
  }
  updateItem(item: Item): Observable<Item> {
    return this.http.put<Item>(
      `${this.url}item/update-item`,
      item,
      this.httpOptions,
    );
  }
}
