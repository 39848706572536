<nb-card accent="success">
  <nb-card-header> PROCESSES FLOW
</nb-card-header>
  <nb-card-body>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/upload-file-manazello.appspot.com/o/Project_Management-removebg-preview.png0.1894716061013364[object%20File]?alt=media"
      class="img-fluid"
      alt="Responsive image"
    />
  </nb-card-body>
  <nb-card-footer>
    <nb-alert accent="success">

    <p class="font-weight-bold">
      The project management module is a decision-making phase for the company
      to start a project and develop it progressively until its closure. This
      module is divided into five key steps which are :
    </p>

    <ol class="font-weight-bold">
      <li class="text-uppercase">Initiating</li>
      <li class="text-uppercase">Planning</li>
      <li class="text-uppercase">EXECUTING</li>
      <li class="text-uppercase">MONITORING & CONTROLLING</li>

      <li class="text-uppercase">CLOSING</li>
    </ol>
</nb-alert>
  </nb-card-footer>
</nb-card>
