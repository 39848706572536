
<nb-card>
  <nb-card-header> Ressource List 
    <button nbButton shape="round" size="small" status="warning" (click)="save()" style="float: right">Assign resource to Activity</button>
  </nb-card-header>

  <nb-card-body>
    <angular2-smart-table
      class="order-table"
      [settings]="settings"
      [source]="resourceList"
      (delete)="confirmation($event, dialog)"
    >
    </angular2-smart-table>
  </nb-card-body>
</nb-card>
<nb-tag status="warning" appearance="outline" text="total: {{ budget }} Dt" style="float: right"></nb-tag> 
 <!--Delete Confirm -->
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirm Archive Operation</nb-card-header>
    <nb-card-body> Are you sure you want to archive this item ? </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-md-2">
          <button nbButton status="basic" (click)="ref.close()">
            <nb-icon icon="close"></nb-icon>Close
          </button>
        </div>
        <div class="col-md-5"></div>
        <div class="col-md-2">
          <button nbButton status="danger" (click)="onArchive(selectedItem)">
            <span *ngIf="!selectedItem.archived; else elseBlock"
              ><nb-icon  icon="archive-outline" ></nb-icon
              >&nbsp; Archive !</span
            >
            <ng-template #elseBlock>
              <sapn
                ><nb-icon icon="corner-left-up-outline"></nb-icon>
                Unarchive</sapn
              >
            </ng-template>
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!--end Delete Confirm-->
