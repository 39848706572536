<nb-card style="max-height: 560px;">
  <nb-card-header>
    <h3 class="modal-title">Update Meeting</h3>
  </nb-card-header>
  <nb-card-body>
    <div class="update-form-row">
      <label for="meeting-title" style="width: 300px;">Title : </label>
      <input nbInput fullWidth id="meeting-title" required [(ngModel)]="meet.title" placeholder="Title" style="max-width: 60%;"/>
    </div>
    <div class="update-form-row">
      <label for="meeting-Description" style="width: 300px;">Description : </label>
      <input nbInput fullWidth id="meeting-Description" required [(ngModel)]="meet.description" placeholder="Description" style="max-width: 60%;"/>
    </div>
    <div class="update-form-row">
      <label for="selected-type" style="width: 300px;">Type :</label>
      <nb-select fullWidth id="selected-type" [(ngModel)]="meet.type" placeholder="Sélectionnez un type" style="padding: 0;">
        <nb-option *ngFor="let type of typeMeetings" [value]="type">{{ type }}</nb-option>
      </nb-select>
    </div>
    <div class="update-form-row">
      <label for="selected-users" style="width: 300px;">Invited Members : </label>
      <nb-select fullWidth class="col-sm-9" id="selected-users" [(ngModel)]="meet.participant" multiple style="padding: 0; max-width: 60%;">
        <nb-option *ngFor="let user of meet.participant" [value]="user">{{ user }}</nb-option>
      </nb-select>
    </div>
    <div class="update-form-row">
      <label style="width: 300px;">Start Date : </label>
      <input nbInput fullWidth placeholder="Pick Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="meet.dateStart" style="max-width: 60%;">
      <nb-date-timepicker withSeconds #dateTimePicker></nb-date-timepicker>
    </div>
    <div class="update-form-row">
      <label style="width: 300px;">End Date : </label>
      <input nbInput fullWidth placeholder="Pick Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="meet.dateEnd" style="max-width: 60%;">
      <nb-date-timepicker withSeconds #dateTimePicker></nb-date-timepicker>
    </div>
    <div class="update-form-row">
      <label for="meeting-lien" style="width: 300px;">Link : </label>
      <input nbInput fullWidth id="meeting-lien" required [(ngModel)]="meet.lien" placeholder="Lien" style="max-width: 60%;"/>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button nbButton status="success"  (click)="updateMeet()" style="margin-bottom: 3%;">Update this Meeting</button>
      <button nbButton status="info"  (click)="updateRecursiveMeet()">Update this and the following Meetings </button>
    </div>
  </nb-card-footer>
</nb-card>
