import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss'],
})
export class ActivityTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
