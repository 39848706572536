import {Level} from './enum/Level';
import {MeetingType} from './enum/MeetingType';
import {Auth} from '../../auth/model/Auth';
import {Employee} from './Employee';

export class Meeting {
  idmeet: string;
  title: string;
  description: string;
  lien: String;
  type: MeetingType;
  dateStart:  Date = new Date();
  dateEnd: Date = new Date() ;
  participant: String[];
  idProject: string;
  repeatDays: string[];
  duration: number;
  recursiveKey: string;
}
