import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { DataDescription } from 'app/modules/project-management/models/DataDescription';
import { Project } from 'app/modules/project-management/models/Project';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';
import { CrispService } from 'app/modules/project-management/services/crisp.service';

@Component({
  selector: 'ngx-data-understanding',
  templateUrl: './data-understanding.component.html',
  styleUrls: ['./data-understanding.component.scss']
})
export class DataUnderstandingComponent {
  dataInfo: any;
  selectedFileId: string;
  selectedFile: any;
  files: any[] = [];
  dataDescription: any;
  projectId: string;
  project: Project;
  file: File | null = null;
  dataDescriptions: DataDescription[];
  settings = {
    actions: { edit: false, delete: false, add: false },

    columns: {
      attributeName: {
        title: 'Attribute Name'
      },
      dataType: {
        title: 'Data Type'
      },
      valueRange: {
        title: 'Value Range'
      },
      count: {
        title: 'Count'
      },
      description: {
        title: 'Description'
      }
    }
  };

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private toastrService: NbToastrService,
    private crispService : CrispService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.findProjectById();
        this.getDataDescriptions();
      }
    });
    

    // Fetch Data Description
    
    this.crispService.getFiles(this.projectId).subscribe(data => {
      this.files = data;
    });
  }
  
  onFileSelected(fileId: string): void {
    this.selectedFileId = fileId;
    this.crispService.getFile(fileId).subscribe(data => {
      this.selectedFile = data;
    });
  }

  processSelectedFile(): void {
    if (this.selectedFileId) {
      this.crispService.processFile(this.selectedFileId).subscribe(response => {
        console.log(response);
      }, error => {
        console.error("Error processing file", error);
      });
    }
  }

  findProjectById() {
    this.projectService.findProjectById(this.projectId).subscribe({
      next: (value: Project) => {
        this.project = value;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  
  getDataDescriptions() {
    this.crispService.getDataDescriptions(this.projectId).subscribe({
      next: (data: DataDescription[]) => {
        this.dataDescriptions = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  onDeleteDataDescriptions() {
    this.crispService.deleteDataDescriptions(this.projectId)
      .subscribe(() => {
        console.log('Data descriptions deleted successfully.');
        this.toastrService.success("Data descriptions deleted successfully")
        // Optionally, add logic to update UI or show a confirmation message
      }, error => {
        console.error('Error deleting data descriptions:', error);
        this.toastrService.danger("failed to delete Data")
      });
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
}
