import { Component, OnInit } from '@angular/core';
import { Agenda } from '../../../models/Agenda';
import { ActivatedRoute, Router } from '@angular/router';
import { CalenderService } from '../../../services/calender/calender.service';
import { TokenStorageService } from 'app/modules/auth/service/token/token.service';
import { superAccess } from 'app/modules/auth/accessControle/accessControle';

@Component({
  selector: 'ngx-agenda-details',
  templateUrl: './agenda-details.component.html',
  styleUrls: ['./agenda-details.component.scss']
})
export class AgendaDetailsComponent implements OnInit {
  agendaId: string; // Change the type to string
  agenda: Agenda;
  flippedState: boolean = false;
  selected: Agenda = null;
  statusColor: string = 'success';
  accessControle: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private agendaService: CalenderService,
    private tokenStorageService: TokenStorageService,
  ) {const currentUser = this.tokenStorageService.getUser();

    this.accessControle = superAccess(currentUser); }

  ngOnInit(): void {
    // Get the agenda ID from the route parameter
    this.route.paramMap.subscribe((params) => {
      this.agendaId = params.get('id'); // Parse as string
      // Fetch agenda details using the ID
      this.fetchAgendaDetails(this.agendaId);
    });
   /*  this.authService.getLoggedInUser().subscribe(
      (data:User) => {
        this.user=data;
        console.log(this.user);
      },
      (error) =>{
        console.error('Error!!!!!', error);
      }
    ); */
  }

  fetchAgendaDetails(id: string): any {
    // Parse the ID to a number if necessary
    this.agendaService.getAgendaById(id).subscribe(
      data => {
        this.agenda = data;
        console.log('Agenda details:', this.agenda);
      },
      error => {
        console.error('Error fetching agenda details:', error);
      }
    );
  }
  flip(): void {
    this.selected = this?.agenda;
    this.flippedState = !this.flippedState;
  }
  refresh(event) {
    this.agenda = null;
    this.agenda = event;
    this.flip();
  }
  onButtonClick(id: string) {
    this.router.navigate(['projectManagement/minutes',  id]);
  }
}
