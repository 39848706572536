import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { CrispService } from 'app/modules/project-management/services/crisp.service';

@Component({
  selector: 'ngx-dm-project-plan',
  templateUrl: './dm-project-plan.component.html',
  styleUrls: ['./dm-project-plan.component.scss']
})
export class DMProjectPlanComponent {
  projectId: string;
  businessUnderstandingId: string;
  businessUnderstanding: any;
  loading: boolean = false; // Loading state

  fileData: { [key: string]: string } = {}; // To store Base64 strings of files
  
  stages = [
    {
      name: 'Business Understanding',
      tasks: [
        { name: 'Business Objectives', route: 'businessObjectives' },
        { name: 'Business Constraints', route: 'businessConstraints' },
        { name: 'Prior Knowledge', route: 'priorKnowledge' },
        { name: 'Initial Hypotheses', route: 'initialHypotheses' },
        { name: 'Business Understanding Document', route: 'businessUnderstandingDocument' },
        { name: 'Project Plan', route: 'projectPlan' },
        { name: 'Risk Assessment', route: 'riskAssessment' }
      ],
      expanded: false
    }]


  constructor(
    private route: ActivatedRoute,
    private businessUnderstandingService: CrispService,
    private router: Router, private toastrService: NbToastrService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.loadBusinessUnderstanding();
    });
  }

  loadBusinessUnderstanding(): void {
    this.loading = true;
    this.businessUnderstandingService.getBusinessUnderstandingByProjectId(this.projectId).subscribe(
      (data: any) => {
        this.businessUnderstanding = data;
        this.businessUnderstandingId = data.id;
        this.loading = false;

      },
      error => {
        console.error('Error fetching Business Understanding:', error);
        this.loading = false;
      }
    );
  }


  downloadDocument(type: string): void {
    if (this.projectId) {
      this.businessUnderstandingService.downloadDocument(this.businessUnderstandingId, type).subscribe(
        (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${type}.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.toastrService.success(`${type} downloaded successfully`, 'Success');
        },
        (error) => {
          this.toastrService.danger('Error downloading document: ' + error.message, 'Error');
        }
      );
    } else {
      this.toastrService.warning('Please provide a project ID', 'Warning');
    }
  }
  onFileSelected(event: any, fieldName: string): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileData[fieldName] = (reader.result as string).split(',')[1]; // Remove the base64 prefix
      };
      reader.readAsDataURL(file);
    }
  }
}
