<nb-reveal-card [revealed]="flippedState" [showToggleButton]="false">
  <nb-card-front>
    <nb-card accent="basic">
      <nb-card-body>
        <nb-alert accent="{{ statusColor }}">
          <div>
            <button nbButton (click)="navigateToScrumProcess()">Back</button>
          </div>
          <div class="row">
            <h6
              class="text-center"
              style="margin-bottom: 5%"
              class="col-md-6 offset-md-4"
            >
              Sprint General Informations:
            </h6>
            <nb-icon
              *ngIf="accessControle"
              icon="edit-outline"
              status="basic"
              (click)="flip()"
            ></nb-icon>
          </div>

          <div class="container row">
            <div class="col-md-6">
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="bookmark-outline" status="info"></nb-icon>
                  Sprint Name :
                </strong>
                {{ sprint.title }}
              </p>
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="person-outline" status="info"></nb-icon>
                  Sprint Manager :
                </strong>
                
              </p>


              <p>
                <strong class="text-secondary"
                ><em class="fas fa-building" style="color: dodgerblue"></em>
                  description :
                </strong>
                {{ sprint.description }}
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <strong class="text-secondary"
                ><nb-icon status="success" icon="calendar-outline"></nb-icon>
                  Start Date :
                </strong>
                {{ sprint.startDate | date: "mediumDate" }}
              </p>
              <p>
                <strong class="text-secondary"
                ><nb-icon status="danger" icon="calendar-outline"></nb-icon>
                  End Date :
                </strong>
                {{ sprint.endDate | date: "mediumDate" }}
              </p>
              <p>
                <strong class="text-secondary"
                ><nb-icon
                  status="info"
                  icon="checkmark-square-outline"
                ></nb-icon
                >retrospective :  {{sprint.restrospectives}}
                </strong><textarea
                nbInput
                fullWidth
                placeholder="Entrez votre texte ici"
                [(ngModel)]="restrospective"

              ></textarea>
              </p>
              <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
              <button nbButton status="primary" (click)="onSendSprint()">save</button>
            </div>
          </div>
        </nb-alert>
      </nb-card-body>
    </nb-card>
  </nb-card-front>
  <nb-card-back>
    <nb-card accent="{{ statusColor }}">
      <nb-card-header>
        <nb-icon
          icon="chevron-left-outline"
          pack="nebular-essentials"
          aria-hidden="true"
          (click)="flip()"
        ></nb-icon>
        Back
      </nb-card-header>
      <nb-card-body>
      </nb-card-body>
    </nb-card>
  </nb-card-back>
</nb-reveal-card>
