<!--<nb-notifications class="notifications-list">-->
 <div class="sahar">
   <nb-card>
    <nb-card-header>Notifications</nb-card-header>
    <nb-card-body style="overflow-y: auto ;     max-height: 300px;
    width: 350px;">
      <nb-list>
        <nb-list-item *ngFor="let notification of notifications">
          <div class="notification-content" >
          <nb-icon icon="bell-outline"  class="indicator"></nb-icon>
<!--            <h5>{{this.notification.title}} {{ this.notification.body }} {{this.notification.date}}</h5>-->
         <div class="d-flex flex-column justify-content-center">
           <span style="font-size: 17px; font-weight: 600">{{this.notification.title}}</span>
           <span>{{ this.notification.body }}</span>
           <span style="font-size: 13px">{{this.notification.date | date: 'dd/MM/yyyy' }}  </span>
         </div>
          </div>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" (click)="onDeleteAllConfirm()">See All</button>
    </nb-card-footer>
  </nb-card>
 </div>
<!--</nb-notifications>-->
