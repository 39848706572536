<p class="text-center">Add your stories to the Project Backlog, then drag and drop them to the appropriate Sprint</p>
<div class="container" cdkDropListGroup>


  <nb-card class="backlog-section">

    <nb-card-header style="padding: 4%">
      <h4 class="text-center">Project Backlog</h4>
    </nb-card-header>
    <nb-card-body>
      <div>
        <label>Story Title :</label>
        <div class="d-flex justify-content-between">
          <input nbInput type="text" placeholder="Add story title" [(ngModel)]="newTask.title" name="taskInput" (keydown.enter)="addTask()">
          <button nbButton size="small" hero outline status="primary" (click)="addTask()">Add Task</button>        </div>
      </div>
      <div class="backlog-list" (dragover)="onTaskDragOver($event)" (drop)="onTaskDrop($event, null)">
        <nb-card status="info" *ngFor="let taskTitle of backlogTaskTitles" class="task-item" [draggable]="true"
                 (dragstart)="onTaskDragStart($event, taskTitle, null)">
          <nb-card-header style="padding: 1%"></nb-card-header>
          <nb-card-body class="d-flex justify-content-between w-100">
            <span>{{ taskTitle }}</span>
            <!-- <button nbButton status="danger" (click)="deleteTaskFromBacklog(task)" class="delete-button">x</button> -->
            <nb-icon icon="backspace-outline" status="danger" style="cursor: pointer" (click)="deleteTask(taskTitle)"></nb-icon>
          </nb-card-body>
        </nb-card>
      </div>
    </nb-card-body>
  </nb-card>


  <nb-card class="sprint-section">
    <nb-card-header style="padding: 4%" class="d-flex justify-content-between align-items-center w-100">
      <h4 class="text-center w-100">Sprints Backlog</h4>
    </nb-card-header>
    <nb-card-body>
      <div class="w-100 d-flex justify-content-center">
        <button nbButton hero outline size="small" status="primary" (click)="showSprintForm(dialog)">Add Sprint</button>
      </div>
      <div class="sprint-list">
        <nb-card *ngFor="let sprint of sprintList" class="sprint-item" style="cursor: pointer" [id]="sprint.id" [draggable]="true"
             (dragover)="onTaskDragOver($event)" (drop)="onTaskDrop($event, sprint)">
          <nb-card-header class="sprint-header" (click)="toggleSprintCollapse(sprint)">
            <div class="title-container w-75">
              <h1>{{ sprint.title }}</h1>
              <nb-icon  *ngIf="sprint.status === 'notStarted'" icon="close-outline" status="danger" style="cursor: pointer" (click)="deleteSprintFromList(sprint)"></nb-icon>
            </div>
            <nb-icon icon="edit-2-outline" (click)="openSprintModal(sprint)" style="cursor: pointer"></nb-icon>
            <nb-icon [icon]="sprint.collapsed ? 'chevron-down-outline' : 'chevron-up-outline'" style="cursor: pointer"></nb-icon>
          </nb-card-header>
          <nb-card-body *ngIf="!sprint.collapsed">
            <nb-card status="info" *ngFor="let task of sprint.tasks" class="task-item" [draggable]="true"
                 (dragstart)="onTaskDragStart($event, task.title, sprint)" (dragover)="onTaskDragOver($event)"
                 (drop)="onTaskDrop($event, sprint)">
              <nb-card-header style="padding: 1%"></nb-card-header>
              <nb-card-body class="d-flex justify-content-between w-100 align-items-center">
                <span>{{ task.title }}</span>
                <nb-icon icon="close-outline" status="danger" style="cursor: pointer" (click)="deleteTaskFromSprint(task, sprint)"></nb-icon>
              </nb-card-body>
            </nb-card>
            <div class="placeholder" *cdkDragPlaceholder></div>
          </nb-card-body>
        </nb-card>
      </div>
    </nb-card-body>

  </nb-card>

  <button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button" (click)="toggleTooltip($event)">
    <mat-icon>help_outline</mat-icon>
  </button>
  <!-- The card with the description (initially hidden) -->
  <div class="tooltip-card" [class.show]="showTooltip" style="background: transparent; border: none;">
    <nb-card>
      <nb-card-body>
        <button nbButton (click)="closeTooltip()" class="close-sticker">↩</button>
        <ul>Backlog in Scrum methodology: The agile product backlog in Scrum is a prioritized features list, containing short descriptions of all functionality desired in the product.</ul>
        <ul>User Story:</ul>
        <ul>A user story is a high-level requirement that describes a specific feature from an end-user perspective.</ul>
        <ul>A Sprint is a fixed-length iteration, usually ranging from one to four weeks, where the development team collaboratively works on a set of prioritized user stories, tasks, or backlog items. Each sprint begins with a sprint planning meeting, where the development team selects the items they believe they can complete during the sprint, based on their capacity and previous performance</ul>
      </nb-card-body>
    </nb-card>
  </div>
</div>
<!-- -->
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card style="width: 400px">
    <nb-card-header class="text-center">Add new Sprint</nb-card-header>
    <nb-card-body>
      <form>
        <div class="add-form-row">
          <label for="sprintTitle">Title : </label>
          <input placeholder="Add title" nbInput type="text" id="sprintTitle" [(ngModel)]="newSprint.title" name="title" required>
        </div>
        <div class="add-form-row">
          <label for="sprintStartDate">Start Date : </label>
          <input nbInput id="sprintStartDate" placeholder="Select start date" [nbDatepicker]="startDate" [(ngModel)]="newSprint.startDate" name="startDate" required>
        </div>
        <nb-datepicker #startDate></nb-datepicker>
        <div class="add-form-row">
          <label for="sprintEndDate">End Date : </label>
          <input nbInput id="sprintEndDate" placeholder="Select end date" [nbDatepicker]="endDate" [(ngModel)]="newSprint.endDate" name="endDate" required>
        </div>
        <nb-datepicker #endDate></nb-datepicker>
        <div class="add-form-row">
          <label for="sprintStatus">Status :</label>
<!--          <select id="sprintStatus" [(ngModel)]="newSprint.status" name="status" required>-->
<!--            <option value="Not Started">Not Started</option>-->
<!--            <option value="In Progress">In Progress</option>-->
<!--            <option value="Completed">Completed</option>-->
<!--          </select>-->
          <nb-select
            id="sprintStatus"
            style="width: 140px"
            placeholder="Status"
            [size]="'small'"
            hero
            shape="semi-round"
            (selectedChange)="changeSprintStatus($event)">
            <nb-option [value]="'notStarted'" style="padding: 5%;">
              Not Started
            </nb-option>
            <nb-option [value]="'inProgress'" style="padding: 5%;">
              In Progress
            </nb-option>
            <nb-option [value]="'completed'" style="padding: 5%;" >
              Completed
            </nb-option>
          </nb-select>
        </div>
<!--        <div>-->
<!--          <label for="sprintType">Type:</label>-->
<!--          <input type="text" id="sprintType" [(ngModel)]="newSprint.type" name="type" required>-->
<!--        </div>-->
        <div class="add-form-row">
          <label for="sprintDescription">Description:</label>
          <textarea placeholder="Add description" nbInput id="sprintDescription" [(ngModel)]="newSprint.description" name="description" required style="resize: none"></textarea>
        </div>
<!--        <div class="button-row">-->
<!--          <button nbButton (click)="cancelAddSprint()" class="failed">Cancel</button>-->
<!--          <button nbButton class="save-button">Save Sprint</button>-->
<!--        </div>-->
      </form>
    </nb-card-body>
    <nb-card-footer>
      <div class="w-100 d-flex justify-content-between ">
        <button nbButton status="primary" (click)="ref.close(); addSprint()">Confirm</button>
        <button nbButton (click)="ref.close()">Cancel</button>
      </div>
    </nb-card-footer>
  </nb-card>

</ng-template>


