<div>
  <div class="d-flex justify-content-between">
    <nb-card style="width: 23%;">
      <nb-card-header>
        <span class="text-center w-100" style="display: block; font-size: 20px;">Sprints</span>
      </nb-card-header>
      <nb-card-body style="padding: 1%" class="d-flex align-items-center justify-content-center">
        <nb-icon icon="layout-outline" status="basic" style="width: 25%; height: 50px; margin-right: 25%;"></nb-icon>
        <p *ngIf="isSprintDataAvailable; else loadingPlaceholder"
            class="text-center" style="font-size: 35px; margin: 0;">{{sprintsNumber}}</p>
      </nb-card-body>
    </nb-card>

    <nb-card style="width: 23%;">
      <nb-card-header>
        <span class="text-center w-100" style="display: block; font-size: 20px;">Stories</span>
      </nb-card-header>
      <nb-card-body style="padding: 1%"  class="d-flex align-items-center justify-content-center">
        <nb-icon icon="bookmark-outline" status="info" style="width: 25%; height: 50px; margin-right: 25%;" ></nb-icon>
        <p *ngIf="isStoryDataAvailable; else loadingPlaceholder"
        class="text-center" style="font-size: 35px; margin: 0;">{{storiesNumber}}</p>
      </nb-card-body>
    </nb-card>

    <nb-card style="width: 23%;">
      <nb-card-header>
        <span class="text-center w-100" style="display: block; font-size: 20px;">Tasks</span>
      </nb-card-header>
      <nb-card-body style="padding: 1%"  class="d-flex align-items-center justify-content-center">
        <nb-icon icon="checkmark-square-outline" status="success" style="width: 25%; height: 50px; margin-right: 25%;" ></nb-icon>
        <p *ngIf="isTaskDataAvailable; else loadingPlaceholder"
        class="text-center" style="font-size: 35px; margin: 0;">{{tasksNumber}}</p>
      </nb-card-body>
    </nb-card>

    <nb-card style="width: 23%;">
      <nb-card-header>
        <span class="text-center w-100" style="display: block; font-size: 20px;">Issues</span>
      </nb-card-header>
      <nb-card-body style="padding: 1%"  class="d-flex align-items-center justify-content-center">
        <nb-icon icon="alert-circle-outline" status="danger" style="width: 25%; height: 50px; margin-right: 25%;" ></nb-icon>
        <p *ngIf="isIssueDataAvailable; else loadingPlaceholder"
        class="text-center" style="font-size: 35px; margin: 0;">{{issuesNumber}}</p>
      </nb-card-body>
    </nb-card>
  </div>
  <div>
    <h3 style="margin: 5% 0">Sprint Progress Overview</h3>
    <div class="chart tab-pane active" id="pie-chart" style="height: 400px;">
      <canvas id="piechart">{{pieChart}}</canvas>
    </div>
  </div>
  <div>
    <h3 style="margin: 5% 0">Workload Distribution</h3>
    <div  class="chart tab-pane" id="bar-chart" height="300" style="height: 300px;">
      <canvas id="barchart">{{barChart}}</canvas>
    </div>
  </div>
  <div>
    <h3 style="margin: 5% 0">User Story Allocation by Sprints</h3>
    <div class="chart tab-pane active" id="pie-chart2" style="height: 400px;">
      <canvas id="piechart2">{{pieChart2}}</canvas>
    </div>
  </div>
</div>
<ng-template #loadingPlaceholder>
  <div>Loading...</div>
</ng-template>
