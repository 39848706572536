<nb-card>
  <nb-card-header>
    <h4>{{data.question}}</h4>
  </nb-card-header>
  <nb-card-body>
    <div>
      <label>Title</label>
      <input nbInput fullWidth fieldSize="medium" [(ngModel)]="data.text" [placeholder]="'Enter Title'">
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div mat-dialog-actions class="dialog-actions">
      <button nbButton status="primary" [mat-dialog-close]="data.text" cdkFocusInitial color="primary">Confirm</button>
      <button nbButton status="secondary" (click)="onNoClick()">Cancel</button>
    </div>
  </nb-card-footer>
</nb-card>





