<nb-card status="success">
  <nb-card-header><h2>Business Understanding Details</h2></nb-card-header>
  <nb-card-body>
    <nb-spinner *ngIf="loading" status="primary"></nb-spinner>

    <div *ngIf="!loading && businessUnderstanding">
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="clipboard-outline" status="info"></nb-icon>
            Business Objectives
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <span>{{ businessUnderstanding.businessObjectives }}</span>
          </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="alert-triangle-outline" status="warning"></nb-icon>
            Business Constraints
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <span>{{ businessUnderstanding.businessConstraints }}</span>
          </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="book-open-outline" status="primary"></nb-icon>
            Prior Knowledge
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <span>{{ businessUnderstanding.priorKnowledge }}</span>
          </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="bulb-outline" status="success"></nb-icon>
            Initial Hypotheses
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <span>{{ businessUnderstanding.initialHypotheses }}</span>
          </nb-accordion-item-body>
        </nb-accordion-item>
        
        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="file-text-outline" status="info"></nb-icon>
            Business Understanding Document
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <button *ngIf="businessUnderstanding.businessUnderstandingDocument" nbButton status="primary" (click)="downloadDocument('businessUnderstandingDocument')">Download Business Understanding Document</button>
          </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="file-text-outline" status="info"></nb-icon>
            Project Plan
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <button *ngIf="businessUnderstanding.projectPlan" nbButton status="primary" (click)="downloadDocument('projectPlan')">Download Project Plan</button>
          </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon icon="file-text-outline" status="info"></nb-icon>
            Risk Assessment
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <button *ngIf="businessUnderstanding.riskAssessment" nbButton status="primary" (click)="downloadDocument('riskAssessment')">Download Risk Assessment</button>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>
    <div *ngIf="!loading && !businessUnderstanding">Vous n'avez pas encore completé la phase Business Understanding</div>
  </nb-card-body>
</nb-card>
