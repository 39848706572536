<form class="form" [formGroup]="createDeliveryUnitForm" (ngSubmit)="create()">
    <div class="form-group">
      <label>Delivery unit:</label>
      <input
      nbInput
      fullWidth
        class="form-control"
        type="text"
        formControlName="name"
      />
      <div *ngIf="formcontrols.name?.invalid && (formcontrols.name?.dirty || formcontrols.name?.touched)">
        <p class="text-danger" *ngIf="formcontrols.name?.errors?.required">*Required</p>
      </div>
    </div>
    <div class="form-group">
      <label class="text-label">Description/Comment:</label>
      <textarea nbInput fullWidth class="form-control" formControlName="description"></textarea>
      <div
        *ngIf="
          formcontrols.description?.invalid && (formcontrols.description?.dirty || formcontrols.description?.touched)
        "
      >
        <p class="text-danger" *ngIf="formcontrols.description?.errors?.required">*Required</p>
      </div>
    </div>


    <input
      [ngClass]="selectedItem===null ?'btn btn-success btnCreate':'btn btn-warning btnCreate'"
      type="submit"
      [value]="selectedItem===null ? 'Create' : 'Update'"
      [disabled]="createDeliveryUnitForm.invalid"
    />
  </form>
