import { Component } from '@angular/core';

@Component({
  selector: 'ngx-assessing-situation',
  templateUrl: './assessing-situation.component.html',
  styleUrls: ['./assessing-situation.component.scss']
})
export class AssessingSituationComponent {

}
