<form class="form" [formGroup]="createProjectCharterform" (ngSubmit)="create()">
  <div class="form-group">
    <label for="subject">Title:</label>
    <input class="form-control" type="text" formControlName="title"  nbInput/>
    <div *ngIf="title?.invalid && (title?.dirty || title?.touched)">
      <p class="text-danger" *ngIf="title?.errors?.required">*Required</p>
    </div>
  </div>
  <div class="form-group">
    <label class="text-label" for="text">Statement of Work:</label>
    <textarea class="form-control" formControlName="statementWork" nbInput></textarea>
    <div
      *ngIf="
        statementWork?.invalid &&
        (statementWork?.dirty || statementWork?.touched)
      "
    >
      <p class="text-danger" *ngIf="statementWork?.errors?.required">
        *Required
      </p>
    </div>
  </div>
  <input
    class="btn btn-primary btnCreate"
    type="submit"
    value="Create"
    [disabled]="createProjectCharterform.invalid"
  />
</form>
