<nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
    <nb-card-front>
<nb-card>
    <nb-card-header> Ressource Request List    </nb-card-header>
  
    <nb-card-body>
      <angular2-smart-table
        class="order-table"
        [settings]="settings"
        [source]="resourceRquestList"
        (delete)="confirmation($event, dialog)"
        (create)="onCreate()"
        (userRowSelect)="onUserRowSelect($event)"
      >
      </angular2-smart-table>
    </nb-card-body>
</nb-card>
   <!--Delete Confirm -->
  <ng-template #dialog let-data let-ref="dialogRef">
    <nb-card>
      <nb-card-header>Confirm Delete Operation</nb-card-header>
      <nb-card-body> Are you sure you want to delete this item ? </nb-card-body>
      <nb-card-footer>
        <div class="row">
          <div class="col-md-2">
            <button nbButton status="basic" (click)="ref.close()">
              <nb-icon icon="close"></nb-icon>Close
            </button>
          </div>
          <div class="col-md-5"></div>
          <div class="col-md-2">
            <button nbButton status="danger" (click)="onArchive(selectedItem)">
              <span 
                ><nb-icon  icon="archive-outline" ></nb-icon
                >&nbsp; Delete !</span
              >
              
            </button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  <!--end Delete Confirm-->
</nb-card-front>
<nb-card-back *ngIf="flippedState">
  <nb-card>
    <nb-card-header>
        <nb-icon
          icon="chevron-left-outline"
          pack="nebular-essentials"
          aria-hidden="true"
          (click)="flipBack()"
        ></nb-icon>
        Back
      </nb-card-header>
    <ngx-resource-request [selectedItem]="selectedItem" (refreshData)="refresh($event)"></ngx-resource-request>
  </nb-card>
</nb-card-back>
</nb-flip-card>
  