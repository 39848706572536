import {Component, Input, OnInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {Sprint} from '../../../models/Sprint';
import {sprintStatus} from '../../../models/enum/sprintStatus';
import {SprintService} from '../../../services/sprint/sprint.service';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';
import { Project } from 'app/modules/project-management/models/Project';

@Component({
  selector: 'ngx-sprint-form-dialog',
  templateUrl: './sprint-form-dialog.component.html',
  styleUrls: ['./sprint-form-dialog.component.scss'],
})
export class SprintFormDialogComponent implements OnInit {
  @Input() sprint: Sprint;
  project: Project;

  constructor(protected dialogRef: NbDialogRef<SprintFormDialogComponent>, private sprintService: SprintService,private projectService: ProjectService ) {}

  ngOnInit(): void {
    if (!this.sprint.tasks) {
      this.sprint.tasks = []; // Initialize tasks if not present
    }
    console.log(this.sprint);
    this.getProjectById();

  }
  saveSprint() {
    // Call the SprintService to update the sprint
    this.sprintService.updateSprint(this.sprint).subscribe(
      (updatedSprint) => {
        // Handle the response from the backend if needed
        console.log('Sprint updated successfully:', updatedSprint);
        this.dialogRef.close();
      },
      (error) => {
        // Handle any errors that occur during the update
        console.error('Error updating sprint:', error);
      });
  }
  getProjectById(){
    this.projectService.findProjectById(this.sprint.project).subscribe({
      next: (result: any) =>{
        this.project=result;
        console.log(this.project);
      },
      error:(err:any) =>{
        console.log(err);
      },
    });

  }
  cancel() {
    this.dialogRef.close();
  }
  changeSprintStatus(event) {
    this.sprint.status = event;
    console.log(this.sprint.status);
    console.log(event);
  }

  newSprint: Sprint = {
    id: null,
    title: '',
    startDate: null,
    endDate: null,
    status: sprintStatus.notStarted,
    description: '',
    restrospectives: '',
    tasks: [],
    project: '',
    collapsed: true,
  };
}

