import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-sprint-planning',
  templateUrl: './sprint-planning.component.html',
  styleUrls: ['./sprint-planning.component.scss']
})
export class SprintPlanningComponent {
  @Input() selectedSprint;
}
