<nb-tabset class="tabset-container" fullWidth>
  <nb-tab tabTitle="1- Backlog" responsive>
    <ngx-sprint-backlog></ngx-sprint-backlog>
  </nb-tab>
  <nb-tab tabTitle="2- Timeline" tabIcon="analytics-outline" responsive>
    <ngx-roadmap></ngx-roadmap>
  </nb-tab>
  <nb-tab tabTitle="3- Board" responsive>
    <ngx-sprints-table></ngx-sprints-table>
  </nb-tab>
  <nb-tab tabTitle="4- Review" responsive>
    <ngx-redirectingtable></ngx-redirectingtable>
  </nb-tab>
  <nb-tab tabTitle="5- Retrospective" responsive>
    <ngx-redirectingtable-retro></ngx-redirectingtable-retro>
  </nb-tab>
  <nb-tab tabTitle="5- Sprint Planning" responsive>
    <ngx-redirectingtable-planning></ngx-redirectingtable-planning>
  </nb-tab>
  
</nb-tabset>

