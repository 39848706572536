import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) { }

  getNbrSprintsByStatus(idProject: string): Observable<Map<String, number>> {
    return this.http.get<Map<String, number>>(`${this.psUrl}dashboard/get-nbr-sprint-by-status/${idProject}`);
  }

  getNbrStoriesByProject(idProject: string): Observable<number> {
    return this.http.get<number>(`${this.psUrl}dashboard/get-nbr-stories/${idProject}`);
  }
  getNbrTasksByProject(idProject: string): Observable<number> {
    return this.http.get<number>(`${this.psUrl}dashboard/get-nbr-tasks/${idProject}`);
  }
  getNbrIssuesByProject(idProject: string): Observable<number> {
    return this.http.get<number>(`${this.psUrl}dashboard/get-nbr-issues/${idProject}`);
  }
  getNbrSprintsByProject(idProject: string): Observable<number> {
    return this.http.get<number>(`${this.psUrl}dashboard/get-nbr-sprints/${idProject}`);
  }
  getNbrStoriesBySprint(idProject: string): Observable<Map<String, number>> {
    return this.http.get<Map<String, number>>(`${this.psUrl}dashboard/get-nbr-stories-by-sprint/${idProject}`);
  }
  getNbrTasksByAssignee(idProject: string): Observable<Map<String, number>> {
    return this.http.get<Map<String, number>>(`${this.psUrl}dashboard/get-nbr-tasks-by-assignee/${idProject}`);
  }
}
