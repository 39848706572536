import { Component, OnInit } from '@angular/core';
import {CalenderService} from '../../../services/calender/calender.service';
import {Meeting} from '../../../models/meeting';
import {Auth} from '../../../../auth/model/Auth';
import {MeetingType} from '../../../models/enum/MeetingType';
import {AuthService} from '../../../../auth/service/auth.service';
import {TokenStorageService} from '../../../../auth/service/token/token.service';
import {Employee} from '../../../models/Employee';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { Project } from 'app/modules/project-management/models/Project';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';

@Component({
  selector: 'ngx-create-meet',
  templateUrl: './create-meet.component.html',
  styleUrls: ['./create-meet.component.scss'],
})
export class CreateMeetComponent implements OnInit {
  meet: Meeting = new Meeting();
  users: Auth[] = [];
  selectedType: MeetingType;
  meetTypes = [
    {name : 'Planning', value : MeetingType.SprintPlanning},
    {name : 'Daily', value : MeetingType.DailyScrum},
    {name : 'Review', value : MeetingType.SprintReview},
    {name : 'Retrospective', value : MeetingType.SprintRetrospective},
    {name : 'Refinement', value : MeetingType.BacklogRefinement},
  ];
  emp: Employee = new Employee();
  projectId: string;
  project: Project;
  projectMembers: string[] = [];
  constructor(private projectService: ProjectService, private calendarService: CalenderService, private authService: AuthService,
              private tokenStorageService: TokenStorageService, private route: ActivatedRoute) {
  }

  usersLoaded = false;
  userConnected = JSON.parse(localStorage.getItem('auth-user'));
  public connectedUser: Auth = new Auth();

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.project = new Project();
      this.projectService.findProjectById(this.projectId).subscribe({
        next: (value: Project) => {
          this.project = value;
          console.log(value);

          this.projectMembers = [...this.project?.memberList];
          console.log(this.projectMembers);

        },
        error: (err) => {
          console.log(err);
        },
      });
    });
  }

  createMeeting(): void {
     // Set the idProject in your 'this.meet' object, assuming it has a property 'project'
     this.meet.idProject = this.projectId;

     // Rest of your existing code for adding the meeting
     this.calendarService.addMeeting(this.meet).subscribe(
       (response: any) => {
         console.log('Salle créée avec succès', response);
         console.log(this.meet);
         window.location.reload();
       },
       (error: any) => {
         console.error('Error creating meeting', error);
       },
     );
  }

}
