
import { AfterViewInit, Component, TemplateRef } from '@angular/core';
import { Project } from '../../models/Project';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../services/project/project.service';
import { TokenStorageService } from '../../../auth/service/token/token.service';
import { NbDialogRef, NbDialogService, NbThemeService } from '@nebular/theme';
import Highcharts from 'highcharts';
import HighchartsTheme from 'highcharts/themes/dark-blue';
import HighchartsGantt from 'highcharts/modules/gantt';
import exportingInit from 'highcharts/modules/exporting';
import { RoadmapService } from '../../services/roadmap/roadmap.service';
import { Sprint } from '../../models/Sprint';
import {Task} from '../scrum-board/utils/models/task';
import { Observable } from 'rxjs';

exportingInit(Highcharts);
HighchartsGantt(Highcharts);
HighchartsTheme(Highcharts);
@Component({
  selector: 'ngx-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss'],
})
export class RoadmapComponent implements AfterViewInit {
  selectedOption: string = 'Edit';
  dialogRef: NbDialogRef<any>;

  currentIndex: number = 0; // Index du morceau de tâches actuellement affiché
  taskChunks: any[] = []; // Sous-listes de tâches
  chunkSize: number = 10;
  tasksNumber: number = Math.ceil(12 / 10);
  project: Project = null;
  list: Sprint[];
  title: string;
  Data: any[] = [];
  projectId: string;

  constructor(private route: ActivatedRoute,
              private projectService: ProjectService,
              private tokenStorageService: TokenStorageService,
              private dialogService: NbDialogService,
              private routeUrl: Router,
              private themeService: NbThemeService,
              private roadmapService: RoadmapService,
  ) {
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.background();
        this.getAllSprint();
        this.getTitle();
      }
    });
  }
  openModal(templateRef: TemplateRef<any>): void {
    console.log('Modal opened');
    this.dialogRef = this.dialogService.open(templateRef);
  }
  closeModal = (): void => {
    // this.dialogRef.onClose.subscribe(() => {
    //   this.routeUrl.navigate([window.location.pathname + '?projectId=' + this.projectId]); // Remplacez '/autre-page' par le chemin de la page de redirection souhaitée
    // });
    this.dialogRef.close();
  }
  getTitle() {
    this.roadmapService.findProject(this.projectId).subscribe(
      {
        next: (result: any) => {
          this.title = ' Diagram Roadmap : Project ' + result.title;
        },
      },
    );
  }
  getAllSprint() {
    this.roadmapService.findAllSprintProject(this.projectId).subscribe({
      next: (result) => {
        this.list = result.filter(sprint => {
          return sprint.status.toString() !== 'completed' ;
        });
        this.fillSeriesData(this.list);
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  previousPage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.GanttView();
    }

  }

  background() {
    const handleStyleChanges = (mutationsList: MutationRecord[], observerInstance: MutationObserver) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const bodyElement = document.getElementsByTagName('body')[0];
          const currentWindowBackgroundColor = getComputedStyle(bodyElement).backgroundColor;
          console.log('Nouvelle couleur de fond détectée :', currentWindowBackgroundColor);
        }
      }
    };

    // Créer une instance de MutationObserver
    const observer = new MutationObserver((mutationsList, observerInstance) => {
      handleStyleChanges(mutationsList, observerInstance);
    });

    // Options de configuration pour observer les changements de style
    const observerOptions = {
      attributes: true, // Observer les mutations d'attributs
      attributeFilter: ['style'], // Filtre pour observer uniquement les mutations de l'attribut 'style'
    };

    // Commencer l'observation en attachant l'observateur à l'élément racine
    observer.observe(document.documentElement, observerOptions);

  }

  nextPage() {
    if (this.currentIndex < this.taskChunks.length - 1) {
      this.currentIndex++;
      this.GanttView();
    }
  }
  getTaskByID(id: string): Observable<Task> {
    return this.roadmapService.findTaskByID(id);
  }
  // async fillSeriesData(tasks: Sprint[]) {
  //   if (tasks) {
  //     for (const task of tasks) {
  //       this.Data.push({
  //         name: task.title,
  //         x: new Date(task.startDate).getTime(),
  //         x2: new Date(task.endDate).getTime(),
  //       });
  //       if (task.tasks && task.tasks.length > 0) {
  //         for (const subTask of task.tasks) {
  //           this.Data.push({
  //             name: task.title + '.' + subTask.title,
  //             x: new Date(subTask.startDate).getTime(),
  //             x2: new Date(subTask.dueDate).getTime(),
  //           });
  //           if (subTask.subTasks && subTask.subTasks.length > 0) {
  //             for (const SubsubTask of subTask.subTasks) {
  //               const subTaskData: Task = await this.getTaskByID(SubsubTask).toPromise();
  //               this.Data.push({
  //                 name: task.title + '.' + subTask.title + '.' + subTaskData.title,
  //                 x: new Date(subTaskData.startDate).getTime(),
  //                 x2: new Date(subTaskData.dueDate).getTime(),
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }
  //
  //   }
  // }
  async fillSeriesData(sprints: Sprint[]) {
    for (const sprint of sprints) {
      this.Data.push({
        name: sprint.title,
        x: new Date(sprint.startDate).getTime(),
        x2: new Date(sprint.endDate).getTime(),
      });

      if (sprint.tasks && sprint.tasks.length > 0) {
        console.log('je suis sprint.tasks');
        console.log(sprint.tasks);
        for (const tasks of sprint.tasks) {
          const taskup = await this.roadmapService.findTaskByID(tasks.id).toPromise();
          console.log('je suis task et sss');
          console.log(taskup);
          this.Data.push({
            name: sprint.title + '.' + taskup.title,
            x: new Date(taskup.startDate).getTime(),
            x2: new Date(taskup.dueDate).getTime(),
          });
          console.log(this.Data);
          if (taskup.subTasks) {
            console.log(taskup.subTasks);
            await this.processSubTasks(taskup.subTasks, sprint.title, tasks.title);
          }
        }
      }
    }
    this.GanttView();
  }
  async processSubTasks(subTask: string[], taskTitle: string, subTaskTitle: string) {
    if (subTask && subTask.length > 0) {
      for (const SubsubTask of subTask) {
        const subTaskData: Task = await this.getTaskByID(SubsubTask).toPromise();
        this.Data.push({
          name: taskTitle + '.' + subTaskTitle + '.' + subTaskData.title,
          x: new Date(subTaskData.startDate).getTime(),
          x2: new Date(subTaskData.dueDate).getTime(),
        });
        if (subTaskData.subTasks) {
          await this.processSubTasks(subTaskData.subTasks, taskTitle + '.' + subTaskTitle, subTaskData.title);
        }
      }
    }
  }

  GanttView() {
    const tasks: any[] = []; // Initialisez une nouvelle liste pour les tâches
    console.log('seriesData', this.Data);
    let j = 0 ;
    while ( j < this.Data.length) {
      const task = {
        name: this.Data[j].name,
        start: new Date(this.Data[j].x).getTime(),
        end: new Date(this.Data[j].x2).getTime(),
      };
      tasks.push(task);
      j++ ;
    }
    console.log(tasks);
    for (let i = 0; i < tasks.length; i += this.chunkSize) {
      this.taskChunks.push(tasks.slice(i, i + this.chunkSize));
    }
    if (typeof (tasks.length / 10) === 'number')
      console.log(tasks.length / 10);
    else console.log(tasks.length + 1);
    console.log(Math.ceil(tasks.length / 10));
    const currentTasks = this.taskChunks[this.currentIndex];

    const seriesData = currentTasks?.map((task) => {
      return {
        name: task.name,
        x: new Date(task.start).getTime(),
        x2: new Date(task.end).getTime(),
      };
    });
    Highcharts.ganttChart('container', {
      chart: {
        backgroundColor: this.getBackgroundColor(),
      },
      rangeSelector: {
        enabled: true,
        buttons: [
          { type: 'day', count: 1, text: '1 jour' },
          { type: 'week', count: 1, text: '1 semaine' },
          { type: 'month', count: 1, text: '1 mois' },
          { type: 'all', text: 'Tout' },
        ],
        selected: 1,
        buttonPosition: {
          align: 'left',
        },
      },
      series: [{
        type: 'xrange',
        name: 'Projet 1',
        data: seriesData as Highcharts.XrangePointOptionsObject[],
        dataLabels: {
          enabled: true,
          verticalAlign: 'middle',
          format: '{x2:%e %b}', // Format de date personnalisé pour la deuxième ligne
        },
      }],
      xAxis: [{
        type: 'datetime',
        tickInterval: 24 * 3600 * 1000, // Afficher une étiquette par jour (interval de 24 heures)
        dateTimeLabelFormats: {
          day: '%e %b', // Format d'affichage pour les jours
        },
      }, {
        type: 'datetime',
        //  linkedTo: 0,
        opposite: true,
        tickInterval: 7 * 24 * 3600 * 1000, // Afficher une étiquette par semaine (interval de 7 jours)
        dateTimeLabelFormats: {
          week: '%b,%Y', // Format d'affichage pour les semaines
        },
      }],

    });
  }
  getBackgroundColor(): string {
    if (this.themeService.currentTheme === 'default' || this.themeService.currentTheme === 'corporate') {
      return '#EDF1F7';
    } else if (this.themeService.currentTheme === 'cosmic') {
      return '#1B1B38';
    } else if (this.themeService.currentTheme === 'dark') {
      return '#151A30';
    } else return '';
  }
}
