import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HumainCapitalManagementRoutingModule } from './humain-capital-management-routing.module';


import {
  NbAccordionModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbProgressBarModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbTagModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule, NbWindowModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { NbEvaIconsModule } from '@nebular/eva-icons';


@NgModule({
  declarations: [
   
  ],
  imports: [
    HumainCapitalManagementRoutingModule,
    
    NbIconModule,
    CommonModule,
    FormsModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbDialogModule,
    NbCardModule,
    MatMenuModule,
    NbEvaIconsModule,
    NbAlertModule,
    NbStepperModule,
    ReactiveFormsModule,
    NbListModule,
    NbUserModule,
    NbBadgeModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbAccordionModule,
    NbTagModule,
    NbCheckboxModule,
    NbLayoutModule,
    NbTooltipModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NbWindowModule,
    NbFormFieldModule,
    CalendarModule.forRoot({
        provide : DateAdapter,
        useFactory : adapterFactory,
    }),
    NbToggleModule,
    NbProgressBarModule,


  ],
})
export class HumainCapitalManagementModule { }
