<mat-toolbar  class="justify-content-between px-5 mb-4 toolbar">
  <div>
    <button nbButton (click)="navigateToScrumProcess()">Back</button>
  </div>
  <div>
    <span class="mat-h2" style="font-size : 22px">Scrum Project Board</span>
  </div>
  <div>
    <ngx-dialog (emitText)="addColumn($event)" question="Add new list">
      <button nbButton status="primary">
        Add New List
      </button>
    </ngx-dialog>
  </div>
</mat-toolbar>
