<div>
  <button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button" (click)="toggleTooltip($event)">
    <mat-icon>help_outline</mat-icon>
  </button>
  <h3 class="w-100 text-center">Lean Methods Choice</h3>
  <div class="content">
    <nb-card class="item">
      <nb-card-header>
        <h4 class="w-100 text-center">Kanban</h4>
      </nb-card-header>
      <nb-card-body>
        <div class="item-body">
          <img src="https://i.ibb.co/RN95KjM/image.png" alt="kanban" class="img"/>
          <button nbButton status="primary" (click)="chooseLeanMethod('KANBAN')">Choose</button>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card class="item">
      <nb-card-header>
        <h4 class="w-100 text-center">5S</h4>
      </nb-card-header>
      <nb-card-body>
        <div class="item-body">
          <img src="https://i.ibb.co/phCZbJb/image-removebg-preview.png" alt="5S" class="img"/>
          <button nbButton status="primary" (click)="chooseLeanMethod('5S')">Choose</button>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card class="item">
      <nb-card-header>
        <h4 class="w-100 text-center">PDCA</h4>
      </nb-card-header>
      <nb-card-body>
        <div class="item-body">
          <img src="https://i.ibb.co/w7Yvts3/image.png" alt="PDCA" class="img"/>
          <button nbButton status="primary" (click)="chooseLeanMethod('PDCA')">Choose</button>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card class="item">
      <nb-card-header>
        <h4 class="w-100 text-center">VSM</h4>
      </nb-card-header>
      <nb-card-body>
        <div class="item-body">
          <img src="https://i.ibb.co/mR2qPf6/image.png" alt="VSM" class="img"/>
          <button nbButton status="primary" (click)="chooseLeanMethod('VSM')">Choose</button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="tooltip-card" [class.show]="showTooltip" style="background: transparent; border: none;">
    <nb-card style="max-height: 500px; overflow-y: auto; text-align: justify;">
      <nb-card-body>
        <button nbButton (click)="closeTooltip()" class="close-sticker">↩</button>
        <p style="margin-top: 3%; font-size: 16px;">In this description, you will find information about our four process improvement methods: Kanban, 5S, VSM, and PDCA.
          Each method has its unique benefits, and by understanding their features, you can make an informed choice based on your specific needs.
        </p>
        <h5 style="margin-top: 5%">Method Selection: Kanban</h5>
        <p>
          <strong>Description:</strong> <br>
          Kanban is a visual management method that originated from Lean manufacturing.
          It focuses on improving workflow efficiency, reducing waste, and enhancing overall productivity.
        </p>
        <p><strong>Why Choose Kanban:</strong></p>
        <ul>
          <li><strong>Flexibility:</strong> Kanban is adaptable to various industries and processes, making it suitable for both manufacturing and service sectors.</li>
          <li><strong>Real-time Visibility:</strong> The visual boards provide instant insights into the status of tasks, enabling better decision-making and resource allocation.</li>
          <li><strong>Continuous Improvement:</strong> By identifying and addressing process inefficiencies, Kanban promotes a culture of ongoing improvement.</li>
        </ul>

        <h5 style="margin-top: 5%">Method Selection: 5S</h5>
        <p>
          <strong>Description:</strong> <br>
          The 5S method aims to create an organized and efficient workplace by following five principles: Sort, Set in order, Shine, Standardize, and Sustain.
        </p>
        <p><strong>Why Choose 5S:</strong></p>
        <ul>
          <li><strong>Improved Efficiency:</strong> 5S optimizes workspace layout and organization, leading to reduced search times and increased productivity.</li>
          <li><strong>Enhanced Safety:</strong> A clean and organized workspace reduces the risk of accidents and promotes a safer environment for employees.</li>
          <li><strong>Cultural Shift:</strong> 5S fosters a culture of discipline and continuous improvement by encouraging employees to maintain order and cleanliness.</li>
        </ul>

        <h5 style="margin-top: 5%">Method Selection: PDCA (Plan-Do-Check-Act)</h5>
        <p>
          <strong>Description:</strong> <br>
          PDCA is a systematic method for continuous improvement. It involves four stages:
          Plan (identify the problem and plan for improvement),
          Do (implement the plan on a small scale),
          Check (evaluate the results),
          and Act (implement the solution on a larger scale or refine the plan).
        </p>
        <p><strong>Why Choose PDCA:</strong></p>
        <ul>
          <li><strong>Iterative Improvement:</strong> PDCA encourages a cycle of continuous learning and adjustment, leading to ongoing improvements over time.</li>
          <li><strong>Structured Approach:</strong> The method provides a clear structure for problem-solving, ensuring that changes are well-planned and tested.</li>
          <li><strong>Data-Driven:</strong> PDCA emphasizes the importance of data collection and analysis, enabling informed decision-making.</li>
        </ul>

        <h5 style="margin-top: 5%">Method Selection: Value Stream Mapping (VSM)</h5>
        <p>
          <strong>Description:</strong> <br>
          VSM is a comprehensive method used to analyze, visualize, and optimize the flow of materials and information in a process.
        </p>
        <p><strong>Why Choose VSM:</strong></p>
        <ul>
          <li><strong>Holistic Analysis:</strong> VSM provides a bird's-eye view of the entire process, helping to identify inefficiencies and areas for optimization.</li>
          <li><strong>Waste Reduction:</strong> By pinpointing bottlenecks and non-value-added activities, VSM helps streamline the process and eliminate waste.</li>
          <li><strong>Customer-Centric:</strong> VSM focuses on improving processes to meet customer needs more effectively, enhancing overall satisfaction.</li>
        </ul>
      </nb-card-body>
    </nb-card>
  </div>
</div>

