import {Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { NbTagComponent, NbTagInputDirective } from '@nebular/theme';
import { Project } from 'app/modules/project-management/models/Project';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';
import { SprintService } from 'app/modules/project-management/services/sprint/sprint.service';
import { TeamService } from 'app/modules/project-management/services/team/team.service';
import { Sprint } from '../../../scrum-board/utils/models/column.model';
import { ScrumBoadService } from '../../../scrum-board/utils/services/scrum-boad.service';
import {Task} from '../../../scrum-board/utils/models/task';

@Component({
  selector: 'ngx-lean-item-assignee',
  templateUrl: './lean-item-assignee.component.html',
  styleUrls: ['./lean-item-assignee.component.scss']
})
export class LeanItemAssigneeComponent implements OnInit {

  @Input() project: Project;
  @Input() task: Task;
  options: string[] = [];
  filteredControlOptions$: Observable<string[]>;
  inputFormControl: FormControl;
  membersList: Set<string> = new Set<string>();
  @ViewChild(NbTagInputDirective, { read: ElementRef }) tagInput: ElementRef<HTMLInputElement>;
  constructor(private boardApi: ScrumBoadService) { }

  ngOnInit(): void {
    console.log(this.project);
    this.task.assignees?.map((assingee: string) => {
      this.membersList.add(assingee);
    });
    this.options = [...this.project?.memberList];
    this.options = this.options.filter(item => !this.task?.assignees?.includes(item));
    this.filteredControlOptions$ = of(this.options);
    this.inputFormControl = new FormControl();

  }
    addToTeam() {
      this.membersList.add(this.inputFormControl.value);
      this.task.assignees = [...this.membersList];
      this.boardApi.updateTask(this.task).subscribe(res => {
        this.task = res;
        this.options = this.options.filter(value => value !== this.inputFormControl.value);
        this.filteredControlOptions$ = of(this.options);
        this.inputFormControl.reset();
      });
    }
    removeMember(member: string) {
    this.membersList.delete(member);
    this.task.assignees = [...this.membersList];
      this.boardApi.updateTask(this.task).subscribe(res => {
        this.task = res;
        this.options.push(member);
        this.filteredControlOptions$ = of(this.options);
      });
    }

}
