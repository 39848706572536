import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-assumption-table',
  templateUrl: './assumption-table.component.html',
  styleUrls: ['./assumption-table.component.scss'],
})
export class AssumptionTableComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }

}
