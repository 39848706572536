<nb-card class="custom-card">
  <nb-card-header>
    <div class="modal-title">
      <h4 class="title">Edit Sprint</h4>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form (ngSubmit)="saveSprint()">
      <div class="form-group update-form-row">
        <label for="sprintTitle">Title :</label>
        <input nbInput type="text" id="sprintTitle" [(ngModel)]="sprint.title" name="title" required style="width:61%">
      </div>
      <div class="form-group update-form-row">
        <label for="sprintStartDate">Start Date :</label>
        <input nbInput id="sprintStartDate" [nbDatepicker]="startDate" [(ngModel)]="sprint.startDate" name="startDate" required>
      </div>
      <nb-datepicker #startDate></nb-datepicker>
      <div class="form-group update-form-row">
        <label for="sprintEndDate">End Date :</label>
        <input nbInput id="sprintEndDate" [nbDatepicker]="endDate" [(ngModel)]="sprint.endDate" name="endDate" required>
      </div>
      <nb-datepicker #endDate></nb-datepicker>
      <div class="form-group update-form-row">
        <label for="sprintStatus">Status :</label>
        <nb-select
          id="sprintStatus"
          style="width: 140px"
          placeholder="Status"
          [size]="'small'"
          hero
          shape="semi-round"
          (selectedChange)="changeSprintStatus($event)"
          [(selected)]="sprint.status">
          <nb-option [value]="'notStarted'" style="padding: 5%;">
            Not Started
          </nb-option>
          <nb-option [value]="'inProgress'" style="padding: 5%;">
            In Progress
          </nb-option>
          <nb-option [value]="'completed'" style="padding: 5%;">
            Completed
          </nb-option>
        </nb-select>
      </div>
      <div class="form-group update-form-row">
        <label for="sprintDescription">Description :</label>
        <textarea nbInput id="sprintDescription" [(ngModel)]="sprint.description" name="description" required style="width:61%"></textarea>
      </div>
      <div class="modal-actions w-100 d-flex justify-content-between align-items-center">
        <button nbButton type="button" status="danger" (click)="cancel()">Cancel</button>
        <button nbButton type="submit" status="success">Save</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
