<nb-card>
  <nb-card-header>
    <h2>Sprints</h2>
    <nb-select [(ngModel)]="selectedView">
      <nb-option value="active">Active Sprints</nb-option>
      <nb-option value="archived">Archived Sprints</nb-option>
    </nb-select>
  </nb-card-header>
  <nb-card-body>
    <angular2-smart-table
      [settings]="settings"
      [source]="selectedView === 'active' ? activeSprints : archivedSprints"
      (userRowSelect)="onUserRowSelect($event)"
      class="order-table"
      style="cursor: pointer">
    </angular2-smart-table>
  </nb-card-body>
</nb-card>
