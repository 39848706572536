import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { TokenStorageService } from 'app/modules/auth/service/token/token.service';
import { Agenda } from 'app/modules/project-management/models/Agenda';
import { CalenderService } from 'app/modules/project-management/services/calender/calender.service';

@Component({
  selector: 'ngx-create-minutes',
  templateUrl: './create-minutes.component.html',
  styleUrls: ['./create-minutes.component.scss']
})
export class CreateMinutesComponent implements OnInit {
  participantNames: string[] = [];
  agendaId: string;
  agenda: Agenda;
  meetingMinutesForm: FormGroup;
  @ViewChild('participantNameInput') participantNameInput: ElementRef;
  actionItemResponsiblePerson: FormControl = new FormControl();


  constructor(
    private toastrService: NbToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private agendaService: CalenderService,
    private tokenStorageService: TokenStorageService
  ) {
    this.meetingMinutesForm = this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      location: ['', Validators.required],
      agendaName: ['', Validators.required],
      agendaDescription: ['', Validators.required],
      participants: this.fb.array([]),
      actionItems: this.fb.array([]),
      finalNotes: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.agendaId = params.get('id');
      this.fetchAgendaDetails(this.agendaId);
    });
  }

  fetchAgendaDetails(id: string): void {
    this.agendaService.getAgendaById(id).subscribe(
      data => {
        this.agenda = data;
        this.populateFormWithAgendaDetails();
        console.log('Agenda details:', this.agenda);
      },
      error => {
        console.error('Error fetching agenda details:', error);
      }
    );
  }

  populateFormWithAgendaDetails(): void {
    if (this.agenda) {
      this.meetingMinutesForm.patchValue({
        date: this.agenda.date,
        time: this.agenda.time,
        location: this.agenda.location,
        agendaName: this.agenda.agendaName,
        agendaDescription: this.agenda.agendaDescription,
      });
    }
  }

  get participants(): FormArray {
    return this.meetingMinutesForm.get('participants') as FormArray;
  }

  get actionItems(): FormArray {
    return this.meetingMinutesForm.get('actionItems') as FormArray;
  }

  addParticipant(name: string): void {
    if (name) {
      this.participants.push(this.fb.control(name, Validators.required));
      this.participantNames.push(name); // Add the participant to the participantNames array

    }
    this.participantNameInput.nativeElement.value = '';  // Corrected this line
  }

  addActionItem(title: string, description: string, responsiblePerson: string, deadline: string): void {
    if (title && description && responsiblePerson && deadline) {
      this.actionItems.push(this.fb.group({
        title: [title, Validators.required],
        description: [description, Validators.required],
        responsiblePerson: [responsiblePerson, Validators.required],
        deadline: [deadline, Validators.required]
      }));
    }
  }
  private showToast(position, status, message) {
    this.toastrService.show(message, 'Status', { position, status });
  }
  onSubmit(): void {
    if (this.meetingMinutesForm.valid) {
      const meetingMinutesDTO = this.meetingMinutesForm.value;
      meetingMinutesDTO.participants = this.participants.value;
      meetingMinutesDTO.actionItems = this.actionItems.value;

      this.agendaService.createMeetingMinutesFromAgenda(this.agendaId, meetingMinutesDTO).subscribe(
        (response) => {
          console.log('Meeting minutes created:', response);
          const agendaId = response.agendaId; // Assuming the response contains the agenda's ID

          this.showToast('top-right', 'success', 'MeetingMinutes created successfully');
          this.router.navigate(['projectManagement/minutess',  agendaId]);

          // Optionally, navigate to another page or show a success message
        },
        (error) => {
          console.error('Error creating meeting minutes:', error);
          this.showToast('top-right', 'danger', 'Failed to create agenda');

        }
      );
    }
  }
  
}
