<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card size="giant">
      <nb-card-header>Update </nb-card-header>
      <nb-card-body>
        <ngx-activities-form [selectedItem]="activitySelected" (dataChanged)="refresh($event)"></ngx-activities-form>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton (click)="ref.close()">Close Dialog</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  <button nbButton (click)="open(dialog)">Open Dialog</button>