import { NbMenuItem } from '@nebular/theme';
import moment from 'moment';
import { ProjectManagementItems } from './ProjectManagementItems';

let user: any;
let hrChildren: any = [];
let crmChildren: any = [];
let adminChildren: any = [];
let pmChildren: any = [];
let financeChildren: any = [];
let accountingChildren: any = [];
let commMarkChildren: any = [];
let recoveryLitigeChildren: any = [];
let pimChildren: any = [];
const dateToday = new Date();
const ishidd: any = [];

const authUserJSON = localStorage.getItem('auth-user');
const getRole = localStorage.getItem('userrole');
if (authUserJSON !== null) {
  user = JSON.parse(authUserJSON);



  if (getRole === 'ROLE_PROJECT_MANAGER' || getRole === 'ROLE_SCRUM_MASTER'|| getRole ==='ROLE_MONITORING_MANAGER') {
    ishidd[1] = user && user.role === 'ROLE_ADMIN' || user.role === 'ROLE_PROJECT_MANAGER' || user.role === 'ROLE_SUPER_ADMIN';

    console.log(ProjectManagementItems.loadVisibilityState());
    console.log(user);
    console.log(user.role);

    pmChildren = [
      {
        title: 'Project Management Process',
        link: '/projectManagement/process',
      },
      {
        title: 'Project Charter',
        link: '/projectManagement/project-charter',
      },
      {
        title: 'Projects',
        link: '/projectManagement/projects',
      },
      {
        title: 'Shared Projects',
        link: '/projectManagement/sharedProject',
      },
      {
        title: 'Work space',
        link: '/projectManagement/tasks',
      },
      {
        title: 'Agenda',
        link: '/projectManagement/create-agenda',
        children: [
          {
            title: 'Add New',
            link: '/projectManagement/create-agenda',
          },
          {
            title: 'All Agendas',
            link: '/projectManagement/agendas',
          },
        ],
      },
      {
        title: 'MeetingMinutes',
        link: '/projectManagement/create-minutes',
        children: [
          {
            title: 'Minutes List',
            link: '/projectManagement/minutes',
          },
        ],
      },
    ];
  }
  
  if (user.role.some(role => role.name === 'ROLE_ADMIN')) {
    ishidd[0] = user && (user.role.some(role => role.name === 'ROLE_ADMIN') || user.role.some(role => role.name === 'ROLE_HR_MANAGER') || user.role.some(role => role.name === 'ROLE_SUPER_ADMIN'));
    hrChildren = [
      {
        title: 'Human Dashboard',
        link: '/hr/dashboard',
      },
      {
        title: 'Organigram',
        link: '/hr/organigram/view',
      },
      {
        title: 'Departments',
        link: '/hr/department/list',
        children: [
          {
            title: 'Add New',
            link: '/hr/department/create',
          },
          {
            title: 'All Departments',
            link: '/hr/department/list',
          },
        ],
      },
      {
        title: 'Employees',
        link: '/hr/employee/list_',
        children: [
          {
            title: 'Add New',
            link: '/hr/employee/create_',
          },
          {
            title: 'All Employees',
            link: '/hr/employee/list_',
          },
        ],
      },
      {
        title: 'Contracts',
        link: '/hr/contracts/keypad',
        children: [
          {
            title: 'All Contracts',
            link: '/hr/contracts/keypad',
          },
        ],
      },
      {
        title: 'Expenses',
        link: '/hr/expenses/keypad',
        children: [
          {
            title: 'All Expenses',
            link: '/hr/expenses/grid',
          },
        ],
      },
      {
        title: 'Payroll',
        link: '/pages/layout/infinite-list',
        children: [
          {
            title: 'Payroll Data',
            link: '/hr/pay/grid4payroll',
          },
        ],
      },
      {
        title: 'Absences',
        pathMatch: 'prefix',
        link: '/hr/absences/global_calendar',
        children: [
          {
            title: 'View Calendar',
            link: '/hr/absences/global_calendar',
          },
          {
            title: 'All Employees Requests',
            link: '/hr/absences/allLeavesRequestsGridView',
          },
          {
            title: 'All Employees Releases',
            link: '/hr/release/grid',
          },
        ],
      },
      {
        title: 'Recruitment',
        children: [
          {
            title: 'Recruitment Workflow',
            link: '/hr/recruitment/workflow',
          },
          {
            title: 'Job',
            link: '/hr/recruitment/jobs/addJob',
          },
          {
            title: 'Job Offers',
            link: '/hr/recruitment/jobs/grid',
          },
          {
            title: 'Skills',
            link: '/hr/skills/grid',
          },
          {
            title: 'Applications',
            link: '/hr/recruitment/applications/grid',
          },
          {
            title: 'Interviews',
            link: '/hr/recruitment/interview/room',
          },
        ],
      },
      {
        title: 'Evaluation',
        pathMatch: 'prefix',
        link: '/hr/evaluation/grid',
        children: [
          {
            title: 'Add new',
            link: '/hr/evaluation/create',
          },
          {
            title: 'All Evaluations',
            link: '/hr/evaluation/grid',
          },
        ],
      },
      {
        title: 'Trainings',
        pathMatch: 'prefix',
        children: [
          {
            title: 'Add new',
            link: '/hr/trainings/training/create',
          },
          {
            title: 'All Trainings',
            link: '/hr/trainings/grid',
          },
        ],
      },
      {
        title: 'History',
        pathMatch: 'prefix',
        children: [
          {
            title: 'Departments',
            link: '/hr/department/history',
          },
          {
            title: 'Employees',
            link: '/hr/employee/history',
          },
          {
            title: 'Contracts',
            link: '/hr/contracts/history',
          },
          {
            title: 'Absences',
            link: '/hr/absences/history',
          },
        ],
      },
    ];
  }

  if (getRole === 'ROLE_SUPER_ADMIN') {
    ishidd[7] = user && user.role.some(role => role.name === 'ROLE_SUPER_ADMIN');
    adminChildren = [
      {
        title: 'Insights',
        link: '/administration/dashboardRequests',
      },
      {
        title: 'Account activation requests',
        link: '/administration/rentalRequest',
      },
      {
        title: 'Archived requests',
        link: '/administration/archivedRequests',
      },
      {
        title: 'Users Management',
        link: '/administration/users',
      },
      {
        title: 'Landing Page Management',
        link: '/administration/landingPageManagement',
      },
      {
        title: 'Blocked Users',
        link: '/administration/blockedUsers',
      },
      {
        title: 'User History',
        link: '/administration/UserHistory',
      },
    ];
  }
}

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'APIs',
    icon: 'home-outline',
    link: 'models',
  },
  {
    title: 'Administration',
    icon: 'settings-outline',
    expanded: false,
    children: adminChildren,
    hidden: getRole !== 'ROLE_SUPER_ADMIN',
  },
  {
    title: 'Project Management',
    icon: 'list-outline',
    children: pmChildren,

    hidden: !(getRole === 'ROLE_PROJECT_MANAGER' || getRole === 'ROLE_SCRUM_MASTER' || getRole === 'ROLE_SUPER_ADMIN' || getRole==='ROLE_MONITORING_MANAGER'),

  },
  {
    title: 'Human Capital Management',
    icon: 'people-outline',
    children: hrChildren,
    hidden: !ishidd[0],
  },
  {
    title: 'Finance',
    icon: 'activity-outline',
    children: financeChildren,
    hidden: !ishidd[3],
  },
  {
    title: 'Accounting',
    icon: 'briefcase-outline',
    children: accountingChildren,
    hidden: !ishidd[4],
  },
  {
    title: 'Product & Stock',
    icon: 'car-outline',
    children: pimChildren,
    hidden: !ishidd[5],
  },
  {
    title: 'Communication & Marketing',
    icon: 'share-outline',
    children: commMarkChildren,
    hidden: !ishidd[2],
  },
  {
    title: 'Recovery & Litige',
    icon: 'shield-off-outline',
    children: recoveryLitigeChildren,
    hidden: !ishidd[6],
  },
  {
    title: 'CRM',
    icon: 'people-outline',
    children: crmChildren,
    hidden: !ishidd[8],
  },
];
