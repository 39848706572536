import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {BoardService} from '../../scrum-board/utils/services/board.service';
import {NbDialogService, NbThemeService} from '@nebular/theme';
import {Priority, Task, Type} from '../../scrum-board/utils/models/task';
import {Column, Sprint} from '../../scrum-board/utils/models/column.model';
import {ScrumBoadService} from '../../scrum-board/utils/services/scrum-boad.service';
import {catchError, first, switchMap} from 'rxjs/operators';
import {BehaviorSubject, throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import { Project } from 'app/modules/project-management/models/Project';

@Component({
  selector: 'ngx-lean-board-body',
  templateUrl: './lean-board-body.component.html',
  styleUrls: ['./lean-board-body.component.scss']
})
export class LeanBoardBodyComponent implements OnInit {
  @Input() project: Project;
  showTooltip = false;
  protected readonly Type = Type;
  currentDate: Date = new Date();
  tasks$ = new BehaviorSubject<Task[]>([]);
  board: Column[] = [];
  columns: Column[] = [];
  column: Column = new Column();
  newTask: Task;
  selectedSprintId: string;
  constructor(
    public boardService: BoardService,
    public themeService: NbThemeService,
    private dialogService: NbDialogService,
    private boardApi: ScrumBoadService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    console.log('BOARD - INIT');
    setTimeout(() => {
    this.getColumns();
    },3000)
  }

  getColumns() {
    console.log(this.project);

      this.boardApi.getColumnsByleanMethod(this.project.leanMethod, this.project.id).pipe(first()).subscribe(
        (columns: Column[]) => {
          console.log(columns);
          // this.columns = [...this.columns, ...columns];
          this.boardService.board = columns;
          this.boardService.board$.next([...this.boardService.board]);
          console.log(this.boardService.board);
        },
        (error) => {
          console.error('Error fetching columns:', error);
        });
  }

  onTitleChange(event: any, columnId: string) {
    // this.boardService.changeColumnTitle(event.target.value, columnId);

    this.boardApi.getColumnById(columnId).pipe(
      switchMap((res: Column) => {
        this.column = res;
        this.column.title = event.target.value;
        return this.boardApi.updateColumn(this.column);
      }),
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError('Something went wrong during API call.');
      })).subscribe((updateResult) => {
      console.log('Update result:', updateResult);
    });
  }

  deleteConfirm(dialog: TemplateRef<any>, columnTitle: string) {
    this.dialogService.open(dialog, {context: 'Are you sure you want to delete ' + columnTitle + ' list ?'});
  }

  onDeleteColumn(columnId: string) {
    // this.boardService.deleteColumn(columnId);
    this.boardApi.deleteColumn(columnId).subscribe(res => {
      if (res) {
        this.getColumns();
      }
    });
  }

  onColorChange(color: string, columnId: string) {
    this.boardService.changeColumnColor(color, columnId);
  }

  getStatusColor(item: Task): string {
    return (item?.type.toString() === 'STORY' || item?.type === Type.STORY.valueOf() ) ? 'info'
      : ((item?.type.toString() === 'TASK' || item?.type === Type.TASK.valueOf()) ? 'success'
        : ((item?.type.toString() === 'ISSUE' || item?.type === Type.ISSUE.valueOf()) ? 'danger'
          : ''));
  }

  onAddCard(text: string, columnId: string) {
    if (text) {
      // this.boardService.addCard(text, columnId);
      this.newTask = new Task();
      this.newTask.title = text;
      this.newTask.comments = [];
      this.newTask.checklists = [];
      this.newTask.assignees = [];
      this.newTask.createdAt =  new Date();
      this.newTask.updatedAt =  new Date();
      this.newTask.priority = Priority.LOW;
      this.newTask.sprintId = this.selectedSprintId;
      this.boardApi.addTaskBoard(this.newTask, columnId).subscribe(res => {
        if (res) {
          this.getColumns();
        }
      });
    }
  }

  onDeleteCard(cardId: string, column: Column) {
    // this.boardService.deleteCard(cardId, columnId);
    this.boardApi.deleteTask(cardId).pipe(
      switchMap((res) => {
        this.getColumns();
        column.list = column.list.filter((card: Task) => card.id !== cardId);
        return this.boardApi.updateColumn(column);
      }),
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError('Something went wrong during API call.');
      })).subscribe((updateResult) => {
      console.log('Update result:', updateResult);
    });
  }

  onChangeLike(event: { card: any, increase: boolean }, columnId: string) {
    const {card: {id}, increase} = event;
    this.boardService.changeLike(id, columnId, increase);
  }

  onAddComment(event: { id: string, text: string }, columnId: string) {
    this.boardService.addComment(columnId, event.id, event.text);
  }

  onDeleteComment(comment, columnId, item) {
    this.boardService.deleteComment(columnId, item.id, comment.id);
  }

  drop(event: CdkDragDrop<Task[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Update the source column list after the drop
      const sourceColumn = this.boardService.board.find((column) =>
        column.list.includes(event.previousContainer.data[event.previousIndex]));
      if (sourceColumn) {
        sourceColumn.list = event.previousContainer.data;
      }
      console.log(sourceColumn);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);



      // Update the target column list after the drop
      const targetColumn = this.boardService.board.find((column) =>
        column.list.includes(event.container.data[event.currentIndex]));
      if (targetColumn) {
        targetColumn.list = event.container.data;
      }
      console.log(targetColumn);
      this.boardApi.updateColumn(targetColumn).subscribe(res => {
        console.log('target column updated', res);
      });
      this.boardApi.updateColumn(sourceColumn).subscribe(res => {
        console.log('source column updated', res);
      });
    }
  }

  isPastDue(item: Task): boolean {
    // console.log(item?.dueDate);
    // const dueDateTask = new Date(item?.dueDate);
    // console.log(dueDateTask.getTime());
    // return item?.dueDate
    //   && item?.dueDate?.getTime() < this.currentDate?.getTime();
    return false;
  }

  isDueSoon(item: Task): boolean {
    // return item?.dueDate
    //   && item?.dueDate?.getTime() < this.currentDate?.getTime() + (3 * 24 * 60 * 60 * 1000)
    //   && item?.dueDate?.getTime() > this.currentDate?.getTime();
    return false;
  }
  toggleTooltip(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
  }
  closeTooltip() {
    this.showTooltip = false;
  }

}
