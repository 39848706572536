import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Sprint } from '../../scrum-board/utils/models/column.model';
import { ScrumBoadService } from '../../scrum-board/utils/services/scrum-boad.service';

@Component({
  selector: 'ngx-redirectingtable-retro',
  templateUrl: './redirectingtable-retro.component.html',
  styleUrls: ['./redirectingtable-retro.component.scss']
})
export class RedirectingtableRetroComponent implements OnInit {
  flippedState: boolean = false;
  showTooltip = false;
  archivedSprints: Sprint[] = [];
  selectedView: "active" | "archived" = "active"; // Default to active sprints
  isClicked: boolean = false;
  activeSprints: Sprint[] = [];
  selectedSprint: Sprint;
  list: Sprint[] = [];
  projectId: string;
  settings = {
    mode: "external",
    actions: { edit: false, delete: false, add: false },
    columns: {
      title: {
        title: "Name",
        type: "string",
      },
      description: {
        title: "Description",
        type: "string",
      },
      startDate: {
        title: "Start Date",
        type: "string",
      },
      endDate: {
        title: "End Date",
        type: "string",
      },
      status: {
        title: "Status",
        type: "html",
        valuePrepareFunction: (status) => {
          switch (status) {
            case "notStarted": {
              return `<h5><span class="badge badge-info">Not Started</span></h5>`;
            }
            case "completed": {
              return `<h5><span class="badge badge-success">Completed</span></h5>`;
            }
            case "inProgress": {
              return `<h5><span class="badge badge-warning">In Progress</span></h5>`;
            }
            default: {
              return `<h5><span class="badge badge-basic"></span></h5>`;
            }
          }
        },
      }
    },
  };

  constructor(
    private boardApi: ScrumBoadService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.projectId = params["projectId"];
      if (this.projectId) {
        this.getSprints();
      }
    });
  }

  toggleArchived() {
    this.selectedView = this.selectedView === "active" ? "archived" : "active";
  }

  getSprints() {
    this.boardApi.getAllSprintsByProject(this.projectId).subscribe((res) => {
      this.list = res;
      this.filterSprints();
    });
  }

  filterSprints() {
    this.archivedSprints = this.list.filter(
      (sprint) => sprint.status === "completed"
    );
    this.activeSprints = this.list.filter(
      (sprint) => sprint.status !== "completed"
    );
  }

  onUserRowSelect(event): void {
    this.selectedSprint = event.data;
    this.initializeSprintRetrospective();
    this.flippe();
  }

  initializeSprintRetrospective(): void {
    if (!this.selectedSprint.sprintRetrospective) {
      this.selectedSprint.sprintRetrospective = { id: this.generateUUID(), questions: [] };
    }
  }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  flippe(): void {
    this.flippedState = !this.flippedState;
  }
  toggleTooltip(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
  }
  closeTooltip() {
    this.showTooltip = false;
  }
}
