export class ProjectManagementItems {
    static isProjectManagementVisible: boolean = false;
  
    static toggleVisibility() {
      this.isProjectManagementVisible = !this.isProjectManagementVisible;
      this.saveVisibilityState(); // Save visibility state to local storage
    }
  
    static getVisibility(): boolean {
      return this.isProjectManagementVisible;
    }
  
    private static saveVisibilityState() {
      localStorage.setItem('isProjectManagementVisible', JSON.stringify(this.isProjectManagementVisible));
    }
  
    static loadVisibilityState(): boolean {
      const visibilityState = localStorage.getItem('isProjectManagementVisible');
      if (visibilityState !== null) {
        return JSON.parse(visibilityState);
    }
    return false;
    }
  }
  