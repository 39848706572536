import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { ActivatedRoute } from "@angular/router";
import { Chart, registerables } from "chart.js";
import randomColor from 'randomcolor';
Chart.register(...registerables);

@Component({
  selector: "ngx-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  
  chartData!: Map<String, number>;
  chartData2!: Map<String, number>;
  chartData3!: Map<String, number>;
  pieChart: any = [];
  barChart: any = [];
  pieChart2: any = [];
  status: any = [];
  nbrSprintStatus: number[] = [];
  sprintsNumber: number;
  tasksNumber: number;
  storiesNumber: number;
  issuesNumber: number;
  nbrTasks: number[] = [];
  taskAssignees: any = [];
  nbrStories: number[] = [];
  sprintTitles: any = [];
  public isSprintDataAvailable = false;
  public isTaskDataAvailable = false;
  public isStoryDataAvailable = false;
  public isIssueDataAvailable = false;
  public isChart1DataAvailable = false;
  public isChart2DataAvailable = false;
  public isChart3DataAvailable = false;
  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {}
  idProject: string;
  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next : (params) => {
      this.idProject = params["projectId"];

      this.loadSprintsNumber();
      this.loadStoriesNumber();
      this.loadTasksNumber();
      this.loadIssuesNumber();
      this.loadChart1();
      this.loadChart2();
      this.loadChart3();
      },
      complete : () => {},
    });
  }

  generateRandomColors(opacity: number) {
    return randomColor({
      count: 20,
      luminosity: 'bright', // 'light' or 'dark' for different luminosity
      format: 'rgba', // You can also use 'hex', 'rgb', 'hsl', etc.
      alpha: opacity, // Opacity of the background colors
    });
  }
  loadSprintsNumber() {
    this.dashboardService
        .getNbrSprintsByProject(this.idProject)
        .subscribe((datares) => {
          this.sprintsNumber = datares;
          this.isSprintDataAvailable = true;
        });
  }
  loadStoriesNumber() {
    this.dashboardService
    .getNbrStoriesByProject(this.idProject)
    .subscribe((datares) => {
      this.storiesNumber = datares;
      this.isStoryDataAvailable = true;
    });
  }
  loadTasksNumber() {
    this.dashboardService
        .getNbrTasksByProject(this.idProject)
        .subscribe((datares) => {
          this.tasksNumber = datares;
          this.isTaskDataAvailable = true;
        });
  }
  loadIssuesNumber() {
    this.dashboardService
    .getNbrIssuesByProject(this.idProject)
    .subscribe((datares) => {
      this.issuesNumber = datares;
      this.isIssueDataAvailable = true;
    });
  }
  // Chart 1 : Number of sprints by status
  loadChart1() {
    this.dashboardService
    .getNbrSprintsByStatus(this.idProject)
    .subscribe({
      next : (data) => {
      this.chartData = data;
      for (const key in this.chartData) {
        if (!this.chartData.hasOwnProperty(key)) {
          continue;
        }
        this.status.push(key);
        this.nbrSprintStatus.push(this.chartData[key]);
      }
      const colors = this.generateRandomColors(0.6);
      const configDataSprintstatus = {
        labels: this.status,
        datasets: [
          {
            label: "Number of Sprints By Status",
            data: this.nbrSprintStatus,
            backgroundColor: colors,
            hoverBackgroundColor: colors.map(color => color.replace(/[^,]+(?=\))/, '0.3')),
            borderColor: colors.map(color => color.replace(/[^,]+(?=\))/, '1')),
            borderWidth: 1,
          },
        ],
      };
      this.pieChart = new Chart("piechart", {
        type: "pie",
        data: configDataSprintstatus,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
      },
      complete : () => {},
    });
  }
  // Chart 2 : Number of tasks by assignee
  loadChart2() {
    this.dashboardService.getNbrTasksByAssignee(this.idProject).subscribe({
      next: (data) => {
        this.chartData2 = data;
        this.taskAssignees = [];
        this.nbrTasks = [];
        
        for (const key in this.chartData2) {
          if (this.chartData2.hasOwnProperty(key)) {
            this.taskAssignees.push(key);
            this.nbrTasks.push(this.chartData2[key]);
          }
        }
  
        const colors = this.generateRandomColors(0.6);
        const configDataTaskAssignees = {
          labels: this.taskAssignees,
          datasets: [
            {
              label: "Number of tasks by assignees",
              data: this.nbrTasks,
              backgroundColor: colors,
              hoverBackgroundColor: colors.map(color => color.replace(/[^,]+(?=\))/, '0.3')),
              borderColor: colors.map(color => color.replace(/[^,]+(?=\))/, '1')),
              borderWidth: 1,
            },
          ],
        };
  
        this.barChart = new Chart("barchart", {
          type: "bar",
          data: configDataTaskAssignees,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  color: "grey",
                  stepSize: 1,
                },
                grid: {
                  display: false,
                },
              },
              x: {
                beginAtZero: true,
                ticks: {
                  color: "grey",
                },
                grid: {
                  display: false,
                },
              },
            },
          },
        });
      },
      complete: () => {},
    });
  }
  
   // Chart 3 : Number of Stories by Sprint
  loadChart3() {
    this.dashboardService
    .getNbrStoriesBySprint(this.idProject)
    .subscribe({
      next : (data) => {
      this.chartData3 = data;
      console.log(data);
      for (const key in this.chartData3) {
        if (!this.chartData3.hasOwnProperty(key)) {
          continue;
        }
        this.sprintTitles.push(key);
        this.nbrStories.push(this.chartData3[key]);
      }
      const colors = this.generateRandomColors(0.6);
      const configDataSprintstory = {
        labels: this.sprintTitles,
        datasets: [
          {
            label: "Number of Story by Sprints ",
            data: this.nbrStories,
            backgroundColor: colors,
            hoverBackgroundColor: colors.map(color => color.replace(/[^,]+(?=\))/, '0.3')),
            borderColor: colors.map(color => color.replace(/[^,]+(?=\))/, '1')),
            borderWidth: 1,
          },
        ],
      };
      this.pieChart2 = new Chart("piechart2", {
        type: "pie",
        data: configDataSprintstory,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
      },
      complete : () => {},
    });
  }
}
