import { Component, OnInit } from '@angular/core';
import {ScrumBoadService} from './utils/services/scrum-boad.service';
import {BoardService} from './utils/services/board.service';

@Component({
  selector: 'ngx-scrum-board',
  templateUrl: './scrum-board.component.html',
  styleUrls: ['./scrum-board.component.scss'],
})
export class ScrumBoardComponent {

  constructor(private boardApi: ScrumBoadService, private boardService: BoardService) { }

}
