import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ngx-customselect',
  templateUrl: './customselect.component.html',
  styleUrls: ['./customselect.component.scss']
})
export class CustomselectComponent {
  @Input() control: FormControl;
  @Input() options: string[];

}
