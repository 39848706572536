<nb-card >
    <nb-card-header>
      <h2>Sprints</h2>
      <nb-select [(ngModel)]="selectedView">
        <nb-option value="active">Active Sprints</nb-option>
        <nb-option value="archived">Archived Sprints</nb-option>
      </nb-select>
    </nb-card-header>
    <nb-card-body>
      <nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
        <nb-card-front>
          <nb-card>
            <angular2-smart-table
              [settings]="settings"
              [source]=" selectedView === 'active' ? activeSprints : archivedSprints"
              (userRowSelect)=" onUserRowSelect($event)"
              class="order-table"
              style="cursor: pointer"
            >
            </angular2-smart-table>
          </nb-card>
        </nb-card-front>
        <nb-card-back *ngIf="flippedState">
          <nb-card>
            <nb-card-header>
              <nb-icon
                icon="chevron-left-outline"
                pack="nebular-essentials"
                aria-hidden="true"
                (click)="flippe()"
              ></nb-icon>
              Go to Sprint List
            </nb-card-header>
            <nb-card-body>
              <ngx-sprint-retro [selectedSprint]="selectedSprint" (dataChanged)="refresh($event)">
                
              </ngx-sprint-retro>
            </nb-card-body>
          </nb-card>
         </nb-card-back>
      </nb-flip-card>
    </nb-card-body>
  </nb-card>
  <button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button" (click)="toggleTooltip($event)">
    <mat-icon>help_outline</mat-icon>
  </button>
  <!-- The card with the description (initially hidden) -->
  <div class="tooltip-card" [class.show]="showTooltip" style="background: transparent; border: none;">
    <nb-card>
      <nb-card-body>
        <button nbButton (click)="closeTooltip()" class="close-sticker">↩</button>
        <ul>Sprint Retrospective in Scrum methodology:  is a review conducted after a sprint that plays a key role in the Agile methodology.</ul>
        <ul>In this simple exercise you have to choose atleast one of the figures by number that describes you best, then tell us why you chose this or those figures.</ul>
        <ul>Additional Questions:</ul>
        <ul>The scrum master may add questions, and each team member can add an answer to each question.</ul>
      </nb-card-body>
    </nb-card>
  </div>
  
  