import {Component, Inject, OnInit} from '@angular/core';
import {CalenderService} from '../../../../services/calender/calender.service';
import {AuthService} from '../../../../../auth/service/auth.service';
import {TokenStorageService} from '../../../../../auth/service/token/token.service';
import {Meeting} from '../../../../models/meeting';
import {MeetingType} from '../../../../models/enum/MeetingType';
import {Auth} from '../../../../../auth/model/Auth';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Project } from 'app/modules/project-management/models/Project';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';

@Component({
  selector: 'ngx-edit-meeting',
  templateUrl: './edit-meeting.component.html',
  styleUrls: ['./edit-meeting.component.scss'],
})
export class EditMeetingComponent implements OnInit {
  typeMeetings: MeetingType[] = [
    MeetingType.SprintPlanning,
    MeetingType.DailyScrum,
    MeetingType.SprintReview,
    MeetingType.SprintRetrospective,
    MeetingType.BacklogRefinement,
  ];
  meets: any;
  usersLoaded = false;
  userConnected = JSON.parse(localStorage.getItem('auth-user'));
  public connectedUser: Auth = new Auth();
  users: Auth[] = [];
  idUri: String;
  meet: Meeting = new  Meeting();
  projectId: string;
  project: Project;
  projectMembers: string[] = [];
  constructor(private projectService: ProjectService, private router: Router, private calendarService: CalenderService, private authService: AuthService,
              private tokenStorageService: TokenStorageService, @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EditMeetingComponent>) { }

  ngOnInit(): void {
    this.calendarService.getMeetingById(this.data.idmeet).subscribe(
      m => {
        this.meet = m;
      },
      );
  }

  updateMeet() {
    this.calendarService.updateMeeting(this.meet).subscribe(
      (data) => {
        // Le traitement de la réponse de mise à jour réussie peut être effectué ici, si nécessaire
        console.log('Mise à jour réussie!');

        this.dialogRef.close();
        window.location.reload();

      },
      (error) => {
        console.error('Une erreur s\'est produite lors de la mise à jour : ', error);
      },
    );
  }
  updateRecursiveMeet() {
    this.calendarService.updateRecursiveMeetings(this.meet).subscribe(
      (data) => {
        // Le traitement de la réponse de mise à jour réussie peut être effectué ici, si nécessaire
        console.log('Mise à jour réussie!');

        this.dialogRef.close();
        window.location.reload();

      },
      (error) => {
        console.error('Une erreur s\'est produite lors de la mise à jour : ', error);
      },
    );
  }
}
