<nb-card accent="success">
  <nb-card-header>Upload File</nb-card-header>
  <nb-card-body>
    <div class="container">
      <div class="row mb-3">
        <div class="col">
          <input type="file" (change)="onFileSelected($event)" nbInput />
        </div>
        <div class="col">
          <button nbButton (click)="onUpload()">Upload</button>
        </div>
        <div class="col">
          <button nbButton (click)="onCleanData()">Clean Data</button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <form (ngSubmit)="onCleanDataAdvanced()">
            <nb-checkbox [(ngModel)]="options.removeDuplicates" name="removeDuplicates" class="mr-2">Remove Duplicates</nb-checkbox>
            <nb-checkbox [(ngModel)]="options.imputeMissingValues" name="imputeMissingValues" class="mr-2">Impute Missing Values</nb-checkbox>
            <nb-checkbox [(ngModel)]="options.normalizeData" name="normalizeData" class="mr-2">Normalize Data</nb-checkbox>
            <nb-checkbox [(ngModel)]="options.removeOutliers" name="removeOutliers" class="mr-2">Remove Outliers</nb-checkbox>
            <nb-checkbox [(ngModel)]="options.oneHotEncode" name="oneHotEncode">One-Hot Encode</nb-checkbox>
            <div class="mt-3">
              <button nbButton status="success" type="submit">Clean Data</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>


<nb-card accent="success" *ngIf="columns.length && rows.length && uploadClicked && cleanClicked">
  <nb-card-header>Select Data</nb-card-header>
<nb-card-body>
<nb-accordion *ngIf="columns.length && uploadClicked && cleanClicked">
  <nb-accordion-item>
    <nb-accordion-item-header>Select Columns</nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class="row">
        <div class="col-12">
          <nb-checkbox
            (change)="selectAllColumns($event)"
            [checked]="allColumnsSelected"
          >
            Select All</nb-checkbox>
          <div>
            <nb-checkbox
              *ngFor="let column of columns"
              (change)="onSelectColumn($event, column)"
              [checked]="selectedColumns.includes(column)"
              >{{ column }}</nb-checkbox>
          </div>
        </div>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>

<nb-accordion *ngIf="rows.length && uploadClicked && cleanClicked">
  <nb-accordion-item>
    <nb-accordion-item-header>Select Rows</nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class="row">
        <div class="col-12">
          <nb-checkbox (change)="selectAllRows($event)" [checked]="allRowsSelected">
            Select All</nb-checkbox>
          <div>
            <nb-checkbox
              *ngFor="let row of rows"
              (change)="onSelectRow($event, row)"
              [checked]="selectedRows.includes(row)"
              >{{ row.join(", ") }}</nb-checkbox>
          </div>
        </div>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
</nb-card-body>
<nb-card-footer>
<div class="row" *ngIf=" uploadClicked && cleanClicked ">
  <div class="col-12">
    <button nbButton (click)="onSaveSelection()">Save Selection</button>
  </div>
</div>
</nb-card-footer>

</nb-card>
<h2>Data Cleaning Options</h2>


<div *ngIf="cleanedData">
  <h3>Cleaned Data</h3>
  <pre>{{ cleanedData | json }}</pre>
</div>
<!-- <nb-card>
    <nb-card-header>Files</nb-card-header>
    <nb-card-body>
      <nb-list>
        <nb-list-item *ngFor="let file of files">
          <nb-radio (change)="onSelectFile($event, file.id)">{{ file.filename }}</nb-radio>
        </nb-list-item>
      </nb-list>
      <button nbButton (click)="onDeleteFile()">Delete File</button>
    </nb-card-body>
  </nb-card> -->
