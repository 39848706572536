import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardComponent } from './board/board.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { BoardItemComponent } from './board-item/board-item.component';
import { CommentItemComponent } from './comment-item/comment-item.component';

import { DialogModule } from '../utils/dialog/dialog.module';
import { ColorPanelComponent } from './color-panel/color-panel.component';
import {
  NbAccordionModule, NbAutocompleteModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule, NbCheckboxModule, NbDatepickerModule, NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbOptionModule, NbProgressBarModule,
  NbSelectModule, NbTagModule, NbUserModule,
} from '@nebular/theme';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill';
import {BoardHeaderComponent} from './header/board-header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { TaskChecklistComponent } from './board-item/task-checklist/task-checklist.component';
import { TaskAssigneesComponent } from './board-item/task-assignees/task-assignees.component';
import { SprintsTableComponent } from './sprints-table/sprints-table.component';
import { Angular2SmartTableModule } from 'angular2-smart-table';
@NgModule({
  declarations: [
    BoardComponent,
    BoardItemComponent,
    CommentItemComponent,
    ColorPanelComponent,
    BoardHeaderComponent,
    TaskChecklistComponent,
    TaskAssigneesComponent,
    SprintsTableComponent,
  ],
    imports: [
        CommonModule,
        Angular2SmartTableModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        DragDropModule,
        MatExpansionModule,
        FormsModule,
        DialogModule,
        NbIconModule,
        NgbDropdownModule,
        NbCardModule,
        NbButtonModule,
        NbInputModule,
        NbOptionModule,
        NbSelectModule,
        ReactiveFormsModule,
        NbFormFieldModule,
        QuillModule,
        NbUserModule,
        MatToolbarModule,
        NbDatepickerModule,
        NbCheckboxModule,
        NbProgressBarModule,
        NbTagModule,
        NbBadgeModule,
        NbAccordionModule,
        NbAutocompleteModule,
    ],
  exports: [
    BoardComponent,
    BoardHeaderComponent,
    SprintsTableComponent,
  ],
})
export class BoardModule { }
