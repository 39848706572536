<nb-card accent="info">
  <div class="crisp-container">
    <nb-card-header>
      <div class="crisp-header">
        <h2>Phases and Tasks of CRISP</h2>
      </div>
    </nb-card-header>
    <nb-card-body>
      <nb-accordion>
        <nb-accordion-item *ngFor="let stage of stages">
          <nb-accordion-item-header>
            {{ stage.name }}
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="crisp-stage-content">
              <div class="crisp-task" *ngFor="let task of stage.tasks" (click)="onTaskClick(task)"
              [ngClass]="{
                'green-task': 
                (stage.name === 'Business Understanding' && businessUnderstandingExists) ||
                (stage.name === 'Data Understanding' && dataDescriptionsExist)
              }">
                <span>{{ task.name }}</span>
              </div>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </nb-card-body>
  </div>
</nb-card>
