import { Component } from '@angular/core';

@Component({
  selector: 'ngx-explore-data',
  templateUrl: './explore-data.component.html',
  styleUrls: ['./explore-data.component.scss']
})
export class ExploreDataComponent {

}
