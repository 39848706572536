import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,

} from 'date-fns';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {CalenderService} from '../../services/calender/calender.service';
import {CreateMeetComponent} from './create-meet/create-meet.component';
import {MatDialog} from '@angular/material/dialog';
import {Meeting} from '../../models/meeting';
import {EditMeetingComponent} from './Edit-Meeting/edit-meeting/edit-meeting.component';
import { CreateMultipleMeetComponent } from './create-multiple-meet/create-multiple-meet.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'ngx-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
})
export class CalenderComponent implements OnInit {
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;

  events: Array<CalendarEvent<{ time: any }>> = [];
  desplayData: boolean;

  // toast configuration
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  dataRelances: any[] = [];


  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh = new Subject<void>();


  activeDayIsOpen: boolean = false;
  meet: Meeting = new Meeting();


  constructor(private modal: NgbModal, private calendarService: CalenderService,
              private router: Router, private toastrService: NbToastrService, private matDialog: MatDialog,
              private route: ActivatedRoute) { }

  projectId: string;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      console.log(this.projectId);
      this.calendarService.getMeetingByIdProject(this.projectId).subscribe(
        (ev: Meeting[]) => {

          ev.forEach((item) =>
            this.events.push({
              start: new Date(item.dateStart),
              end: new Date(item.dateEnd),
              title:  'Title  : ' + item?.title +
                      '<br> Description  : ' + item?.description +
                      '<br> Link  : ' + item?.lien +
                      '<br> Start Date  : ' + item?.dateStart,

              actions: [
                {
                  label: '<br><span class="text-info" style="color: #77b3ff"><i class="fa fa-edit"></i> Edit</span>',
                  a11yLabel: 'Edit',
                  onClick: ({event}: { event: CalendarEvent }): void => {
                    this.openDialogMeetUpdate(item?.idmeet);
                    console.log(item?.idmeet);
                  },
                },
                {
                  label: '<span class="ml-4 text-warning" style="color: #ffc43f;"><i class="fa fa-trash"></i> Delete One</span>',
                  onClick: ({event}: { event: CalendarEvent }): void => {
                    this.onDeleteConfirm(item.idmeet);
                    this.router.navigate([`/projectManagement/calendar`]);
                    this.calendarService.deleteMeeting(item.idmeet);
                    console.log(item.idmeet);
                  },
                },
                {
                  label: '<span class="ml-4 text-danger" style="color: #f77070;"><i class="fa fa-archive"></i> Delete Recursive</span>',
                  onClick: ({event}: { event: CalendarEvent }): void => {
                    this.onDeleteRecursiveConfirm(item.recursiveKey);
                  },
                },
              ],
            }),
          );

          this.dataRelances = this.events;
          this.viewDate = new Date();
          // this.calendarService.getMeetingById(this.meet.idmeet).subscribe(data => {
          //   this.meet = data;
          //   (error: any) => console.log(error);
          // });
          // this.calendarService.getMeetingById(this.meet.idmeet);
          // console.log(this.calendarService.getMeetingById(this.meet.idmeet));
        });
    });
  }
  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0);
      this.viewDate = date;
    }
  }
  openDialog() {
    this.matDialog.open(CreateMeetComponent, {
      width: '550px',
    });
  }
  openMultipleMeetDialog() {
    this.matDialog.open(CreateMultipleMeetComponent, {
      width: '550px',
    });
  }

  openDialogMeetUpdate(id: string) {
    this.matDialog.open(EditMeetingComponent, {
      width: '550px',
      data: {
        idmeet: id,
      },
    });

  }
  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = {event, action};
    this.modal.open(this.modalContent, {size: 'lg'});
  }
  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  onDeleteConfirm( id: string) {
    if (window.confirm('Are you sure of this action ?')) {
      this.calendarService.deleteMeeting(id)
        .subscribe(
          () => {
            this.showToast('success', 'Delete Successfully',
              'Meeting Deleted !!');

            // this.router.navigate(['/projectManagement/calendar']).then(() => {
            //
            // });
          });
    }
    window.location.reload();
    console.log(this.meet);
  }
  onDeleteRecursiveConfirm( recursiveKey: string) {
    if (window.confirm('Are you sure of this action ?')) {
      this.calendarService.deleteRecursiveMeetings(recursiveKey)
        .subscribe(
          () => {
            window.location.reload();
            this.showToast('success', 'Delete Successfully',
              'Recursive Meetings Deleted !!');
          });
    }
  }
  // Notificatio Toast
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
