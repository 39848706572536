
// @ts-ignore
import { NgModule } from '@angular/core';
// @ts-ignore
import { RouterModule, Routes } from '@angular/router';
import { CreateAgendaComponent } from './components/agenda/create-agenda/create-agenda.component';
import { AgendaListComponent } from './components/agenda/agenda-list/agenda-list.component';
import { TaskmanagerComponent } from './components/activities/taskManager/taskmanager/taskmanager.component';
import { ScheduleComponent } from './components/activities/schedule/schedule/schedule.component';
import { ProjectCharterTablleComponent } from './components/project-charter/project-charter-tablle/project-charter-tablle.component';
import { ProjectCharterDetailsComponent } from './components/project-charter/project-charter-details/project-charter-details.component';
import { AddProjectCharterComponent } from './components/project-charter/add-project-charter/add-project-charter.component';
import { ProjectTableComponent } from './components/project/project-table/project-table.component';
import { ProjectDetailsComponent } from './components/project/project-details/project-details.component';
import { ProcessComponent } from './components/process/process.component';
import { RoadmapComponent } from './components/roadmap/roadmap.component';
import { ListCardComponent } from './components/project/project-grid/list-card/list-card.component';
import { TaskManagerFormComponent } from './components/activities/taskManager/task-manager-form/task-manager-form.component';
import { SharedProjectComponent } from './components/project/shared-project/shared-project.component';
import { SharedProjectDetailsComponent } from './components/project/shared-project-details/shared-project-details/shared-project-details.component';
import {SprintBacklogComponent} from './components/Sprint/sprint-backlog/sprint-backlog.component';
import {ScrumBoardComponent} from './components/scrum-board/scrum-board.component';
import {ScrumProcessComponent} from './components/scrum-process/scrum-process.component';
import {CalenderComponent} from './components/calender/calender.component';
import {EditMeetingComponent} from './components/calender/Edit-Meeting/edit-meeting/edit-meeting.component';
import {RoadmapProcessComponent} from './components/roadmap-process/roadmap-process.component';
import {SprintProcessComponent} from './components/sprint-process/sprint-process.component';
import {SprintDetailsComponent} from './components/sprint-details/sprint-details.component';
import {JitsiComponent} from './components/meet/jitsi/jitsi.component';
import {DocumentationComponent} from "./components/documentation/documentation.component";
import {LeanProcessComponent} from './components/lean-process/lean-process.component';
import { LeanBoardComponent } from './components/lean-board/lean-board.component';
import { LeanMenuComponent } from './components/lean-menu/lean-menu.component';
import { CreateProjectComponent } from './components/project/create-project/create-project.component';
import { AgendaDetailsComponent } from './components/agenda/agenda-details/agenda-details.component';
import { CreateMinutesComponent } from './components/agenda/create-minutes/create-minutes.component';
import { MinutesListComponent } from './components/agenda/minutes-list/minutes-list.component';
import { AssignActionItemsComponent } from './components/agenda/assign-action-items/assign-action-items.component';
import { CreateReviewComponent } from './components/sprint-review/create-review/create-review.component';
import { OverviewComponent } from './components/CRISP-DM/overview/overview.component';
import { RedirectingCrispComponent } from './components/CRISP-DM/redirecting-crisp/redirecting-crisp.component';
import { CrispProcessComponent } from './components/CRISP-DM/crisp-process/crisp-process.component';
import { CollectDataComponent } from './components/CRISP-DM/Data-Understandings/collect-data/collect-data.component';
import { CrispDetailsComponent } from './components/CRISP-DM/crisp-details/crisp-details.component';
import { SelectCleanComponent } from './components/CRISP-DM/Data-Preparations/select-clean/select-clean.component';

const routes: Routes = [
  {
    path: 'projects',
    component: ProjectTableComponent,
  },
  
  {
    path: 'project-details/:id',
    component: ProjectDetailsComponent,
  },
  {
    path: 'tasks',
    component: ListCardComponent,
  },
  {
    path: 'meet',
    component: JitsiComponent,
  },
  {
    path: 'sharedProject',
    component: SharedProjectComponent,
  },
  {
    path: 'sharedProjectDetails/:id',
    component: SharedProjectDetailsComponent,
  },
  {
    path: 'sprint-details/:id',
    component: SprintDetailsComponent,
  },
  {
    path: 'tasks/:id',
    component: TaskmanagerComponent,
  },
  {
    path: 'schedule/:id',
    component: ScheduleComponent,
  },
  {
    path: 'project-charter',
    component: ProjectCharterTablleComponent,
  },
  {
    path: 'project-charter-details/:project',
    component: ProjectCharterDetailsComponent,
  },
  {
    path: 'create-project-charter',
    component: AddProjectCharterComponent,
  },
  {
    path: 'process',
    component: ProcessComponent,
  },
  {
    path: 'roadmap',
    component: RoadmapComponent,
  },
  {
    path: 'roadmap-process',
    component: RoadmapProcessComponent,
  },
  {
    path: 'tasks/details/:id',
    component: TaskManagerFormComponent,
  },
  {
    path: 'board/:id',
    component: ScrumBoardComponent,
  },
  {
    path: 'scrum-process',
    component: ScrumProcessComponent,
  },
  {
    path: 'lean-process',
    component: LeanProcessComponent,
  },
  {
    path: 'sprint-process',
    component: SprintProcessComponent,
  },
  {
    path: 'sprint',
    component: SprintBacklogComponent,
  },
  {
    path: 'calendar',
    component: CalenderComponent,
  },
  {
    path: 'EditMeet/:id',
    component: EditMeetingComponent,
  },
  {
  path: 'documentation',
  component: DocumentationComponent,
  },
  {
    path: 'lean-board',
    component: LeanBoardComponent,
  },
  {
    path: 'lean-menu',
    component: LeanMenuComponent,
  },
  {
    path: 'agendas',
    component: AgendaListComponent,
  },
  {
    path: 'create-agenda',
    component: CreateAgendaComponent,
  },
  {
    path: 'create-project',
    component: CreateProjectComponent,
  },
  {
    path: 'minutes',
    component: MinutesListComponent,
  },
  {
    path: 'minutess/:id', 
    component: AssignActionItemsComponent
  },
  {
    path: 'agendas/:id', 
    component: AgendaDetailsComponent 
  },
  {
    path: 'minutes/:id', 
    component: CreateMinutesComponent
  },
  {
    path: 'sprint-review/:id',
    component: CreateReviewComponent
  },
  
  {
    path: 'stepperCrisp',
    component: RedirectingCrispComponent
  },
  {
    path: 'crisp-process',
    component: CrispProcessComponent
  },
  {
    path: 'collectData',
    component: CollectDataComponent
  },
  {
    path: 'crispDetails',
    component: CrispDetailsComponent
  },
  {
    path: 'test',
    component: SelectCleanComponent
  },
  
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectManagementRoutingModule {}
