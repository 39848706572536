<nb-card >
  <nb-card-header>
    <h2>Sprints</h2>
    <nb-select [(ngModel)]="selectedView">
      <nb-option value="active">Active Sprints</nb-option>
      <nb-option value="archived">Archived Sprints</nb-option>
    </nb-select>
  </nb-card-header>
  <nb-card-body>
    <nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
      <nb-card-front>
        <nb-card>
          <angular2-smart-table
            [settings]="settings"
            [source]=" selectedView === 'active' ? activeSprints : archivedSprints"
            (userRowSelect)=" onUserRowSelect($event)"
            class="order-table"
            style="cursor: pointer"
          >
          </angular2-smart-table>
        </nb-card>
      </nb-card-front>
      <nb-card-back *ngIf="flippedState">
        <nb-card>
          <nb-card-header>
            <nb-icon
              icon="chevron-left-outline"
              pack="nebular-essentials"
              aria-hidden="true"
              (click)="flippe()"
            ></nb-icon>
            Go to Sprint List
          </nb-card-header>
          <nb-card-body>
            <ngx-create-review [selectedSprint]="selectedSprint" (dataChanged)="refresh($event)">
              
            </ngx-create-review>
          </nb-card-body>
        </nb-card>
       </nb-card-back>
    </nb-flip-card>
  </nb-card-body>
</nb-card>
<button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button" (click)="toggleTooltip($event)">
  <mat-icon>help_outline</mat-icon>
</button>
<!-- The card with the description (initially hidden) -->
<div class="tooltip-card" [class.show]="showTooltip" style="background: transparent; border: none;">
  <nb-card>
    <nb-card-body>
      <button nbButton (click)="closeTooltip()" class="close-sticker">↩</button>
      <ul>Sprint Review in Scrum methodology: A sprint review is a collaborative meeting that is typically held at the end of every sprint. This is when the team runs through work items they completed during the sprint or iteration.</ul>
      <ul>A sprint review ensures key stakeholders are up to date, and it enables them to provide feedback.</ul>
      <ul>At the end of each sprint review, the product owner or the team lead uses this feedback to create necessary work items in the product backlog.</ul>
    </nb-card-body>
  </nb-card>
</div>
