import { Component, Input, OnInit } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'ngx-pdf-minutes',
  templateUrl: './pdf-minutes.component.html',
  styleUrls: ['./pdf-minutes.component.scss']
})
export class PdfMinutesComponent {
  @Input() meetingMinutess: any;

  constructor() { }

  ngOnInit(): void { }

  buildTableBody(data: any, columns: any) {
    const body = [];

    body.push(columns);

    data.forEach((row: any) => {
      const dataRow = [];
      columns.forEach((column: any) => {
        dataRow.push(row[column] ? row[column].toString() : '');
      });
      body.push(dataRow);
    });

    return body;
  }

  generateTable(data: any, columns: any, widths: any) {
    
    return {
      table: {
        headerRows: 1,
        widths: widths,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  generatePdf(): void {
    const docDefinition = {
      content: [
        { text: `Meeting Minutes of ${this.meetingMinutess.agendaName} at ${this.meetingMinutess.date}`, style: 'header' },
        { text: 'Participants', style: 'subheader' },
        this.generateTable(this.meetingMinutess.participants.map((name: string) => ({ name })), ['name'], ['*']),
        { text: 'Agenda Items and Action Items', style: 'subheader' },
        ...this.meetingMinutess.agendaItems.map((agendaItem: any, index: number) => [
          { text: `${index + 1}. ${agendaItem.title}`, style: 'subsubheader' },
          this.generateTable(
            [{ ...agendaItem, agendaSubItem: Array.isArray(agendaItem.agendaSubItem) ? agendaItem.agendaSubItem.join(', ') : agendaItem.agendaSubItem }],
            ['title', 'agendaSubItem', 'duration', 'presenter'],
            ['*', '*', '*', '*']
          ),
          this.generateTable(
            agendaItem.actionItems || [],
            ['title', 'description', 'responsiblePerson', 'deadline'],
            ['*', '*', '*', '*']
          )
        ]),
        { text: 'Unassigned Action Items', style: 'subheader' },
        this.generateTable(this.meetingMinutess.unassignedActionItems || [], ['title', 'description', 'responsiblePerson', 'deadline'], ['*', '*', '*', '*']),
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          marginBottom: 10
        },
        subheader: {
          fontSize: 15,
          bold: true,
          marginTop: 10,
          marginBottom: 5
        },
        subsubheader: {
          fontSize: 13,
          bold: true,
          marginTop: 5,
          marginBottom: 5
        }
      }
    };
  
    pdfMake.createPdf(docDefinition).open();
  }
}
