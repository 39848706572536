export function accessControlProjectManagement(currentUser, menu) {
  const hasAccess = currentUser.role?.some(role => role.name === 'ROLE_PROJECT_MANAGER'|| role.name === 'ROLE_ADMIN');

  if (!hasAccess) {
    const projectManagementMenu = menu.find(item => item.title === 'Project Management');
    if (projectManagementMenu) {
      const filteredChildren = projectManagementMenu.children.filter(
        item => item.title !== 'Project Charter' && item.title !== 'Projects'
      );
      projectManagementMenu.children = filteredChildren;
    }
  } else if (adminAccess(currentUser)) {
    const projectManagementMenu = menu.find(item => item.title === 'Project Management');
    if (projectManagementMenu) {
      const filteredChildren = projectManagementMenu.children.filter(
        item => item.title !== 'Shared Projects'
      );
      projectManagementMenu.children = filteredChildren;
    }
  }

  return menu;
}

export function superAccess(currentUser) {
  return currentUser.role?.some(role => role.name === 'ROLE_PROJECT_MANAGER'|| role.name === 'ROLE_ADMIN');
}

export function adminAccess(currentUser) {
  return currentUser.role?.some(role => role.name === 'ROLE_ADMIN');
}
