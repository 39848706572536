<div class="text-center mb-3">
  <div>
    <nb-select
      placeholder="Choose Profile"
      fullWidth
      (selectedChange)="selectEmployee($event)"
    >
      <nb-option *ngFor="let profile of profileList" value="{{ profile }}"
        ><nb-icon
          icon="radio-button-on"
          class="my-1 mx-1"
          status="success"
        ></nb-icon>
        <p class="my-1">{{ profile }}</p>
      </nb-option>
    </nb-select>
  </div>
</div>
<div cdkDropListGroup class="row">
  <nb-card class="col-6" style="width: 400px; max-width: 100%">
    <nb-card-header>Employees List</nb-card-header>

    <input
      type="text"
      class="form-control mt-3 mb-3"
      nbInput
      placeholder="Search"
      (keyup)="search($event)"
    />
    <div
      cdkDropList
      [cdkDropListData]="employeeList"
      class="{{ listStatus[0] }} example-list"
      (cdkDropListDropped)="drop($event)"
    >
      <nb-alert
        class="mt-1"
        *ngFor="let item of employeeList"
        cdkDrag
        [cdkDragData]="item"
      >
        <div class="row mt-1">
          <div class="col-md-2">
            <nb-user
              [name]="item.employeeName"
              onlyPicture
              size="large"
              [picture]="item.employeeProfileImage"
            ></nb-user>
          </div>
          <div class="col-ms-9">
            <div class="col-md-12">
              <p class="col-md-12">
                {{ item.employeeName }}
                <nb-tag
                  size="small"
                  appearance="primary"
                  [text]="item.employeeProfile"
                ></nb-tag>
              </p>
              <small class="col-md-12">{{ item.employeeEmail }}</small>
            </div>

            <div class="col-md-12"></div>
          </div>
        </div>
      </nb-alert>
    </div>
  </nb-card>
  <nb-card class="col-6" style="width: 400px; max-width: 100%">
    <nb-card-header
      >Employees assigned
      <nb-tag
        status="success"
        class="mb-3"
        style="float: right"
        appearance="outline"
        text="Team membre :{{ employeeListAssigned.length }}"
      ></nb-tag>
    </nb-card-header>

    <div
      cdkDropList
      [cdkDropListData]="employeeListAssigned"
      class="{{ listStatus[1] }} example-list"
      (cdkDropListDropped)="drop($event)"
    >
      <nb-alert
        class="mt-1"
        *ngFor="let item of employeeListAssigned"
        cdkDrag
        [cdkDragData]="item"
      >
        <div class="row mt-1">
          <div class="col-md-2">
            <nb-user
              [name]="item.employeeName"
              onlyPicture
              size="large"
              [picture]="item.employeeProfileImage"
            ></nb-user>
          </div>
          <div class="col-ms-9">
            <div class="col-md-12">
              <p class="col-md-12">
                {{ item.employeeName }}
                <nb-tag
                  size="small"
                  appearance="primary"
                  [text]="item.employeeProfile"
                ></nb-tag>
              </p>
              <small class="col-md-12">{{ item.employeeEmail }}</small>
            </div>

            <div class="col-md-12"></div>
          </div>
        </div>
      </nb-alert>
    </div>
  </nb-card>
</div>
