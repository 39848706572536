<nb-tabset class="tabset-container" fullWidth>
    <nb-tab tabTitle="Overview" tabIcon="pie-chart-outline" responsive>
  <ngx-overview></ngx-overview>
  </nb-tab>
  <nb-tab tabTitle="Crisp Process" tabIcon="book-outline" responsive>
    <ngx-redirecting-crisp></ngx-redirecting-crisp>
  </nb-tab>
  <nb-tab tabTitle="Documentation" tabIcon="book-outline" responsive>
    <ngx-crisp-documentation></ngx-crisp-documentation>
  </nb-tab>
  </nb-tabset>