<form class="form" [formGroup]="createRiksForm" (ngSubmit)="save()">
    <div class="form-group">
    <label >Title:</label>
    <input  nbInput
    fullWidth class="form-control" type="text" formControlName="name" />
    <div *ngIf="formcontrols.name?.invalid && (formcontrols.name?.dirty || formcontrols.name?.touched)">
      <p class="text-danger" *ngIf="formcontrols.name?.errors?.required">*Required</p>
    </div>
  </div>
  <div class="form-group">
    <label class="text-label">Description:</label>
    <textarea  nbInput
    fullWidth class="form-control" formControlName="description"></textarea>
    <div *ngIf="description?.invalid && (description?.dirty || description?.touched)">
      <p class="text-danger" *ngIf="description?.errors?.required">*Required</p>
    </div>
  </div>
  <input
  [ngClass]="selectedItem===null ?'btn btn-success btnCreate':'btn btn-warning btnCreate'"
  type="submit"
  [value]="selectedItem===null ? 'Create' : 'Update'"
  [disabled]="createRiksForm.invalid"
/>


  </form>
