import { Component, OnInit } from '@angular/core';
import { CalenderService } from '../../../services/calender/calender.service';
import { Agenda } from '../../../models/Agenda';
import { RouterModule, Routes, Router } from '@angular/router';

@Component({
  selector: 'ngx-agenda-list',
  templateUrl: './agenda-list.component.html',
  styleUrls: ['./agenda-list.component.scss']
})
export class AgendaListComponent implements OnInit{
  agendas: Agenda[] = [];

  constructor(private agendaService: CalenderService, private router: Router) {}

  ngOnInit(): void {
    this.agendaService.getAgendas().subscribe(
      data => {
        this.agendas = data;
        console.log('Agendas fetched:', this.agendas);
      },
      error => {
        console.error('Error fetching agendas:', error);
      }
    );
  }
  onCardClick(id: string) {
    this.router.navigate(['projectManagement/agendas',  id]);
  }

}
