<nb-reveal-card [revealed]="flippedState" [showToggleButton]="false">
  <nb-card-front>
    <nb-card accent="basic" style="padding-bottom:5%">
      <nb-card-body>
        <nb-alert accent="{{ statusColor }}">
          <div class="row">
            <h6
              class="text-center"
              style="margin-bottom: 5%"
              class="col-md-6 offset-md-4"
            >
              Agenda General Information:
            </h6>
            <button *ngIf="accessControle"
             nbButton status="success" (click)="onButtonClick(agenda.id)"
             >Create Minutes</button>
            <nb-icon
              icon="create-outline"
              status="basic"
              (click)="flip()"
            ></nb-icon>
          </div>

          <div class="container row">
            <div class="col-md-6">
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="bookmark-outline" status="info"></nb-icon>
                  Agenda Name :
                </strong>
                {{ agenda.agendaName }}
              </p>
              
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="pricetags-outline" status="info"></nb-icon>
                  Location :
                </strong>
                {{ agenda.location }}
              </p>
            </div>
            
             <div class="col-md-6">
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="calendar-outline" status="info"></nb-icon>
                  Date :
                </strong>
                {{ agenda.date | date: 'fullDate' }}
              </p>

              <p>
                <strong class="text-secondary"
                  ><em class="fas fa-clock" style="color: dodgerblue"></em>
                  Time :
                </strong>
                {{ agenda.time }}
              </p>
            </div> 
          </div>
        </nb-alert>
      </nb-card-body>
    </nb-card>
  </nb-card-front>
 
</nb-reveal-card>
<nb-card class="agenda-card" >
  <nb-card-header class="agenda-card-header"
  >
    
    <div>
      <strong>{{ agenda.agendaDescription }}</strong>
    </div>
  </nb-card-header>
  <nb-card-body class="agenda-card-body">
    <div class="container-fluid text-center">
      <div class="row">
        <div class="col">

    <div class="table-container">
      <table class="agenda-table">
        <thead>
          <tr>
            <th>N°</th>
            <th>Agenda Item</th>
            <th>Duration (mins)</th>
            <th>Presenter</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of agenda.agendaItems; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              <div><strong><u>{{ item.title }}</u></strong></div>
              <ul>
                <li>{{ item.agendaSubItem }}</li>
              </ul>
            </td>
            <td>{{ item.duration }}</td>
            <td>{{ item.presenter }}</td>
          </tr>
        </tbody>
        
      </table>
    </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

