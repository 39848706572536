import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogConfig,
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {TokenStorageService} from '../../../modules/auth/service/token/token.service';
import {ConnectionService} from 'ng-connection-service';
import {EmployeeService} from '../../../modules/humain-capital-management/services/employeeServices/employee.service';
import { AuthService } from 'app/modules/auth/service/auth.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Users } from 'app/modules/auth/model/Users';
import {NotificatonComponent} from '../../../modules/project-management/components/notification/notificaton/notificaton.component';
import {Notifications} from '../../../modules/project-management/models/Notifications';
import {NotificationService} from '../../../modules/project-management/services/notification/notification.service';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isConnected = true;
  downloadURL: Observable<string>;
  loading: boolean = false ;
  noInternetConnection: boolean;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  img: any;
  user1: Users;
  userid: String;
  notifications: Notifications[] = []; // Use the correct type here

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private tokenStorageService: TokenStorageService,
              private toastrService: NbToastrService,
              private connectionService: ConnectionService,
              private emplService: EmployeeService,
              private dialogService: NbDialogService,
              private authService: AuthService,
              private storage: AngularFireStorage,
              private notificationService: NotificationService,



  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = Boolean(isConnected);
      if (this.isConnected) {
        this.noInternetConnection = false;
      } else {
        this.noInternetConnection = true;
      }
    });
  }

  ngOnInit() {
    this.getNotifications();


    this.currentTheme = this.themeService.currentTheme;

    this.user = this.tokenStorageService.getUser();
    this.userid = this.user.id;
    this.getUserDetails();


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  getUserDetails(): void {
    this.authService.getUser(this.userid)
      .subscribe((data: Users) => {
        this.user1 = data;
        if (this.user.role !== 'ROLE_SUPER_ADMIN') {
          if (this.user1.userProfileImage && this.user1.userProfileImage !== '') {
            this.img = this.user1.userProfileImage.toString();
          } else {
            this.img = 'assets/images/defaultuser.png';
          }
        } else {
          this.img = 'assets/images/logo/Manajero-logo.png';
        }
      });
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize(1024);

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  showToast(message, position, status) {
    this.toastrService.show(status || 'Success', message, { position, status });
  }





  notificationPopupOpen = false;
  notification: String[] = [
    'update sprint',
    'modify user story from user mohamed',
  ];
  showNotificationPopup() {
    // const dialogConfig: NbDialogConfig = {
    //   context: {
    //     // Contenu contextuel pour le dialogue
    //   },
    //   Width: '500px', // Définir la largeur maximale du dialogue
    // };
    this.dialogService.open(NotificatonComponent);
    // const notificationModalRef = this.modalService.show(NotificatonComponent, {
    //   animated: true,
    //   backdrop: 'static', // Clicking outside the modal will not close it
    //   keyboard: false, // Esc key will not close the modal
    // });

    // You can pass data to the notification component if needed
    // notificationModalRef.content.content.header = 'Nouvelle notification';
    // notificationModalRef.content.content =
    //   'Vous avez une nouvelle notification importante.';
  }

  closeNotificationPopup() {
    this.notificationPopupOpen = false;
  }
  getNotifications() {
    this.notificationService.getAllNotif().subscribe(
      (data) => {
        this.notifications = data;
      },
      (error) => {
        console.error('Une erreur s\'est produite lors de la récupération des notifications : ', error);
      },
    );
  }
}

