import {Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FormControl} from '@angular/forms';
import {Project} from '../../../../../models/Project';
import {ProjectService} from '../../../../../services/project/project.service';
import {ActivatedRoute} from '@angular/router';
import {TeamService} from '../../../../../services/team/team.service';
import { NbTagComponent, NbTagInputDirective } from '@nebular/theme';
import {Task} from '../../../utils/models/task';
import {ScrumBoadService} from '../../../utils/services/scrum-boad.service';
import {SprintService} from '../../../../../services/sprint/sprint.service';
import {Sprint} from '../../../../../models/Sprint';

@Component({
  selector: 'ngx-task-assignees',
  templateUrl: './task-assignees.component.html',
  styleUrls: ['./task-assignees.component.scss'],
})
export class TaskAssigneesComponent implements OnInit {
  @Input() task: Task;
  options: string[] = [];
  filteredControlOptions$: Observable<string[]>;
  inputFormControl: FormControl;
  project: Project;
  membersList: Set<string> = new Set<string>();
  projectId: string;
  selectedSprintId: string;
  sprint: Sprint = new Sprint();
  @ViewChild(NbTagInputDirective, { read: ElementRef }) tagInput: ElementRef<HTMLInputElement>;
  constructor(private projectService: ProjectService,
              private route: ActivatedRoute,
              private teamService: TeamService,
              private boardApi: ScrumBoadService,
              private sprintService: SprintService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selectedSprintId = params['id'];
      this.sprintService.findProjectById(this.selectedSprintId).subscribe({
        next: (result) => {
          this.sprint = result;
          this.projectId = this.sprint.project;
          // get project related to the task
          this.task.assignees?.map((assingee: string) => {
            this.membersList.add(assingee);
          });
          this.project = new Project();
          this.findProjectById();
        },
        error: (err: any) => {
          console.log(err);
        },
      });
    });

  }
    findProjectById() {
      this.projectService.findProjectById(this.projectId).subscribe({
        next: (value: Project) => {
          this.project = value;
          this.options = [...this.project?.memberList];
          this.options = this.options.filter(item => !this.task?.assignees?.includes(item));
          this.filteredControlOptions$ = of(this.options);
          this.inputFormControl = new FormControl();

        },
        error: (err) => {
          console.log(err);
        },
      });
    }
    updateProject(project: Project) {
      this.projectService.updateProject(project).subscribe({
        next: (value) => {
          this.project = value;
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
    addToTeam() {
      this.membersList.add(this.inputFormControl.value);
      this.task.assignees = [...this.membersList];
      this.boardApi.updateTask(this.task).subscribe(res => {
        this.task = res;
        this.options = this.options.filter(value => value !== this.inputFormControl.value);
        this.filteredControlOptions$ = of(this.options);
        this.inputFormControl.reset();
      });
      // this.updateProject(this.project);
    }
    removeMember(member: string) {
    this.membersList.delete(member);
    this.task.assignees = [...this.membersList];
      this.boardApi.updateTask(this.task).subscribe(res => {
        this.task = res;
        this.options.push(member);
        this.filteredControlOptions$ = of(this.options);
      });
    }

}
