<mat-toolbar  class="justify-content-between px-5 mb-4 toolbar">
    <!-- <div>
      <button nbButton (click)="navigateToProjectDetails()" status="primary">Back</button>
    </div> -->
    <div class="w-100 d-flex justify-content-center" style="padding: 3%">
      <span class="mat-h2" style="font-size : 22px">{{project.leanMethod}} PROJECT BOARD</span>
    </div>
    <div>
      <!-- <ngx-dialog (emitText)="addColumn($event)" question="Add new list">
        <button nbButton status="primary">
          Add New List
        </button>
      </ngx-dialog> -->
    </div>
  </mat-toolbar>
