<nb-card>
  <nb-card-body>
    <nb-stepper orientation="horizontal"  disableStepNavigation>
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Activity Stages</ng-template>
        <h4>Activities Stages</h4>
        <ngx-activities-status (dataChanged)="refresh($event)"></ngx-activities-status>
        <div class="mt-3">
          <button nbButton nbStepperPrevious style="float: left">prev</button>

          <button nbButton nbStepperNext [disabled]="!enbale" (click)="enableActivityForm()" style="float: right">next</button>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Activity</ng-template>
        <h4>Add Activity</h4>
        <ngx-activities-form [statusActivity]="initialStatus" *ngIf="enbaleActivityForm"></ngx-activities-form>
        

      
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>