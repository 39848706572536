<nb-card accent="info">
    <nb-card-header>
      <div class="row">
        <div class="col-md-11">Project budget (overview)</div>
        
      </div>
    </nb-card-header>
    <nb-card-body>
      <angular2-smart-table
        class="order-table"
        [settings]="settings"
        [source]="budgetList"
    
      >
      </angular2-smart-table>
      </nb-card-body>
      </nb-card>