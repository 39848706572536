import { trigger as animTrigger, state as animState, style as animStyle, transition, animate as aniMate } from '@angular/animations';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { DataDescription } from 'app/modules/project-management/models/DataDescription';
import { Project } from 'app/modules/project-management/models/Project';
import { CrispService } from 'app/modules/project-management/services/crisp.service';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';

@Component({
  selector: 'ngx-describe-data',
  templateUrl: './describe-data.component.html',
  styleUrls: ['./describe-data.component.scss'],
  animations: [
    animTrigger('fadeInOut', [
      animState('void', animStyle({ opacity: 0 })),
      transition(':enter, :leave', [
        aniMate(500)
      ]),
    ]),
  ],
})
export class DescribeDataComponent {
  selectedFileState: string = 'void';

  dataInfo: any;
  selectedFileId: string;
  selectedFile: any;
  files: any[] = [];
  dataDescription: any;
  projectId: string;
  project: Project;
  file: File | null = null;
  dataDescriptions: DataDescription[];

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private toastrService: NbToastrService,
    private crispService: CrispService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.findProjectById();
        this.crispService.getFiles(this.projectId).subscribe(data => {
          this.files = data;
          console.log(this.files);
        });
      }
    });
  }

  onFileSelected(fileId: string): void {
    this.selectedFileId = fileId;
    console.log(this.selectedFileId);
    this.crispService.getFile(fileId).subscribe(data => {
      this.selectedFile = data;
      this.selectedFileState = 'visible'; // Trigger the animation
      this.fetchDataInfoAndDescription();
    });
  }

  fetchDataInfoAndDescription(): void {
    if (this.projectId && this.selectedFileId) {
      this.crispService.fetchDataInfo(this.projectId, this.selectedFileId).subscribe(dataInfo => {
        this.dataInfo = dataInfo;
        this.crispService.setDataInfo(dataInfo);
      });

      this.crispService.fetchDataDescription(this.projectId, this.selectedFileId).subscribe(dataDescription => {
        this.dataDescription = dataDescription;
        this.crispService.setDataDescription(dataDescription);
      });
    }
  }

  processSelectedFile(): void {
    if (this.selectedFileId) {
      this.crispService.processFile(this.selectedFileId).subscribe(response => {
        console.log(response);
        // Fetch updated data info and data description after processing the file
        this.fetchDataInfoAndDescription();
      }, error => {
        console.error("Error processing file", error);
      });
    }
  }

  findProjectById() {
    this.projectService.findProjectById(this.projectId).subscribe({
      next: (value: Project) => {
        this.project = value;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}


