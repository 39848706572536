<nb-accordion style="margin-bottom:5%">
  <nb-accordion-item>
    <nb-accordion-item-header><nb-icon icon="bar-chart-2-outline" status="info" style="margin-right: 1%;"></nb-icon>SPRINTS PROCESS</nb-accordion-item-header>
    <nb-accordion-item-body>
      <nb-stepper orientation="horizontal"  [selectedIndex]="1">

        <nb-step [label]="Initiating" [completed]="currentState==='Initiating' ? true : false" >
          <ng-template #Initiating>Initiating</ng-template>
        </nb-step>

        <nb-step [label]="PlanningEXECUTING" [completed]="currentState==='PlanningEXECUTING' ? true : false" >
          <ng-template #PlanningEXECUTING>Planning/EXECUTING </ng-template>
        </nb-step>

        <nb-step [label]="MONITORINGCONTROLLING" [completed]="currentState==='MONITORINGCONTROLLING' ? true : false">
          <ng-template #MONITORINGCONTROLLING>MONITORING & CONTROLLING</ng-template>
        </nb-step>

        <nb-step [label]="CLOSING"[completed]="currentState==='CLOSING' ? true : false">
          <ng-template #CLOSING> CLOSING</ng-template>
        </nb-step>
      </nb-stepper>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
