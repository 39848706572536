
<div *ngIf="goalList!==null;else noData">


<ngx-help-action-plan></ngx-help-action-plan>


<nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
  <nb-card-front>
    <nb-card accent="col">
      <angular2-smart-table
        class="order-table"
        [settings]="settings"
        [source]="goalList"
        (delete)="confirmation($event, dialog)"
        (create)="onCreate()"
        (userRowSelect)="onUserRowSelect($event)"
      >
      </angular2-smart-table>
      <!--Delete Confirm -->
      <ng-template #dialog let-data let-ref="dialogRef">
        <nb-card>
          <nb-card-header>Confirm Delete Operation</nb-card-header>
          <nb-card-body class="alert alert-warning">
            Are you sure you want to delete this item ?
          </nb-card-body>
          <nb-card-footer>
            <div class="row">
              <div class="col-md-2">
                <button nbButton status="basic" (click)="ref.close()">
                  <nb-icon icon="close"></nb-icon>Close
                </button>
              </div>
              <div class="col-md-5"></div>
              <div class="col-md-2">
                <button
                  nbButton
                  status="danger"
                  (click)="onDeleteConfirm(selectedItem)"
                >
                  <nb-icon icon="trash"></nb-icon>Delete
                </button>
              </div>
            </div>
          </nb-card-footer>
        </nb-card>
      </ng-template>
      <!--end Delete Confirm-->
    </nb-card>
  </nb-card-front>
  <nb-card-back>
    <nb-card>
      <nb-card-header>
        <nb-icon
          icon="chevron-left-outline"
          pack="nebular-essentials"
          aria-hidden="true"
          (click)="flipBack()"
        ></nb-icon>
        Back
      </nb-card-header>
      <nb-card-body>
        <ngx-action-plan
          *ngIf="enableActionPlanProcess"
          (dataChanged)="refresh($event)"
        ></ngx-action-plan>
        <ngx-goals-details [selectedItem]="goalSelected" *ngIf="enableActionPlanDetails" [disabled]="disabled"></ngx-goals-details>
      </nb-card-body>
    </nb-card>
  </nb-card-back>
</nb-flip-card>
</div>
<ng-template #noData>
  <nb-alert outline="primary" class="text-center">
    Create your action plan
  </nb-alert>
  <ngx-action-plan
  (dataChanged)="refresh($event)"
></ngx-action-plan>
</ng-template>
