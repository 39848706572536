import {NgModule,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { NgxFileDropModule } from 'ngx-file-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProjectManagementRoutingModule} from './project-management-routing.module';
import { NbTableModule } from '@nebular/theme';
import { Angular2SmartTableModule } from 'angular2-smart-table';


import {MatExpansionModule} from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';

import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbProgressBarModule,
  NbSelectModule,
  NbStepperModule,
  NbTabsetModule,
  NbWindowModule,
  NbSpinnerModule,
  NbBadgeModule,
  NbLayoutModule,
  NbTagModule,
  NbAccordionModule,
  NbContextMenuModule,
  NbActionsModule,
  NbTreeGridModule,
  NbAutocompleteModule,
  NbThemeModule,
  NbUserModule, NbTooltipModule, NbRadioModule, NbTimepickerModule, NbListModule,
  NbCheckboxModule, NbFormFieldModule, NbOptionModule,
  
} from '@nebular/theme';
//import {Ng2SmartTableModule} from 'angular2-smart-table';
import {NbEvaIconsModule} from '@nebular/eva-icons';

import {ScheduleComponent} from './components/activities/schedule/schedule/schedule.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FullCalendarModule} from '@fullcalendar/angular';
import {TaskmanagerComponent} from './components/activities/taskManager/taskmanager/taskmanager.component';
import {ActivityTableComponent} from './components/activities/activitiesTable/activity-table/activity-table.component';
import {
  ProjectCharterTablleComponent,
} from './components/project-charter/project-charter-tablle/project-charter-tablle.component';
import {
  ProjectCharterDetailsComponent,
} from './components/project-charter/project-charter-details/project-charter-details.component';
import {
  AddProjectCharterComponent,
} from './components/project-charter/add-project-charter/add-project-charter.component';
import {
  ProjectCharterGeneralInfoComponent,
} from './components/project-charter/project-charter-general-info/project-charter-general-info.component';
import {ProblematicComponent} from './components/project-charter/problematic/problematic/problematic.component';
import {
  ProjectProductDescriptionComponent,
} from './components/project-charter/project-product-description/project-product-description/project-product-description.component';
import {
  DeliveryUnitsTableComponent,
} from './components/project-charter/delivery-units/delivery-units-table/delivery-units-table.component';
import {
  AddDeliveryUnitComponent,
} from './components/project-charter/delivery-units/add-delivery-unit/add-delivery-unit.component';
import {
  SuccessCriteriaTableComponent,
} from './components/project-charter/success-criteria/success-criteria-table/success-criteria-table.component';
import {
  AddSuccessCriteriaComponent,
} from './components/project-charter/success-criteria/add-success-criteria/add-success-criteria.component';
import {
  AddProblematicComponent,
} from './components/project-charter/problematic/add-problematic/add-problematic.component';
import {GoalTableComponent} from './components/project-charter/goal/goal/goal-table/goal-table.component';
import {ProjectGoalComponent} from './components/project-charter/goal/goal/project-goal/project-goal.component';
import {
  CreateUpdateProjectGoalComponent,
} from './components/project-charter/goal/goal/create-update-project-goal/create-update-project-goal.component';
import {
  MilestoneTableComponent,
} from './components/project-charter/goal/milestone/milestone-table/milestone-table.component';
import {
  CreateUpdateMilestoneComponent,
} from './components/project-charter/goal/milestone/create-update-milestone/create-update-milestone.component';
import {
  AddProjectProductDescriptionComponent,
} from './components/project-charter/project-product-description/add-project-product-description/add-project-product-description.component';
import {
  HighLevelRiskTableComponent,
} from './components/project-charter/high-level-risk/high-level-risk-table/high-level-risk-table.component';
import {
  AddLevelRiskComponent,
} from './components/project-charter/high-level-risk/add-level-risk/add-level-risk.component';
import {
  KeyStakeholdersTableComponent,
} from './components/project-charter/Key-stakeholders/key-stakeholders-table/key-stakeholders-table.component';
import {
  AddKeyStakeholderComponent,
} from './components/project-charter/Key-stakeholders/add-key-stakeholder/add-key-stakeholder.component';
import {AddAreComponent} from './components/project-charter/Are/add-are/add-are.component';
import {AreTableComponent} from './components/project-charter/Are/are-table/are-table.component';
import {
  AddresponsibilityCustomerComponent,
} from './components/project-charter/responsibilityCustomer/addresponsibility-customer/addresponsibility-customer.component';
import {
  ResponsibilityCustomerTableComponent,
} from './components/project-charter/responsibilityCustomer/responsibility-customer-table/responsibility-customer-table.component';
import {AddBudgetComponent} from './components/project-charter/budget/add-budget/add-budget.component';
import {BudgetTableComponent} from './components/project-charter/budget/budget-table/budget-table.component';
import {
  StatisticalMoreInfoComponent,
} from './components/project-charter/statistical-more-info/statistical-more-info/statistical-more-info.component';
import {
  GenraleInfoFormComponent,
} from './components/project-charter/project-charter-general-info/genrale-info-form/genrale-info-form.component';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireDatabaseModule} from '@angular/fire//compat/database';

import { environment } from '../../../environments/environment';
import { AddChangeRequestComponent } from './components/changeRequest/add-change-request/add-change-request.component';
import { ChangeRequestTableComponent } from './components/changeRequest/change-request-table/change-request-table.component';
import { UploadFileComponent } from './components/project-charter/upload-file/upload-file.component';
import { StatisticComponent } from './components/project-charter/statistic/statistic.component';
import { MatMenuModule } from '@angular/material/menu';
import { EditGoalComponent } from './components/project-charter/goal/goal/edit-goal/edit-goal.component';
import { ExportPdfComponent } from './components/project-charter/export-pdf/export-pdf/export-pdf.component';
import { CreateProjectComponent } from './components/project/create-project/create-project.component';
import { ProjectTableComponent } from './components/project/project-table/project-table.component';
import { ProjectDetailsComponent } from './components/project/project-details/project-details.component';
import { ProjectGeneraleInfoComponent } from './components/project/generale-info/project-generale-info/project-generale-info.component';
import { RequirementTableComponent } from './components/project/requirement/requirement-table/requirement-table.component';
import { AddRequirementComponent } from './components/project/requirement/add-requirement/add-requirement.component';
import { ProcessComponent } from './components/process/process.component';
import { RiskTableComponent } from './components/project/risk/risk-table/risk-table.component';
import { RiskFormComponent } from './components/project/risk/risk-form/risk-form.component';
import { StakeholderTableComponent } from './components/project/stakholder/stakeholder-table/stakeholder-table.component';
import { StakeholderFormComponent } from './components/project/stakholder/stakeholder-form/stakeholder-form.component';
import { SuccessTableComponent } from './components/project/success-criteria/success-table/success-table.component';
import { SuccessFormComponent } from './components/project/success-criteria/success-form/success-form.component';
import { BudgetFormComponent } from './components/project/budget/budget-form/budget-form.component';
import { ProjectBudgetTableComponent } from './components/project/budget/project-budget-table/project-budget-table.component';
import { ProcessStepsComponent } from './components/process-steps/process-steps.component';
import { AssumptionTableComponent } from './components/project/assumption/assumption-table/assumption-table.component';
import { AssumptionFormComponent } from './components/project/assumption/assumption-form/assumption-form.component';
import { GoalsTableComponent } from './components/project/goals/goals-table/goals-table.component';
import { GoalFormComponent } from './components/project/goals/goal-form/goal-form.component';
import { ActionPlanComponent } from './components/project/action-plan/action-plan.component';
import { GeneraleInfoFormComponent } from './components/project/generale-info/generale-info-form/generale-info-form.component';
import { DetailEditChangeRequestComponent } from './components/changeRequest/detail-edit-change-request/detail-edit-change-request.component';
import { ActivitiesTableComponent } from './components/project/activities/activities-table/activities-table.component';
import { ActivitiesFormComponent } from './components/project/activities/activities-form/activities-form.component';
import { ResourceTableComponent } from './components/project/resource/resource-table/resource-table.component';
import { ResourceFormComponent } from './components/project/resource/resource-form/resource-form.component';
import { TeamTableComponent } from './components/project/team/team-table/team-table.component';
import { TeamFormComponent } from './components/project/team/team-form/team-form.component';
import { HelpComponent } from './components/project/risk/help/help.component';
import { HelpActionPlanComponent } from './components/project/action-plan/help-action-plan/help-action-plan.component';
import { ListItemAddedStepComponent } from './components/project/action-plan/list-item-added-step/list-item-added-step.component';
import { ActivitiesStatusComponent } from './components/project/activities/activities-status/activities-status.component';
import { ActivitiesStepperComponent } from './components/project/activities/activities-stepper/activities-stepper.component';
import { GoalsDetailsComponent } from './components/project/goals/goals-details/goals-details.component';
import { AtivityResourceComponent } from './components/project/activities/ativity-resource/ativity-resource.component';
import { AtivityTeamComponent } from './components/project/activities/ativity-team/ativity-team.component';
import { ResourceRequestComponent } from './components/project/resource/resource-request/resource-request.component';
import { CardComponent } from './components/project/project-grid/card/card.component';
import { ListCardComponent } from './components/project/project-grid/list-card/list-card.component';
import { AssignEmployeeComponent } from './components/project/activities/assign-employee/assign-employee.component';
import { TaskModalComponent } from './components/activities/taskManager/task-modal/task-modal.component';
import { TaskManagerFormComponent } from './components/activities/taskManager/task-manager-form/task-manager-form.component';
import { TaskDetailsComponent } from './components/activities/schedule/task-details/task-details.component';
import { ResourceRequestTableComponent } from './components/project/resource/resource-request-table/resource-request-table.component';
import { BudgetListComponent } from './components/project/budget/budget-list/budget-list.component';
import { BudgetEstimatedComponent } from './components/project/budget/budget-estimated/budget-estimated.component';
import { BudgetResourceComponent } from './components/project/budget/budget-resource/budget-resource.component';
import { BudgetAdditionelComponent } from './components/project/budget/budget-additionel/budget-additionel.component';
import { BudgetEstimatedTableComponent } from './components/project/budget/budget-estimated-table/budget-estimated-table.component';
import { BudgetResourceTableComponent } from './components/project/budget/budget-resource-table/budget-resource-table.component';
import { MemberComponent } from './components/project/members/member/member.component';
import { MemberCardComponent } from './components/project/members/member-card/member-card.component';
import { SharedProjectComponent } from './components/project/shared-project/shared-project.component';
import { SharedProjectDetailsComponent } from './components/project/shared-project-details/shared-project-details/shared-project-details.component';

import {RoadmapComponent} from './components/roadmap/roadmap.component';
import {SprintBacklogComponent} from './components/Sprint/sprint-backlog/sprint-backlog.component';
import {SprintFormDialogComponent} from './components/Sprint/sprint-form-dialog/sprint-form-dialog.component';
import {ScrumBoardComponent} from './components/scrum-board/scrum-board.component';
import {BoardModule} from './components/scrum-board/board/board.module';
import {DialogModule} from './components/scrum-board/utils/dialog/dialog.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ScrumProcessComponent } from './components/scrum-process/scrum-process.component';
import { SprintProcessComponent } from './components/sprint-process/sprint-process.component';
import { CalenderComponent } from './components/calender/calender.component';
import {CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule} from 'angular-calendar';
import { CreateMeetComponent } from './components/calender/create-meet/create-meet.component';
import { EditMeetingComponent } from './components/calender/Edit-Meeting/edit-meeting/edit-meeting.component';
import {RoadmapProcessComponent} from './components/roadmap-process/roadmap-process.component';
import { NotificatonComponent } from './components/notification/notificaton/notificaton.component';
import { RestrospectivesComponent } from './components/restrospectives/restrospectives.component';
import { SprintDetailsComponent } from './components/sprint-details/sprint-details.component';
import { JitsiComponent } from './components/meet/jitsi/jitsi.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { CreateMultipleMeetComponent } from './components/calender/create-multiple-meet/create-multiple-meet.component';
import { LeanProcessComponent } from './components/lean-process/lean-process.component';
import { LeanBoardComponent } from './components/lean-board/lean-board.component';
import { LeanBoardHeaderComponent } from './components/lean-board/lean-board-header/lean-board-header.component';
import { LeanBoardBodyComponent } from './components/lean-board/lean-board-body/lean-board-body.component';
import { LeanBoardItemComponent } from './components/lean-board/lean-board-item/lean-board-item.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill';
import { LeanItemAssigneeComponent } from './components/lean-board/lean-board-item/lean-item-assignee/lean-item-assignee.component';
import { LeanItemChecklistComponent } from './components/lean-board/lean-board-item/lean-item-checklist/lean-item-checklist.component';
import { LeanMenuComponent } from './components/lean-menu/lean-menu.component';
import { LeanDocumentationComponent } from './components/lean-documentation/lean-documentation.component';
import { CreateAgendaComponent } from './components/agenda/create-agenda/create-agenda.component';
import { AgendaListComponent } from './components/agenda/agenda-list/agenda-list.component';
import { AgendaDetailsComponent } from './components/agenda/agenda-details/agenda-details.component';
import { CreateMinutesComponent } from './components/agenda/create-minutes/create-minutes.component';
import { MinutesListComponent } from './components/agenda/minutes-list/minutes-list.component';
import { PdfAgendaComponent } from './components/agenda/pdf-agenda/pdf-agenda.component';
import { PdfMinutesComponent } from './components/agenda/pdf-minutes/pdf-minutes.component';
import { AssignActionItemsComponent } from './components/agenda/assign-action-items/assign-action-items.component';
import { CustomselectComponent } from './components/agenda/customselect/customselect.component';
import { RedirectingtableComponent } from './components/sprint-review/redirectingtable/redirectingtable.component';
import { ReviewComponent } from './components/sprint-review/review/review.component';
import { CreateReviewComponent } from './components/sprint-review/create-review/create-review.component';
import { RedirectingtableRetroComponent } from './components/restrospectives/redirectingtable-retro/redirectingtable-retro.component';
import { SprintRetroComponent } from './components/restrospectives/sprint-retro/sprint-retro.component';
import { RetrospectiveUserComponent } from './components/restrospectives/retrospective-user/retrospective-user.component';
import { RedirectingtablePlanningComponent } from './components/sprint-planning/redirectingtable-planning/redirectingtable-planning.component';
import { SprintPlanningComponent } from './components/sprint-planning/sprint-planning/sprint-planning.component';
import { PlanningPokerComponent } from './components/sprint-planning/planning-poker/planning-poker.component';
import { OverviewComponent } from './components/CRISP-DM/overview/overview.component';
import { RedirectingCrispComponent } from './components/CRISP-DM/redirecting-crisp/redirecting-crisp.component';
import { BusinessUnderstandingComponent } from './components/CRISP-DM/Business-Understandings/business-understanding/business-understanding.component';
import { AssessingSituationComponent } from './components/CRISP-DM/Business-Understandings/assessing-situation/assessing-situation.component';
import { DMGoalsComponent } from './components/CRISP-DM/Business-Understandings/dm-goals/dm-goals.component';
import { DMProjectPlanComponent } from './components/CRISP-DM/Business-Understandings/dm-project-plan/dm-project-plan.component';
import { DataUnderstandingComponent } from './components/CRISP-DM/Data-Understandings/data-understanding/data-understanding.component';
import { CollectDataComponent } from './components/CRISP-DM/Data-Understandings/collect-data/collect-data.component';
import { DescribeDataComponent } from './components/CRISP-DM/Data-Understandings/describe-data/describe-data.component';
import { ExploreDataComponent } from './components/CRISP-DM/Data-Understandings/explore-data/explore-data.component';
import { VerifyDataQualityComponent } from './components/CRISP-DM/Data-Understandings/verify-data-quality/verify-data-quality.component';
import { CrispProcessComponent } from './components/CRISP-DM/crisp-process/crisp-process.component';
import { CrispDocumentationComponent } from './components/CRISP-DM/crisp-documentation/crisp-documentation.component';
import { CrispDetailsComponent } from './components/CRISP-DM/crisp-details/crisp-details.component';
import { SelectCleanComponent } from './components/CRISP-DM/Data-Preparations/select-clean/select-clean.component';

// import { NotificationComponent } from './models/notification/notification.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ScheduleComponent,
        CreateAgendaComponent,
        AgendaListComponent,
        TaskmanagerComponent,
        ActivityTableComponent,
        ProjectCharterTablleComponent,
        ProjectCharterDetailsComponent,
        AddProjectCharterComponent,
        ProjectCharterGeneralInfoComponent,
        ProblematicComponent,
        ProjectProductDescriptionComponent,
        DeliveryUnitsTableComponent,
        AddDeliveryUnitComponent,
        SuccessCriteriaTableComponent,
        AddSuccessCriteriaComponent,
        AddProblematicComponent,
        GoalTableComponent,
        ProjectGoalComponent,
        CreateUpdateProjectGoalComponent,
        MilestoneTableComponent,
        CreateUpdateMilestoneComponent,
        AddProjectProductDescriptionComponent,
        HighLevelRiskTableComponent,
        AddLevelRiskComponent,
        KeyStakeholdersTableComponent,
        AddKeyStakeholderComponent,
        AddAreComponent,
        AreTableComponent,
        AddresponsibilityCustomerComponent,
        ResponsibilityCustomerTableComponent,
        AddBudgetComponent,
        BudgetTableComponent,
        StatisticalMoreInfoComponent,
        GenraleInfoFormComponent,
        AddChangeRequestComponent,
        ChangeRequestTableComponent,
        UploadFileComponent,
        StatisticComponent,
        EditGoalComponent,
        ExportPdfComponent,
        CreateProjectComponent,
        ProjectTableComponent,
        ProjectDetailsComponent,
        ProjectGeneraleInfoComponent,
        RequirementTableComponent,
        AddRequirementComponent,
        ProcessComponent,
        RiskTableComponent,
        RiskFormComponent,
        StakeholderTableComponent,
        StakeholderFormComponent,
        SuccessTableComponent,
        SuccessFormComponent,
        BudgetFormComponent,
        ProjectBudgetTableComponent,
        ProcessStepsComponent,
        AssumptionTableComponent,
        AssumptionFormComponent,
        GoalsTableComponent,
        GoalFormComponent,
        ActionPlanComponent,
        GeneraleInfoFormComponent,
        DetailEditChangeRequestComponent,
        ActivitiesTableComponent,
        ActivitiesFormComponent,
        ResourceTableComponent,
        ResourceFormComponent,
        TeamTableComponent,
        TeamFormComponent,
        HelpComponent,
        HelpActionPlanComponent,
        ListItemAddedStepComponent,
        ActivitiesStatusComponent,
        ActivitiesStepperComponent,
        GoalsDetailsComponent,
        AtivityResourceComponent,
        AtivityTeamComponent,
        ResourceRequestComponent,
        CardComponent,
        ListCardComponent,
        AssignEmployeeComponent,
        TaskModalComponent,
        TaskManagerFormComponent,
        TaskDetailsComponent,
        ResourceRequestTableComponent,
        BudgetListComponent,
        BudgetEstimatedComponent,
        BudgetResourceComponent,
        BudgetAdditionelComponent,
        BudgetEstimatedTableComponent,
        BudgetResourceTableComponent,
        MemberComponent,
        MemberCardComponent,
        SharedProjectComponent,
        SharedProjectDetailsComponent,
        RoadmapComponent,
        RoadmapProcessComponent,
        ScrumBoardComponent,
        ScrumProcessComponent,
        SprintBacklogComponent,
        SprintFormDialogComponent,
        SprintProcessComponent,
        CalenderComponent,
        CreateMeetComponent,
        EditMeetingComponent,
        NotificatonComponent,
        RestrospectivesComponent,
        SprintDetailsComponent,
        JitsiComponent,
        DashboardComponent,
        DocumentationComponent,
        CreateMultipleMeetComponent,
        LeanProcessComponent,
        LeanBoardComponent,
        LeanBoardHeaderComponent,
        LeanBoardBodyComponent,
        LeanBoardItemComponent,
        LeanItemAssigneeComponent,
        LeanItemChecklistComponent,
        LeanMenuComponent,
        LeanDocumentationComponent,
        AgendaDetailsComponent,
        CreateMinutesComponent,
        MinutesListComponent,
        AssignActionItemsComponent,
        PdfAgendaComponent,
        PdfMinutesComponent,
        CustomselectComponent,
        RedirectingtableComponent,
        ReviewComponent,
        CreateReviewComponent,
        RedirectingtableRetroComponent,
        SprintRetroComponent,
        RetrospectiveUserComponent,
        RedirectingtablePlanningComponent,
        SprintPlanningComponent,
        PlanningPokerComponent,
        OverviewComponent,
        RedirectingCrispComponent,
        BusinessUnderstandingComponent,
        AssessingSituationComponent,
        DMGoalsComponent,
        DMProjectPlanComponent,
        DataUnderstandingComponent,
        CollectDataComponent,
        DescribeDataComponent,
        ExploreDataComponent,
        VerifyDataQualityComponent,
        CrispProcessComponent,
        CrispDocumentationComponent,
        CrispDetailsComponent,
        SelectCleanComponent,
    ],
  imports: [
    CommonModule,
    NbTableModule,
    ProjectManagementRoutingModule,
    NbButtonModule,
    NbCardModule,
    MatMenuModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Angular2SmartTableModule,
    //Ng2SmartTableModule,
    NbEvaIconsModule,
    NbTabsetModule,
    DragDropModule,
    FullCalendarModule,
    NbAlertModule,
    NbProgressBarModule,
    NbDatepickerModule,
    NbWindowModule,
    NbStepperModule,
    NbSelectModule,
    NbSpinnerModule,
    NbBadgeModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbTagModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NbAccordionModule,
    NbContextMenuModule,
    NbActionsModule,
    NbTreeGridModule,
    NbAutocompleteModule,
    // BeautifulChartsModule
    NbUserModule,
    BoardModule,
    DialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    CalendarCommonModule,
    CalendarDayModule,
    CalendarMonthModule,
    CalendarWeekModule,
    NbTooltipModule,
    NbRadioModule,
    NbTimepickerModule,
    NbListModule,
    NgbDropdownModule,
    QuillModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbOptionModule,
    MatTableModule,
    NgxFileDropModule,
  ],
    exports: [
      ScheduleComponent,
        CreateAgendaComponent,
        AgendaListComponent,
        TaskmanagerComponent,
        ActivityTableComponent,
        ProjectCharterTablleComponent,
        ProjectCharterDetailsComponent,
        AddProjectCharterComponent,
        ProjectCharterGeneralInfoComponent,
        ProblematicComponent,
        ProjectProductDescriptionComponent,
        DeliveryUnitsTableComponent,
        AddDeliveryUnitComponent,
        SuccessCriteriaTableComponent,
        AddSuccessCriteriaComponent,
        AddProblematicComponent,
        GoalTableComponent,
        ProjectGoalComponent,
        CreateUpdateProjectGoalComponent,
        MilestoneTableComponent,
        CreateUpdateMilestoneComponent,
        AddProjectProductDescriptionComponent,
        HighLevelRiskTableComponent,
        AddLevelRiskComponent,
        KeyStakeholdersTableComponent,
        AddKeyStakeholderComponent,
        AddAreComponent,
        AreTableComponent,
        AddresponsibilityCustomerComponent,
        ResponsibilityCustomerTableComponent,
        AddBudgetComponent,
        BudgetTableComponent,
        StatisticalMoreInfoComponent,
        GenraleInfoFormComponent,
        AddChangeRequestComponent,
        ChangeRequestTableComponent,
        UploadFileComponent,
        StatisticComponent,
        EditGoalComponent,
        ExportPdfComponent,
        CreateProjectComponent,
        ProjectTableComponent,
        ProjectDetailsComponent,
        ProjectGeneraleInfoComponent,
        RequirementTableComponent,
        AddRequirementComponent,
        ProcessComponent,
        RiskTableComponent,
        RiskFormComponent,
        StakeholderTableComponent,
        StakeholderFormComponent,
        SuccessTableComponent,
        SuccessFormComponent,
        BudgetFormComponent,
        ProjectBudgetTableComponent,
        ProcessStepsComponent,
        AssumptionTableComponent,
        AssumptionFormComponent,
        GoalsTableComponent,
        GoalFormComponent,
        ActionPlanComponent,
        GeneraleInfoFormComponent,
        DetailEditChangeRequestComponent,
        ActivitiesTableComponent,
        ActivitiesFormComponent,
        ResourceTableComponent,
        ResourceFormComponent,
        TeamTableComponent,
        TeamFormComponent,
        HelpComponent,
        HelpActionPlanComponent,
        ListItemAddedStepComponent,
        ActivitiesStatusComponent,
        ActivitiesStepperComponent,
        GoalsDetailsComponent,
        AtivityResourceComponent,
        AtivityTeamComponent,
        ResourceRequestComponent,
        CardComponent,
        ListCardComponent,
        AssignEmployeeComponent,
        TaskModalComponent,
        TaskManagerFormComponent,
        TaskDetailsComponent,
        ResourceRequestTableComponent,
        BudgetListComponent,
        BudgetEstimatedComponent,
        BudgetResourceComponent,
        BudgetAdditionelComponent,
        BudgetEstimatedTableComponent,
        BudgetResourceTableComponent,
        MemberComponent,
        MemberCardComponent,
        SharedProjectComponent,
        SharedProjectDetailsComponent,
        RoadmapComponent,
        RoadmapProcessComponent,
        ScrumBoardComponent,
        ScrumProcessComponent,
        SprintBacklogComponent,
        SprintFormDialogComponent,
        SprintProcessComponent,
        CalenderComponent,
        CreateMeetComponent,
        EditMeetingComponent,
        NotificatonComponent,
        RestrospectivesComponent,
        SprintDetailsComponent,
        JitsiComponent,
        DashboardComponent,
        DocumentationComponent,
        CreateMultipleMeetComponent,
        LeanProcessComponent,
        LeanBoardComponent,
        LeanBoardHeaderComponent,
        LeanBoardBodyComponent,
        LeanBoardItemComponent,
        LeanItemAssigneeComponent,
        LeanItemChecklistComponent,
        LeanMenuComponent,
        LeanDocumentationComponent,
        AgendaDetailsComponent,
        CreateMinutesComponent,
        MinutesListComponent,
        AssignActionItemsComponent,
        PdfAgendaComponent,
        PdfMinutesComponent,
        CustomselectComponent,
        RedirectingtableComponent,
        ReviewComponent,
        CreateReviewComponent,
        RedirectingtableRetroComponent,
        SprintRetroComponent,
        RetrospectiveUserComponent,
    ],
})
export class ProjectManagementModule {
}