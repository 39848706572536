import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourceService } from '../../../../services/resource/resource.service';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ResourceRequest } from '../../../../models/ResourceRequest';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-resource-request',
  templateUrl: './resource-request.component.html',
  styleUrls: ['./resource-request.component.scss'],
})
export class ResourceRequestComponent implements OnInit {
  resourceRequestForm: FormGroup;
  @Output() refreshData = new EventEmitter<ResourceRequest>();
  id: string;
  @Input() selectedItem = null;
  resourceTypes = ['Human_Resources', 'Financial', 'Material', 'Technological', 'Environmental', 'Time'];
  selectedSubtypes: string[] = [];
  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,

    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.resourceTypes = this.resourceTypes.map(type => type.toUpperCase());


    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
    });
    let date = null;
    if (
      this?.selectedItem?.preferredDate != undefined &&
      this?.selectedItem?.preferredDate !== null
    ) {
      date = new Date(this?.selectedItem?.preferredDate);
    } else {
      date = '';
    }
    console.log(date);

    const formcontrols = {
      resourceName: new FormControl(this?.selectedItem?.resourceName, [
        Validators.required,
      ]),
      resourceType: new FormControl(this.selectedItem?.resourceType, [Validators.required]),
      subtype: new FormControl(this.selectedItem?.subtype, [Validators.required]),
      description: new FormControl(this?.selectedItem?.description, [
        Validators.required,
      ]),
      importance: new FormControl(this?.selectedItem?.importance, [
        Validators.required,
      ]),
      preferredDate: new FormControl(date, [Validators.required]),
    };
    this.resourceRequestForm = this.fb.group(formcontrols);
    this.onResourceTypeChange(); // Call the method to initialize subtype dropdown

  }
  onResourceTypeChange() {
    this.selectedSubtypes = this.getSubtypes(this.resourceRequestForm.value.resourceType);
  }
  getSubtypes(resourceType: string): string[] {
    switch (resourceType) {
      case 'HUMAN_RESOURCES':
        return ['Recruitment', 'Training and Development', 'Employee Relations', 'Compensation and Benefits', 'Performance Management'];
      case 'FINANCIAL':
        return ['Budget allocation and management', 'Cost estimation and forecasting', 'Financial reporting and analysis', 'Funding sources and procurement', 'Expense tracking and control'];
      case 'MATERIAL':
        return ['Raw materials sourcing and procurement', 'Inventory management', 'Material handling and storage', 'Quality control and inspection', 'Supplier management'];
      case 'TECHNOLOGICAL':
        return ['Hardware and equipment', 'Software licenses and subscriptions', 'IT infrastructure', 'Data management tools and systems', 'Technical support and maintenance'];
      case 'ENVIRONMENTAL':
        return ['Energy consumption and management', 'Waste management and recycling', 'Environmental impact assessment', 'Compliance with environmental regulations', 'Sustainable resource utilization'];
      case 'TIME':
        return ['Project scheduling and planning', 'Task prioritization and sequencing', 'Time tracking and reporting', 'Schedule optimization and resource leveling', 'Deadline management and milestone tracking'];
      default:
        return [];
    }}
  save() {
    const data = this.resourceRequestForm.value;
    console.log('save');

    data.project = this.id;
    this.resourceService.sendRequest(data).subscribe({
      next: (result) => {
        console.log(result);

        this.refreshData.emit(result);
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
this.resourceRequestForm.reset();
      },
    });
  }
  update() {
    console.log('test');

    const data = this.resourceRequestForm.value;
    data.id = this.selectedItem.id;
    data.project = this.id;
    this.resourceService.update(data).subscribe({
      next: (result) => {

        this.refreshData.emit(result);
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
this.resourceRequestForm.reset();
      },
    });
  }
}
