<div class="card-row">
  <div class="card-col">
    <nb-card>
      <img src="assets/images/Mur d'humeur.jpg" alt="Timeline Image" class="full-card-image" />
    </nb-card>
  </div>
</div>
<div >
  <div class = "container">
    <div class="row">
      <div class ="col">
        <div class ="p-3">
    <button nbButton shape="semi-round" outline status="primary" (click)="toggleQuestionInput()"> Question <nb-icon icon="question-mark"></nb-icon>
    </button>
  </div>
  </div>
  </div>
    <div class ="row" *ngIf="showQuestionInput">
      <div class="col-10">
        <div class ="p-3">
      <textarea fullWidth nbInput [(ngModel)]="newQuestion.title" placeholder="New Question" minlength="10"></textarea>
    </div>
    </div>
    <div class="col-2">
      <div class ="p-3">
      <button nbButton shape="round" status="success" size="small" (click)="addQuestion()"> Add
        <nb-icon icon="plus-circle-outline"></nb-icon>
      </button>
    </div>
    </div>
    </div>
  </div>
  <div class="container">
    <nb-card *ngFor="let question of questions">
      <div class="row">
        <div class="col">
          <div class="p-3">
            <p>{{ question.title }} (Added by: {{ questionUsernames[question.id] }})</p>
          </div>
        </div>
        <div class="col-2" *ngIf="!isDefaultQuestion(question.id)">
          <button nbButton status="danger" shape="round" size="small" (click)="deleteQuestion(question.id)">
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="p-3">
            <nb-accordion fullWidth multi="true">
              <nb-accordion-item fullWidth *ngFor="let answer of question.answers">
                <nb-accordion-item-header fullWidth>
                  <div class="row">
                    <div class="col-10">
                      <p> (Added by: {{ answerUsernames[answer.id] }})</p>
                    </div>
                    <div class="col-2">
                      <button nbButton status="danger" shape="round" size="small" (click)="deleteAnswer(answer.id, question.id)">
                        <nb-icon icon="trash-2-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </nb-accordion-item-header>
                <nb-accordion-item-body fullWidth>
                  <p>{{ answer.title }}</p>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="p-3">
            <button  nbButton shape="semi-round" outline status="primary" (click)="toggleAnswerInput(question.id)">
              Answer
            </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showAnswerInput[question.id]">
        <div class="col-10">
          <div class="p-3">
            <textarea fullWidth nbInput [(ngModel)]="newAnswers[question.id].title" placeholder="New Answer" minlength="10"></textarea>
          </div>
        </div>
        <div class="col-2">
          <div class="p-3">
            <button nbButton shape="round" status="success" size="small" (click)="addAnswer(question.id)">Add
              <nb-icon icon="plus-circle-outline"></nb-icon>
            </button>
          </div>
        </div>
      </div>
    </nb-card>
  </div>
</div>
