<div *ngIf="activity; else loading">
  <nb-card>
    <nb-card-header class="text-center"><h6 ><nb-icon icon="edit"></nb-icon>Update Activity Informations</h6>  </nb-card-header>
    <nb-card-body>
        <ngx-activities-form [selectedItem]="activity"></ngx-activities-form>

    </nb-card-body>
  </nb-card>
</div>

<ng-template #loading>
  <nb-card [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary">
    <nb-card-body> Loading... </nb-card-body>
  </nb-card>
</ng-template>
