
  <div class="d-flex flex-column justify-content-center align-items-center h-100">
     <div class="col-md-8 mb-4 mt-4">
      <div id="jitsi-iframe" style="height: 50vh;"></div>
    </div> 
    <div class="col-md-4">
      <!-- Votre contenu ici -->
      <button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button" (click)="toggleTooltip($event)">
        <mat-icon>help_outline</mat-icon>
      </button>
    </div>
  </div>

  <div class="tooltip-card" [class.show]="showTooltip">
    <nb-card>
      <nb-card-body>
        <button nbButton (click)="closeTooltip()" class="close-sticker">↩</button>
        <ul>Types of Scrum Meetings:</ul>
        <ul>There are four central agile meetings:
          <li>Sprint Planning</li>
          <li>Daily Standups</li>
          <li>Sprint Reviews</li>
          <li>Sprint Retrospective</li>
        </ul>
        <p>A sprint starts with a sprint planning meeting. Each day, a daily standup meeting is held.
          Finally, at the end of the sprint, a sprint review and retrospective are held.</p>

      </nb-card-body>
    </nb-card>
  </div>
  <div>
    <nb-select placeholder="Sélectionnez un employé" class="custom-select">
      <nb-option *ngFor="let employee of employeeList" [value]="employee.id">
        <div (click)="addEmployee(employee)">
          {{ employee.employeeName }}
        </div>
      </nb-option>
    </nb-select>
    <input nbInput placeholder="Entrez une adresse e-mail externe" [(ngModel)]="externalEmail">
    <button nbButton (click)="addExternalEmployee()">Ajouter</button>

      <input nbInput placeholder="Entrez un lien" [(ngModel)]="linkInput">
      <button nbButton (click)="SendAllEmailsToMeetLink()">Envoyer e-mail</button>

  </div>

  <!-- Affichage des employés sélectionnés -->
  <div class="selected-employees">
    <h3>Employés sélectionnés :</h3>
    <ul>
      <li *ngFor="let employee of selectedEmployees">{{ employee.employeeEmail }}</li>
    </ul>
  </div>

