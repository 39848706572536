import {AfterViewInit, Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EmployeeService} from '../../../../humain-capital-management/services/employeeServices/employee.service';
import { Employee as HcmEmployee } from '../../../../humain-capital-management/models/Employee';
import {Meeting} from '../../../models/meeting';
import {Project} from '../../../models/Project';
import {ProjectService} from '../../../services/project/project.service';
import {CalenderService} from '../../../services/calender/calender.service';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'ngx-jitsi',
  templateUrl: './jitsi.component.html',
  styleUrls: ['./jitsi.component.scss'],
})
export class JitsiComponent implements OnInit , AfterViewInit {
  projectId: string;
  project: Project;
  projectMembers: string[] = [];
  domain: string = 'meet.jit.si'; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;
  employeeList: HcmEmployee[];
  selectedEmployees: HcmEmployee[] = [];
  externalEmail: string = ''; // Nouvelle propriété pour stocker l'adresse e-mail externe
  linkInput: string = ''; // Nouvelle propriété pour stocker le lien entré par l'utilisateur
  showTooltip = false;
  meet: Meeting = new Meeting();

  constructor(
    private router: Router,
    private employeeService: EmployeeService, private route: ActivatedRoute, private projectService: ProjectService, private calendarService: CalenderService,
  ) {
}

  ngOnInit(): void {

    this.room = 'manazello-pm/' + this.generatRandomDomain(); // Set your room name
    this.user = {
      name: 'Project Management', // Set your username
    };
    this.getAllEMployee();
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.project = new Project();
    });
  }
  getAllEMployee() {
    this.employeeService.findAll().subscribe({
      next: (result) => {
        this.employeeList = [...result];
        console.log(this.employeeList);
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
generatRandomDomain() {    // Generate a random three-letter domain prefix
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  let randomPrefix = '';
  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * letters.length);
    randomPrefix += letters[randomIndex];
  }
  return randomPrefix;
  }
  ngAfterViewInit(): void {
    this.options = {
      roomName: this.room,
      width: 800,
      height: 500,
      configOverwrite: {prejoinPageEnabled: false},
      interfaceConfigOverwrite: {
        // overwrite interface properties
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.user.name,
      },
    };

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    // Event handlers
    this.api.addEventListeners({
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
    });
  }
  addExternalEmployee() {
    if (this.externalEmail.trim() !== '') {
      // Créer un objet Employee complet avec des valeurs par défaut pour les propriétés manquantes
      const externalEmployee: HcmEmployee = {
        id: '', // Remplir avec un ID approprié
        employeeReference: '', // Remplir avec une référence appropriée
        employeeName: this.externalEmail,
        employeeProfileImage: '',
        employeeProfile: '',
        employeePasseport: '',
        employeeGender: '',
        employeeEducation: '',
        employeeDateOfBirth: new Date(), // Remplir avec une date de naissance par défaut
        employeeNationality: '',
        employeeDrivingLicence: '',
        employeeCity: '',
        employeeCountry: '',
        employeeEmail: this.externalEmail, // Remplir avec l'adresse e-mail externe
        employeeCellPhone: '',
        department: null, // Remplir avec le département par défaut
        employeeSocialSecurity: '',
        employeeMaritalStatus: '',
        employeeNbKids: 0,
        employeeContractInfo: '',
        employeeTerminationDate: '',
        employeeTerminationReason: '',
        employeeBloodType: '',
        employeeHealthProblem: '',
        roleEmployee: '',
        active: '',
        isArchived: '',
        user: '',
      };

      // Appeler la fonction addEmployee avec l'employé externe complet
      this.addEmployee(externalEmployee);

      // Réinitialiser l'input d'adresse e-mail externe
      this.externalEmail = '';
    }
  }
  addEmployee(employee: HcmEmployee) {
    this.selectedEmployees.push(employee);
  }
  handleParticipantLeft = async (participant) => {
    const data = await this.getParticipants();
  }

  handleParticipantJoined = async (participant) => {
    const data = await this.getParticipants();
  }

  handleVideoConferenceJoined = async (participant) => {
    const data = await this.getParticipants();
  }

  handleVideoConferenceLeft = () => {
 // this.router.navigate(['/pm/thanks']);
    console.log('By');
  }

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }
  toggleTooltip(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
  }
  closeTooltip() {
    this.showTooltip = false;
  }


  SendAllEmailsToMeetLink() {
    this.meet.idProject = this.projectId;
    this.meet.lien = this.linkInput;
    this.meet.participant = this.selectedEmployees.map((employee: HcmEmployee) => {
      return employee.employeeName;
    });
    console.log(this.meet);
    // Rest of your existing code for adding the meeting
    this.calendarService.addMeeting(this.meet).subscribe(
      (response: any) => {
        console.log('Salle créée avec succès', response);
        console.log(this.meet);
        window.location.reload();
      },
      (error: any) => {
        console.error('Error creating meeting', error);
      },
    );
  }
}

