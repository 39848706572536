import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RoadmapService} from '../../services/roadmap/roadmap.service';
import {Sprint} from '../../models/Sprint';
import {Priority, Task, Type} from '../scrum-board/utils/models/task';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {sprintStatus} from '../../models/enum/sprintStatus';


declare const gantt: any;
@Component({
  selector: 'ngx-roadmap-process',
  templateUrl: './roadmap-process.component.html',
  styleUrls: ['./roadmap-process.component.scss'],
})
export class RoadmapProcessComponent implements AfterViewInit , OnInit {
  roadmapForm: FormGroup;
  list: Sprint[];
  Data: any[] = [];
  tasks: any[] = [];
  projectId: string;

  constructor(private formBuilder: FormBuilder,
              private roadmapService: RoadmapService, private route: ActivatedRoute,
  ) {
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.ganttView();
      }
    });
  }

  ngOnInit(): void {

    this.roadmapForm = this.formBuilder.group({
      tasks: [
        {
          id: '',
          name: '',
          start: '',
          end: '',
        },
      ],
    });
  }

  getTitle(): string {
    return 'Update Project Roadmap';
  }

  getAllSprint() {
    this.roadmapService.findAllSprintProject(this.projectId).subscribe({
      next: (result) => {
        // Filtrer les sprints ayant un statut différent de "completed"
        this.list = result.filter(sprint => {
          return sprint.status.toString() !== 'completed' ;
        });
        this.fillSeriesData(this.list);
      },
      error: (err: any) => {
        console.log(err);
      },
    });  }

  getTaskByID(id: string): Observable<Task> {
    return this.roadmapService.findTaskByID(id);
  }

  async fillSeriesData(sprints: Sprint[]) {

    for (const sprint of sprints) {
      const sprintStartDate = new Date(sprint.startDate);
      const sprintEndDate = new Date(sprint.endDate);

      // Ajouter le sprint en tant que parent
      this.Data.push({
        id: sprint.id,
        text: sprint.title,
        start_date: sprintStartDate,
        end_date: sprintEndDate,
        parent: 0, // Les sprints ont le niveau parent 0
      });

      if (sprint.tasks && sprint.tasks.length > 0) {
        for (const tasks of sprint.tasks) {
          try {
            const taskup = await this.roadmapService.findTaskByID(tasks.id).toPromise();
            const taskStartDate = new Date(taskup.startDate);
            const taskEndDate = new Date(taskup.dueDate);

            // Ajouter la tâche comme enfant du sprint
            this.Data.push({
              id: taskup.id,
              text: taskup.title,
              start_date: taskStartDate,
              end_date: taskEndDate,
              parent: sprint.id, // Parent est l'ID du sprint
            });

            if (taskup.subTasks) {
              await this.processSubTasks(taskup.subTasks, taskup.id, sprint.id, this.Data);
            }
          } catch (error) {
            console.error('Error fetching task:', error);
          }
        }
      }
    }

    // Utiliser ganttData pour alimenter le diagramme de Gantt
    gantt.parse({data: this.Data});
  }

  async processSubTasks(subTask: string[], parentTaskId: string, sprintId: string, ganttData: any[]) {
    if (subTask && subTask.length > 0) {
      for (const SubsubTask of subTask) {
        console.log(subTask);
        console.log('subTask');
        console.log(SubsubTask);
        const subTaskData: Task = await this.getTaskByID(SubsubTask).toPromise();
        const subTaskStartDate = new Date(subTaskData.startDate);
        const subTaskEndDate = new Date(subTaskData.dueDate);

        // Ajouter la sous-tâche avec la hiérarchie appropriée
        ganttData.push({
          id: subTaskData.id,
          text: subTaskData.title,
          start_date: subTaskStartDate,
          end_date: subTaskEndDate,
          parent: parentTaskId, // Parent est l'ID de la tâche parent
        });

        if (subTaskData.subTasks) {
          await this.processSubTasks(subTaskData.subTasks, subTaskData.id, sprintId, ganttData);
        }
      }
    }
  }


  ganttView() {
    gantt.config.lightbox.sections = [
      {name: 'description', height: 38, map_to: 'text', type: 'textarea', focusy: false},
      {name: 'time', type: 'duration', map_to: 'auto'},
    ];
    const currentDate = new Date(); // Obtenez la date actuelle
    gantt.config.start_date = new Date(currentDate.getFullYear() - 55, currentDate.getMonth(), currentDate.getDate());
    const endDate = new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDate());
    gantt.config.end_date = endDate;
    gantt.init('ganttChartContainer');
    this.getAllSprint();
  }

  async ganttUpdate() {
    this.tasks = gantt.getTaskByTime();
    if (this.tasks && this.tasks.length > 0) {
      for (const task of this.tasks) {
        const exists = await this.ExistTest(this.list, task);
        if (exists === false) {
          if (task.parent === 0) {
            const sprint: Sprint = {
              id: null,
              title: task.text,
              startDate: null,
              endDate: null,
              status: sprintStatus.notStarted,
              description: '',
              restrospectives: '',
              tasks: [],
              project: this.projectId,
              collapsed: true,
            };
            sprint.startDate = new Date(task.start_date);
            sprint.startDate.setDate(sprint.startDate.getDate() + 1);
            sprint.endDate = task.end_date;
            this.list.push(sprint);
            this.roadmapService.addSprint(sprint).subscribe(() => {
                console.log(sprint);
                console.log('Sprint add successfully.');
              },
              (error) => {
                console.error(`Failed to add sprint. Error: ${error}`);
              });
          } else {
            const newTask: Task = {
              id: null,
              title: task.text,
              description: null,
              type: Type.TASK,
              sprintId: task.parent, // Assign sprintId to parent
              assignees: [],
              priority: Priority.LOW,
              startDate: null,
              dueDate: null,
              createdAt: null,
              updatedAt: null,
              comments: [],
              checklists: [],
              subTasks: [],
            };
            newTask.startDate = new Date(task.start_date);
            newTask.startDate.setDate(newTask.startDate.getDate() + 1);
            newTask.dueDate = task.end_date;
            this.roadmapService.findTaskByID(task.id).subscribe((Tasku) => {
                if (Tasku) {
                } else {
                  // La tâche n'a pas été trouvée
                  console.log('La tâche n a pas été trouvée');
                }
              },
              (error) => {
                if (error.status === 404) {
                  this.roadmapService.addTask(newTask).subscribe(
                    () => {
                      console.log('Task added successfully.');
                      // Find the task's ID after adding
                      if (newTask.sprintId === task.parent)
                        this.roadmapService.findTaskIdByTitle(newTask.title).subscribe(
                          (taskId) => {
                            console.log('Task ID:', taskId);
                            // Assign the task to the parent sprint
                            this.roadmapService.assignTaskToSprint(task.parent, taskId).subscribe(
                              () => {
                                console.log('Task added to sprint successfully.');
                              },
                            );
                            this.roadmapService.findTaskByID(task.parent).subscribe((Taskup) => {
                              if (Taskup) {
                                if (!Taskup.subTasks) {
                                  Taskup.subTasks = []; // Initialize as an empty array if it's null
                                }
                                Taskup.subTasks.push(taskId);
                                console.log(Taskup.subTasks);
                                this.roadmapService.updateTask(Taskup).subscribe(() => {
                                  console.log('Tasks add to task successfully.');
                                });
                              }
                            });
                          },
                        );
                    },
                  );
                }
              });
          }
        }
//        Convertir start data dans tasks
        const yearStart = task.start_date.getFullYear();
        const monthStart = task.start_date.getMonth() + 1;
        const dayStart = task.start_date.getDate();
        const formattedStartTask = `${yearStart}-${monthStart < 10 ? '0' : ''}${monthStart}-${dayStart < 10 ? '0' : ''}${dayStart}`;
        // Convertir End data dans tasks
        const yearEnd = task.end_date.getFullYear();
        const monthEnd = task.end_date.getMonth() + 1;
        const dayEnd = task.end_date.getDate();
        const formattedEndTask = `${yearEnd}-${monthEnd < 10 ? '0' : ''}${monthEnd}-${dayEnd < 10 ? '0' : ''}${dayEnd}`;

        // Parcourir les éléments de this.Data
        for (const data of this.list) {
          // Vérifier si les propriétés sont définies avant d'accéder à leurs valeurs
          if (task.id && data.id && task.end_date && data.startDate && data.endDate) {
            const dataStart = String(data.startDate);
            const dataEnd = String(data.endDate);
            if (task.id === data.id) {
              if (formattedStartTask !== dataStart || formattedEndTask !== dataEnd || task.text !== data.title) {
                this.roadmapService.findSprintByID(task.id).subscribe((sprint) => {
                  if (sprint) {
                    sprint.title = task.text;
                    sprint.startDate = new Date(task.start_date);
                    sprint.startDate.setDate(sprint.startDate.getDate() + 1);
                    sprint.endDate = task.end_date;
                  }
                  console.log('je suis sprnt ', sprint);
                  this.roadmapService.updateSprint(sprint).subscribe(() => {
                    if (sprint.tasks) {
                      for (const SprintTask of sprint.tasks) {
                        this.roadmapService.assignTaskToSprint(sprint.id, SprintTask.id).subscribe(() => {
                          console.log('Task add to sprint avec succès.');
                        });
                      }
                    }
                    console.log('Sprint updated avec succès.');
                  });
                });
              }
            } else {
              for (const taskofSprint of data.tasks) {
                if (task.id === taskofSprint.id) {
                  this.roadmapService.findTaskByID(task.id).subscribe((Taskup) => {
                    if (Taskup) {
                      console.log('taskup', Taskup);
                      let dataStartTask = '';
                      if (Taskup.startDate) {
                        const startDate = new Date(Taskup.startDate);
                        if (startDate) {
                          const year = startDate.getFullYear();
                          const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
                          const day = startDate.getDate().toString().padStart(2, '0');
                          dataStartTask = `${year}-${month}-${day}`;
                        }
                      }
                      let dataEndTask = '';
                      if (Taskup.dueDate) {
                        const EndDate = new Date(Taskup.dueDate);
                        if (EndDate) {
                          const year = EndDate.getFullYear();
                          const month = (EndDate.getMonth() + 1).toString().padStart(2, '0');
                          const day = EndDate.getDate().toString().padStart(2, '0');
                          dataEndTask = `${year}-${month}-${day}`;
                        }
                      }
                      if (task.text !== Taskup.title || dataStartTask !== formattedStartTask || dataEndTask !== formattedEndTask) {
                        Taskup.title = task.text;
                        Taskup.startDate = task.start_date;
                        Taskup.dueDate = task.end_date;
                        this.roadmapService.updateTask(Taskup).subscribe(() => {
                          console.log('Tasks updated successfully.');
                        });
                      }
                    }
                  });
                } else {
                  console.log(taskofSprint);
                  const TaskProcess = await this.ProcessSubTaskk(task, taskofSprint, formattedStartTask, formattedEndTask);
                 if (TaskProcess) {
                   this.roadmapService.updateTask(TaskProcess).subscribe(() => {
                    console.log('Tasks updated successfully.');
                  });
                }
                }
              }
            }
          }
        }
      }
    }
    const deletedElements = this.Data.filter(item => !this.tasks.some(updatedItem => updatedItem.id === item.id));
    console.log(deletedElements);
    for (const e of deletedElements) {
      this.roadmapService.findSprintByID(e.id).subscribe(
        (sprint) => {
          if (sprint) {
            console.log('Sprint found:', sprint);

            // Mettre à jour le statut du sprint côté serveur
            this.roadmapService.updateSprintStatusAsCompleted(sprint.id).subscribe(
              (updatedSprint) => {
                console.log('Sprint status updated successfully:', updatedSprint);

                // Mettre à jour le sprint local avec le statut mis à jour
               // sprint.status = updatedSprint.status;

                // Supprimer le sprint côté serveur
                // this.roadmapService.deleteSprint(sprint.id).subscribe(
                //   () => {
                //     console.log(`Sprint "${sprint.title}" deleted successfully.`);
                //     // Supprimer le sprint de la liste dans le composant
                //     const index = this.list.indexOf(sprint);
                //     if (index > -1) {
                //       this.list.splice(index, 1);
                //     }
                //   },
                //   (error) => {
                //     console.error(`Failed to delete sprint "${sprint.title}". Error: ${error}`);
                //   },
                // );
              },
              (error) => {
                console.error(`Failed to update sprint status. Error: ${error}`);
              },
            );
          } else {
            this.roadmapService.findTaskByID(e.id).subscribe(
              (task) => {
                if (task) {
                  console.log('Task found:', task);

                  // Supprimer la tâche côté serveur
                  this.roadmapService.deleteTask(task.id).subscribe(
                    () => {
                      console.log('Task deleted successfully.');

                      // Supprimer la tâche du sprint côté serveur
                      this.roadmapService.removeTaskFromSprint(task.sprintId, task.id).subscribe(
                        () => {
                          console.log('Task removed from sprint successfully.');
                        },
                        (error) => {
                          console.error(`Failed to remove task from sprint. Error: ${error}`);
                        },
                      );
                    },
                    (error) => {
                      console.error(`Failed to delete task. Error: ${error}`);
                    },
                  );
                }
              },
              (error) => {
                console.error(`Failed to fetch task. Error: ${error}`);
              },
            );
          }
        },
        (error) => {
          console.error(`Failed to fetch sprint. Error: ${error}`);
        },
      );
    }
  }

  onSubmit(): void {
    this.ganttUpdate();
  }

  private async ExistTest(list: Sprint[], task: any): Promise<boolean> {
    for (const sprint of list) {
      if (sprint.id === task.id || (sprint.tasks && sprint.tasks.some(taskP => taskP.id === task.id))) {
        return true;
      } else {
        if (sprint.tasks) {
          for (const taskP of sprint.tasks) {

            if (taskP.subTasks && Array.isArray(taskP.subTasks) && taskP.subTasks.includes(task.id)) {
              return true;
            } else if (taskP.subTasks) {
              const result = await this.subTaskProcess(taskP.subTasks, task);
              if (result) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  private async subTaskProcess(subTasks: string[], task: any): Promise<boolean> {
    if (subTasks && Array.isArray(subTasks)) {
      for (const taskSb of subTasks) {
        if (taskSb === task.id) {
          return true;
        } else {
          const Taskup = await this.roadmapService.findTaskByID(taskSb).toPromise();
          if (Taskup && Taskup.subTasks?.length > 0) {
            const result = await this.subTaskProcess(Taskup.subTasks, task);
            if (result) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  private async ProcessSubTaskk(task: any, taskofSprint: Task, formattedEndTask: any, formattedStartTask: any): Promise<Task> {
    if (taskofSprint.subTasks && taskofSprint.subTasks.length > 0) { // Ajouter une vérification ici
      for (const SubsubTask of taskofSprint.subTasks) {
        const Taskup = await this.roadmapService.findTaskByID(SubsubTask).toPromise();
        if (Taskup) {
          if (Taskup.id === task.id) {
          let dataStartTask = '';
          if (Taskup.startDate) {
            const startDate = new Date(Taskup.startDate);
            if (startDate) {
              const year = startDate.getFullYear();
              const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
              const day = startDate.getDate().toString().padStart(2, '0');
              dataStartTask = `${year}-${month}-${day}`;
            }
          }
          let dataEndTask = '';
          if (Taskup.dueDate) {
            const EndDate = new Date(Taskup.dueDate);
            if (EndDate) {
              const year = EndDate.getFullYear();
              const month = (EndDate.getMonth() + 1).toString().padStart(2, '0');
              const day = EndDate.getDate().toString().padStart(2, '0');
              dataEndTask = `${year}-${month}-${day}`;
            }
          }

          if (task.text !== Taskup.title || dataStartTask !== formattedStartTask || dataEndTask !== formattedEndTask) {
            Taskup.title = task.text;
            Taskup.startDate = task.start_date;
            Taskup.dueDate = task.end_date;
            return Taskup; // Return the modified Task object
          }
          }
          if (Taskup.subTasks && Taskup.subTasks.length > 0) {
            const updatedSubTask = await this.ProcessSubTaskk(task, Taskup, formattedEndTask, formattedStartTask);
            if (updatedSubTask) {
              return updatedSubTask;
            }
          }
        }
      }
    }

    return null; // Return null if no updates were made
  }
}
