<nb-card class="documentation-card">
  <nb-card-header class="header-container" style="padding: 4%">
  <h3>Scrum Process Definition and Sprint Planning</h3>
  </nb-card-header>
  <nb-card-body class="documentation-body">
<h2>Scrum Process Definition</h2>
<ul>
  Scrum is an agile development methodology used in the development of Software based on an iterative and incremental processes. Scrum is adaptable, fast, flexible and effective agile framework that is designed to deliver value to the customer throughout the development of the project.
</ul>
<h2>Product Backlog</h2>
<ul>
  The agile product backlog in Scrum is a prioritized features list, containing short descriptions of all functionality desired in the product. When applying Scrum, it's not necessary to start a project with a lengthy, upfront effort to document all requirements.
</ul>
<h1>Tasks</h1>
<ul> In Scrum, tasks in the backlog are detailed items of work that need to be completed to achieve the goals defined in the product backlog. These tasks are typically smaller, more specific, and tangible pieces of work compared to user stories. Tasks help the development team understand what needs to be done to implement a user story or feature</ul>
<ul> There are different types of tasks that can be included in the backlog. These tasks are categorized based on their nature and purpose. The most common types of tasks in Scrum are:</ul>
<ul>
  <li>User Story:</li> <ul>A user story is a high-level requirement that describes a specific feature from an end-user perspective. It typically follows the format of "As a [user], I want [goal] so that [reason]." User stories represent the needs and desires of users and stakeholders. They serve as placeholders for conversations between the development team and the product owner to determine the details of the feature.</ul>
<li>Task:</li> <ul> Tasks are specific units of work that need to be completed to implement a user story or a feature. They are detailed, actionable items that the development team works on during a sprint. Tasks should be small and manageable, typically taking no more than a few days to complete.</ul>
<li>Bug/Issue:</li> <ul> Bugs or issues are problems or defects in the software that need to be addressed. When users encounter unexpected behavior or errors, they are reported as bugs. The development team creates tasks to investigate, fix, and validate these issues</ul>
</ul>

<h2>Sprints</h2>
<ul>
  <li>Definition of Sprint:</li>
  <ul> A Sprint is a fixed-length iteration, usually ranging from one to four weeks, where the development team collaboratively works on a set of prioritized user stories, tasks, or backlog items. Each sprint begins with a sprint planning meeting, where the development team selects the items they believe they can complete during the sprint, based on their capacity and previous performance.
    During the sprint, the development team focuses on delivering the selected items and meets daily in short time-boxed events called Daily Stand-ups or Daily Scrums. These meetings help synchronize the team's activities and address any impediments or challenges that may arise during the sprint.
    The development team strives to complete the selected items by the end of the sprint, creating a potentially shippable product increment. A sprint ends with a sprint review and a sprint retrospective. </ul>
  <li>Sprint Review:</li>
  <ul> At the end of each sprint, a sprint review meeting is held. The development team presents the work they have completed during the sprint to stakeholders, product owners, and other interested parties. The purpose of the sprint review is to obtain feedback and gather insights on the increment delivered. This feedback helps in refining the product backlog and adjusting the team's priorities for the upcoming sprints. </ul>
  <li>Sprint Retrospective:</li>
  <ul> Following the sprint review, the team holds a sprint retrospective meeting. In this meeting, the team reflects on the sprint and the processes they followed. The retrospective is an opportunity to identify what went well during the sprint and what could be improved. The team discusses potential changes and action items to enhance their performance in future sprints. </ul>
</ul>

<h2>Project board</h2>
<ul>
  A project board is a visual management tool used to track the progress of work items during a sprint or an iteration. It provides a clear and transparent overview of the status of various tasks, user stories, or backlog items that the development team is working on. The project board is typically set up on a physical board in a team's workspace or can be represented digitally through project management tools or software. The main purpose of the project board is to visualize the workflow and make it easy for the team and stakeholders to understand the current state of the project.
</ul>

<ul>
  Key Components of a Scrum Project Board:

  <li>Columns:</li> <ul>The project board is divided into columns that represent the different stages or states of the work items. Common column names include "To Do," "In Progress," "Testing," and "Done." The leftmost column usually represents the backlog or the list of items that are planned for the sprint but not yet started.</ul>

  <li>Work Items:</li> <ul>Work items, which can be user stories, tasks, or issues, are represented as cards or sticky notes on the project board. Each card typically contains essential information about the work item, such as the title, description, assignee, and any other relevant details.</ul>

  <li>Movement of Work Items:</li> <ul>As work progresses, the team moves the work items across the columns on the project board to indicate their current status. For example, a work item moves from "To Do" to "In Progress" when development starts and then to "Testing" when it's ready for quality assurance.</ul>

  <li>Visual Signals:</li> <ul>Project boards often use visual signals, such as colors or icons, to provide additional information about the work items. For instance, a color-coded label might indicate the priority of a task or whether it's a bug or a new feature.</ul>

  <li>Daily Stand-ups:</li> <ul>The project board becomes the focal point of the daily stand-up or daily scrum meetings. Team members can quickly review the board to update their progress and discuss any potential impediments.</ul>
</ul>

<h2>Types of meetings,</h2>
<ul>In the Scrum process, various types of meetings, also known as ceremonies, are held to facilitate effective collaboration, communication, and progress tracking. Each meeting serves a specific purpose and involves different participants. Here are the key meetings in Scrum:

</ul>
<ul>
  <li>Sprint Planning Meeting:</li>
  <ul>This meeting marks the beginning of a new sprint. The development team, Scrum Master, and Product Owner collaborate to determine which backlog items will be worked on during the sprint.Typically, time-boxed to 2 hours for a 4-week sprint. Shorter sprints will have proportionally shorter planning meetings.</ul>
  <li>Daily Stand-up (Daily Scrum) Meeting:</li>
  <ul>Held daily during the sprint, this short meeting is for team synchronization. Each team member shares what they accomplished yesterday, what they plan to do today, and any potential impediments.Usually time-boxed to 15 minutes to keep it concise and focused.</ul>
  <li>Sprint Review Meeting:</li>
  <ul>At the end of the sprint, the team presents the completed work to stakeholders and the Product Owner. Feedback is gathered, and the Product Backlog may be adjusted based on the feedback.Time-boxed to 1-2 hours for a 4-week sprint, with shorter durations for shorter sprints.</ul>
  <li>Sprint Retrospective Meeting:</li>
  <ul>Also held at the end of the sprint, this meeting is an opportunity for the Scrum Team to reflect on their processes and identify improvements for the next sprint.Time-boxed to 1.5-2 hours for a 4-week sprint</ul>
  <li>Backlog Refinement (Grooming) Meeting:</li>
  <ul>This meeting involves refining and preparing the Product Backlog items for future sprints. The team discusses and breaks down backlog items, estimates their effort, and ensures they are ready to be pulled into a sprint.Typically conducted once a week and time-boxed to 1-2 hours.Typically conducted once a week and time-boxed to 1-2 hours.</ul>Each of these meetings is crucial in the Scrum process and contributes to the iterative and incremental development approach, allowing the Scrum Team to deliver valuable and high-quality increments at the end of each sprint.
</ul>
  </nb-card-body>
</nb-card>
