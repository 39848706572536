<nb-card status ="sucess">
  <nb-card-header>
    <label for="fileDropdown">Select a file:</label>
    <select nbSelect id="fileDropdown" (change)="onFileSelected($event.target.value)">
      <option value="" disabled selected>Please select a file</option>
      <option *ngFor="let file of files" [value]="file.id">{{ file.filename }}</option>
    </select>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="selectedFile" [@fadeInOut]>
      <h3>File Details</h3>
      <p><strong>Filename:</strong> {{ selectedFile.filename }}</p>
      <p><strong>Is Cleaned:</strong> {{ selectedFile.cleaned }}</p>
      <p><strong>Is Selected:</strong> {{ selectedFile.selected }}</p>
      <button nbButton status="primary" (click)="processSelectedFile()">Process and Describe File</button>
    </div>
  </nb-card-body>
</nb-card>
    <div *ngIf="dataInfo" class="data-section" [@fadeInOut]>
      <nb-card>
        <nb-card-header>
          <span>Data Info</span>
        </nb-card-header>
        <nb-card-body>
          <table mat-table [dataSource]="dataInfo | keyvalue" class="mat-elevation-z8">
            <ng-container matColumnDef="column">
              <th mat-header-cell *matHeaderCellDef> Column </th>
              <td mat-cell *matCellDef="let element"> {{ element.key }} </td>
            </ng-container>

            <ng-container matColumnDef="nonNullCount">
              <th mat-header-cell *matHeaderCellDef> Features Count </th>
              <td mat-cell *matCellDef="let element"> {{ element.value }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['column', 'nonNullCount']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['column', 'nonNullCount'];"></tr>
          </table>
        </nb-card-body>
      </nb-card>
    </div>

    <div *ngIf="dataDescription" class="data-section" [@fadeInOut]>
      <nb-card>
        <nb-card-header>
          <span>Data Description</span>
        </nb-card-header>
        <nb-card-body>
          <table mat-table [dataSource]="dataDescription | keyvalue" class="mat-elevation-z8">
            <ng-container matColumnDef="column">
              <th mat-header-cell *matHeaderCellDef> Column </th>
              <td mat-cell *matCellDef="let element"> {{ element.key }} </td>
            </ng-container>

            <ng-container matColumnDef="details">
              <th mat-header-cell *matHeaderCellDef> Details </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngFor="let detail of element.value | keyvalue">
                  <strong>{{ detail.key }}:</strong> {{ detail.value }}<br>
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['column', 'details']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['column', 'details'];"></tr>
          </table>
        </nb-card-body>
      </nb-card>
    </div>

