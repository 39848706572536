import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TokenStorageService} from '../../../auth/service/token/token.service';
import { superAccess} from '../../../auth/accessControle/accessControle';
import {SprintService} from '../../services/sprint/sprint.service';
import {Sprint} from '../../models/Sprint';

@Component({
  selector: 'ngx-sprint-details',
  templateUrl: './sprint-details.component.html',
  styleUrls: ['./sprint-details.component.scss'],
})
export class SprintDetailsComponent implements OnInit {
  id: string;

  flippedState: boolean = false;
  statusColor: string = '';
  restrospective: string = '' ;
  errorMessage: string = '';
  sprint: Sprint = new Sprint();
  accessControle: boolean;
  currentWindow: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sprintService: SprintService ,
    private tokenStorageService: TokenStorageService,
  ) {}
  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();

    this.accessControle = superAccess(currentUser);
    this.currentWindow = 'home';
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      this.sprintService.findProjectById(this.id).subscribe({
        next: (result) => {
          this.sprint = result;
        },
        error: (err: any) => {
          console.log(err);
        },
      });
    });
  }
  refresh(event: Sprint) {
    this.sprint = null;
    this.sprint = event;
  }
  flip(): void {
    this.sprint = this?.sprint;
    this.flippedState = !this.flippedState;
  }
  async onSendSprint() {

    this.sprint.restrospectives = this.restrospective;
    try {
      await this.sprintService.updateSprint(this.sprint).subscribe({
        next: (result: Sprint) => {
        },
        error: (err: any) => {
          console.log(err);
        },
      });
      this.errorMessage = '';
    } catch (error) {
      console.error(error);
      this.errorMessage = 'La mise à jour a échoué. Veuillez réessayer.';
    }

  }
  navigateToScrumProcess() {
    this.router.navigate([`/projectManagement/scrum-process`] , { queryParams: { projectId: this.sprint.project }});
  }


}
