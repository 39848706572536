import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CalenderService } from '../../../services/calender/calender.service';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-create-agenda',
  templateUrl: './create-agenda.component.html',
  styleUrls: ['./create-agenda.component.scss']
})
export class CreateAgendaComponent implements OnInit {
  agendaForm: FormGroup;

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      
      title: {
        title: 'Title',
        type: 'string',
      },
      agendaSubItem: {
        title: 'Sub Item',
        type: 'string',
      },
      duration: {
        title: 'Duration',
        type: 'number',
      },
      presenter: {
        title: 'Presenter',
        type: 'string',
      },
    },
  };

  constructor(private fb: FormBuilder, private agendaService: CalenderService, public nbtheme: NbThemeService, private toastrService: NbToastrService, private router:Router) {
    this.agendaForm = this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      location: ['', Validators.required],
      agendaName: ['', Validators.required],
      agendaDescription: ['', Validators.required],
      agendaItems: this.fb.array([])
    });
  }

  ngOnInit(): void {}

  get agendaItems(): FormArray {
    return this.agendaForm.get('agendaItems') as FormArray;
  }

  createAgendaItem(): FormGroup {
    return this.fb.group({
      title: ['', Validators.required],
      duration: ['', [Validators.required, Validators.min(1)]],
      presenter: ['', Validators.required],
      agendaSubItems: this.fb.array([])
    });
  }

  addAgendaItem(): void {
    this.agendaItems.push(this.createAgendaItem());
  }

  removeAgendaItem(index: number): void {
    this.agendaItems.removeAt(index);
  }

  getAgendaSubItems(index: number): FormArray {
    return this.agendaItems.at(index).get('agendaSubItems') as FormArray;
  }

  addAgendaSubItem(agendaIndex: number): void {
    this.getAgendaSubItems(agendaIndex).push(this.fb.control('', Validators.required));
  }

  removeAgendaSubItem(agendaIndex: number, subItemIndex: number): void {
    this.getAgendaSubItems(agendaIndex).removeAt(subItemIndex);
  }

  onCreateConfirm(event): void {
    if (window.confirm('Are you sure you want to create?')) {
      const newItem = this.createAgendaItem();
      newItem.patchValue(event.newData);
      newItem.setControl('agendaSubItems', this.fb.array(event.newData.agendaSubItems.map(subItem => this.fb.control(subItem))));
      this.agendaItems.push(newItem);
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      const index = this.agendaItems.controls.findIndex(item => item.value.title === event.data.title);
      this.agendaItems.removeAt(index);
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  onSubmit(): void {
    if (this.agendaForm.invalid) {
      this.markAllAsTouched();
      return;
    }
    if (this.agendaForm.valid) {
      this.agendaService.createAgenda(this.agendaForm.value).subscribe(
        response => {
          this.showToast('top-right', 'success', 'Agenda created successfully');
          console.log('Agenda created successfully', response);
          this.router.navigate(['/projectManagement/agendas']);

                },
        error => {
          this.showToast('top-right', 'danger', 'Failed to create agenda');
          console.error('Error creating agenda', error);
        }
      );
    }
  }
  private showToast(position, status, message) {
    this.toastrService.show(message, 'Status', { position, status });
  }
  markAllAsTouched() {
    this.agendaForm.markAllAsTouched();
    this.agendaItems.controls.forEach(item => {
      item.markAsTouched();
      this.getAgendaSubItems(this.agendaItems.controls.indexOf(item)).controls.forEach(subItem => {
        subItem.markAsTouched();
      });
    });
  }
}
