<div class="steppers-container row">
  <div class="col-md-12 col-lg-12 col-xxxl-12 w-100">
    <nb-card>
      <nb-card-header>
        <div class="row">
          <div class="col-md-11">
            <h3>Create Agenda</h3>
          </div>
          <div class="col-md-1">
            <a nbTooltip="Go to agenda's list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/projectManagement/agendas']" nbButton>
              <nb-icon icon="corner-up-right-outline"></nb-icon>
            </a>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <form [formGroup]="agendaForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
          <nb-stepper #stepper>
            <nb-step [stepControl]="agendaForm" label="Agenda">
              <div>
                <label for="date">Date:</label>
                <input nbInput fullWidth formControlName="date" type="date" class="form-control" id="date" required>
                <div *ngIf="agendaForm.get('date').invalid && agendaForm.get('date').touched" class="invalid-feedback">
                  Please provide a valid date.
                </div>
              </div>
              <div>
                <label for="time">Time:</label>
                <input nbInput fullWidth formControlName="time" type="time" class="form-control" id="time" required>
                <div *ngIf="agendaForm.get('time').invalid && agendaForm.get('time').touched" class="invalid-feedback">
                  Please provide a valid time.
                </div>
              </div>
              <div>
                <label for="agendaName">Agenda Name:</label>
                <input nbInput fullWidth formControlName="agendaName" type="text" class="form-control" id="agendaName" required>
                <div *ngIf="agendaForm.get('agendaName').invalid && agendaForm.get('agendaName').touched" class="invalid-feedback">
                  Please provide a valid agenda name.
                </div>
              </div>
              <div>
                <label for="agendaDescription">Description:</label>
                <input nbInput fullWidth formControlName="agendaDescription" type="text" class="form-control" id="agendaDescription" required>
                <div *ngIf="agendaForm.get('agendaDescription').invalid && agendaForm.get('agendaDescription').touched" class="invalid-feedback">
                  Please provide a valid description.
                </div>
              </div>
              <div>
                <label for="location">Location:</label>
                <input nbInput fullWidth formControlName="location" type="text" class="form-control" id="location" required>
                <div *ngIf="agendaForm.get('location').invalid && agendaForm.get('location').touched" class="invalid-feedback">
                  Please provide a valid location.
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-10"></div>
                <div class="col-md-2 mt-2">
                  <button status="info" nbButton nbStepperNext type="button">Next</button>
                </div>
              </div>
            </nb-step>

            <nb-step [stepControl]="agendaForm" label="Agenda Items">
              <nb-card>
                <nb-card-header>Agenda Items</nb-card-header>
                <nb-card-body>
                  <angular2-smart-table [settings]="settings" [source]="agendaItems.value" (createConfirm)="onCreateConfirm($event)" (deleteConfirm)="onDeleteConfirm($event)">
                  </angular2-smart-table>
                  <div formArrayName="agendaItems">
                    <div *ngFor="let item of agendaItems.controls; let i = index" [formGroupName]="i">
                      <h3>Agenda Item {{ i + 1 }}</h3>
                      <label for="title-{{ i }}">Title:</label>
                      <input nbInput fullWidth formControlName="title" type="text" class="form-control" id="title-{{ i }}" required>
                      <div *ngIf="item.get('title').invalid && item.get('title').touched" class="invalid-feedback">
                        Please provide a valid title.
                      </div>
                      <label for="duration-{{ i }}">Duration:</label>
                      <input nbInput fullWidth formControlName="duration" type="number" class="form-control" id="duration-{{ i }}" required>
                      <div *ngIf="item.get('duration').invalid && item.get('duration').touched" class="invalid-feedback">
                        Please provide a valid duration.
                      </div>
                      <label for="presenter-{{ i }}">Presenter:</label>
                      <input nbInput fullWidth formControlName="presenter" type="text" class="form-control" id="presenter-{{ i }}" required>
                      <div *ngIf="item.get('presenter').invalid && item.get('presenter').touched" class="invalid-feedback">
                        Please provide a valid presenter.
                      </div>

                      <div formArrayName="agendaSubItems">
                        <div *ngFor="let subItem of getAgendaSubItems(i).controls; let j = index">
                          <label for="subItem-{{ i }}-{{ j }}">Sub Item {{ j + 1 }}:</label>
                          <input nbInput fullWidth [formControlName]="j" type="text" class="form-control" id="subItem-{{ i }}-{{ j }}" required>
                          <div *ngIf="subItem.invalid && subItem.touched" class="invalid-feedback">
                            Please provide a valid sub item.
                          </div>
                          <button nbButton status="danger" (click)="removeAgendaSubItem(i, j)">Remove Sub Item</button>
                        </div>
                        <button nbButton status="info" (click)="addAgendaSubItem(i)">Add Sub Item</button>
                      </div>
                    </div>
                  </div>
                </nb-card-body>
              </nb-card>
              <button nbButton status="primary" nbStepperNext type="button">Next</button>
            </nb-step>
          </nb-stepper>
          <div class="row mt-2">
            <div class="col-md-10"></div>
            <div class="col-md-2 mt-2">
              <button status="primary" nbButton type="submit">Save Agenda</button>
            </div>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>
