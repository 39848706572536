<nb-card >
    <nb-card-header>
      <h2>Sprints</h2>
      <nb-select [(ngModel)]="selectedView">
        <nb-option value="active">Active Sprints</nb-option>
        <nb-option value="archived">Archived Sprints</nb-option>
      </nb-select>
    </nb-card-header>
    <nb-card-body>
      <nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
        <nb-card-front>
          <nb-card>
            <angular2-smart-table
              [settings]="settings"
              [source]=" selectedView === 'active' ? activeSprints : archivedSprints"
              (userRowSelect)=" onUserRowSelect($event)"
              class="order-table"
              style="cursor: pointer"
            >
            </angular2-smart-table>
          </nb-card>
        </nb-card-front>
        <nb-card-back *ngIf="flippedState">
          <nb-card>
            <nb-card-header>
              <nb-icon
                icon="chevron-left-outline"
                pack="nebular-essentials"
                aria-hidden="true"
                (click)="flippe()"
              ></nb-icon>
              Go to Sprint List
            </nb-card-header>
            <nb-card-body>
              <ngx-sprint-planning [selectedSprint]="selectedSprint" (dataChanged)="refresh($event)">
                
              </ngx-sprint-planning>
            </nb-card-body>
          </nb-card>
         </nb-card-back>
      </nb-flip-card>
    </nb-card-body>
  </nb-card>
  