import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-edit-goal',
  templateUrl: './edit-goal.component.html',
  styleUrls: ['./edit-goal.component.scss'],
})
export class EditGoalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
