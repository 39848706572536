<nb-card class="documentation-card">
    <nb-card-header class="header-container" style="padding: 4%">
    <h3>Scrum Process Definition and Sprint Planning</h3>
    </nb-card-header>
    <nb-card-body class="documentation-body">
        <h1>CRISP-DM Help Overview</h1>
  <ul>
    CRISP-DM, which stands for Cross-Industry Standard Process for Data Mining, is an industry-proven way
    to guide your data mining efforts.  </ul>
  <h1>Business Understanding Overview</h1>
  <ul>
    Even before working in IPACT CRISP-DM, you should take the time to explore what your organization
    expects to gain from data mining. Try to involve as many key people as possible in these discussions and
    document the results. The final step of this CRISP-DM phase discusses how to produce a project plan
    using the information gathered here.
      </ul>
  <h1>Determining Business Objectives</h1>
  <ul> Your first task is to try to gain as much insight as possible into the business goals for data mining. This
    may not be as easy as it seems, but you can minimize later risk by clarifying problems, goals, and
    resources.</ul>
  <ul> The CRISP-DM methodology provides a structured way for you to accomplish this.
</ul>
  <ul>
    <li>Task List:</li> <ul>Start gathering background information about the current business situation.</ul>
    <ul>Document specific business objectives decided upon by key decision makers.</ul>
    <ul>Agree upon criteria used to determine data mining success from a business perspective.</ul>
  </ul>
  
  <h2>Compiling the Business Background</h2>
  <ul>
    <li>Task List:</li>
    <ul>Understanding your organization's business situation helps you know what you're working with in terms
        of:</ul>
  <ul>  • Available resources (personnel and material)</ul>
  <ul>    • Problems</ul>
  <ul>    • Goals </ul>
  </ul>
  
    
<h2>Defining Business Objectives
</h2>  
<ul>This is where things get specific. As a result of your research and meetings, you should construct a
    concrete primary objective agreed upon by the project sponsors and other business units affected by the
    results
</ul>
<ul>
    <li>Task List:</li>
   <ul> Be sure to take notes on the following points for later incorporation into the project plan. Remember to
keep goals realistic.</ul>
<ul>  • Describe the problem you want to solve using data mining.
</ul>
  <ul>   • Specify all business questions as precisely as possible.</ul>
  <ul>    • Determine any other business requirements (such as not losing any existing customers while increasing
    cross-sell opportunities).
    </ul>
    <ul>  •  Specify expected benefits in business terms (such as reducing churn among high-value customers by
        10%).
        </ul>

</ul>

<h2>
    Business Success Criteria

</h2>
<ul>
    The goal ahead may be clear, but will you know once you're there? It's important to define the nature of
business success for your data mining project before proceeding further.
</ul>
<ul>
    <li>Task List:
    </li>
    <ul>• As precisely as possible, document the success criteria for this project</ul>
    <ul>• Make sure each business objective has a correlative criterion for success.</ul>
    <ul>• Align the arbiters of the subjective measurements of success. If possible, take notes on their
        expectations.
        </ul>
</ul>
<h1>Assessing the Situation
</h1>
<ul>Now that you have a clearly defined goal, it's time to make an assessment of where you are right now. This
    step involves asking questions such as:
    
    <ul>
        • What sort of data are available for analysis?
    </ul>
    <ul>
        • Do you have the personnel needed to complete the project?
    </ul>
    <ul>
        • What are the biggest risk factors involved?
    </ul>
    <ul>
        • Do you have a contingency plan for each risk?
    </ul>
</ul>
    </nb-card-body>
  </nb-card>
  