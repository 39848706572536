import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Notifications} from '../../models/Notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) { }
  addNotification(notif: Notifications): Observable<Notifications> {
    return this.http.post<Notifications>(`${this.psUrl}Notif/add`, notif, this.httpOptions);
  }

  getAllNotif(): Observable<Notifications[]> {
    return this.http.get<Notifications[]>(`${this.psUrl}Notif/all`, this.httpOptions);
  }

  update(notif: Notifications): Observable<Notifications> {
    return this.http.put<Notifications>(`${this.psUrl}Notif/update`, notif , this.httpOptions);
  }

  delete(id: String): Observable<void> {
    return this.http.delete<void>(`${this.psUrl}Notif/delete/${id}`, this.httpOptions);
  }

  getById(id: String ): Observable<Notifications> {
    return this.http.get<Notifications>(`${this.psUrl}Notif/GetById/${id}`);
  }

  deleteAll(): Observable<void> {
    return this.http.delete<void>(`${this.psUrl}Notif/deleteall/`, this.httpOptions);
  }

  // deleteAllNotifications(): Observable<any> {
  //   return this.http.delete<any>(this.apiUrl);
  // }
}
