import { Component } from '@angular/core';

@Component({
  selector: 'ngx-crisp-documentation',
  templateUrl: './crisp-documentation.component.html',
  styleUrls: ['./crisp-documentation.component.scss']
})
export class CrispDocumentationComponent {

}
