import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-sprint-process',
  templateUrl: './sprint-process.component.html',
  styleUrls: ['./sprint-process.component.scss'],
})
export class SprintProcessComponent {

  constructor() { }

}
