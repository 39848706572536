<nb-card style="max-height: 560px;">
  <nb-card-header>
    <h3 class="modal-title">Create Multiple Meetings</h3>
  </nb-card-header>
  <nb-card-body>
    <div class="add-form-row">
      <label for="meeting-title" style="width: 300px;">Title : </label>
      <input nbInput fullWidth id="meeting-title" required [(ngModel)]="meet.title" placeholder="Title" style="max-width: 60%;"/>
    </div>
    <div class="add-form-row">
    <label for="meeting-Description" style="width: 300px;">Description : </label>
    <input nbInput fullWidth id="meeting-Description" required [(ngModel)]="meet.description" placeholder="Description" style="max-width: 60%;"/>
  </div>
    <div class="add-form-row">
      <label for="selected-type" style="width: 300px;">Type :</label>
      <nb-select fullWidth id="selected-type" [(ngModel)]="meet.type" placeholder="Sélectionnez un type">
        <nb-option *ngFor="let type of meetTypes" [value]="type.value">{{ type.name }}</nb-option>
      </nb-select>
    </div>
    <div class="add-form-row">
      <label for="selected-users" style="width: 300px;">Invited Members : </label>
      <nb-select fullWidth class="col-sm-9" id="selected-users" [(ngModel)]="meet.participant" multiple style="padding: 0; max-width: 60%;">
        <nb-option *ngFor="let user of projectMembers" [value]="user">{{ user }}</nb-option>
      </nb-select>
    </div>
    <div class="add-form-row">
      <label style="width: 300px;">Start Date : </label>
      <input nbInput fullWidth placeholder="Pick Date" [nbDatepicker]="startDatePicker" [(ngModel)]="meet.dateStart" style="max-width: 60%;">
      <nb-date-timepicker withSeconds #startDatePicker></nb-date-timepicker>
    </div>
    <div class="add-form-row">
      <label style="width: 300px;">End Date : </label>
      <input nbInput fullWidth placeholder="Pick Date" [nbDatepicker]="endDatePicker" [(ngModel)]="meet.dateEnd" style="max-width: 60%;">
      <nb-date-timepicker withSeconds #endDatePicker></nb-date-timepicker>
    </div>
    <div class="add-form-row">
      <label for="repeat-day" style="width: 300px;">Repeat Day : </label>
      <nb-select fullWidth class="col-sm-9" id="repeat-day" [(ngModel)]="meet.repeatDays" multiple style="padding: 0; max-width: 60%;">
        <nb-option *ngFor="let day of repeatDays" [value]="day">{{ day }}</nb-option>
      </nb-select>
    </div>
    <div class="add-form-row">
      <label for="duration" style="width: 300px;">Duration : </label>
      <nb-select fullWidth class="col-sm-9" id="duration" [(ngModel)]="meet.duration" style="padding: 0; max-width: 60%;">
        <nb-option *ngFor="let duration of meetDurations" [value]="duration">{{ duration }}</nb-option>
      </nb-select>
    </div>
    <div class="add-form-row">
      <label for="meeting-lien" style="width: 300px;">Link : </label>
      <input nbInput fullWidth id="meeting-lien" required [(ngModel)]="meet.lien" placeholder="Lien" style="max-width: 60%;"/>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="success" (click)="createMeeting()">Save</button>
  </nb-card-footer>
</nb-card>
