
 <nb-card class="agenda-card" *ngFor="let minute of minutes" >
    <nb-card-header class="agenda-card-header">
      <div class="row">
        <div class="col-10">
          <h2>{{ minute.agendaName }}</h2>
          <div>
            <h2>{{ minute.date | date: 'fullDate' }} at {{ minute.time }} on {{ minute.location }} </h2>
          </div>
        </div>
        <div class="col-2">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
            <nb-icon icon="more-horizontal-outline"></nb-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a [routerLink]="['/projectManagement/minutess', minute.id]" style="text-decoration: dotted;">
              <button mat-menu-item>
                <span><nb-icon class="text-info" icon="eye-outline"></nb-icon> Details</span>
              </button>
            </a>
            <!-- <a style="text-decoration: dotted;">
              <button mat-menu-item>
                <span><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
              </button>
            </a> -->
            <a (click)="onArchiveClick(minute.id, $event)">
              <button mat-menu-item>
                <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon> Archive</span>
              </button>
            </a>
          </mat-menu>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body class="agenda-card-body">
      <div class="container-fluid text-center">
        <div class="row">
          <div class="col">
            <div class="table-container">
              <nb-table class="agenda-table">
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Agenda Item</th>
                    <th>Duration (mins)</th>
                    <th>Presenter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of minute.agendaItems; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                      <div><strong><u>{{ item.title }}</u></strong></div>
                      <ul>
                        <li>{{ item.agendaSubItem }}</li>
                      </ul>
                    </td>
                    <td>{{ item.duration }}</td>
                    <td>{{ item.presenter }}</td>
                  </tr>
                </tbody>
              </nb-table>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  
  <nb-select [(selected)]="filter" (selectedChange)="onFilterChange($event)">
    <nb-option [value]="false">Unarchived</nb-option>
    <nb-option [value]="true">Archived</nb-option>
  </nb-select>
  