<nb-card accent="info" status="info">
  <nb-card-header>
    <h2>Sprint Review for {{ selectedSprint.title }}</h2>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="selectedSprint.sprintReviews && selectedSprint.sprintReviews.length > 0; else noReviews" class="table-container">
      <table class="steelBlueCols">
        <thead>
          <tr>
            <th>Process</th>
            <th>Steps to Execute</th>
            <th>Expected Results</th>
            <th>Technical Constraints</th>
            <th>Pass</th>
            <th>Fail</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let review of selectedSprint.sprintReviews">
            <td>{{ review.process }}</td>
            <td>{{ review.stepsToExecute }}</td>
            <td>{{ review.expectedResults }}</td>
            <td>{{ review.technicalConstraints }}</td>
            <td>{{ review.pass }}</td>
            <td>{{ review.fail }}</td>
            <td>{{ review.comments }} added by {{ currentUser.email }}</td>
            <td>
              <div class="actions">
                <nb-icon icon="edit-outline" (click)="editReview(review)"></nb-icon>
                <nb-icon icon="trash-2-outline" (click)="deleteReview(review.id)"></nb-icon>
                <nb-icon
                  [icon]="showAddForm ? 'arrow-up-outline' : 'arrow-down-outline'"
                  (click)="addForm()"
                ></nb-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #noReviews>
      <div class ="row">
        <div class ="col">
      <p>No reviews available. Please add a review.</p>
      <nb-icon
                  [icon]="showAddForm ? 'arrow-up-outline' : 'arrow-down-outline'"
                  (click)="addForm()"
                ></nb-icon>
              </div>
            </div>
    </ng-template>
  </nb-card-body>
</nb-card>

<nb-card *ngIf="showAddForm || !selectedSprint.sprintReviews" accent="primary" status="warning">
  <nb-card-header>
    <h2>{{ sprintReviewForm.value.id ? 'Update' : 'Add' }} Sprint Review</h2>
  </nb-card-header>
  <nb-card-body>
    <form
      [formGroup]="sprintReviewForm"
      (ngSubmit)="sprintReviewForm.value.id ? updateReview() : addReview()"
    >
      <div class="form-group">
        <label>Process & Sub-process</label>
        <input nbInput fullWidth formControlName="process" />
      </div>
      <div class="form-group">
        <label>Steps to Execute</label>
        <textarea nbInput fullWidth formControlName="stepsToExecute"></textarea>
      </div>
      <div class="form-group">
        <label>Expected Results</label>
        <textarea nbInput fullWidth formControlName="expectedResults"></textarea>
      </div>
      <div class="form-group">
        <label>Technical Constraints</label>
        <textarea nbInput fullWidth formControlName="technicalConstraints"></textarea>
      </div>
      <div class="form-group">
        <nb-checkbox formControlName="pass">Pass</nb-checkbox>
        <nb-checkbox formControlName="fail">Fail</nb-checkbox>
      </div>
      <div class="form-group">
        <label>Comments</label>
        <textarea nbInput fullWidth formControlName="comments"></textarea>
      </div>
      <button nbButton status="primary" type="submit">
        {{ sprintReviewForm.value.id ? 'Update' : 'Add' }} Review
      </button>
    </form>
  </nb-card-body>
</nb-card>

