import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../../models/Project';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'ngx-lean-board',
  templateUrl: './lean-board.component.html',
  styleUrls: ['./lean-board.component.scss']
})
export class LeanBoardComponent implements OnInit{

  projectId: string;
  project: Project = new Project();

  constructor(private route: ActivatedRoute, private projectService: ProjectService) {}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.findProjectById();
      }
    });
  }
  findProjectById() {
    this.projectService.findProjectById(this.projectId).subscribe({
      next: (value: Project) => {
        this.project = value;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

}
