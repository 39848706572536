import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ProfileUserComponent} from './modules/profile-user/profile-user.component';
import {HomePageComponent} from './@theme/components/home-page/home-page.component';
import {AuthGuard} from './auth-guard/auth.guard';
import {Page404Component} from './@theme/components/page404/page404.component';
import {
  ConfirmationMailProfileComponent,
} from './modules/profile-user/confirmation-mail-profile/confirmation-mail-profile.component';
import {ModelsComponent} from './modules/all-modules/models.component';
export const routes: Routes = [
  {
    path: 'projectManagement',
    loadChildren: () =>
      import(
        './modules/project-management/project-management.module')
        .then((m) => m.ProjectManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module')
      .then(m => m.AuthModule),
  },
  

   {
    path: 'hr',
    loadChildren: () =>
      import(
        './modules/humain-capital-management/humain-capital-management.module')
        .then((m) => m.HumainCapitalManagementModule),
    canActivate: [AuthGuard],
  },
  
  {
    path: 'profile',
    component: ProfileUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation-mail',
    component: ConfirmationMailProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'models',
    component: ModelsComponent,
    canActivate: [AuthGuard],
  },

 
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard],
  },
  {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  {path: '**', pathMatch: 'full', component: Page404Component},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
