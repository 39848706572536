<div class="d-flex flex-column  mb-3 ">
  <div class="">
    <div>
      <h1>Meeting Calendar</h1>
      <div class=""></div>
      <div class="">

        <div class="w-100 d-flex justify-content-between">
          <button (click)="openDialog()" nbButton status="primary">
            <i class="fas fa-plus-circle text-white" style="margin-right: 2%;"></i>
            Add Single
          </button>
          <button (click)="openMultipleMeetDialog()" nbButton status="primary">
            <i class="fas fa-plus-circle text-white" style="margin-right: 2%;"></i>
            Add Multiple
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>



<div class="row text-center mt-3">
  <div class="col-md-4">
    <div class="btn-group">
      <button
        nbButton
        status="basic"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        <nb-icon icon="arrow-ios-back-outline"></nb-icon> Prev
      </button>
      &nbsp;
      <button
        nbButton
        status="primary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
        Today
      </button>
      &nbsp;
      <button
        nbButton
        status="basic"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        Next <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
      </button>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Month
      </div>
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Week
      </div>
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Day
      </div>
    </div>
  </div>
</div>
<br />
<nb-card>

  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
  <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
  >{{ day.badgeTotal }}</span
  >
      <span class="cal-day-number"
      >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
      >
    </div>
    <small style="margin: 5px" *ngIf="day.events.length !==0"
    >There are {{ day.events.length }} Meets on this day</small
    >
  </ng-template>
  <div [ngSwitch]="view" >
    <mwl-calendar-month-view
      style="color: black;"
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [cellTemplate]="customCellTemplate"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>



    <mwl-calendar-week-view
      style="color: black;"
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      style="color: black;"
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
</nb-card>
