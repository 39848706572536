import { Component } from '@angular/core';

@Component({
  selector: 'ngx-verify-data-quality',
  templateUrl: './verify-data-quality.component.html',
  styleUrls: ['./verify-data-quality.component.scss']
})
export class VerifyDataQualityComponent {

}
