<form class="form" [formGroup]="createProblematicForm" (ngSubmit)="create()">
    <div class="form-group">
    <label for="subject">Title:</label>
    <input nbInput fullWidth   class="form-control" type="text" formControlName="name" />
    <div *ngIf="name?.invalid && (name?.dirty || name?.touched)">
      <p class="text-danger" *ngIf="name?.errors?.required">*Required</p>
    </div>
  </div>
  <div class="form-group">
    <label class="text-label" for="text">Description:</label>
    <textarea nbInput fullWidth  class="form-control" formControlName="description"></textarea>
    <div *ngIf="description?.invalid && (description?.dirty || description?.touched)">
      <p class="text-danger" *ngIf="description?.errors?.required">*Required</p>
    </div>
  </div>
    <input
      [ngClass]="projectChart?.problematic===null ?'btn btn-success btnCreate':'btn btn-warning btnCreate'"
      type="submit"
      [value]="projectChart?.problematic===null ? 'Create' : 'Update'"
      [disabled]="createProblematicForm.invalid"

    />
   
  </form>
  