
  <div class="row">
    <div class="col-6 col-sm-4">
     
      <div class="card l-bg-green-dark">
        <div class="card-statistic-3 p-4">
            <div class="card-icon card-icon-large"><i class="fas fa-award"></i></div>
            <div class="mb-4">
                <h5 class="card-title mb-0">Goals</h5>
            </div>
            <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      {{ goals }} 
                    </h2>
                </div>
                
            </div>
          
        </div>
    </div>
    </div>
    <div class="col-6 col-sm-4">
   
      <div class="card l-bg-blue-dark">
        <div class="card-statistic-3 p-4">
            <div class="card-icon card-icon-large"><i class="fas fa-layer-group"></i></div>
            <div class="mb-4">
                <h5 class="card-title mb-0">Delivery</h5>
            </div>
            <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      {{ deliveryUnits }} 
                    </h2>
                </div>
                
            </div>
          
        </div>
    </div>
    </div>
    <div class="col-6 col-sm-4">
      
      <div class="card l-bg-orange-dark">
        <div class="card-statistic-3 p-4">
            <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
            <div class="mb-4">
                <h5 class="card-title mb-0">Budget</h5>
            </div>
            <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      {{ budget }} $
                    </h2>
                </div>
                
            </div>
          
        </div>
    </div>
    </div>
  </div>

