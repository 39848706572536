<nb-card class="documentation-card">
  <nb-card-header class="header-container" style="padding: 4%">
    <h3>Lean Manufacturing Documentation</h3>
  </nb-card-header>
  <nb-card-body class="documentation-body">
    <h2>Lean Process Definition</h2>
    <ul>
      Lean manufacturing is a systematic approach to process improvement that aims to maximize value while minimizing
      waste.
      The core principles of lean manufacturing revolve around identifying and eliminating activities that do not add
      value to the final product or service, thus optimizing the entire value chain.
    </ul>

    <h2>Project board</h2>
    <ul>
      A project board is a visual management tool that uses visual cues, such as cards, to signal the flow of work
      within a process.
      The project board is typically set up on a physical board in a team's workspace or can be represented digitally
      through project management tools or software.
      The main purpose of the project board is to visualize the workflow and make it easy for the team and stakeholders
      to understand the current state of the project.
    </ul>

    <ul>
      Key Components of a Scrum Project Board:

      <li>Columns:</li>
      <ul>The project board is divided into columns that represent the different stages or states of the work items.
      </ul>

      <li>Cards:</li>
      <ul>Cards are represented as sticky notes on the project board. Each card typically contains essential information
        about the work item, such as the title, description, assignee, and any other relevant details.</ul>

      <li>Movement of Work Items:</li>
      <ul>As work progresses, the team moves the work items across the columns on the project board to indicate their
        current status. The status is defined by the project method (Kanban, 5S, PDCA, VSM)</ul>

      <li>Visual Signals:</li>
      <ul>Project boards often use visual signals, such as colors or icons, to provide additional information about the
        work items. For instance, a color-coded label might indicate the priority of a task</ul>

    </ul>

    <h2>Types of meetings,</h2>
    <ul>
      In a Lean manufacturing project, various types of meetings are conducted to facilitate communication,
      collaboration, problem-solving, and continuous improvement.
    </ul>
    <ul>
      <li>Planning Meeting (Catchball Sessions):</li>
      <ul>Catchball is a communication technique where ideas, goals, and plans are bounced back and forth between
        different levels of the organization. It promotes collaboration, alignment, and consensus-building by involving
        various stakeholders in decision-making.</ul>
      <li>Daily Stand-Up Meetings (Daily Huddle):</li>
      <ul>These short, daily meetings bring together team members to discuss progress, identify any obstacles, and plan
        the day's activities. The focus is on quick information sharing, problem-solving, and ensuring that the team is
        aligned and focused on priorities.</ul>
      <li>Project Review Meeting:</li>
      <ul>These meetings review the progress of ongoing projects against set objectives, milestones, and performance
        metrics. They provide a platform for identifying any deviations from the plan and adjusting strategies
        accordingly.</ul>
      <li>Retrospective Meeting and Lessons Learned :</li>
      <ul>At the end of a project or a specific phase, teams hold retrospective meetings to reflect on what went well,
        what could be improved, and what lessons were learned. These meetings feed into continuous improvement efforts.
      </ul>
      <li>Gemba Walks and Refinement Meeting:</li>
      <ul>Regular Gemba walks involve managers and leaders visiting the actual work area to observe processes and engage
        with employees. These interactions create opportunities for refinement discussions on the spot, based on
        real-time observations.
      </ul>
    </ul>
  </nb-card-body>
</nb-card>
