<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirm your action</nb-card-header>
    <nb-card-body>{{ data }}</nb-card-body>
    <nb-card-footer>
      <div class="w-100 d-flex justify-content-between ">
        <button nbButton status="primary" (click)="ref.close(); onCardDelete(item.id)">Confirm</button>
        <button nbButton (click)="ref.close()">Cancel</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
<ng-template #cardInfo let-data let-info="dialogRef">
  <nb-card class="card-info">
    <nb-card-header>
      <div>
        <div class="d-flex align-items-center justify-content-between text-textDarkest w-100">
          <nb-form-field>
            <nb-icon nbPrefix [icon]="getIcon()"></nb-icon>
            <nb-select
              [hidden]="item.type === Type.STORY"
              placeholder="Type"
              [size]="'small'"
              hero
              [status]="getStatus()"
              shape="semi-round"
              (selectedChange)="changeItemType($event)"
              [(selected)]="item.type">
<!--              <nb-option [value]="Type.STORY" style="padding: 5%;" [hidden]="item.type === Type.STORY">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="Bookmark"-->
<!--                     style="width: 20px;height: auto;margin-right: 10%;">-->
<!--                  <path fill="#b3eaff"-->
<!--                        d="M23,4H9A1,1,0,0,0,8,5V27a1,1,0,0,0,.62.92,1,1,0,0,0,1.09-.21L16,21.41l6.29,6.3A1,1,0,0,0,23,28a.84.84,0,0,0,.38-.08A1,1,0,0,0,24,27V5A1,1,0,0,0,23,4Z"-->
<!--                        class="color8c9eff svgShape"></path>-->
<!--                  <path fill="#38b8ea"-->
<!--                        d="M23,28a.84.84,0,0,0,.38-.08A1,1,0,0,0,24,27V5a1,1,0,0,0-1-1H16V21.41l6.29,6.3A1,1,0,0,0,23,28Z"-->
<!--                        class="color5f7cf9 svgShape"></path>-->
<!--                </svg>-->
<!--                STORY-->
<!--              </nb-option>-->
              <nb-option [value]="Type.TASK" style="padding: 5%;" [hidden]="item.type === Type.TASK">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" id="Task"
                     style="width: 20px;height: auto;margin-right: 10%;">
                  <path d="M0 0v7h7V3.41l-1 1V6H1V1h3.59l1-1H0zm7 0L4 3 3 2 2 3l2 2 4-4-1-1z" fill="#0eda84"
                        class="color000000 svgShape"></path>
                </svg>
                TASK
              </nb-option>
              <nb-option [value]="Type.ISSUE" style="padding: 5%;" [hidden]="item.type === Type.ISSUE">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" id="Issue"
                     style="width: 20px;height: auto;margin-right: 10%;">
                  <path fill="#da0d51" fill-rule="evenodd"
                        d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                        class="color000000 svgShape">
                  </path>
                </svg>
                ISSUE
              </nb-option>
            </nb-select>
          </nb-form-field>

          <div class="d-flex justify-content-end w-25">
            <nb-icon icon="trash-outline" class="card-options" (click)="info.close(); deleteConfirm(dialog)"></nb-icon>
            <nb-icon icon="close-outline" class="card-options" style="margin-left: 5px"
                     (click)="info.close()"></nb-icon>
          </div>
        </div>
        <div class="d-flex w-100 flex-wrap pb-16">
          <textarea class="textarea-inline-control form-control"
                    [ngClass]="{
                            'card-name-light-mode': themeService.currentTheme === 'default' || themeService.currentTheme === 'corporate',
                            'card-name-dark-mode': themeService.currentTheme === 'dark' || themeService.currentTheme === 'cosmic'}"
                    cdkTextareaAutosize
                    [(ngModel)]="item.title"
                    (blur)="onTitleChange($event)"
                    [rows]="1">
            </textarea>
        </div>
      </div>

    </nb-card-header>
    <nb-card-body>
      <div class="card-content">
        <div style="width: 70%">
          <div>
            <p class="titles">
              Description
            </p>
            <ng-container *ngIf="isEditing; else readonlyModeTmpl">
              <quill-editor class="content-editor"
                            [modules]="editorOptions"
                            [(ngModel)]="descriptioninput"
                            [placeholder]="''"
                            (onEditorCreated)="editorCreated($event)"
                            [ngClass]="{
                            'editor-light-mode': themeService.currentTheme === 'default' || themeService.currentTheme === 'corporate',
                            'editor-dark-mode': themeService.currentTheme === 'dark',
                            'editor-cosmic-mode': themeService.currentTheme === 'cosmic'
                            }">
              </quill-editor>

              <div class="d-flex align-items-center">
                <button class="editor-btn" nbButton status="primary" (click)="save()">Save</button>
                <button class="editor-btn" nbButton (click)="cancel()">Cancel</button>
              </div>
            </ng-container>
            <ng-template #readonlyModeTmpl>
              <div class="ql-snow content-readonly" [ngClass]="{
                            'content-readonly-light-mode': themeService.currentTheme === 'default' || themeService.currentTheme === 'corporate',
                            'content-readonly-dark-mode': themeService.currentTheme === 'dark',
                            'content-readonly-cosmic-mode': themeService.currentTheme === 'cosmic'
                            }"
                   *ngIf="!isEditing"
                   (click)="setEditMode(true)">
                <div class="ql-editor"
                     [innerHTML]="item.description || 'Click to add description'"></div>
                <div class="icon-edit">
                  <nb-icon class="card-options" icon="edit-2-outline"></nb-icon>
                </div>
              </div>
            </ng-template>
          </div>
          <div style="margin-top: 3%;">
<!--            <p class="titles">Assignees</p>-->
            <div class="d-flex align-items-center mb-3">
              <!--              <nb-user [onlyPicture]="true"-->
              <!--                       [name]="userCon?.firstName"-->
              <!--                       [picture]="">-->
              <!--              </nb-user>-->
              <ngx-task-assignees [task]="item" style="width: 95%"></ngx-task-assignees>
            </div>
          </div>
          <div style="margin-top: 5%;">
            <nb-accordion *ngIf="item.checklists.length !== 0" style="width: 95%">
              <nb-accordion-item>
                <nb-accordion-item-header style="padding: 2%">
                  <p class="titles mb-0">Checklists</p>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div *ngFor="let list of item.checklists">
                    <ngx-task-checklist
                      [task]="item"
                      [list]="list"
                    ></ngx-task-checklist>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div>
            <p class="titles" style="margin-top: 8%;">Comments</p>
            <div>
              <!--new Comment-->
              <div class="d-flex">
                <nb-user [onlyPicture]="true"
                         [name]="userCon.firstName ? userCon.firstName : userCon.username"
                         [picture]="userCon.userImg">
                </nb-user>
                <textarea class="comment-input form-control"
                          [ngClass]="{
                            'comment-input-light-mode': themeService.currentTheme === 'default' || themeService.currentTheme === 'corporate',
                            'comment-input-dark-mode': themeService.currentTheme === 'dark',
                            'comment-input-cosmic-mode': themeService.currentTheme === 'cosmic'
                            }"
                          [(ngModel)]="commentText"
                          placeholder="Add a comment"
                          [rows]="1"
                          cdkTextareaAutosize
                          (click)="setCommentEdit(true)">
                </textarea>
              </div>
              <div *ngIf="isEditingComment" class="d-flex mt-2 ml-2">
                <button class="editor-btn" nbButton status="primary" (click)="addComment()">Save</button>
                <button class="editor-btn" nbButton (click)="cancelAddComment()">Cancel</button>
              </div>

              <!--  all comments-->
              <div *ngFor="let comment of item.comments">
                <div class="d-flex align-items-center mt-3">
                  <nb-user [onlyPicture]="false"
                           [name]="comment?.user?.firstName ? comment?.user?.firstName : comment?.user?.username"
                           [picture]="comment?.user?.userImg">
                  </nb-user>
<!--                  <span style="font-size: 14px;">{{ comment.user?.email}}</span>-->
                  <span style="font-size: 12px; margin-left: 5px; color: grey;">
                    {{ comment?.updatedAt | date:"medium" }}
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span style="font-size: 15px; margin-left: 10%;">{{ comment?.body}}</span>
                  <nb-icon *ngIf="userCon.id === comment?.user.id"
                    icon="close-outline" class="card-options" style="margin-right: 10%"
                    (click)="deleteCommentConfirm(commentDialog)"></nb-icon>
                </div>
                <ng-template #commentDialog let-data let-commentRef="dialogRef">
                  <nb-card>
                    <nb-card-header>Confirm your action</nb-card-header>
                    <nb-card-body>{{ data }}</nb-card-body>
                    <nb-card-footer>
                      <div class="w-100 d-flex justify-content-between ">
                        <button nbButton status="primary" (click)="commentRef.close(); deleteComment(comment.id)">Confirm</button>
                        <button nbButton (click)="commentRef.close()">Cancel</button>
                      </div>
                    </nb-card-footer>
                  </nb-card>
                </ng-template>
              </div>

            </div>
          </div>
        </div>
        <div>
          <div>
            <p class="titles">Priority</p>
            <nb-form-field>
              <nb-icon nbPrefix icon="arrow-upward-outline"></nb-icon>
              <nb-select
                style="width: 120px"
                placeholder="Priority"
                [size]="'small'"
                hero
                [status]="getPriorityStatus()"
                shape="semi-round"
                (selectedChange)="changeItemPriority($event)"
                [(selected)]="item.priority">
                <nb-option [value]="Priority.HIGH" style="padding: 5%;" [hidden]="item.priority === Priority.HIGH">
                  <nb-icon style="color: red" icon="arrow-upward-outline"></nb-icon>
                  HIGH
                </nb-option>
                <nb-option [value]="Priority.MEDIUM" style="padding: 5%;" [hidden]="item.priority === Priority.MEDIUM">
                  <nb-icon style="color: orange" icon="arrow-upward-outline"></nb-icon>
                  MEDIUM
                </nb-option>
                <nb-option [value]="Priority.LOW" style="padding: 5%;" [hidden]="item.priority === Priority.LOW">
                  <nb-icon style="color: limegreen" icon="arrow-upward-outline"></nb-icon>
                  LOW
                </nb-option>
              </nb-select>
            </nb-form-field>
          </div>
          <div style="margin-top: 15%;">
            <p class="titles">Dates</p>
            <input nbInput placeholder="Pick Date Range" [nbDatepicker]="formpicker" [(ngModel)]="date"
                   (ngModelChange)="onDateChanged()">
            <nb-rangepicker #formpicker></nb-rangepicker>
          </div>
          <div style="margin-top: 15%;">
            <div class="d-flex justify-content-between">
              <p class="titles">Checklist</p>
              <ngx-dialog (emitText)="onAddChecklist($event, item.id)" [question]="'Add items to ' + item.title">
                <nb-icon icon="plus-circle-outline" class="card-options"></nb-icon>
              </ngx-dialog>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex flex-column w-100">
        <span
          style="font-size: 11px; margin-left: 5px; color: grey;">Created At :<span> {{item.createdAt | date:"medium" }}</span></span>
        <span
          style="font-size: 11px; margin-left: 5px; color: grey;">Updated At :<span> {{item.updatedAt | date:"medium" }}</span></span>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
<div class="d-flex justify-content-between align-items-start mb-2">
  <div class="mt-1" style="font-size: 15px">{{ item?.title }}</div>

  <div ngbDropdown class="d-inline-block" container="body">

    <nb-icon class="card-options" ngbDropdownToggle icon="edit-2-outline"></nb-icon>

    <div ngbDropdownMenu aria-labelledby="card-options">
      <button ngbDropdownItem (click)="openDetails(cardInfo)">Edit</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem (click)="deleteConfirm(dialog)">Delete</button>
    </div>

  </div>
</div>


