import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Task} from '../../components/scrum-board/utils/models/task';
import {catchError, map, tap} from 'rxjs/operators';
import {Task as SprintTask} from '../../models/Task';


@Injectable({
  providedIn: 'root',
})
export class TaskService {
  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) { }
  addTask(task: SprintTask): Observable<Task> {
    return this.http.post<Task>(`${this.psUrl}task/addStory`, task);
  }

  deleteTask(id: string): Observable<string> {
    return this.http.delete<string>(`${this.psUrl}task/delete/${id}`);
  }
  getAllTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.psUrl}task/find-all`);
  }
  deleteTaskByTitle(taskTitle: string): Observable<string> {
    return this.http.delete<string>(`${this.psUrl}task/deleteTaskByTitle/${taskTitle}`);
  }

  findTaskIdByTitle(taskTitle: string): Observable<string> {
    return this.http.get(`${this.psUrl}task/findTaskIdByTitle/${taskTitle}`, { responseType: 'text' });
  }
}
