<nb-card>
  <div ngxFileDrop (onFileDrop)="onFileDrop($event)" [ngClass]="{ 'file-over': isHovering }" (onFileOver)="onFileOver($event)" (onFileLeave)="onFileLeave($event)">
    Drag and drop your CSV file here, or click to select a file
  </div>
  <input type="file" (change)="onFileChange($event)" />
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</nb-card>