export const quillConfiguration = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ color: [] }, { background: [] }],
    [{ 'font': [] }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};
