<nb-tabset class="tabset-container" fullWidth>
  <nb-tab tabTitle="Board" tabIcon="layout-outline" active="true" responsive>
    <ngx-lean-board></ngx-lean-board>
  </nb-tab>
  <nb-tab tabTitle="Calendar" tabIcon="calendar-outline" responsive>
    <ngx-calender></ngx-calender>
  </nb-tab>
  <nb-tab tabTitle="Meetings" tabIcon="video-outline" responsive>
    <ngx-jitsi></ngx-jitsi>
  </nb-tab>
  <nb-tab tabTitle="Documentation" tabIcon="book-outline" responsive>
    <ngx-lean-documentation></ngx-lean-documentation>
  </nb-tab>
</nb-tabset>
