import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
   ref: any;

  constructor() { }

  ngOnInit(): void {
  }

}
