import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Sprint} from '../../models/Sprint';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: `root`,
})
export class SprintService {
  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) { }
  addSprint(sprint: Sprint): Observable<Sprint> {
    return this.http.post<Sprint>(`${this.psUrl}sprint/addSprint`, sprint);
  }
  updateSprint(sprint: Sprint): Observable<Sprint> {
    return this.http.put<Sprint>(`${this.psUrl}sprint/updateSprint`, sprint, this.httpOptions);
  }

  deleteSprint(id: string): Observable<any> {
    return this.http.delete(`${this.psUrl}sprint/delete/${id}`, { observe: 'response' });
  }
  updateSprintStatusAsInProgress(id: string): Observable<Sprint> {
    return this.http.put<Sprint>(`${this.psUrl}sprint/updateSprintDoing/${id}`, {});
  }
  updateSprintStatusAsCompleted(id: string): Observable<Sprint> {
    return this.http.put<Sprint>(`${this.psUrl}sprint/updateSprintCompleted/${id}`, {});
  }
  getAllSprintsByStatus(status: string): Observable<Sprint[]> {
    return this.http.get<Sprint[]>(`${this.psUrl}sprint/allSprint/${status}`);
  }

  assignTaskToSprint(sprintId: string, taskId: string): Observable<void> {
    return this.http.post<void>(`${this.psUrl}sprint/assignTaskToSprint/${sprintId}/${taskId}`, {});
  }
  removeTaskFromSprint(sprintId: string, taskId: string): Observable<any> {
    const url = `${this.psUrl}sprint/removeTaskFromSprint/${sprintId}/${taskId}`;
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as const,
      responseType: 'text' as const,
    };

    return this.http.request('delete', url, options);
  }
  findAllSprintProject(projectId: string): Observable<Sprint[]> {
    return this.http.get<Sprint[]>(`${this.psUrl}sprint/all/${projectId}`);
  }

  findProjectById(id: string): Observable<Sprint> {
    return this.http.get<Sprint>(`${this.psUrl}sprint/findSprintById/${id}`);
  }
}
