import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Project } from "../../models/Project";
import { ProjectService } from "../../services/project/project.service";
import { NbGlobalPhysicalPosition, NbToastrService } from "@nebular/theme";
import { ScrumBoadService } from "../scrum-board/utils/services/scrum-boad.service";
import { Column } from "../scrum-board/utils/models/column.model";
import { concatMap } from "rxjs/operators";

@Component({
  selector: "ngx-lean-process",
  templateUrl: "./lean-process.component.html",
  styleUrls: ["./lean-process.component.scss"],
})
export class LeanProcessComponent implements OnInit {
  showTooltip = false;
  items: string[] = ["kanban", "5S", "PDCA", "VSM"];
  selectedItem: string;
  projectId: string;
  project: Project;
  positions = NbGlobalPhysicalPosition;
  column: Column = new Column();

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private toastrService: NbToastrService,
    private boardApi: ScrumBoadService
  ) {}

  ngOnInit(): void {
    console.log("lean choice");
    this.route.queryParams.subscribe((params) => {
      this.projectId = params["projectId"];
      if (this.projectId) {
        this.findProjectById();
      }
    });
  }
  chooseLeanMethod(method: string) {
    this.project.leanMethod = method;
    if (this.project) {
      this.updateProject();
      this.addProjectColumns(this.project.leanMethod);
      // this.router.navigate(['/projectManagement/project-details/' + this.projectId]);
      this.router.navigate(["/projectManagement/projects"]);
      this.showToast("Method saved", this.positions.TOP_RIGHT, "success");
    } else {
      this.showToast("Try Again", this.positions.TOP_RIGHT, "warning");
    }
  }
  findProjectById() {
    this.projectService.findProjectById(this.projectId).subscribe({
      next: (value: Project) => {
        this.project = value;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  updateProject() {
    this.projectService.updateProject(this.project).subscribe({
      next: (result: any) => {
        this.project = result;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {},
    });
  }
  addProjectColumns(leanMethod: String) {
    switch (leanMethod) {
      case "KANBAN": {
        console.log("add kanban ");
        const column1: any = {
          title: "TO DO",
          projectId: this.project.id,
          leanMethod: "KANBAN",
        };
        const column2: any = {
          title: "DOING",
          projectId: this.project.id,
          leanMethod: "KANBAN",
        };
        const column3: any = {
          title: "DONE",
          projectId: this.project.id,
          leanMethod: "KANBAN",
        };
        this.boardApi
          .addColumn(column1)
          .pipe(
            concatMap(() => this.boardApi.addColumn(column2)),
            concatMap(() => this.boardApi.addColumn(column3))
          )
          .subscribe(
            () => {
              console.log("All columns added successfully");
            },
            (error) => {
              console.error("An error occurred:", error);
            }
          );
        break;
      }
      case "PDCA": {
        console.log("add PDCA ");
        const column1: any = {
          title: "PLAN",
          projectId: this.project.id,
          leanMethod: "PDCA",
        };
        const column2: any = {
          title: "DO",
          projectId: this.project.id,
          leanMethod: "PDCA",
        };
        const column3: any = {
          title: "CHECK",
          projectId: this.project.id,
          leanMethod: "PDCA",
        };
        const column4: any = {
          title: "ACT",
          projectId: this.project.id,
          leanMethod: "PDCA",
        };
        this.boardApi
          .addColumn(column1)
          .pipe(
            concatMap(() => this.boardApi.addColumn(column2)),
            concatMap(() => this.boardApi.addColumn(column3)),
            concatMap(() => this.boardApi.addColumn(column4))
          )
          .subscribe(
            () => {
              console.log("All columns added successfully");
            },
            (error) => {
              console.error("An error occurred:", error);
            }
          );
        break;
      }
      case "5S": {
        console.log("add 5S ");
        const column1: any = {
          title: "1 - SORT",
          projectId: this.project.id,
          leanMethod: "5S",
        };
        const column2: any = {
          title: "2 - SET IN ORDER",
          projectId: this.project.id,
          leanMethod: "5S",
        };
        const column3: any = {
          title: "3 - SHINE",
          projectId: this.project.id,
          leanMethod: "5S",
        };
        const column4: any = {
          title: "4 - STANDARIZE",
          projectId: this.project.id,
          leanMethod: "5S",
        };
        const column5: any = {
          title: "5 - SUSTAIN",
          projectId: this.project.id,
          leanMethod: "5S",
        };
        this.boardApi
          .addColumn(column1)
          .pipe(
            concatMap(() => this.boardApi.addColumn(column2)),
            concatMap(() => this.boardApi.addColumn(column3)),
            concatMap(() => this.boardApi.addColumn(column4)),
            concatMap(() => this.boardApi.addColumn(column5))
          )
          .subscribe(
            () => {
              console.log("All columns added successfully");
            },
            (error) => {
              console.error("An error occurred:", error);
            }
          );
        break;
      }
      case "VSM": {
        console.log("add VSM ");
        const column1: any = {
          title: "1 - VSM SCOPE",
          projectId: this.project.id,
          leanMethod: "VSM",
        };
        const column2: any = {
          title: "2 - CURRENT STATE",
          projectId: this.project.id,
          leanMethod: "VSM",
        };
        const column3: any = {
          title: "3 - FUTURE STATE",
          projectId: this.project.id,
          leanMethod: "VSM",
        };
        const column4: any = {
          title: "4 - VALUE STREAM PLAN",
          projectId: this.project.id,
          leanMethod: "VSM",
        };
        const column5: any = {
          title: "5 - PLAN IMPLEMENTATION",
          projectId: this.project.id,
          leanMethod: "VSM",
        };
        this.boardApi
          .addColumn(column1)
          .pipe(
            concatMap(() => this.boardApi.addColumn(column2)),
            concatMap(() => this.boardApi.addColumn(column3)),
            concatMap(() => this.boardApi.addColumn(column4)),
            concatMap(() => this.boardApi.addColumn(column5))
          )
          .subscribe(
            () => {
              console.log("All columns added successfully");
            },
            (error) => {
              console.error("An error occurred:", error);
            }
          );
        break;
      }
      default: {
        console.log("lean method undefined");
        break;
      }
    }
  }

  showToast(message, position, status) {
    this.toastrService.show(status || "Success", message, { position, status });
  }
  toggleTooltip(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
  }
  closeTooltip() {
    this.showTooltip = false;
  }
}
