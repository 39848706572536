<div class="container-fluid">
  <button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button" (click)="toggleTooltip($event)">
    <mat-icon>help_outline</mat-icon>
  </button>
  <div cdkDropListGroup class="row board-content">

    <div class="col-sm-6 col-lg-3 mb-3" *ngFor="let column of boardService.board$.asObservable() | async">

      <!-- Column Title -->
      <div class="d-flex justify-content-between align-items-center p-2 shadow-sm column-header">
<!--        <h5 class="m-0 column-title">{{column.title}}</h5>-->
        <div class="d-flex w-100 flex-wrap pb-16">
          <!-- <textarea class="textarea-inline-control form-control"
                    [ngClass]="{
                            'column-name-light-mode': themeService.currentTheme === 'default' || themeService.currentTheme === 'corporate',
                            'column-name-dark-mode': themeService.currentTheme === 'dark' || themeService.currentTheme === 'cosmic'}"
                    cdkTextareaAutosize
                    [(ngModel)]="column.title"
                    (blur)="onTitleChange($event, column.id)"
                    [rows]="1">
            </textarea> -->
            <span class="w-100 text-center">{{column.title}}</span>
        </div>
<!--        <button mat-icon-button class="icon-delete" (click)="onDeleteColumn(column.id)">-->
<!--          <mat-icon style="font-size: 18px;">delete</mat-icon>-->
<!--        </button>-->

        <!-- <nb-icon icon="trash-outline" (click)="deleteConfirm(dialog, column.title)" ></nb-icon>
        <ng-template #dialog let-data let-ref="dialogRef">
          <nb-card>
            <nb-card-header>Confirm your action</nb-card-header>
            <nb-card-body>{{ data }}</nb-card-body>
            <nb-card-footer>
              <div class="w-100 d-flex justify-content-between ">
                <button nbButton status="primary" (click)="ref.close(); onDeleteColumn(column.id)">Confirm</button>
                <button nbButton (click)="ref.close()">Cancel</button>
              </div>
            </nb-card-footer>
          </nb-card>
        </ng-template> -->
      </div>

      <!-- Add New Card To Column -->
      <ngx-dialog (emitText)="onAddCard($event, column.id)" [question]="'Add new card in ' + column.title + ' list'">
        <button mat-raised-button class="w-100 mb-1 btn-add">+</button>
      </ngx-dialog>

      <!-- Color Panel -->
<!--      <ngx-color-panel (emitColor)="onColorChange($event, column.id)"></ngx-color-panel>-->

      <div cdkDropList [cdkDropListData]="column.list" class="example-list" (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of column.list">
          <nb-card  [status]="getStatusColor(item)" cdkDrag class="example-box" >
            <nb-card-header style="padding: 2%">
            </nb-card-header>
            <nb-card-body>
              <ngx-lean-board-item
                [item]="item"
                [project]="project"
                (emitText)="onAddComment($event, column.id)"
                (emitCardItem)="onChangeLike($event, column.id)"
                (emitDeleteCard)="onDeleteCard($event, column)"
              >
              </ngx-lean-board-item>
            </nb-card-body>
            <nb-card-footer style="padding: 2%" *ngIf="isDueSoon(item) || isPastDue(item)">
              <nb-badge *ngIf="isDueSoon(item)"
                        text="Due soon" status="warning" position="bottom right" style="margin: 4%; position: relative">
              </nb-badge>
              <nb-badge *ngIf="isPastDue(item)"
                        text="Past due" status="danger" position="bottom right" style="margin: 4%; position: relative">
              </nb-badge>
            </nb-card-footer>
          </nb-card>
        </div>
      </div>
    </div>
  </div>


  <div class="tooltip-card" [class.show]="showTooltip" style="background: transparent; border: none;">
    <nb-card>
      <nb-card-body>
        <button nbButton (click)="closeTooltip()" class="close-sticker">↩</button>
        <ul>A project board is a visual management tool used to track the progress of work items during the project work span. It provides a clear and transparent overview of the status of various tasks or items that the team is working on</ul>
      </nb-card-body>
    </nb-card>
  </div>
</div>
