import { Component } from '@angular/core';

@Component({
  selector: 'ngx-planning-poker',
  templateUrl: './planning-poker.component.html',
  styleUrls: ['./planning-poker.component.scss']
})
export class PlanningPokerComponent {

}
