<div class="d-flex flex-column">
<!--  <nb-select-->
<!--    style="width: 150px; margin-bottom: 3%; align-self: center"-->
<!--    placeholder="Sprints"-->
<!--    [size]="'medium'"-->
<!--    hero-->
<!--    shape="rectangle"-->
<!--    (selectedChange)="selectSprint($event)"-->
<!--    [(selected)]="selectedSprint">-->
<!--    <nb-option [value]="sprint" style="padding: 5%;" *ngFor="let sprint of sprints">-->
<!--      {{sprint.title}}-->
<!--    </nb-option>-->
<!--  </nb-select>-->
  <ngx-board-header></ngx-board-header>
  <ngx-board></ngx-board>
</div>

