import { Component } from '@angular/core';

@Component({
  selector: 'ngx-dm-goals',
  templateUrl: './dm-goals.component.html',
  styleUrls: ['./dm-goals.component.scss']
})
export class DMGoalsComponent {

}
