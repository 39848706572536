<ngx-list-item-added-step
[newItem]="[newItem]"
></ngx-list-item-added-step>
<form class="form" [formGroup]="activityStatusForm" (ngSubmit)="save()">
  <div class="form-group">
    <label>Title:</label>
    <input
      nbInput
      fullWidth
      class="form-control"
      type="text"
      formControlName="title"
      (change)="titleIsValid()"
    />
  </div>

  <input class="btn btn-success btnCreate" value="Create" type="submit"  [disabled]="activityStatusForm.invalid || titleIsValid()"/>
</form>
