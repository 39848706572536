import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-lean-documentation',
  templateUrl: './lean-documentation.component.html',
  styleUrls: ['./lean-documentation.component.scss']
})
export class LeanDocumentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
