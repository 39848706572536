
<nb-card>
    <nb-card-header>
        <h6>Risk Assessement Matrix</h6>
    </nb-card-header>
    <nb-card-body>
        <img
        src="https://firebasestorage.googleapis.com/v0/b/upload-file-manazello.appspot.com/o/image-13-removebg-preview.png0.12420686445111362[object%20File]?alt=media"
        alt="Risk Assessement Matrix"
        class="img-fluid"
        alt="Responsive image"
        />
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="ref.close()">CLose</button>
      </nb-card-footer>
</nb-card>

