import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {NbDialogService, NbThemeService} from '@nebular/theme';
import {Checklist, Priority, Task, Type} from '../../utils/models/task';
import {Column, Comment, User} from '../../utils/models/column.model';
import {quillConfiguration} from '../../utils/models/editor';
import {FormControl} from '@angular/forms';
import {TokenStorageService} from '../../../../../auth/service/token/token.service';
import {EmployeeService} from '../../../../../humain-capital-management/services/employeeServices/employee.service';
import {BoardService} from '../../utils/services/board.service';
import {Auth} from '../../../../../auth/model/Auth';
import {ScrumBoadService} from '../../utils/services/scrum-boad.service';
import {Employee} from '../../../../../humain-capital-management/models/Employee';

@Component({
  selector: 'ngx-board-item',
  templateUrl: './board-item.component.html',
  styleUrls: ['./board-item.component.scss'],
})
export class BoardItemComponent implements OnInit {
  @Input() item: Task;
  @Output() emitText: EventEmitter<{ id: string; text: string }> = new EventEmitter();
  @Output() emitCardItem: EventEmitter<{ card: any; increase: boolean }> = new EventEmitter();
  @Output() emitDeleteCard: EventEmitter<string> = new EventEmitter();
  protected readonly Type = Type;
  protected readonly Priority = Priority;
  editorOptions = quillConfiguration;
  isEditing: boolean = false;
  isEditingComment: boolean ;
  isWorking: boolean;
  descriptioninput = '';
  userPictureOnly: boolean = false;
  userCon: User = new User();
  userConImg: any;
  commentControl: FormControl;
  newComment: Comment = new Comment();
  newChecklist: Checklist = new Checklist();
  commentText = '';
  commentInput = '';
  open = false;
  date = {
    start : new Date,
    end : new Date,
  };
  constructor(private dialogService: NbDialogService, private tokenStorageService: TokenStorageService,
              private emplService: EmployeeService, private boardService: BoardService,
              public themeService: NbThemeService, private boardApi: ScrumBoadService) {

  }
  ngOnInit() {
    this.date.start = this.item.startDate;
    this.date.end = this.item.dueDate;
    this.userCon = this.tokenStorageService.getUser();
    this.emplService.getemployeebyuser(this.userCon.id).subscribe((employee: Employee) => {
      this.userCon.firstName = employee.employeeName;
      this.userCon.userImg = this.getImageUser(this.userCon.id);
    },
    (err) => {
      console.log(err);
      this.userCon.firstName = this.userCon.username;
    });
    if (this.userCon.role === 'ROLE_SUPER_ADMIN') {
      this.userCon.userImg = 'assets/images/logo/Manajero-logo.png';
    }
  }
  setCommentEdit(mode: boolean) {
    this.isEditingComment = mode;
    console.log(this.date);
  }

  onOpenComment() {
    this.open = !this.open;
  }

  onCommentTextEmit(id: string) {
    this.emitText.emit({id, text: this.commentInput});
    this.commentInput = '';
  }

  onCardItemEmit(card: any, increase: boolean) {
    this.emitCardItem.emit({card, increase});
  }

  onCardDelete(id: string) {
    this.emitDeleteCard.emit(id);
  }

  deleteConfirm(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {context: 'Are you sure you want to delete this card ?'});
  }
  openDetails(dialog: TemplateRef<any>) {
    this.descriptioninput = this.item.description;
    this.dialogService.open(dialog, {context: 'test'});
  }
  // Card details
  onTitleChange(event: any) {
    this.item.title = event.target.value;
    this.boardApi.updateTask(this.item).subscribe();
  }
  // type
  changeItemType(event) {
    console.log(event);
    this.item.type = event;
    console.log(this.item.type);
    this.boardApi.updateTask(this.item).subscribe();
  }
  getStatus(): string {
    return (this.item?.type?.toString() === 'STORY' || this.item?.type === Type.STORY.valueOf() ) ? 'info'
      : ((this.item?.type?.toString() === 'TASK' || this.item?.type === Type.TASK.valueOf()) ? 'success'
        : ((this.item?.type?.toString() === 'ISSUE' || this.item?.type === Type.ISSUE.valueOf()) ? 'danger'
          : ''));
  }
  getIcon(): string {
    return (this.item?.type?.toString() === 'STORY' || this.item?.type === Type.STORY.valueOf() ) ? 'bookmark-outline'
      : ((this.item?.type?.toString() === 'TASK' || this.item?.type === Type.TASK.valueOf()) ? 'checkmark-square-outline'
        : ((this.item?.type?.toString() === 'ISSUE' || this.item?.type === Type.ISSUE.valueOf()) ? 'alert-circle-outline'
          : ''));
  }
  getSelectedType(item: Task): Type {
    return item.type?.toString() === 'TASK' ? Type.TASK
      : (item.type?.toString() === 'STORY' ? Type.STORY
        : (item.type?.toString() === 'ISSUE' ? Type.ISSUE
          : item.type));
  }
  // Description
  setEditMode(mode: boolean) {
    this.isEditing = mode;
  }
  editorCreated(editor: any) {
    if (editor && editor.focus) {
      editor.focus();
    }
  }
  save() {
    this.item.description = this.descriptioninput;
    this.boardApi.updateTask(this.item).subscribe();
    this.setEditMode(false);
  }
  cancel() {
    this.descriptioninput = this.item.description;
    this.setEditMode(false);
  }
  // Priority
  changeItemPriority(event) {
    this.item.priority = event;
    this.boardApi.updateTask(this.item).subscribe();
  }
  getPriorityStatus(): string {
    return this.item && (this.item?.priority?.toString() === 'HIGH' || this.item?.priority === Priority.HIGH.valueOf() ) ? 'danger'
      : ((this.item?.priority?.toString() === 'MEDIUM' || this.item?.priority === Priority.MEDIUM.valueOf()) ? 'warning'
        : ((this.item?.priority?.toString() === 'LOW' || this.item?.priority === Priority.LOW.valueOf()) ? 'success'
          : ''));
  }
  // Comment
  getImageUser(id: string): string {
    let img: any;
    this.emplService.getImageEmployee(id).subscribe(response => {
      const fileBlob = response.body;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        img = e.target.result;
      };
      reader.readAsDataURL(fileBlob);
    });
    return img;
  }
  addComment() {
    this.newComment = new Comment();
    this.newComment.body = this.commentText;
    this.newComment.createdAt = new Date().toISOString();
    this.newComment.updatedAt = new Date().toISOString();
    this.newComment.taskId = this.item.id;
    this.newComment.user = this.userCon;
    this.boardApi.addComment(this.newComment, this.item.id).subscribe((res: Comment) => {
      this.newComment = res;
      this.item.comments = [this.newComment, ...this.item.comments];
    });
    this.boardService.board$.next([...this.boardService.board]);
    // this.boardService.addComment('', this.item.id, this.commentText);
    this.commentText = '';
    this.setCommentEdit(false);
  }

  cancelAddComment() {
    this.commentText = '';
    this.setCommentEdit(false);
  }
  deleteCommentConfirm(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {context: 'Are you sure you want to delete this comment ?'});
  }
  deleteComment(commentId: string) {
    this.boardApi.deleteComment(commentId).subscribe(res => {
      if (res) {
        console.log(res);
        this.boardService.board.map(col => {
          col.list.map(task => {
            task.comments = task.comments.filter(comment => comment.id !== commentId);
          });
        });
        this.boardService.board$.next([...this.boardService.board]);
      }
    });
  }

  onAddChecklist(name: string, cardId: string) {
    if (name) {
      // this.boardService.addChecklist(cardId, name);
      this.newChecklist = new Checklist();
      this.newChecklist.name = name;
      this.newChecklist.taskId = cardId;
      this.boardApi.addChecklist(this.newChecklist, cardId).subscribe(res => {
        this.newChecklist = res;
        this.boardService.board.map(col => {
          col.list.map(task => {
            task.checklists = task.id === cardId ? [...task.checklists, this.newChecklist] : [...task.checklists] ;
          });
          this.boardService.board$.next([...this.boardService.board]);
        });
      });
    }
  }
  onChecklistDelete(checklistId: string) {
    console.log('');
  }
  onAddItem(name: string, cardId: string, listId: string) {
    if (name) {
      this.boardService.addItem(cardId, listId, name);
    }
  }
  onChecklistNameChange(event: any, listId: string) {
    this.item.checklists.map((list: Checklist) => {
      if (list.id === listId) {
        list.name = event.target.value;
      }
    });
  }
  toggle(isChecked: boolean) {
    console.log('');
  }
  onDateChanged() {
    this.item.startDate = this.date.start;
    this.item.dueDate = this.date.end;
    this.boardApi.updateTask(this.item).subscribe();
  }
}
