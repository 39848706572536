import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-statistical-more-info',
  templateUrl: './statistical-more-info.component.html',
  styleUrls: ['./statistical-more-info.component.scss'],
})
export class StatisticalMoreInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
