<nb-card class="agenda-card" *ngFor="let agenda of agendas" (click)="onCardClick(agenda.id)">
  <nb-card-header class="agenda-card-header"
  >
    <h2 >{{ agenda.agendaName }}</h2>
    <div>
      <h3><strong>{{ agenda.date | date: 'fullDate' }} at {{ agenda.time }} on {{ agenda.location }}</strong></h3>
    </div>
  </nb-card-header>
  <nb-card-body class="agenda-card-body">
    <div class="container-fluid text-center">
      <div class="row">
        <div class="col">

    <div class="table-container">
      <nb-table class="agenda-table">
        <thead>
          <tr>
            <th>N°</th>
            <th>Agenda Item</th>
            <th>Duration (mins)</th>
            <th>Presenter</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of agenda.agendaItems; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              <div><strong><u>{{ item.title }}</u></strong></div>
              <ul>
                <li>{{ item.agendaSubItem }}</li>
              </ul>
            </td>
            <td>{{ item.duration }}</td>
            <td>{{ item.presenter }}</td>
          </tr>
        </tbody>
        
      </nb-table>
    </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
