import { Component } from '@angular/core';

@Component({
  selector: 'ngx-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent {

}
