
<nb-card accent="success">
  <nb-card-header>
    <div class="row">
      <div class="col-10">
        <h3>Data Understanding Details</h3>
      </div>
      <div class="col-2">
      <button nbButton status="danger" shape="round" size="small" (click)="onDeleteDataDescriptions()">
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>
        </div>
  </nb-card-header>
  <nb-card-body>
    
<div *ngIf="project">
  
    <div *ngIf="dataDescriptions && dataDescriptions.length > 0">
      
      <angular2-smart-table [settings]="settings" [source]="dataDescriptions"></angular2-smart-table>
    </div>
    <div *ngIf="!dataDescriptions">Vous n'avez pas encore completé la phase Data Understanding</div>
  </div>
</nb-card-body>
</nb-card>

