import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MeetingMinutes } from 'app/modules/project-management/models/MeetingMinutes';
import { CalenderService } from 'app/modules/project-management/services/calender/calender.service';
import { NbToastrService } from '@nebular/theme';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-minutes-list',
  templateUrl: './minutes-list.component.html',
  styleUrls: ['./minutes-list.component.scss']
})
export class MinutesListComponent implements OnInit {
  minutes: MeetingMinutes[] = [];
  filter: boolean = false;
  search: string = '';
  constructor(
    private minutesService: CalenderService,
    private router: Router,
    private toastrService: NbToastrService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.loadMinutes();

  }
  loadMinutes(): void {
    this.minutesService.getAllMeetingMinutess(this.filter).subscribe(
      data => {
        this.minutes = data;
        console.log('meeting minutes fetched:', this.minutes);
      },
      error => {
        console.error('Error fetching minutes:', error);
      }
    );
  }
  onCardClick(id: string): void {
    this.router.navigate(['projectManagement/minutess', id]);
  }

  onDeleteClick(id: string, event: Event): void {
    event.stopPropagation(); // Prevent the card click event from firing
    if (confirm('Are you sure you want to delete this meeting minute?')) {
      this.minutesService.deleteMeetingMinutes(id).subscribe(
        () => {
          this.minutes = this.minutes.filter(minute => minute.id !== id);
          this.toastrService.success('Meeting minute deleted successfully', 'Success');
        },
        error => {
          console.error('Error deleting minute:', error);
          this.toastrService.danger('Failed to delete meeting minute', 'Error');
        }
      );
    }
  }
  onArchiveClick(id: string, event: Event): void {
     event.stopPropagation(); // Prevent the card click event from firing
    if (confirm('Are you sure you want to archive this meeting minute?')) {
      this.minutesService.archiveMeetingMinutes(id).subscribe(
        () => {
          this.loadMinutes();
          this.toastrService.success('Meeting minute archived successfully', 'Success');
        },
        error => {
          console.error('Error archiving minute:', error);
          this.toastrService.danger('Failed to archive meeting minute', 'Error');
        }
      );
    } 
  }

  onFilterChange(archived: boolean): void {
    this.filter = archived;
    this.loadMinutes();
  }
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.minutes, 'dataMeetingMinutes');
  }
}
