import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NbDialogService} from '@nebular/theme';
import {DatePipe} from '@angular/common';
import {TokenStorageService} from '../../../auth/service/token/token.service';
import {Project} from '../../models/Project';
import {truncate_with_ellipsis} from '../../utils/reutilizable-function';
import {SprintService} from '../../services/sprint/sprint.service';
import {adminAccess, superAccess} from '../../../auth/accessControle/accessControle';

@Component({
  selector: 'ngx-restrospectives',
  templateUrl: './restrospectives.component.html',
  styleUrls: ['./restrospectives.component.scss'],
})
export class RestrospectivesComponent implements OnInit {

  constructor(
    private sprintService: SprintService,
    private router: Router,
    private dialogService: NbDialogService,
    private datepipe: DatePipe,
    private tokenStorageService: TokenStorageService,
    private route: ActivatedRoute,
  ) {}
  projectId: string;
  list: Project[];
  currentUser = null;
  enabled: boolean = false;
  refDialog = null;
  selectedItem = null;
  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    if (!superAccess(this.currentUser) && !adminAccess(this.currentUser)) {this.settings.actions = true; }
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.getAllSprint();
      }
    });  }
  getAllSprint() {
    this.sprintService.findAllSprintProject(this.projectId).subscribe({
      next: (result: any) => {
        this.list = result?.filter((sprint: any) => sprint.status === 'completed');
        console.log(result);
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  settings: any = {
    actions: {add: false},
    mode: 'external',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-archive fa-sm"></i>',
      confirmDelete: true,
    },
    columns: {
      title: {
        title: 'Title',
        type: 'html',
        valuePrepareFunction: (title) => {
          return `<i class="fas fa-bookmark text-primary"></i> ${title}`;
        },
      },
      startDate: {
        title: 'Start Date',
        type: 'html',
        valuePrepareFunction: (startDate) => {
          const date = this.datepipe.transform(startDate, 'yyyy-MM-dd ');
          return `<i class="fas fa-calendar-alt text-success"></i> ${date}`;
        },
      },
      endDate: {
        title: 'End Date',
        type: 'html',
        valuePrepareFunction: (endDate) => {
          const date = this.datepipe.transform(endDate, 'yyyy-MM-dd ');
          return `<i class="fas fa-calendar-alt text-danger"></i> ${date}`;
        },
      },
      description: {
        title: 'description',
        type: 'string',
        valuePrepareFunction: (description) => {
          return (description != null) && truncate_with_ellipsis(description);
        },
        // },
        // restrospective: {
        //   title: 'restrospective',
        //   type: 'string',
        //   valuePrepareFunction: (restrospective) => {
        //     return (restrospective != null) && truncate_with_ellipsis(restrospective);
        //   },
      },
    },
  };

  onArchive(selectedItem: any) {

  }
  displayDeteails(event): void {
    this.router.navigate([
      '/projectManagement/sprint-details/' + event.data.id,
    ]);  }
  onUserRowSelect(event): void {
    this.router.navigate([
      '/projectManagement/sprint-details/' + event.data.id,
    ]);
  }

  confirmation(event, dialog: TemplateRef<any>) {
    this.selectedItem = event;

    this.refDialog = this.dialogService.open(dialog, {
      context: 'Are you sure to delete this item ?',
    });
  }
  filtre(event: any) {

  }

}
