import { Component } from '@angular/core';

@Component({
  selector: 'ngx-scrum-process',
  templateUrl: './scrum-process.component.html',
  styleUrls: ['./scrum-process.component.scss'],
})
export class ScrumProcessComponent  {

  constructor() { }


}
