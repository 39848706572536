import {Task} from './task';

export class Comment {
  id: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  taskId: string;
  // userId: string;
  // mapped to display by userId
  user: User;
}
export class User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  userImg: string;
}

export interface Card {
  id: string;
  text: string;
  like: number;
  comments: Comment[];
}

export class Column {
  id: string;
  title: string;
  color: string;
  list: Task[];
  sprintId: string;
  leanMethod: string;
  projectId: string;

}
export class Sprint {
  id: string;
  title: string;
  startDate: Date | null;
  endDate: Date | null;
  status: string;
  type: string;
  description: string;
  retrospectives: string;
  tasks: string[];
  collapsed: boolean;
  project:string;
  sprintReviews: SprintReview[]; // Add the sprintReviews field
  sprintRetrospective: SprintRetrospective;

  constructor() {
    this.tasks = [];
    this.sprintReviews = [];
  }
}

export class SprintReview {
  id: string;
  process : string;
  stepsToExecute:string;
  expectedResults:string;
  technicalConstraints:string;
  pass: boolean;
  fail: boolean;
  comments: string;
 
}
export interface SprintRetrospective {
  id: string | null;
  questions: Question[];
}
export interface Question {
  id?: string;
  userId?: string;
  title?: string;
  sprintRetrospective?: SprintRetrospective;
  answers?: Answer[];
}
export interface Answer {
  id?: string;
  title?: string;
  userId?: string;
  questionId?: String;
}