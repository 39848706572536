import { Column } from '../../scrum-board/utils/models/column.model';
import { BoardService } from '../../scrum-board/utils/services/board.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SprintService } from '../../../services/sprint/sprint.service';
import { ScrumBoadService } from '../../scrum-board/utils/services/scrum-boad.service';
import {Sprint} from '../../../models/Sprint';
import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'app/modules/project-management/models/Project';

@Component({
  selector: 'ngx-lean-board-header',
  templateUrl: './lean-board-header.component.html',
  styleUrls: ['./lean-board-header.component.scss']
})
export class LeanBoardHeaderComponent implements OnInit {

  @Input() project: Project;

  sprint: Sprint = new Sprint();
  newColumn: Column;
  selectedSprintId: string;
  constructor(
    public boardService: BoardService,
    private boardApi: ScrumBoadService,
    private router: Router,
    private route: ActivatedRoute,
    private sprintService: SprintService ,
  ) { }

  ngOnInit(): void {
    console.log('lean header');
    console.log(this.project);

  }

  // addColumn(event: string) {
  //   if (event) {
  //     // this.boardService.addColumn(event);
  //     this.newColumn = new Column();
  //     this.newColumn.title = event;
  //     this.newColumn.list = [];
  //     this.newColumn.sprintId = this.selectedSprintId;
  //     this.boardApi.addColumn(this.newColumn).subscribe((res: Column) => {
  //       console.log(res);
  //       this.boardService.board$.next([...this.boardService.board, this.newColumn]);
  //     });
  //   }
  // }

  navigateToProjectDetails() {
    // this.router.navigate([`/projectManagement/project-details/${this.project.id}`]);
    this.router.navigate([`/projectManagement/projects`]);
  }


}
