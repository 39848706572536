<nb-reveal-card [revealed]="flippedState" [showToggleButton]="false">
  <nb-card-front>
    <nb-card accent="basic" style="padding-bottom:5%">
      <nb-card-body>
        <nb-alert accent="{{ statusColor }}">
          <div class="row">
            <h6
              class="text-center"
              style="margin-bottom: 5%"
              class="col-md-6 offset-md-4"
            >
              Meeting Minutes General Information:
            </h6>
            
          </div>

          <div class="container row">
            <div class="col-md-6">
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="bookmark-outline" status="info"></nb-icon>
                  Agenda Name :
                </strong>
                {{ meetingMinutes.agendaName }}
              </p>
              
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="pricetags-outline" status="info"></nb-icon>
                  Location :
                </strong>
                {{ meetingMinutes.location }}
              </p>
            </div>
            
             <div class="col-md-6">
              <p>
                <strong class="text-secondary">
                  <nb-icon icon="calendar-outline" status="info"></nb-icon>
                  Date :
                </strong>
                {{ meetingMinutes.date | date: 'fullDate' }}
              </p>

              <p>
                <strong class="text-secondary"
                  ><em class="fas fa-clock" style="color: dodgerblue"></em>
                  Time :
                </strong>
                {{ meetingMinutes.time }}
              </p>
            </div> 
          </div>
        </nb-alert>
        <p class="center red"> {{meetingMinutes.description}}</p>
        <div style="position: absolute; top: 10px; right: 10px;">
          <ngx-pdf-minutes [meetingMinutess]="meetingMinutes"></ngx-pdf-minutes>
        </div>
      </nb-card-body>
      
    </nb-card>
   
  </nb-card-front>
 
  
</nb-reveal-card>
<nb-card class="agenda-card" style="padding-bottom:5%">
  
<nb-card class="agenda-card" id="agenda-card">
  <nb-card-header class="agenda-card-header">
    <h4>Participants</h4>
    
  </nb-card-header>
  <nb-card-body class="agenda-card-body">
    <!-- Participants Table -->
  
    <table class="agenda-table">
      <thead>
        <tr>
          <th scope="col">Name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let participant of meetingMinutes.participants">
          <td>{{ participant }}</td>
        </tr>
      </tbody>
    </table>
    <div style="margin-bottom: 20px;"></div>

    <div class="center red underline">Feuille de présence</div>
    <div style="margin-bottom: 20px;"></div>

    <p class="center red">•	Les personnes qui n'ont pas assisté à la réunion sont invités à envoyer un courriel de justification de leur absence à « l’email d’administration ».</p>
      <p class="center red">•	Les personnes présentes et absentes sont tenues de lire attentivement le procès-verbal pour approbation avant de l'archiver.
      </p>
      <div style="margin-bottom: 20px;"></div>
    <div style="margin-bottom: 40px;"></div>
<nb-card-body>
    <!-- Agenda Items and Action Items -->
    <div cdkDropListGroup>
      <div class="row">
        <div class="col-md-6" *ngFor="let agendaItem of meetingMinutes.agendaItems; let i = index">
          <nb-card>
            <nb-card-header>{{ agendaItem.title }}</nb-card-header>
            <nb-card-body>
              <table  class="agenda-table">
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Agenda Item</th>
                    <th>Duration (mins)</th>
                    <th>Presenter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>
                      <div><strong><u>{{ agendaItem.title }}</u></strong></div>
                      <ul>
                        <li>{{ agendaItem.agendaSubItem }}</li>
                      </ul>
                    </td>
                    <td>{{ agendaItem.duration }}</td>
                    <td>{{ agendaItem.presenter }}</td>
                  </tr>
                </tbody>
              </table>

              <table  class="agenda-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Responsible Person</th>
                    <th>Deadline</th>
                  </tr>
                </thead>
                <tbody
                  cdkDropList
                  [cdkDropListData]="agendaItem.actionItems || []"
                  [cdkDropListConnectedTo]="getConnectedDropListIds()"
                  (cdkDropListDropped)="drop($event, agendaItem)"
                  [id]="'agendaItemList-' + i"
                >
                  <ng-container *ngIf="agendaItem.actionItems && agendaItem.actionItems.length > 0; else noItems">
                    <tr *ngFor="let actionItem of agendaItem.actionItems" cdkDrag>
                      <td>{{ actionItem.title }}</td>
                      <td>{{ actionItem.description }}</td>
                      <td>{{ actionItem.responsiblePerson }}</td>
                      <td>{{ actionItem.deadline }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <ng-template #noItems>
                <tr class="empty-placeholder">
                  <td colspan="4">Drop items here</td>
                </tr>
              </ng-template>
            </nb-card-body>
          </nb-card>
        </div>
      </div>
    </div>
  </nb-card-body>
    <!-- Unassigned Action Items -->
    <h4>Unassigned Action Items</h4>
    <div cdkDropList class="unassigned-action-list"
         [cdkDropListData]="unassignedActionItems"
         [cdkDropListConnectedTo]="getConnectedDropListIds()"
         (cdkDropListDropped)="drop($event)"
         id="unassigned">
      <nb-card>
        <nb-card-header>Unassigned Action Items</nb-card-header>
        <nb-card-body>
          <table  class="agenda-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Responsible Person</th>
                <th>Deadline</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="unassignedActionItems.length > 0; else noUnassignedItems">
                <tr *ngFor="let actionItem of unassignedActionItems" cdkDrag>
                  <td>{{ actionItem.title }}</td>
                  <td>{{ actionItem.description }}</td>
                  <td>{{ actionItem.responsiblePerson }}</td>
                  <td>{{ actionItem.deadline }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ng-template #noUnassignedItems>
            <tr class="empty-placeholder">
              <td colspan="4">No unassigned items</td>
            </tr>
          </ng-template>
        </nb-card-body>
      </nb-card>
    </div>
    <!-- <button nbButton status="primary" (click)="saveAssignments()">Save Assignments</button> -->
  </nb-card-body>

  <p class="center red"> {{meetingMinutes.finalNotes}}</p>

</nb-card>
