import { Component, OnInit } from '@angular/core';
import {Sprint} from '../../utils/models/column.model';
import {ScrumBoadService} from '../../utils/services/scrum-boad.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'ngx-sprints-table',
  templateUrl: './sprints-table.component.html',
  styleUrls: ['./sprints-table.component.scss'],
})
export class SprintsTableComponent implements OnInit {
  archivedSprints: Sprint[] = [];
  selectedView: 'active' | 'archived' = 'active'; // Par défaut, affiche les sprints actifs

  activeSprints: Sprint[] = [];
  selectedSprint: Sprint;
  list: Sprint[] = [];
  projectId: string;
  settings = {
    mode: 'external',
    actions: { edit: false, delete : false, add : false },
    columns: {
      title: {
        title: 'Name',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      startDate: {
        title: 'Start Date',
        type: 'string',
      },
      endDate: {
        title: 'End Date',
        type: 'string',
      },
      status: {
        title: 'Status',
        type: 'html',
        valuePrepareFunction: (status) => {

          switch (status) {
            case 'notStarted': {
              return `<h5><span class="badge badge-info">Not Started </span></h5>`;
            }
            case 'completed': {
              return `<h5><span class="badge badge-success">Completed </span></h5>`;
            }
            case 'inProgress': {
              return `<h5><span class="badge badge-warning">In Progress </span></h5>`;
            }
            default: {
              return `<h5><span class="badge badge-basic"> </span></h5>`;
            }
          }
        },
      },
    },
  };
  constructor(private boardApi: ScrumBoadService,
              private router: Router,
              private route: ActivatedRoute,
              ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      if (this.projectId) {
        this.getSprints();
      }
    });
  }
  toggleArchived() {
    this.selectedView = this.selectedView === 'active' ? 'archived' : 'active';
  }
  getSprints() {
    this.boardApi.getAllSprintsByProject(this.projectId).subscribe(res => {
      this.list = res;
      this.filterSprints();
      console.log(res);
    });
  }

  filterSprints() {
    this.archivedSprints = this.list.filter(sprint => sprint.status === 'completed');
    this.activeSprints = this.list.filter(sprint => sprint.status !== 'completed');
  }
  onUserRowSelect(event): void {
    this.selectedSprint = event.data;
    console.log(this.selectedSprint);
    this.router.navigate(['/projectManagement/board', this.selectedSprint.id]);
  }
}
