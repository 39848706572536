import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Question, Answer } from '../../components/scrum-board/utils/models/column.model';

@Injectable({
  providedIn: 'root'
})
export class QasService {
  private baseUrl: string;

  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {
    this.baseUrl = environment.psUrl + 'qas';
  }
  ensureDefaultQuestions(defaultQuestions: Question[]): Observable<Question[]> {
    return this.http.post<Question[]>(`${this.baseUrl}/ensure-default-questions`, defaultQuestions);
  }
  getUsernameForQuestion(questionId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/questions/${questionId}/username`);
  }
  getUsernameForAnswer(answerId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/answers/${answerId}/username`);
  }

  getAllQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.baseUrl}/questions`);
  }

  getQuestionById(id: string): Observable<Question> {
    return this.http.get<Question>(`${this.baseUrl}/questions/${id}`);
  }

  createQuestion(sprintRetrospectiveId: string, question: Question, userId: string): Observable<Question> {
    return this.http.post<Question>(`${this.baseUrl}/questions/sprint/${sprintRetrospectiveId}`, { ...question, userId });
  }

  updateQuestion(id: string, questionDetails: Question): Observable<Question> {
    return this.http.put<Question>(`${this.baseUrl}/questions/${id}`, questionDetails);
  }

  deleteQuestion(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/questions/${id}`);
  }

  getAllAnswers(): Observable<Answer[]> {
    return this.http.get<Answer[]>(`${this.baseUrl}/answers`);
  }

  getAnswerById(id: string): Observable<Answer> {
    return this.http.get<Answer>(`${this.baseUrl}/answers/${id}`);
  }

  createAnswer(questionId: string, answer: Answer, userId: string): Observable<Answer> {
    return this.http.post<Answer>(`${this.baseUrl}/answers/question/${questionId}`, { ...answer, userId });
  }

  updateAnswer(id: string, answerDetails: Answer): Observable<Answer> {
    return this.http.put<Answer>(`${this.baseUrl}/answers/${id}`, answerDetails);
  }

  deleteAnswer(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/answers/${id}`);
  }
}
