<nb-tabset class="tabset-container" fullWidth>
  <nb-tab tabTitle="Dashboard" tabIcon="pie-chart-outline" responsive>
<ngx-dashboard></ngx-dashboard>
  </nb-tab>
  <nb-tab tabTitle="Sprints" tabIcon="layout-outline" tabId="backlog" active="true" responsive>
    <ngx-sprint-process></ngx-sprint-process>
  </nb-tab>
  <nb-tab tabTitle="Calendar" tabIcon="calendar-outline" responsive>
    <ngx-calender></ngx-calender>
  </nb-tab>
  <nb-tab tabTitle="Meetings" tabIcon="video-outline" responsive>
<ngx-jitsi></ngx-jitsi>
</nb-tab>
  <nb-tab tabTitle="Documentation" tabIcon="book-outline" responsive>
    <ngx-documentation></ngx-documentation>
  </nb-tab>
</nb-tabset>
