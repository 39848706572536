import { Component } from '@angular/core';

@Component({
  selector: 'ngx-retrospective-user',
  templateUrl: './retrospective-user.component.html',
  styleUrls: ['./retrospective-user.component.scss']
})
export class RetrospectiveUserComponent {

}
