import { Component } from '@angular/core';

@Component({
  selector: 'ngx-crisp-process',
  templateUrl: './crisp-process.component.html',
  styleUrls: ['./crisp-process.component.scss']
})
export class CrispProcessComponent {

}
