<nb-card>
    <nb-card-header> Ressource List 
    </nb-card-header>
  
    <nb-card-body>
      <angular2-smart-table
      class="order-table"
      [settings]="settings"
        [source]="resourceList"
      >
      </angular2-smart-table>
    </nb-card-body>
  </nb-card>
