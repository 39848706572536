<nb-card>
  <nb-card-body>
    <nb-stepper orientation="horizontal">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Goals</ng-template>

        <ngx-list-item-added-step
          [newItem]="[newItem]"
        ></ngx-list-item-added-step>
        <div>
          <ngx-goal-form (dataChanged)="refresh($event)"></ngx-goal-form>
        </div>
        <div class="mt-3">
          <button nbButton nbStepperPrevious style="float: left">prev</button>

          <button nbButton nbStepperNext style="float: right">next</button>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Activities</ng-template>
        <div>
          <ngx-activities-stepper></ngx-activities-stepper>
        </div>
        <div class="mt-3">
          <button nbButton nbStepperPrevious style="float: left">prev</button>

        </div>
      </nb-step>

    </nb-stepper>
  </nb-card-body>
</nb-card>
