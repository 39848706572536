import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ngx-collect-data',
  templateUrl: './collect-data.component.html',
  styleUrls: ['./collect-data.component.scss']
})
export class CollectDataComponent {
  public isHovering: boolean = false;
  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  constructor(private http: HttpClient) {}

  onFileDrop(event: any) {
    const file = event[0].fileEntry.file((file: File) => {
      this.uploadFile(file);
    });
  }

  onFileOver(event: any) {
    this.isHovering = true;
  }

  onFileLeave(event: any) {
    this.isHovering = false;
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    this.uploadFile(file);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    this.http.post<{ headers: string[], data: any[] }>('https://gateway.manajero.com/pm/api/csv/upload', formData).subscribe(response => {
      if (response && response.data.length > 0) {
        this.displayedColumns = response.headers;
        this.dataSource.data = response.data;
      }
    });
  }

}
