import { Component } from '@angular/core';

@Component({
  selector: 'ngx-business-understanding',
  templateUrl: './business-understanding.component.html',
  styleUrls: ['./business-understanding.component.scss']
})
export class BusinessUnderstandingComponent {

}
