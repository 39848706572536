import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {Column, Comment, Sprint, User} from '../models/column.model';
import {Checklist, Item, Priority, Task, Type} from '../models/task';
import {ScrumBoadService} from './scrum-boad.service';
import {TokenStorageService} from '../../../../../auth/service/token/token.service';
import {Employee} from '../../../../../humain-capital-management/models/Employee';
import {EmployeeService} from '../../../../../humain-capital-management/services/employeeServices/employee.service';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BoardService {

  // private initBoard = [
  //   {
  //     id: '1',
  //     title: 'Backlog',
  //     color: '#009886',
  //     list: [
  //       {
  //         id: '1',
  //         title: 'Scrum Board',
  //         comments: [
  //           {
  //             id: '1',
  //             body: 'testing comment',
  //             createdAt: new Date().toISOString(),
  //             updatedAt: new Date().toISOString(),
  //             taskId: '1',
  //             // userId: 'Bader Moussa',
  //             // mapped to display by userId
  //             user: new User(),
  //           },
  //         ],
  //         description: 'Progress bar, progress state, item name',
  //         type: Type.STORY,
  //         assignees: [],
  //         priority: Priority.HIGH,
  //         startDate: new Date(),
  //         dueDate: new Date(),
  //         createdAt: new Date(),
  //         updatedAt: new Date(),
  //         checklists: [],
  //         subTasks: [],
  //       },
  //     ],
  //   },
  //   {
  //     id: '2',
  //     title: 'To Do',
  //     color: '#009886',
  //     list: [],
  //   },
  //   {
  //     id: '3',
  //     title: 'Doing',
  //     color: '#009886',
  //     list: [],
  //   },
  //   {
  //     id: '4',
  //     title: 'Done',
  //     color: '#009886',
  //     list: [],
  //   },
  // ];
  private initBoard = [];
  public board: Column[] = this.initBoard;
  public board$ = new BehaviorSubject<Column[]>(this.initBoard);
  newColumn: Column = new Column();
  newCard: Task = new Task();
  newComment: Comment = new Comment();
  newChecklist: Checklist = new Checklist();
  newItem: Item = new Item();
  userCon: User = new User();
  constructor(private boardApi: ScrumBoadService, private tokenStorageService: TokenStorageService,
              private emplService: EmployeeService) {}

  getBoard$() {
    return this.board$.asObservable();
  }

  changeColumnColor(color: string, columnId: string) {
    this.board = this.board.map((column: Column) => {
      if (column.id === columnId) {
        column.color = color;
      }
      return column;
    });
    this.board$.next([...this.board]);
  }

  changeColumnTitle(title: string, columnId: string) {
    this.board = this.board.map((column: Column) => {
      if (column.id === columnId) {
        column.title = title;
        this.boardApi.updateColumn(column).subscribe(
          (res: Column) => {
            column = res;
          },
          (error) => {
            console.error('Error updating column:', error);
          });
      }
      return column;
    });
    this.board$.next([...this.board]);
  }

  addColumn(title: string) {
    this.newColumn.title = title;
    this.newColumn.list = [];
    this.boardApi.addColumn(this.newColumn).subscribe((res: Column) => {
      this.newColumn = res;
    });
    this.board = [...this.board, this.newColumn];
    this.board$.next([...this.board]);
  }

  deleteColumn(columnId) {
    this.boardApi.deleteColumn(columnId).subscribe(res => {
      this.board = res ? this.board.filter((column: Column) => column.id !== columnId) : this.board;
    });
    this.board$.next([...this.board]);
  }

  addCard(text: string, columnId: string) {

    this.newCard.title = text;
    this.newCard.comments = [];
    this.newCard.checklists = [];
    this.newCard.assignees = [];
    this.newCard.createdAt =  new Date();
    this.newCard.updatedAt =  new Date();
    this.board = this.board.map((column: Column) => {
      if (column.id === columnId) {

        this.boardApi.addTaskBoard(this.newCard, columnId).subscribe((res: Task) => {
          this.newCard = res;
          column.list = [...column.list, this.newCard];
        });
      }
      return column;
    });

    this.board$.next([...this.board]);
  }

  deleteCard(cardId: string, columnId: string) {
    this.board = this.board.map((column: Column) => {
      if (column.id === columnId) {
        this.boardApi.deleteTask(cardId).subscribe(res => {
          column.list = res ? column.list.filter((card: Task) => card.id !== cardId) : column.list;
        });
      }
      return column;
    });

    this.board$.next([...this.board]);
  }

  addComment(columnId: string, cardId: string, text: string) {
    this.board = this.board.map((column: Column) => {
      // if (column.id === columnId) {
        const list = column.list.map((card: Task) => {
          if (card.id === cardId) {
            // get connected user data
            this.userCon = this.tokenStorageService.getUser();
            this.emplService.getemployeebyuser(this.userCon.id).subscribe((employee: Employee) => {
              this.userCon.id = employee.id;
              this.userCon.username = employee.employeeName;
              this.userCon.firstName = employee.employeeName;
              this.userCon.email = employee.employeeEmail;
            });
            // add new comment
            this.newComment.body = text;
            this.newComment.createdAt = new Date().toISOString();
            this.newComment.updatedAt = new Date().toISOString();
            this.newComment.taskId = cardId;
            this.newComment.user = this.userCon;

            this.boardApi.addComment(this.newComment, cardId).subscribe((res: Comment) => {
              this.newComment = res;
              card.comments = [this.newComment, ...card.comments];
            });
          }
          return card;
        });

        column.list = list;
      // }
      return column;
    });

    this.board$.next([...this.board]);
  }

  deleteComment(columnId, itemId, commentId) {
    this.board = this.board.map((column: Column) => {
      if (column.id === columnId) {
        const list = column.list.map((item) => {
          if (item.id === itemId) {
            this.boardApi.deleteComment(commentId).subscribe(res => {
              item.comments = res ? item.comments.filter((comment: Comment) => {
                return comment.id !== commentId;
              }) : item.comments;
            });

          }
          return item;
        });
        column.list = list;
      }
      return column;
    });
    this.board$.next([...this.board]);
  }

  addChecklist(cardId: string, text: string) {
    this.board = this.board.map((column: Column) => {
      const list = column.list.map((card: Task) => {
        if (card.id === cardId) {
          const newChecklist = {
            id: 'C' + Date.now(),
            name : text,
            taskId : cardId,
            items: [],
            progress: 0,
          };
          card.checklists = [...card.checklists, newChecklist];
        }
        return card;
      });

      column.list = list;
      return column;
    });

    this.board$.next([...this.board]);
  }

  deleteChecklist(cardId: string, listId: string) {
    this.board = this.board.map((column: Column) => {
      const list = column.list.map((card: Task) => {
        if (card.id === cardId) {
          card.checklists = card.checklists.filter((checklist: Checklist) => {
            return checklist.id !== listId;
          });
        }
        return card;
      });

      column.list = list;
      return column;
    });

    this.board$.next([...this.board]);
  }

  addItem(cardId: string, listId: string, text: string) {
    this.board = this.board.map((column: Column) => {
      const list = column.list.map((card: Task) => {
        if (card.id === cardId) {
          const checklists = card.checklists.map((checklist: Checklist) => {
            if (checklist.id === listId) {
              const newItem: Item = {
                id: 'I' + Date.now(),
                name: text,
                status: false,
                checklistId : listId,
              };
              checklist.items = [...checklist.items, newItem];
            }
            return checklist;
          });
        }
        return card;
      });

      column.list = list;
      return column;
    });

    this.board$.next([...this.board]);
  }

  deleteItem(cardId: string, listId: string, itemId: string) {
    this.board = this.board.map((column: Column) => {
      const list = column.list.map((card: Task) => {
        if (card.id === cardId) {
          const checklists = card.checklists.map((checklist: Checklist) => {
            if (checklist.id === listId) {
              checklist.items = checklist.items.filter((item: Item) => {
                return item.id !== itemId;
              });
            }
            return checklist;
          });
        }
        return card;
      });

      column.list = list;
      return column;
    });

    this.board$.next([...this.board]);
  }

  changeLike(cardId: string, columnId: string, increase: boolean) {
    // this.board = this.board.map((column: Column) => {
    //   if (column.id === columnId) {
    //     const list = column.list.map((card: Task) => {
    //       if (card.id === cardId) {
    //         if (increase) {
    //           card.like++;
    //         } else {
    //           if (card.like > 0) {
    //             card.like--;
    //           }
    //         }
    //       }
    //       return card;
    //     });
    //
    //     column.list = list;
    //     return column;
    //   } else {
    //     return column;
    //   }
    // });
    //
    // this.board$.next([...this.board]);
  }
}
