import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Sprint } from "../../scrum-board/utils/models/column.model";
import { ScrumBoadService } from "../../scrum-board/utils/services/scrum-boad.service";
import { title } from "process";

@Component({
  selector: "ngx-redirectingtable",
  templateUrl: "./redirectingtable.component.html",
  styleUrls: ["./redirectingtable.component.scss"],
})
export class RedirectingtableComponent {
  flippedState: boolean = false;
  showTooltip =false;
  archivedSprints: Sprint[] = [];
  selectedView: "active" | "archived" = "active"; // Default to active sprints
  isClicked: boolean = false;
  activeSprints: Sprint[] = [];
  selectedSprint: Sprint;
  list: Sprint[] = [];
  projectId: string;
  settings = {
    mode: "external",
    actions: { edit: false, delete: false, add: false },
    columns: {
      title: {
        title: "Name",
        type: "string",
      },
      description: {
        title: "Description",
        type: "string",
      },
      startDate: {
        title: "Start Date",
        type: "string",
      },
      endDate: {
        title: "End Date",
        type: "string",
      },
      status: {
        title: "Status",
        type: "html",
        valuePrepareFunction: (status) => {
          switch (status) {
            case "notStarted": {
              return `<h5><span class="badge badge-info">Not Started</span></h5>`;
            }
            case "completed": {
              return `<h5><span class="badge badge-success">Completed</span></h5>`;
            }
            case "inProgress": {
              return `<h5><span class="badge badge-warning">In Progress</span></h5>`;
            }
            default: {
              return `<h5><span class="badge badge-basic"></span></h5>`;
            }
          }
        },
      },

  
    },
  };

  constructor(
    private boardApi: ScrumBoadService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.projectId = params["projectId"];
      if (this.projectId) {
        this.getSprints();
      }
    });
  }



  toggleArchived() {
    this.selectedView = this.selectedView === "active" ? "archived" : "active";
  }

  getSprints() {
    this.boardApi.getAllSprintsByProject(this.projectId).subscribe((res) => {
      this.list = res;
      this.filterSprints();
      console.log(res);
    });
  }

  filterSprints() {
    this.archivedSprints = this.list.filter(
      (sprint) => sprint.status === "completed"
    );
    this.activeSprints = this.list.filter(
      (sprint) => sprint.status !== "completed"
    );
  }

  onUserRowSelect(event): void {
    this.selectedSprint = event.data;
    console.log(this.selectedSprint);
    this.flippe();
    // this.router.navigate(['/projectManagement/sprint-review', this.selectedSprint.id]);
  }
  flippe(): void {
    this.flippedState = !this.flippedState;
  }
  toggleTooltip(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
  }
  closeTooltip() {
    this.showTooltip = false;
  }
}
