import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-crisp-details',
  templateUrl: './crisp-details.component.html',
  styleUrls: ['./crisp-details.component.scss']
})
export class CrispDetailsComponent {
  projectId : string;

  constructor(private route: ActivatedRoute,  private router: Router, 
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
    });}


  navigateBackToProjectList(): void {
    this.router.navigate(['/projectManagement/crisp-process'], { queryParams: { projectId: this.projectId } });
  }
}
