import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { ProjectService } from 'app/modules/project-management/services/project/project.service';
import { ScrumBoadService } from '../../scrum-board/utils/services/scrum-boad.service';
import { Project } from 'app/modules/project-management/models/Project';
import { CrispService } from 'app/modules/project-management/services/crisp.service';
import { DataDescription } from 'app/modules/project-management/models/DataDescription';

@Component({
  selector: 'ngx-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit{
  projectId: string;
  project: Project;
  businessUnderstanding: any;
  businessUnderstandingExists: boolean = false;
  dataDescriptionsExist: boolean = false;
  dataDescriptions: DataDescription[];
  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private toastrService: NbToastrService,
    private boardApi: ScrumBoadService,
    private crispService: CrispService
  ) {}
  ngOnInit(): void {
    console.log("crisp");
    this.route.queryParams.subscribe((params) => {
      this.projectId = params["projectId"];
      if (this.projectId) {
        this.findProjectById();
      }
    }); 
  this.loadBusinessUnderstanding();
  this.loadDataDescriptions();
 }
    findProjectById() {
      this.projectService.findProjectById(this.projectId).subscribe({
        next: (value: Project) => {
          this.project = value;
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
    stages = [
      {
        name: 'Business Understanding',
        tasks: [
          { name: 'Define Business Objectives', route: 'business-objectives' },
          { name: 'Assess Situation', route: 'assess-situation' },
          { name: 'Determine Data Mining Goals', route: 'data-mining-goals' },
          { name: 'Produce Project Plan', route: 'produce-project-plan' }
        ],
        expanded: true
      },
      {
        name: 'Data Understanding',
        tasks: [
          { name: 'Gather Initial Data', route: 'gather-data' },
          { name: 'Describe Data', route: 'describe-data' },
          { name: 'Explore Data', route: 'explore-data' },
          { name: 'Verify Data Quality', route: 'verify-data-quality' }
        ],
        expanded: true
      },
      {
        name: 'Data Preparation',
        tasks: [
          { name: 'Select Data', route: 'select-data' },
          { name: 'Clean Data', route: 'clean-data' },
          { name: 'Construct Data', route: 'construct-data' },
          { name: 'Integrate Data', route: 'integrate-data' },
          { name: 'Format Data', route: 'format-data' }
        ],
        expanded: true
      },
      {
        name: 'Modeling',
        tasks: [
          { name: 'Select Modeling Technique', route: 'select-modeling-technique' },
          { name: 'Data Mining Algorithm Development', route: 'data-mining-development' },
          { name: 'Parameter Setting', route: 'parameter-setting' },
          { name: 'Model Training', route: 'model-training' }
        ],
        expanded: true
      },
      {
        name: 'Evaluation',
        tasks: [
          { name: 'Evaluate Model Performance', route: 'evaluate-model-performance' },
          { name: 'Compare Results', route: 'compare-results' },
          { name: 'Refine Model', route: 'refine-model' },
          { name: 'Review Project Results', route: 'review-project-results' }
        ],
        expanded: true
      },
      {
        name: 'Deployment',
        tasks: [
          { name: 'Plan Deployment', route: 'plan-deployment' },
          { name: 'Prepare for Production', route: 'prepare-production' },
          { name: 'Monitor and Maintain', route: 'monitor-maintain' },
          { name:  'Review & Refine', route: 'review-refine' }
        ],
        expanded: true
      }
    ];

  toggleStage(stage: any) {
    stage.expanded = !stage.expanded;
  }

  onTaskClick1(task: any) {
    // Handle task click logic here, perhaps using routing navigation
    this.router.navigate(['/projectManagement/stepperCrisp'], {
      queryParams: { projectId: this.projectId }, 
      fragment: task.route
     });
     


    console.log('Task clicked:', task.name, task.route);
  }
  onTaskClick(task: any) {
    this.router.navigate(['/projectManagement/crispDetails'], {
      queryParams: { projectId: this.projectId }, 
      fragment: task.route
     });
     


    console.log('Task clicked:', task.name, task.route);
  }
  loadBusinessUnderstanding(): void {
    this.crispService.getBusinessUnderstandingByProjectId(this.projectId).subscribe(
      (data: any) => {
        this.businessUnderstanding = data; 
        this.businessUnderstandingExists = true; // Set flag if data is found
      },
      error => {
        if (error.status === 404) {
          this.businessUnderstandingExists = false; // Set flag if data is not found
        }
        console.error('Error fetching Business Understanding:', error);
      }
    );
  }
  loadDataDescriptions(): void {
    this.crispService.getDataDescriptions(this.projectId).subscribe(
      (data: any) => {
        console.log(this.projectId);
        this.dataDescriptions = data; 
        this.dataDescriptionsExist = true;
      },
      error => {
        if (error.status === 404) {
          this.dataDescriptionsExist = false;
        }
        console.error('Error fetching Data Descriptions:', error);
      }
    );
  }
  

}
