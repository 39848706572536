import { Component, Input, OnInit } from '@angular/core';
import { TokenStorageService } from 'app/modules/auth/service/token/token.service';
import { CalenderService } from 'app/modules/project-management/services/calender/calender.service';
import { QasService } from 'app/modules/project-management/services/qas/qas.service';
import { Question, Answer } from '../../scrum-board/utils/models/column.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ngx-sprint-retro',
  templateUrl: './sprint-retro.component.html',
  styleUrls: ['./sprint-retro.component.scss']
})
export class SprintRetroComponent implements OnInit {
  @Input() selectedSprint: any;

  currentUser: any;
  questions: Question[] = [];
  newQuestion: Question = { title: '', userId: '' };
  newAnswers: { [questionId: string]: Answer } = {};
  userRole: string | null = '';
  showQuestionInput: boolean = false;
  showAnswerInput: { [questionId: string]: boolean } = {};
  questionUsernames: { [questionId: string]: string } = {};
  answerUsernames: { [answerId: string]: string } = {};

  // Define two default questions
  defaultQuestions: Question[] = [
    { id: uuidv4(), title: 'Choose one or multiple numbers from this image that has a figure that better represents you.  ', userId: '6653a2250760816fbd80f8cd' },
    { id: uuidv4(), title: 'In a paragraph tell us why you chose said figures?', userId: '6653a2250760816fbd80f8cd' }
  ];

  constructor(
    private tokenStorageService: TokenStorageService,
    private sprintService: CalenderService,
    private qas: QasService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    this.userRole = localStorage.getItem('userrole');
    this.ensureDefaultQuestions();
  }

  ensureDefaultQuestions(): void {
    this.qas.ensureDefaultQuestions(this.defaultQuestions).subscribe(defaultQuestions => {
      this.defaultQuestions = defaultQuestions;
      this.getQuestions();
    });
  }

  getQuestions(): void {
    if (this.selectedSprint && this.selectedSprint.sprintRetrospective) {
      this.questions = [...this.defaultQuestions, ...this.selectedSprint.sprintRetrospective.questions];
      this.questions.forEach(question => {
        if (!this.newAnswers[question.id]) {
          this.newAnswers[question.id] = { title: '', userId: this.currentUser.id };
        }
        this.showAnswerInput[question.id] = false;
        
        this.qas.getUsernameForQuestion(question.id).subscribe(response => {
          this.questionUsernames[question.id] = response.username;
        });

        question.answers.forEach(answer => {
          this.qas.getUsernameForAnswer(answer.id).subscribe(response => {
            this.answerUsernames[answer.id] = response.username;
          });
        });
      });
    }
  }

  toggleQuestionInput(): void {
    this.showQuestionInput = !this.showQuestionInput;
  }

  toggleAnswerInput(questionId: string): void {
    this.showAnswerInput[questionId] = !this.showAnswerInput[questionId];
  }

  addQuestion(): void {
    if (['ROLE_SCRUM_MASTER', 'ROLE_PROJECT_MANAGER', 'ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(this.userRole)) {
      if (this.newQuestion.title.length >= 10) {
        const sprintId = this.selectedSprint.id;
        this.newQuestion.userId = this.currentUser.id;
        this.qas.createQuestion(sprintId, this.newQuestion, this.currentUser.id).subscribe(question => {
          this.questions.push(question);
          this.newQuestion = { title: '', userId: '' };
          this.newAnswers[question.id] = { title: '', userId: this.currentUser.id };
          this.showQuestionInput = false;
          this.qas.getUsernameForQuestion(question.id).subscribe(response => {
            this.questionUsernames[question.id] = response.username;
          });
        });
      } else {
        alert('Question must be at least 10 characters long.');
      }
    }
  }

  addAnswer(questionId: string): void {
    if (['ROLE_SCRUM_MASTER', 'ROLE_PROJECT_MANAGER', 'ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(this.userRole)) {
      const newAnswer = this.newAnswers[questionId];
      if (newAnswer.title.length >= 10) {
        newAnswer.userId = this.currentUser.id;
        this.qas.createAnswer(questionId, newAnswer, this.currentUser.id).subscribe(answer => {
          const question = this.questions.find(q => q.id === questionId);
          if (question) {
            question.answers.push(answer);
            this.newAnswers[questionId] = { title: '', userId: this.currentUser.id };
            this.showAnswerInput[questionId] = false;
            this.qas.getUsernameForAnswer(answer.id).subscribe(response => {
              this.answerUsernames[answer.id] = response.username;
            });
          }
        });
      } else {
        alert('Answer must be at least 10 characters long.');
      }
    }
  }

  deleteQuestion(questionId: string): void {
    this.qas.deleteQuestion(questionId).subscribe(() => {
      this.questions = this.questions.filter(q => q.id !== questionId);
    });
  }

  deleteAnswer(answerId: string, questionId: string): void {
    this.qas.deleteAnswer(answerId).subscribe(() => {
      const question = this.questions.find(q => q.id === questionId);
      if (question) {
        question.answers = question.answers.filter(a => a.id !== answerId);
      }
    });
  }

  isDefaultQuestion(questionId: string): boolean {
    return this.defaultQuestions.some(question => question.id === questionId);
  }
}
