<div>
  <div class="gantt-update-position">
    <h1 class="title-h1">{{title}}</h1>
    <ng-template #modalContent >
      <nb-card class="custom-modal">
        <nb-card-body>
          <ngx-roadmap-process></ngx-roadmap-process>
          <div class="btn-view">
            <button
              nbButton
              shape="round"
              size="small"
              status="danger"
              (click)="closeModal()"> Annuler
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </ng-template>
  </div>

  <nb-card>
    <nb-card-body>
      <div class="dropdown-container">
        <nb-select [(ngModel)]="selectedOption">
          <nb-option value="Edit">&#9881;</nb-option>
          <nb-option value="Update" (click)="openModal(modalContent)">Update</nb-option>
          <nb-option value="Delete">Delete</nb-option>
        </nb-select>
      </div>
      <nb-card id="container" style="width: 100%; height: 700px;"></nb-card>
      <button nbButton status="primary" (click)="previousPage()" [disabled]="currentIndex === 0">Précédent</button>
      <span>{{ currentIndex + 1 }} / {{ tasksNumber }}</span>
      <button nbButton status="primary" (click)="nextPage()" [disabled]="currentIndex === tasksNumber - 1">Suivant
      </button>
    </nb-card-body>
    <!-- The tooltip card -->
    <div class="tooltip-card" [class.show]="" style="background: transparent; border: none;">
      <nb-card>
        <nb-card-body>
          <button nbButton  class="close-sticker">↩</button>
          <ul>Gantt chart:</ul>
          <ul>Commonly used in project management, is one of the most popular and useful ways of showing activities (tasks or events) displayed against time. On the left of the chart is a list of the activities and along the top is a suitable time scale. Each activity is represented by a bar; the position and length of the bar reflects the start date, duration and end date of the activity</ul>
        </nb-card-body>
      </nb-card>
    </div>

    <!-- The tooltip button -->
    <button #tooltipTrigger mat-icon-button matTooltip="Backlog in Scrum methodology" class="help-button">
      <mat-icon>help_outline</mat-icon>
    </button>
  </nb-card>
</div>
