import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router } from '@angular/router';
import {CrispService} from '../../../services/crisp.service'
import { DataDescription } from 'app/modules/project-management/models/DataDescription';

@Component({
  selector: 'ngx-redirecting-crisp',
  templateUrl: './redirecting-crisp.component.html',
  styleUrls: ['./redirecting-crisp.component.scss']
})
export class RedirectingCrispComponent implements OnInit,AfterViewInit{
  projectId: string;
  file: File | null = null;
  showFileUploadSection = false;
  dataInfo: any;
  dataDescription: any;

  dataDescriptions: DataDescription[];
  businessUnderstandingId: string;
  businessUnderstanding: any;
  businessForm: FormGroup;
  dataUnderstandingForm: FormGroup;
  dataPreparationForm: FormGroup;
  modelingForm: FormGroup;
  evaluationForm: FormGroup;
  deploymentForm: FormGroup;
  fileData: { [key: string]: string } = {}; // To store Base64 strings of files


  

  stages = [
    {
      name: 'Business Understanding',
      tasks: [
        { name: 'Business Objectives', route: 'businessObjectives' },
        { name: 'Business Constraints', route: 'businessConstraints' },
        { name: 'Prior Knowledge', route: 'priorKnowledge' },
        { name: 'Initial Hypotheses', route: 'initialHypotheses' },
        { name: 'Business Understanding Document', route: 'businessUnderstandingDocument' },
        { name: 'Project Plan', route: 'projectPlan' },
        { name: 'Risk Assessment', route: 'riskAssessment' }
      ],
      expanded: false
    },
    {
      name: 'Data Understanding',
      tasks: [
        { name: 'Gather Initial Data', route: 'gather-data' },
        { name: 'Describe Data', route: 'describe-data' },
        { name: 'Explore Data', route: 'explore-data' },
        { name: 'Verify Data Quality', route: 'verify-data-quality' }
      ],
      expanded: false
    },
    {
      name: 'Data Preparation',
      tasks: [
        { name: 'Select Data', route: 'select-data' },
        { name: 'Clean Data', route: 'clean-data' },
        { name: 'Construct Data', route: 'construct-data' },
        { name: 'Integrate Data', route: 'integrate-data' },
        { name: 'Format Data', route: 'format-data' }
      ],
      expanded: false
    },
    {
      name: 'Modeling',
      tasks: [
        { name: 'Select Modeling Technique', route: 'select-modeling-technique' },
        { name: 'Data Mining Algorithm Development', route: 'data-mining-development' },
        { name: 'Parameter Setting', route: 'parameter-setting' },
        { name: 'Model Training', route: 'model-training' }
      ],
      expanded: false
    },
    {
      name: 'Evaluation',
      tasks: [
        { name: 'Evaluate Model Performance', route: 'evaluate-model-performance' },
        { name: 'Compare Results', route: 'compare-results' },
        { name: 'Refine Model', route: 'refine-model' },
        { name: 'Review Project Results', route: 'review-project-results' }
      ],
      expanded: false
    },
    {
      name: 'Deployment',
      tasks: [
        { name: 'Plan Deployment', route: 'plan-deployment' },
        { name: 'Prepare for Production', route: 'prepare-production' },
        { name: 'Monitor and Maintain', route: 'monitor-maintain' },
        { name:  'Review & Refine', route: 'review-refine' }
      ],
      expanded: false
    }
  ];

  constructor(private fb: FormBuilder,private route: ActivatedRoute, private crispService: CrispService, private router: Router, private toastrService: NbToastrService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      // Use the projectId as needed within your component
      console.log('Project ID:', this.projectId);
      this.loadBusinessUnderstanding();
      console.log(this.businessUnderstanding);
    });
    this.businessForm = this.fb.group({
      businessObjectives: [''],
      businessConstraints: [''],
      priorKnowledge: [''],
      initialHypotheses: [''],
      businessUnderstandingDocument: [''],
      projectPlan: [''],
      riskAssessment: [''],
      projectId: this.projectId
    });

    this.dataUnderstandingForm = this.fb.group({
      'gather-data': ['', ],
      'describe-data': ['', ],
      'explore-data': ['', ],
      'verify-data-quality': ['', ],
    });

    this.dataPreparationForm = this.fb.group({
      'select-data': ['', ],
      'clean-data': ['', ],
      'construct-data': ['', ],
      'integrate-data': ['', ],
      'format-data': ['', ],
    });

    this.modelingForm = this.fb.group({
      'select-modeling-technique': ['', ],
      'data-mining-development': ['', ],
      'parameter-setting': ['', ],
      'model-training': ['', ],
    });

    this.evaluationForm = this.fb.group({
      'evaluate-model-performance': ['', ],
      'compare-results': ['', ],
      'refine-model': ['', ],
      'review-project-results': ['', ],
    });

    this.deploymentForm = this.fb.group({
      'plan-deployment': ['', ],
      'prepare-production': ['', ],
      'monitor-maintain': ['', ],
      'review-refine': ['', ],
    });
  }
  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
  }
  onSubmit1(formName: string): void {
    let formData: any;
    if (formName === 'businessForm') {
      formData = { ...this.businessForm.value, ...this.fileData };
      this.crispService.createOrUpdate(formData, this.projectId).subscribe(
        response => {
          console.log('Business Understanding created successfully:', response);
          this.showFileUploadSection = true;
          this.businessUnderstandingId = response.id; // Assuming response contains the ID
        },
        error => {
          console.error('Error creating Business Understanding:', error);
        }
      );
    }
  }
  onSubmit(formName: string, stepper: any): void {
    let formData: any;
    switch(formName) {
      case 'businessForm':
        formData = { ...this.businessForm.value, ...this.fileData };
        this.crispService.createOrUpdate(formData, this.projectId).subscribe(
          (response) => {
            console.log('Business Understanding created successfully:', response);
            stepper.next();
          },
          (error) => {
            console.error('Error creating Business Understanding:', error);
          }
        );
        break;
      
      // Handle other forms similarly
    }
  }
  loadBusinessUnderstanding(): void {
    this.crispService.getBusinessUnderstandingByProjectId(this.projectId).subscribe(
      (data: any) => {
        console.log(this.projectId);
        this.businessUnderstanding = data;
        this.businessUnderstandingId = data.id;
        this.businessForm.patchValue({
          businessObjectives: data.businessObjectives,
          businessConstraints: data.businessConstraints,
          priorKnowledge: data.priorKnowledge,
          initialHypotheses: data.initialHypotheses,
        });

      },
      error => {
        console.error('Error fetching Business Understanding:', error);
      }
    );
  }
  onFileSelected(event: any, fieldName: string): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileData[fieldName] = (reader.result as string).split(',')[1]; // Remove the base64 prefix
      };
      reader.readAsDataURL(file);
    }
  }
  uploadDocument(type: string): void {
    if (this.fileData[type] && this.businessUnderstandingId) {
      this.crispService.uploadDocument(this.businessUnderstandingId, this.fileData[type], type).subscribe(
        () => {
          this.toastrService.success(`${type} uploaded successfully`, 'Success');
        },
        (error) => {
          this.toastrService.danger('Error uploading document: ' + error.message, 'Error');
        }
      );
    } else {
      this.toastrService.warning('Please select a file and provide a project ID', 'Warning');
    }
  }

  onFileSelectedData(event: any) {
    this.file = event.target.files[0];
  }

  uploadCSV() {
    if (this.file && this.projectId) {
      this.crispService.uploadCSV(this.file, this.projectId).subscribe({
        next: (response: any) => {
          this.toastrService.success(response, 'Success');
          this.crispService.setDataInfo(response.dataInfo);
        this.crispService.setDataDescription(response.dataDescription);
        },
        error: (err) => {
          this.toastrService.danger('Error uploading file: ' + err.message, 'Error');
        },
      });
    } else {
      this.toastrService.warning('Please select a file', 'Warning');
    }
  }

  
  navigateBackToProjectList(): void {
    // Navigate back to the project list with the current projectId
    this.router.navigate(['/projectManagement/crisp-process'], { queryParams: { projectId: this.projectId } });
  }
  
}
