<nb-card>
  <nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
    <nb-card-front>
      <nb-card accent="info">
        <nb-card-header>
          <div class="row">
            <div class="col-md-11">
            </div>
            <div class="col-md-1" *ngIf="problematic?.problematic!=null">
              <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <nb-icon icon="more-horizontal-outline"></nb-icon>
              </button>
              <mat-menu #menu="matMenu">
                
                  <a style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary"  (click)="updateProblematic()">
                    <button mat-menu-item>
                      <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                    </button>
                  </a>
                  <a nbTooltip="Delete data" nbTooltipStatus="primary" (click)="confirmation(dialog)">
                    <button mat-menu-item>
                      <span><nb-icon class="text-danger" icon="trash-outline"></nb-icon>&nbsp; Delete</span>
                    </button>
                  </a>
              
             
                 <ng-template #dialog let-data let-ref="dialogRef">
                  <nb-card >
                    <nb-card-header>Confirm Delete Operation</nb-card-header>
                    <nb-card-body class="alert alert-warning ">
                      Are you sure you want to delete this item ?
                    </nb-card-body>
                    <nb-card-footer>
                      <div class="row">
                        <div class="col-md-2">
                          <button nbButton status="basic"  (click)="ref.close()"><nb-icon icon="close" ></nb-icon>Close</button>
                        </div>
                        <div class="col-md-5"></div>
                        <div class="col-md-2">
                          <button nbButton status="danger"  (click)="delete()"><nb-icon icon="trash"></nb-icon>Delete</button>
                        </div>

                      </div>
                    </nb-card-footer>
                  </nb-card>
                </ng-template> 
    
              </mat-menu>
            </div></div
        ></nb-card-header>
        <nb-card-body>
          <article>
            <div *ngIf="problematic?.problematic!=null; else noData">
              <p>
                <strong
                
                  ><U>{{ problematic.problematic.name }}</U></strong
                >
              </p>
              <nb-alert outline="primary">
                {{ problematic.problematic.description }}
              </nb-alert>
            </div>
            <ng-template #noData>
              <div class="col-md-4 offset-md-5">
                <p >No Data Found</p>
                <button nbButton status="primary"  (click)="flip()">Add New + </button>
              </div>
            </ng-template>
          </article>
        </nb-card-body>
      </nb-card>
    </nb-card-front>
    <nb-card-back style="min-height: 100px">
      <nb-card accent="primary">
        <nb-card-header>  <nb-icon icon="chevron-left-outline" pack="nebular-essentials" aria-hidden="true"   (click)="flip()"></nb-icon>
         Back
          
        </nb-card-header>
        <nb-card-body>
          <ngx-add-problematic
            [problematicSelected]="selectedItem"
            [projectChart]="problematic"
            (refreshData)="refresh($event)"
          ></ngx-add-problematic>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-flip-card>
</nb-card>
