import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-ativity-team',
  templateUrl: './ativity-team.component.html',
  styleUrls: ['./ativity-team.component.scss'],
})
export class AtivityTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
