import { Component, Input, OnInit } from '@angular/core';
import { superAccess } from '../../../../../auth/accessControle/accessControle';
import { TokenStorageService } from '../../../../../auth/service/token/token.service';
import { Project } from '../../../../models/Project';
import { ProjectService } from '../../../../services/project/project.service';
import {Type} from '../../../../models/enum/Type';
import { Router } from '@angular/router';
import {methodologyChoice} from '../../../../models/enum/methodologyChoice';

@Component({
  selector: 'ngx-project-generale-info',
  templateUrl: './project-generale-info.component.html',
  styleUrls: ['./project-generale-info.component.scss'],
})
export class ProjectGeneraleInfoComponent implements OnInit {
  @Input() project: Project;
  statusColor: string = '';
  flippedState: boolean = false;
  selected: Project = null;
  accessControle: boolean = false;
  protected readonly Type = Type;
  constructor(private projectService: ProjectService,    private tokenStorageService: TokenStorageService, private router: Router,
    ) {   const currentUser = this.tokenStorageService.getUser();

      this.accessControle = superAccess(currentUser);
    }

  ngOnInit(): void {
    console.log(this.project);

    this.updateStatusColor();


  }

  updateStatusColor() {
    '' + this.project.status === 'DONE'
      ? (this.statusColor = 'success')
      : '' + this.project.status === 'IN_PTOGRESS'
      ? (this.statusColor = 'warning')
      : (this.statusColor = 'danger');
  }
  updateStatus(event) {
    this.project.status = event;
    this.projectService.updateProject(this.project).subscribe({
      next: (result: any) => {
        this.project = result;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.updateStatusColor();
      },
    });
  }
  flip(): void {
    this.selected = this?.project;
    this.flippedState = !this.flippedState;
  }
  refresh(event) {
    this.project = null;
    this.project = event;
    this.flip();
  }

  redirectToSprints() {
    if (this.project && this.project.id) {
        console.log(this.project);

        if (this.project.methodology?.toString() === 'CRISPDM'){
          this.router.navigate(['/projectManagement/crisp-process'], { queryParams: { projectId: this.project.id }});
      }
      if (this.project.methodology?.toString() === 'scrum') {
        this.router.navigate(['/projectManagement/scrum-process'], { queryParams: { projectId: this.project.id }});
      } else if (this.project.methodology?.toString() === 'lean') {
        if (this.project.leanMethod?.toString() === 'KANBAN') {
          this.router.navigate(['/projectManagement/lean-menu'], { queryParams: { projectId: this.project.id } });

        } else if (this.project.leanMethod?.toString() === '5S') {
          this.router.navigate(['/projectManagement/lean-menu'], { queryParams: { projectId: this.project.id } });

        } else if (this.project.leanMethod?.toString() === 'PDCA') {
          this.router.navigate(['/projectManagement/lean-menu'], { queryParams: { projectId: this.project.id } });

        } else if (this.project.leanMethod?.toString() === 'VSM') {
          this.router.navigate(['/projectManagement/lean-menu'], { queryParams: { projectId: this.project.id } });

        } else {
          this.router.navigate(['/projectManagement/lean-process'], { queryParams: { projectId: this.project.id } });
        }
    } else {
      // Handle other methodologies or show an error message
      console.error('Unknown methodology:', this.project.methodology);
    }}
  }

}
