import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Sprint} from '../../models/Sprint';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Project} from '../../models/Project';
import {Task} from '../../components/scrum-board/utils/models/task';

@Injectable({
  providedIn: 'root'})
export class RoadmapService {
  psUrl = environment.psUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient) {}

  findAllSprintProject(id: string): Observable<Sprint[]> {
    return this.http.get<Sprint[]>(`${this.psUrl}sprint/all/${id}`);
  }
  findTaskByID(id: string): Observable<Task> {
    return this.http.get<Task>(`${this.psUrl}task/find-task/${id}`);
  }
  findSprintByID(id: string): Observable<Sprint> {
    return this.http.get<Sprint>(`${this.psUrl}sprint/findSprintById/${id}`);
  }
  findProject(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.psUrl}project/findProjectById/${id}`);
  }

  findProjectById(id: string): Observable<Sprint> {
    return this.http.get<Sprint>(`${this.psUrl}sprint/all/${id}`);
  }
  removeTaskFromSprint(sprintId: string, taskId: string): Observable<any> {
    const url = `${this.psUrl}sprint/removeTaskFromSprint/${sprintId}/${taskId}`;
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as const,
      responseType: 'text' as const,
    };

    return this.http.request('delete', url, options);
  }
  deleteSprint(id: string): Observable<any> {
    return this.http.delete(`${this.psUrl}sprint/delete/${id}`, { observe: 'response' });
  }
  updateSprintStatusAsNotStarted(id: string): Observable<Sprint> {
    return this.http.put<Sprint>(`${this.psUrl}sprint/updateSprintNotStarted/${id}`, {});
  }
  updateSprintStatusAsCompleted(id: string): Observable<Sprint> {
    return this.http.put<Sprint>(`${this.psUrl}sprint/updateSprintCompleted/${id}`, {});
  }
  deleteTask(id: string): Observable<any> {
    return this.http.delete(`${this.psUrl}task/delete/${id}`, this.httpOptions);
  }

  updateSprint(sprint: Sprint): Observable<Sprint> {
    return this.http.put<Sprint>(`${this.psUrl}sprint/updateSprint`, sprint, this.httpOptions);
  }
  assignTaskToSprint(sprintId: string, taskId: string): Observable<void> {
    return this.http.post<void>(`${this.psUrl}sprint/assignTaskToSprint/${sprintId}/${taskId}`, {});
  }
  updateTask(task: Task): Observable<Task> {
    return this.http.put<Task>(`${this.psUrl}task/updateTask`, task, this.httpOptions);
  }
  addSprint(sprint: Sprint): Observable<Sprint> {
    return this.http.post<Sprint>(`${this.psUrl}sprint/addSprint`, sprint);
  }
  addTask(task: Task): Observable<Task> {
    return this.http.post<Task>(`${this.psUrl}task/addStory`, task);
  }
  findTaskIdByTitle(taskTitle: string): Observable<string> {
    return this.http.get(`${this.psUrl}task/findTaskIdByTitle/${taskTitle}`, { responseType: 'text' });
  }
}
