import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-team-table',
  templateUrl: './team-table.component.html',
  styleUrls: ['./team-table.component.scss'],
})
export class TeamTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
