<nb-accordion>
  <nb-accordion-item>
    <nb-accordion-item-header style="padding: 2%">
      <span class="titles mb-0"> Assignees</span>
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <nb-card>
        <nb-card-header class="d-flex" style="padding: 3%">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div style="width: 80%">
              <nb-form-field style="max-width: 100%;">
                <input
                  style="max-width: 100%;"
                  [formControl]="inputFormControl"
                  nbInput
                  type="text"
                  placeholder="Search Members"
                  [nbAutocomplete]="autoControl"
                />
                <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
              </nb-form-field>
              <nb-autocomplete #autoControl>
                <nb-option
                  *ngFor="let option of filteredControlOptions$ | async"
                  [value]="option"
                >
                  {{ option }}
                </nb-option>
              </nb-autocomplete>
            </div>
            <nb-icon (click)="addToTeam()" icon="person-add-outline" style="cursor : pointer"></nb-icon>
          </div>
        </nb-card-header>
        <nb-card-body>
          <div *ngFor="let member of membersList" class="d-flex align-items-center justify-content-between">
            <nb-user [onlyPicture]="false"
                     [name]="member"
                     [picture]="">
            </nb-user>
            <nb-icon (click)="removeMember(member)" icon="person-remove-outline" style="cursor : pointer;width: 20%"></nb-icon>

          </div>
        </nb-card-body>
      </nb-card>

    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
