<h1>Assumption log </h1>
<form class="form">
    <div class="form-group">
      <label for="subject">Area:</label>
      <input
        nbInput
        fullWidth
        class="form-control"
        type="text"
      />
    
    </div>
    <div class="form-group">
      <label class="text-label" for="text">Estimated Amount:</label>
      <input
        nbInput
        fullWidth
        class="form-control"
        type="number"
      />
     
    </div>
    <div class="form-group">
      <label class="text-label" for="text">Budget Paid:</label>
      <input
        nbInput
        fullWidth
        class="form-control"
        type="number"
      />
     
    </div>
    <div class="form-group">
      <label class="text-label" for="text">comment:</label>
      <textarea
        nbInput
        fullWidth
        class="form-control"
        type="number"
      >
      </textarea>
      
     
    </div>
    <input
      class="btn btn-success btnCreate"
      value="Create"
      type="submit"
     
    />
  </form>
  