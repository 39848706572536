import {Component, OnInit, TemplateRef} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {SprintFormDialogComponent} from '../sprint-form-dialog/sprint-form-dialog.component';
import {Sprint} from '../../../models/Sprint';
import {Task} from '../../../models/Task';
import {Task as BoardTask, Type} from '../../scrum-board/utils/models/task';
import {SprintService} from '../../../services/sprint/sprint.service';
import {sprintStatus} from '../../../models/enum/sprintStatus';
import {TaskService} from '../../../services/task/task.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Column} from '../../scrum-board/utils/models/column.model';
import {ScrumBoadService} from '../../scrum-board/utils/services/scrum-boad.service';
import { MatTooltip } from '@angular/material/tooltip';



@Component({
  selector: 'ngx-sprint-backlog',
  templateUrl: './sprint-backlog.component.html',
  styleUrls: ['./sprint-backlog.component.scss'],
})
export class SprintBacklogComponent implements OnInit {
  showForm = false;
  showTooltip = false;
  sprintList: Sprint[] = [];
  backlogList: (string | Task)[] = []; // Use union type for backlogList
  backlogTaskTitles: string[] = [];
  backlogTasks: BoardTask[] = [];
  newSprint: Sprint = {
    id: null,
    title: '',
    startDate: null,
    endDate: null,
    status: sprintStatus.notStarted,
    description: '',
    restrospectives: '',
    tasks: [],
    project: '',
    collapsed: true,
  };
  newTask: Task = { // Updated to be of type Task
    id: null,
    title: '',
    sprintId: null, // Initialize it to null as it will be assigned to a sprint later
  };
  tasks: Task[] = [];
  taskTitle: string = '';

  projectId: string;
  newColumn: Column = new Column();
  taskToUpdate: BoardTask = new BoardTask();
  boardTaskId: string;
  constructor(private dialogService: NbDialogService,
              private sprintService: SprintService,
              private taskService: TaskService,
              private boardApi: ScrumBoadService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      console.log(this.projectId);
      if (this.projectId) {
        // Load the sprints for the specific project
        this.loadSprintsByProject(this.projectId);
      }
    });
    // this.fetchAllTasks();
  }

  loadSprintsByProject(projectId: string) {
    this.sprintService.findAllSprintProject(this.projectId).subscribe(
      (sprints) => {
        this.sprintList = sprints;
      },
      (error) => {
        console.error('Error loading sprints:', error);
        // You can show an error message to the user if needed
      },
      () => {
        // this.backlogTasks = [];
        this.backlogTaskTitles = [];
        this.sprintList?.map(sprint => {
          this.boardApi.findTaskBySprintId(sprint.id).subscribe(res => {
            res?.map(task => {
              if (task.type.toString() === 'STORY') {
                // this.backlogTasks = [...this.backlogTasks, task];
                this.backlogTaskTitles = [...this.backlogTaskTitles, task.title];
              }
            });
            // this.backlogTasks.push(...res);
          });
        });
      });
  }

  fetchAllTasks(): void {
    this.taskService.getAllTasks().subscribe(
      (tasks: BoardTask[]) => {
        // this.backlogList = tasks;
        tasks?.map((task: BoardTask) => {
          if (task.type === Type.STORY) {
            this.backlogTaskTitles = [...this.backlogTaskTitles, task.title];
          }
        });
        console.log('success', this.backlogTaskTitles);
      },
      (error) => {
        console.error('Error fetching tasks:', error);
        // Handle error if needed
      });
  }

  openSprintModal(sprint: Sprint) {
    this.dialogService.open(SprintFormDialogComponent, {
      context: {
        sprint: { ...sprint }, // Create a shallow copy to avoid direct mutations
      },
    });
  }

  showSprintForm(dialog: TemplateRef<any>) {
    // this.showForm = true;
    this.dialogService.open(dialog, {context: 'test'});
  }

  addSprint() {
    this.newSprint.tasks = [];
    this.newSprint.project = this.projectId;
    console.log(this.projectId);
    console.log(this.newSprint.project);

    this.sprintService.addSprint(this.newSprint).subscribe(
      (newSprint) => {
        // If the sprint was added successfully, add it to the sprintList
        this.sprintList?.push(newSprint);
        // add the default column Backlog to the new sprint
        this.newColumn = new Column();
        this.newColumn.title = newSprint.title + ' Backlog';
        this.newColumn.list = [];
        this.newColumn.sprintId = newSprint.id;
        this.boardApi.addColumn(this.newColumn).subscribe((res: Column) => {
          console.log('added column to new sprint', res);
        });
        // Reset the newSprint object
        this.newSprint = {
          id: null,
          title: '',
          startDate: null,
          endDate: null,
          status: sprintStatus.notStarted,
          description: '',
          restrospectives: '',
          tasks: [],
          project: '',
          collapsed: true,
        };

        this.showForm = false;
      },
      (error) => {
        // Handle any errors that occur during adding the sprint
        console.error('Error adding sprint:', error);
        // You can show an error message to the user if needed
      });
  }

  addTask() {
    if (this.newTask.title.trim() === '') {
      return;
    }
    // Call the TaskService to add the task
    this.taskService.addTask(this.newTask).subscribe(
      (addedTask: BoardTask) => {
        // The task was successfully added, now add it to the backlog list
        this.backlogTaskTitles.push(addedTask.title);
        this.newTask.title = ''; // Clear the title in newTask
      },
      (error) => {
        // Handle any error that occurred during task addition
        console.error('Error adding task:', error);
        if (error instanceof HttpErrorResponse) {
          console.error('Server returned status code:', error.status);
          console.error('Error body:', error.error);
        }
      });
  }

  onTaskDragStart(event: DragEvent, taskTitle: string, sprint: Sprint | null) {
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', JSON.stringify(taskTitle));
      if (sprint) {
        event.dataTransfer.setData('sprint', JSON.stringify(sprint));
      } else {
        event.dataTransfer.setData('sprint', '');
      }
    }
  }

  onTaskDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onTaskDrop(event: DragEvent, sprint: Sprint | null) {
    event.preventDefault();
    const taskTitle = event.dataTransfer?.getData('text/plain');
    const sourceSprintString = event.dataTransfer?.getData('sprint');
  
    if (taskTitle) {
      const taskTitleString: string = JSON.parse(taskTitle);
  
      if (sourceSprintString) {
        const sourceSprint = JSON.parse(sourceSprintString);
        if (sourceSprint && sourceSprint.tasks) {
          const task = sourceSprint.tasks.find((t: Task) => t.title === taskTitleString);
          if (task) {
            const index = sourceSprint.tasks.indexOf(task);
            if (index > -1) {
              sourceSprint.tasks.splice(index, 1);
              this.sprintService.removeTaskFromSprint(sourceSprint.id, task.id).subscribe(
                () => {
                  console.log(`Task "${task.title}" removed from sprint "${sourceSprint.title}" successfully.`);
                },
                (error) => {
                  console.error(`Failed to remove task "${task.title}" from sprint "${sourceSprint.title}". Error: ${error}`);
                  sourceSprint.tasks.splice(index, 0, task);
                });
            }
          }
        }
      } else {
        const index = this.backlogTaskTitles.indexOf(taskTitleString);
        if (index > -1) {
          this.backlogTaskTitles.splice(index, 1);
        }
      }
  
      if (sprint) {
        const existingTask = sprint.tasks.find((t: any) => t.title === taskTitleString);
        if (!existingTask) {
          this.taskService.findTaskIdByTitle(taskTitleString).subscribe(
            (taskId) => {
              console.log('Task ID:', taskId);
  
              const task: BoardTask = new BoardTask();
              task.id = taskId;
              task.title = taskTitleString;
              task.sprintId = sprint.id;
  
              this.boardTaskId = taskId;
              sprint.tasks.push(task);
  
              this.sprintService.assignTaskToSprint(sprint.id, this.boardTaskId).subscribe(
                () => {
                  console.log(`Task "${this.boardTaskId}" added to sprint "${sprint.title}" successfully.`);
                },
                (error) => {
                  console.error(`Failed to add task "${this.boardTaskId}" to sprint "${sprint.title}". Error: ${error}`);
                  sprint.tasks = sprint.tasks.filter(t => t.id !== task.id);
                  this.backlogTaskTitles.push(taskTitleString);
                },
                () => {
                  this.boardApi.findTaskById(this.boardTaskId).subscribe({
                    next: (res) => {
                      this.taskToUpdate = res;
                    },
                    complete: () => {
                      this.boardApi.getColumnsBySprint(sprint.id).subscribe(res => {
                        res.map(col => {
                          if (col.title === sprint.title + ' Backlog') {
                            col.list = [...col.list, this.taskToUpdate];
                            this.boardApi.updateColumn(col).subscribe(result => {
                              console.log(result);
                            });
                          }
                        });
                      });
                    },
                  });
                });
            },
            (error) => {
              console.error(`Failed to find task ID for "${taskTitleString}". Error: ${error}`);
              this.backlogTaskTitles.push(taskTitleString);
            });
        }
      }
    }
  }
  

  deleteTask(taskTitle: string) {
    // Handle deleting the task from the backlog
    const index = this.backlogTaskTitles.indexOf(taskTitle);
    if (index > -1) {
      this.backlogTaskTitles.splice(index, 1);

      // Call the TaskService to find the task ID by its title (if needed)
      this.taskService.findTaskIdByTitle(taskTitle).subscribe(
        (taskId) => {
          console.log('Task ID:', taskId);

          // Call the TaskService to delete the task by its ID
          this.taskService.deleteTask(taskId).subscribe(
            (message) => {
              console.log(message); // Log the message returned from the backend (optional)
              console.log(`Task "${taskTitle}" deleted successfully from the database.`);
            },
            (error) => {
              console.error(`Failed to delete task "${taskTitle}" from the database. Error: ${error}`);
              // Since the task was not deleted from the backend, add it back to the backlogTaskTitles list
              this.backlogTaskTitles.splice(index, 0, taskTitle);
            });
        },
        (error) => {
          console.error(`Failed to find task ID for "${taskTitle}". Error: ${error}`);
          // Since the task ID was not found, add the task back to the backlogTaskTitles list
          this.backlogTaskTitles.splice(index, 0, taskTitle);
        });
    }
  }

  deleteTaskFromSprint(task: BoardTask, sprint: Sprint) {
    const index = sprint.tasks.indexOf(task);
    if (index > -1) {
      sprint.tasks.splice(index, 1);

      this.sprintService.removeTaskFromSprint(sprint.id, task.id).subscribe(
        (response) => {
          console.log(response); // Log the response returned from the backend (optional)
          console.log(`Task "${task.title}" removed from sprint "${sprint.title}" successfully.`);
        },
        (error) => {
          console.error(`Failed to remove task "${task.title}" from sprint "${sprint.title}". Error: ${error}`);
          // Since the task was not removed from the backend, add it back to the Sprint's tasks list
          sprint.tasks.splice(index, 0, task);
        });
    }
  }

  deleteSprintFromList(sprint: Sprint) {
    console.log(sprint.status.toString());
    if (sprint.status.toString() === 'notStarted') {
      console.log(sprint.status);
      // Call the deleteSprint method and handle the response accordingly
      this.sprintService.deleteSprint(sprint.id).subscribe(
        () => {
          console.log(`Sprint "${sprint.title}" deleted successfully.`);
          // Optionally, you can remove the deleted sprint from the sprintList in your component
          const index = this.sprintList.indexOf(sprint);
          if (index > -1) {
            this.sprintList.splice(index, 1);
          }
        },
        (error) => {
          console.error(`Failed to delete sprint "${sprint.title}". Error: ${error}`);
        });
    } else {
      console.log(`Sprint with ID ${sprint.id} cannot be deleted due to its status.`);
      // Show a message to the user or handle it in any other way as per your requirement
    }
  }


  toggleSprintCollapse(sprint: Sprint) {
    sprint.collapsed = !sprint.collapsed;
  }

  changeSprintStatus(event) {
    this.newSprint.status = event;
    console.log(this.newSprint.status);
    console.log(event);
  }
  toggleTooltip(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
  }
  closeTooltip() {
    this.showTooltip = false;
  }


}


