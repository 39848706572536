<nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
  <nb-card-front>
    <nb-card accent="col">
      <angular2-smart-table
        class="order-table"
        [settings]="settings"
        [source]="data"
        (delete)="confirmation($event, dialog)"
        (create)="create()"
        (userRowSelect)="onUserRowSelect($event)"
      >
      </angular2-smart-table>
      <!--Delete Confirm -->
      <ng-template #dialog let-data let-ref="dialogRef">
        <nb-card>
          <nb-card-header>Confirm Archive Operation</nb-card-header>
          <nb-card-body class="alert alert-warning">
            Are you sure you want to archive this item ?
          </nb-card-body>
          <nb-card-footer>
            <div class="row">
              <div class="col-md-2">
                <button nbButton status="basic" (click)="ref.close()">
                  <nb-icon icon="close"></nb-icon>Close
                </button>
              </div>
              <div class="col-md-5"></div>
              <div class="col-md-2">
                <button
                  nbButton
                  status="danger"
                  (click)="onDeleteConfirm(selectedItem)"
                >

                  <nb-icon icon="trash"></nb-icon>Delete
                </button>
              </div>
            </div>
          </nb-card-footer>
        </nb-card>
      </ng-template>
      <!--end Delete Confirm-->
    </nb-card>
  </nb-card-front>
  <nb-card-back>
    <nb-card>
      <nb-card-header>
        <nb-icon
          icon="chevron-left-outline"
          pack="nebular-essentials"
          aria-hidden="true"
          (click)="flippe()"
        ></nb-icon>
        Back
      </nb-card-header>
      <nb-card-body>
        <ngx-add-change-request
          *ngIf="flippedState && !enabled "
          [selectedItem]="selectedItem"
          (dataChanged)="refresh($event)"
        ></ngx-add-change-request>

        <ngx-detail-edit-change-request
          *ngIf="flippedState && enabled "
          [selectedItem]="selectedItem"
          (dataChanged)="refresh($event)"
        >
        </ngx-detail-edit-change-request>
      </nb-card-body>
    </nb-card>
  </nb-card-back>
</nb-flip-card>
