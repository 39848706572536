import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {Checklist, Item, Task} from '../../../utils/models/task';
import {NbDialogService, NbThemeService} from '@nebular/theme';
import {BoardService} from '../../../utils/services/board.service';
import {ScrumBoadService} from '../../../utils/services/scrum-boad.service';

@Component({
  selector: 'ngx-task-checklist',
  templateUrl: './task-checklist.component.html',
  styleUrls: ['./task-checklist.component.scss'],
})
export class TaskChecklistComponent implements OnInit {
  @Input() task: Task;
  @Input() list: Checklist;
  protected readonly Math = Math;
  newItem: Item = new Item();

  constructor(public themeService: NbThemeService, private dialogService: NbDialogService,
              private boardService: BoardService, private boardApi: ScrumBoadService) {
  }

  ngOnInit(): void {
    console.log('checklist');
  }

  onChecklistNameChange(event: any, listId: string) {
    this.list.name = event.target.value;
    this.boardApi.updateChecklist(this.list).subscribe(res => {
      this.list = res;
    });
  }

  onItemNameChange(event: any, item: Item) {
    item.name = event.target.value;
    this.boardApi.updateItem(item).subscribe(res => {
      this.list.items.map((i: Item) => {
        if (i.id === item.id) {
          i.name = item.name;
        }
      });

    });

  }

  deleteConfirm(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {context: 'Are you sure you want to delete this ?'});
  }

  onChecklistDelete() {
    // this.boardService.deleteChecklist(this.task.id, this.list.id);
    this.boardApi.deleteChecklist(this.list.id).subscribe(res => {
      if (res) {
        this.boardService.board.map(col => {
          col.list.map(task => {
            if (task.id === this.task.id) {
              task.checklists = task.checklists.filter(checklist => checklist.id !== this.list.id);
            }
          });
        });
        this.boardService.board$.next([...this.boardService.board]);
      }
    });
  }

  onItemDelete(item: Item) {
    // this.boardService.deleteItem(this.task.id, this.list.id, item.id);
    this.boardApi.deleteItem(item.id).subscribe(res => {
      if (res) {
        this.boardService.board.map(col => {
          col.list.map(task => {
            if (task.id === this.task.id) {
              task.checklists.map(checklist => {
                if (checklist.id === this.list.id) {
                  checklist.items = checklist.items.filter(i => i.id !== item.id);
                  checklist.progress = item.status ? this.list.progress - 1 : checklist.progress;
                  this.boardApi.updateChecklist(checklist).subscribe(result => {
                    checklist = result;
                  });

                }
              });
            }
          });
        });
        this.boardService.board$.next([...this.boardService.board]);
      }
    });
  }

  onAddItem(name: string, cardId: string, listId: string) {
    if (name) {
      // this.boardService.addItem(cardId, listId, name);
      this.newItem = new Item();
      this.newItem.name = name;
      this.newItem.checklistId = listId;
      this.newItem.status = false;
      this.boardApi.addItem(this.newItem, listId).subscribe(res => {
        this.newItem = res;
        this.boardService.board.map(col => {
          col.list.map(task => {
            if (task.id === this.task.id) {
              task.checklists.map(checklist => {
                if (checklist.id === this.list.id) {
                  checklist.items = [...checklist.items, this.newItem];
                }
              });
            }
          });
          this.boardService.board$.next([...this.boardService.board]);
        });
      });
    }
  }

  toggle(isChecked: boolean, item: Item) {
    item.status = isChecked;
    this.boardApi.updateItem(item).subscribe(res => {
      item = res;
      this.list.items.map(i => {
        if (i.id === item.id) {
          i.status = item.status;
        }
      });
      this.list.progress = item.status ? this.list.progress + 1 : this.list.progress - 1;
      this.boardApi.updateChecklist(this.list).subscribe(result => {
        this.list = result;
      });
      this.boardService.board$.next([...this.boardService.board]);
    });
  }

}
