<nb-card>
  <nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
    <nb-card-front>
      <nb-card accent="info">
        
        <nb-card-body>
          <angular2-smart-table
            class="order-table"
            [settings]="settings"
            [source]="stakeholderList"
            (delete)="confirmation($event, dialog)"
            (create)="onCreate()"
            (userRowSelect)="onUserRowSelect($event)"
          >
          </angular2-smart-table>
          <!--Delete Confirm -->
          <ng-template #dialog let-data let-ref="dialogRef">
            <nb-card>
              <nb-card-header>Confirm Delete Operation</nb-card-header>
              <nb-card-body>
                Are you sure you want to delete this item ?
              </nb-card-body>
              <nb-card-footer>
                <div class="row">
                  <div class="col-md-2">
                    <button nbButton status="basic" (click)="ref.close()">
                      <nb-icon icon="close"></nb-icon>Close
                    </button>
                  </div>
                  <div class="col-md-5"></div>
                  <div class="col-md-2">
                    <button
                      nbButton
                      status="danger"
                      (click)="delete(selectedItem)"
                    >
                      <nb-icon icon="trash"></nb-icon>Delete
                    </button>
                  </div>
                </div>
              </nb-card-footer>
            </nb-card>
          </ng-template>
          <!--end Delete Confirm-->
        </nb-card-body>
      </nb-card>
    </nb-card-front>
    <nb-card-back>
      <nb-card>
        <nb-card-header>
          <nb-icon
            icon="chevron-left-outline"
            pack="nebular-essentials"
            aria-hidden="true"
            (click)="flip()"
          ></nb-icon>
          Key stakeholders
        </nb-card-header>
        <nb-card-body>
          <ngx-add-key-stakeholder
            [selectedItem]="selectedItem"
            [projectCharter]="data"
            (refreshData)="refresh($event)"
          ></ngx-add-key-stakeholder>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-flip-card>
</nb-card>
