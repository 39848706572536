<div class="steppers-container row">
  <div class="col-md-12 col-lg-12 col-xxxl-12 w-100">
    <nb-card>
      <nb-card-header>
        <div class="row">
          <div class="col-md-11">
            <h3>Project Workflow</h3>
          </div>
          <div class="col-md-1">
            <a
              nbTooltip="Back to project list"
              nbTooltipStatus="primary"
              shape="round"
              status="basic"
              (click)="navigateBackToProjectList()"
              nbButton
            >
              <nb-icon icon="corner-up-right-outline"></nb-icon>
            </a>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <nb-stepper #stepper>
          <!-- Business Understanding Step -->
          <nb-step
            id="business-objectives"
            [stepControl]="businessForm"
            label="Business Understanding"
          >
            <form
              [formGroup]="businessForm"
              (ngSubmit)="onSubmit1('businessForm')"
            >
              <div *ngFor="let task of stages[0].tasks">
                <div class="row mt-2">
                  <div class="col-md-11">
                    <label
                      *ngIf="
                        task.route !== 'businessUnderstandingDocument' &&
                        task.route !== 'projectPlan' &&
                        task.route !== 'riskAssessment'
                      "
                      class="small"
                      >{{ task.name }}</label
                    >
                    <textarea
                      *ngIf="
                        task.route !== 'businessUnderstandingDocument' &&
                        task.route !== 'projectPlan' &&
                        task.route !== 'riskAssessment'
                      "
                      formControlName="{{ task.route }}"
                      fullWidth
                      id="{{ task.route }}"
                      name="{{ task.route }}"
                      nbInput
                      placeholder="{{ task.name }}"
                    ></textarea>
                  </div>
                  <div
                    class="col-md-1 mt-3"
                    *ngIf="
                      task.route !== 'businessUnderstandingDocument' &&
                      task.route !== 'projectPlan' &&
                      task.route !== 'riskAssessment'
                    "
                  >
                    <nb-icon
                      icon="info-outline"
                      nbTooltip="{{ task.name }}"
                      nbTooltipStatus="primary"
                    ></nb-icon>
                  </div>
                </div>
              </div>
              <!-- File Upload Section -->
              <div *ngIf="showFileUploadSection" class="row mt-2">
                <div
                  class="col-md-4"
                  *ngFor="let task of stages[0].tasks | slice : 4"
                >
                  <label class="small">{{ task.name }}</label>
                  <input
                    type="file"
                    (change)="onFileSelected($event, task.route)"
                    id="{{ task.route }}"
                    name="{{ task.route }}"
                    nbInput
                  />
                  <div class="row mt-2 justify-content-center">
                    <button
                      *ngIf="fileData[task.route]"
                      nbButton
                      outline
                      status="success"
                      (click)="uploadDocument(task.route)"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <button nbButton nbStepperPrevious>prev</button>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2" align="right">
                  <button nbButton status="info" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </nb-step>

          <!-- Data Understanding Step -->

          <nb-step id="gather-data" label="Data Understanding">
            <form (ngSubmit)="uploadCSV()">
              <div class="row mt-2">
                <div class="col-md-11">
                  <label class="small">Upload CSV File</label>
                  <input
                    type="file"
                    (change)="onFileSelectedData($event)"
                    nbInput
                  />
                </div>
                <div class="col-md-1 mt-3">
                  <nb-icon
                    icon="info-outline"
                    nbTooltip="Upload CSV file"
                    nbTooltipStatus="primary"
                  ></nb-icon>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <button nbButton nbStepperPrevious>prev</button>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2" align="right">
                  <button nbButton status="primary" type="submit">
                    Upload CSV
                  </button>
                </div>
              </div>
            </form>
          </nb-step>

          <!-- Data Preparation Step -->
          <nb-step
            id="prepare-data"
            [stepControl]="dataPreparationForm"
            label="Data Preparation"
          >
            <form
              [formGroup]="dataPreparationForm"
              (ngSubmit)="onSubmit('dataPreparationForm', stepper)"
            >
              <ngx-select-clean></ngx-select-clean>

              <div class="row mt-2">
                <div class="col-md-2">
                  <button nbButton nbStepperPrevious>prev</button>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2" align="right">
                  <button nbButton status="info" type="submit">next</button>
                </div>
              </div>
            </form>
          </nb-step>

          <!-- Modeling Step -->
          <nb-step id="modeling" [stepControl]="modelingForm" label="Modeling">
            <form
              [formGroup]="modelingForm"
              (ngSubmit)="onSubmit('modelingForm', stepper)"
            >
              <div *ngFor="let task of stages[3].tasks">
                <div class="row mt-2">
                  <div class="col-md-11">
                    <label class="small">{{ task.name }}</label>
                    <input
                      fieldSize="large"
                      formControlName="{{ task.route }}"
                      fullWidth
                      id="{{ task.route }}"
                      name="{{ task.route }}"
                      nbInput
                      placeholder="{{ task.name }}"
                      type="text"
                    />
                  </div>
                  <div class="col-md-1 mt-3">
                    <nb-icon
                      icon="info-outline"
                      nbTooltip="{{ task.name }}"
                      nbTooltipStatus="primary"
                    ></nb-icon>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <button nbButton nbStepperPrevious>prev</button>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2" align="right">
                  <button nbButton status="info" type="submit">next</button>
                </div>
              </div>
            </form>
          </nb-step>

          <!-- Evaluation Step -->
          <nb-step
            id="evaluation"
            [stepControl]="evaluationForm"
            label="Evaluation"
          >
            <form
              [formGroup]="evaluationForm"
              (ngSubmit)="onSubmit('evaluationForm', stepper)"
            >
              <div *ngFor="let task of stages[4].tasks">
                <div class="row mt-2">
                  <div class="col-md-11">
                    <label class="small">{{ task.name }}</label>
                    <input
                      fieldSize="large"
                      formControlName="{{ task.route }}"
                      fullWidth
                      id="{{ task.route }}"
                      name="{{ task.route }}"
                      nbInput
                      placeholder="{{ task.name }}"
                      type="text"
                    />
                  </div>
                  <div class="col-md-1 mt-3">
                    <nb-icon
                      icon="info-outline"
                      nbTooltip="{{ task.name }}"
                      nbTooltipStatus="primary"
                    ></nb-icon>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <button nbButton nbStepperPrevious>prev</button>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2" align="right">
                  <button nbButton status="info" type="submit">next</button>
                </div>
              </div>
            </form>
          </nb-step>

          <!-- Deployment Step -->
          <nb-step
            id="deployment"
            [stepControl]="deploymentForm"
            label="Deployment"
          >
            <form
              [formGroup]="deploymentForm"
              (ngSubmit)="onSubmit('deploymentForm', stepper)"
            >
              <div *ngFor="let task of stages[5].tasks">
                <div class="row mt-2">
                  <div class="col-md-11">
                    <label class="small">{{ task.name }}</label>
                    <input
                      fieldSize="large"
                      formControlName="{{ task.route }}"
                      fullWidth
                      id="{{ task.route }}"
                      name="{{ task.route }}"
                      nbInput
                      placeholder="{{ task.name }}"
                      type="text"
                    />
                  </div>
                  <div class="col-md-1 mt-3">
                    <nb-icon
                      icon="info-outline"
                      nbTooltip="{{ task.name }}"
                      nbTooltipStatus="primary"
                    ></nb-icon>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <button nbButton nbStepperPrevious>prev</button>
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2" align="right">
                  <button nbButton status="info" type="submit">next</button>
                </div>
              </div>
            </form>
          </nb-step>

          <!-- Completed Step -->
          <nb-step id="completed" [hidden]="true" label="Completed">
            <div align="center" class="step-container">
              <nb-alert class="w-50 mt-3" status="success">
                <span class="text-center">
                  Project Completed Successfully!
                </span>
              </nb-alert>
              <button (click)="stepper.reset()" nbButton>
                Restart the process
              </button>
            </div>
          </nb-step>
        </nb-stepper>
      </nb-card-body>
    </nb-card>
  </div>
</div>
