import {Comment} from './column.model';

export class Task {
  id: string;
  title: string;
  description: string;
  type: Type;
  sprintId: string;
  assignees: string[];
  priority: Priority;
  startDate: Date;
  dueDate: Date;
  createdAt: Date;
  updatedAt: Date;
  comments: Comment[];
  checklists: Checklist[];
  subTasks: string[];
}

export enum Type {
  STORY,
  TASK,
  ISSUE,
}
export enum Priority {
  LOW,
  MEDIUM,
  HIGH,
}

export class Checklist {
  id: string;
  name: string;
  taskId: string;
  items: Item[];
  progress: number;
}
export class Item {
  id: string;
  name: string;
  status: boolean;
  checklistId: string;
}
