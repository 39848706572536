<nb-card accent="info">
    <nb-card-header>
      <div class="row">
        <div class="col-md-11">
          <h3>CRISP DM Process Details</h3>
        </div>
        <div class="col-md-1">
          <a nbTooltip="Back to project overview" nbTooltipStatus="primary" shape="round" status="basic" (click)="navigateBackToProjectList()" nbButton>
            <nb-icon icon="corner-up-right-outline"></nb-icon>
          </a>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <nb-tabset class="tabset-container" fullWidth>
        <nb-tab tabTitle="Business Understanding" tabIcon="pie-chart-outline" responsive>
          <ngx-dm-project-plan></ngx-dm-project-plan>
      </nb-tab>
      <nb-tab tabTitle="Raw Data" tabIcon="book-outline" responsive>
        <ngx-data-understanding></ngx-data-understanding>
      </nb-tab>
      <nb-tab tabTitle="Data Description" tabIcon="book-outline" responsive>
        <ngx-describe-data></ngx-describe-data>
      </nb-tab>
      
      </nb-tabset>
    </nb-card-body>
</nb-card>