<nb-card>
  <nb-card-header style="padding:1%">
    <div class="d-flex justify-content-between align-items-center">
                    <textarea class="checklist-name form-control"
                              [ngClass]="{
                              'card-name-light-mode': themeService.currentTheme === 'default' || themeService.currentTheme === 'corporate',
                              'card-name-dark-mode': themeService.currentTheme === 'dark' || themeService.currentTheme === 'cosmic'}"
                              cdkTextareaAutosize
                              [(ngModel)]="list.name"
                              (blur)="onChecklistNameChange($event, list.id)"
                              [rows]="1">
                    </textarea>
      <nb-icon icon="trash-2-outline" style="margin-right:3%" class="card-options" (click)="deleteConfirm(listDialog)"></nb-icon>
    </div>

  </nb-card-header>
  <nb-card-body>
    <nb-progress-bar
      [value]="list.progress? Math.round((list.progress * 100) / list.items.length) : 0"
      [displayValue]="true"
      status="primary"
      size="tiny">
    </nb-progress-bar>
    <br>
    <nb-icon icon="plus-square-outline" class="card-options" (click)="onAddItem('new Item',task.id,list.id)"></nb-icon>
    <span>&nbsp;Add an item here</span>
    <div *ngFor="let i of list.items" class="mt-2 d-flex justify-content-between">
      <nb-checkbox [checked]="i.status" status="basic" (checkedChange)="toggle($event, i)">
      </nb-checkbox>
      <textarea class="item-name form-control" nbInput fullWidth fieldSize="small"
                cdkTextareaAutosize
                [(ngModel)]="i.name"
                (blur)="onItemNameChange($event, i)"
                [rows]="1">
        </textarea>
      <nb-icon icon="close-square-outline" style="margin-right:3%" class="card-options" (click)="deleteConfirm(itemDialog)"></nb-icon>
      <ng-template #itemDialog let-data let-ref="dialogRef">
        <nb-card>
          <nb-card-header>Confirm your action</nb-card-header>
          <nb-card-body>{{ data }}</nb-card-body>
          <nb-card-footer>
            <div class="w-100 d-flex justify-content-between ">
              <button nbButton status="primary" (click)="ref.close(); onItemDelete(i)">Confirm</button>
              <button nbButton (click)="ref.close()">Cancel</button>
            </div>
          </nb-card-footer>
        </nb-card>
      </ng-template>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #listDialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirm your action</nb-card-header>
    <nb-card-body>{{ data }}</nb-card-body>
    <nb-card-footer>
      <div class="w-100 d-flex justify-content-between ">
        <button nbButton status="primary" (click)="ref.close(); onChecklistDelete()">Confirm</button>
        <button nbButton (click)="ref.close()">Cancel</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

