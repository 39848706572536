import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../../services/notification/notification.service';
import {Notifications} from '../../../models/Notifications';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-notificaton',
  templateUrl: './notificaton.component.html',
  styleUrls: ['./notificaton.component.scss'],
})
export class NotificatonComponent implements OnInit {
  // notifications: Notifications = new Notifications();
  notifications: Notifications[] = []; // Use the correct type here
  private numero: any;

  constructor(private router: Router, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getAllNotifications();
  }
  getAllNotifications() {
    this.notificationService.getAllNotif().subscribe(
      (data: Notifications[]) => {
        this.notifications = data;
        console.log('data' , data);
        },
      (error) => {
        console.error(error);
      },
    );
  }

  onDeleteAllConfirm( ) {
    this.notificationService.deleteAll().subscribe(
      () => {
        this.router.navigate(['/projectManagement/calendar']).then(() => {
          console.log('Navigated to Calendar after deletion.');
        });
        console.log('All notifications deleted successfully.');
      },
      (error) => {
        console.error('Error deleting notifications:', error);
      },
    );
    window.location.reload();

  }


}
